import * as QP from "shared-lib/query-product";
import * as Attributes from "../shared/attributes";

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes
): boolean {
  return Attributes.matchAttribute("PROP-duct-connection-type-MULTI", searchAttributes, variantAttributes);
}
