import * as Types from "../types";
import { Input } from "./types";
import { calculate } from "./calculator";
import * as ResultTypes from "../result-items-types";
import * as Messages from "../messages";

const source = "CentrifugalFanSelector";
export async function select(input: Input): Promise<Types.CalculatorResult<ResultTypes.CentrifugalFan>> {
  const { airFlow } = input;

  if (!airFlow) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing(source)]);
  }

  const calculationResult = await calculate(input);
  if (calculationResult.type === "CalculatorError") {
    return calculationResult;
  }

  return calculationResult;
}
