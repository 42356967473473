import { createSelector, Selector } from "reselect";
import { showAdvancedSelector } from "client-lib/user-settings";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import { RootState } from "../../types";
import { ViewerDiagramContainerComponent, OwnProps, StateProps } from "./container-component";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  showAdvancedSelector,
  (showAdvanced) => ({
    showAdvanced: showAdvanced,
  })
);

const mapPropsToQuery = (): QD.DiaqMapQuery<unknown> => QD.createMapQuery({});

export const ViewerDiagramContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(ViewerDiagramContainerComponent);
