import React from "react";

export interface DispatchProp<TActionUnionType> {
  readonly dispatch: Dispatch<TActionUnionType>;
}

export interface Dispatch<TActionUnionType> {
  (action: TActionUnionType): void;
}

/** @description Helper function that does prevent defaults before dispatching
 * @param actionCreator: The action creator function
 * @param dispatch: The dispatch function
 */
export function dispatchForButton<TActionUnionType>(
  actionCreator: () => TActionUnionType,
  dispatch: Dispatch<TActionUnionType>
): React.EventHandler<React.MouseEvent<HTMLButtonElement>> {
  return function onLoginButtonClick(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    event.stopPropagation();
    dispatch(actionCreator());
  };
}
