/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import * as React from "react";
import * as SC from "shared-lib/system-calculator";
import { OctaveBandsTable, OctaveBandsTableTd, OctaveBandsTableTh, Heading3 } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import { isAllDefined } from "shared-lib/utils";
import * as FanSound from "shared-lib/system-calculator/shared/fan-sound";
import { VisualizerOwnProps } from "../types";
import * as RWS from "../shared";

export type Props = VisualizerOwnProps;

export function OctaveBandsTableHruVisualizerContainerComponent({
  visualizerParams,
  products,
  translate,
}: Props): React.ReactElement<Props> {
  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const allResultItemMaps = products.map((p) => p.resultItemMap);
  const allResults = allResultItemMaps.map(
    (resultItemMap) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (R.values(resultItemMap)[0].value as any) as {
        [key: string]: SC.OctaveBands3rd;
      }
  );

  const items = visualizerParams.split(",");
  const itemsInResult = allResults[0] || {};
  const rows = items.filter((i) => itemsInResult[i]);
  if (rows.length === 0) {
    return <span />;
  }

  const singleProduct = products.length === 1;

  return (
    <div>
      <OctaveBandsTable equalWidths={true}>
        {singleProduct ? (
          <colgroup key={"single"}>
            <col key={"single_label"} />
            {R.range(0, 8).map((idx) => (
              <col key={`single_band_${idx}`} width={"8%"} />
            ))}
            <col key={"db"} width={"4%"} />
            <col key={`single_band_total`} width={"8%"} />
            <col key={"dba"} width={"6%"} />
          </colgroup>
        ) : (
          <colgroup key={"multi"}>
            <col key={"label"} className="w-1/12" />
            {products.map((_p, pIdx) => (
              <React.Fragment key={pIdx}>
                {R.range(0, 12).map((idx) => (
                  <col key={`band_${idx}`} />
                ))}
              </React.Fragment>
            ))}
            <col key={"empty"} className="w-1/12" />
          </colgroup>
        )}
        <thead>
          <tr>
            <HeaderFreqBands translate={translate} numProducts={products.length} />
            {!singleProduct ? <OctaveBandsTableTh /> : null}
          </tr>
        </thead>
        <tbody>
          {rows.map((i) => (
            <OctaveBandsTableRow
              key={i}
              label={translate(Texts.createText(i))}
              rawOctaveBands3rd={allResults.map((results) => results[i])}
            />
          ))}
        </tbody>
      </OctaveBandsTable>
    </div>
  );
}

function OctaveBandsTableRow({
  label,
  rawOctaveBands3rd,
}: {
  readonly label: string;
  readonly rawOctaveBands3rd: ReadonlyArray<SC.OctaveBands3rd | undefined>;
}): React.ReactElement<{}> {
  const octaveBands = rawOctaveBands3rd.map(
    (bands) => bands && (FanSound.calcOctaveBandsFrom3rds(bands) as SC.OctaveBands)
  );
  const octaveBands3rdA = rawOctaveBands3rd.map(
    (bands) => bands && (FanSound.aWeightOctaveBands3rd(bands) as SC.OctaveBands3rd)
  );

  return (
    <OctaveBandsTableRowValues
      label={label}
      octaveBandValues={octaveBands.map((octaveBand, idx) => [
        octaveBand?.hz63,
        octaveBand?.hz125,
        octaveBand?.hz250,
        octaveBand?.hz500,
        octaveBand?.hz1000,
        octaveBand?.hz2000,
        octaveBand?.hz4000,
        octaveBand?.hz8000,
        "dB",
        FanSound.calcTotFromOctaveBands3rd(octaveBands3rdA[idx]),
        "dB(A)",
      ])}
    />
  );
}

function OctaveBandsTableRowValues({
  label,
  octaveBandValues,
}: {
  readonly label: string;
  readonly octaveBandValues: ReadonlyArray<ReadonlyArray<number | string | undefined>>;
}): React.ReactElement<{}> {
  const singleProduct = octaveBandValues.length === 1;
  return (
    <tr>
      <OctaveBandsTableTd colSpan={singleProduct ? 1 : 2}>{label}</OctaveBandsTableTd>
      {...R.unnest(
        octaveBandValues.map((values, idx) => [
          !singleProduct && idx !== 0 ? <OctaveBandsTableTd key={`empty_${idx}`} /> : null,
          ...values.map((cellValue, cellIdx) => <SoundCell key={`${idx}_${cellIdx}`} sound={cellValue} />),
        ])
      )}
      {!singleProduct ? <OctaveBandsTableTd /> : null}
    </tr>
  );
}

function SoundCell({ sound }: { readonly sound: number | string | undefined }): React.ReactElement<{}> {
  if (typeof sound === "string") {
    return <OctaveBandsTableTd textAlign="left">{sound}</OctaveBandsTableTd>;
  } else {
    return <RWS.SoundCell sound={sound} />;
  }
}

function HeaderFreqBands({
  translate,
  numProducts,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly numProducts: number;
}): React.ReactElement<{}> {
  const headings = ["63", "125", "250", "500", "1k", "2k", "4k", "8k"];
  if (numProducts === 1) {
    return (
      <>
        <OctaveBandsTableTh>
          <Heading3>{translate(Texts.sound_power_level())}</Heading3>
        </OctaveBandsTableTh>
        {headings.map((text) => (
          <OctaveBandsTableTh key={text} textAlign="center">
            {text}
          </OctaveBandsTableTh>
        ))}
        <OctaveBandsTableTh />
        <OctaveBandsTableTh textAlign="center">{translate(Texts.total())}</OctaveBandsTableTh>
        <OctaveBandsTableTh />
      </>
    );
  } else {
    return (
      <>
        <OctaveBandsTableTh colSpan={2}>
          <Heading3>{translate(Texts.sound_power_level())}</Heading3>
        </OctaveBandsTableTh>
        {R.range(0, numProducts).map((idx) => (
          <React.Fragment key={idx}>
            {idx !== 0 ? <OctaveBandsTableTh /> : null}
            {headings.map((text) => (
              <OctaveBandsTableTh key={text} textAlign="center">
                {text}
              </OctaveBandsTableTh>
            ))}
            <OctaveBandsTableTh colSpan={3} textAlign="center">
              {translate(Texts.total())}
            </OctaveBandsTableTh>
          </React.Fragment>
        ))}
      </>
    );
  }
}
