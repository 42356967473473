import * as React from "react";
import { DispatchProp } from "client-lib/redux-integration";
import * as Texts from "shared-lib/language-texts";
import { PropertyValueSet, PropertyValue, PropertyFilter } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import * as UserSettingsClient from "client-lib/user-settings";
import * as UserSettingsShared from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as SC from "shared-lib/system-calculator";
import { InputParam } from "shared-lib/system-calculator";
import { Amount } from "uom";
import { Button } from "client-lib/elements";
import {
  PropertiesSelectorMultiContainer,
  selectionsFromVariant,
  getVariants,
} from "containers/properties-selector-multi";
import { AnyQuantity, customUnits } from "shared-lib/uom";

type Props = OwnProps & StateProps & Response & DispatchProp<UserSettingsClient.Action>;

export type PropertyValuesChanged = (propertyValueSet: PropertyValueSet.PropertyValueSet) => void;
export type Calculate = (propertyValueSet: PropertyValueSet.PropertyValueSet) => Amount.Amount<AnyQuantity>;

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly productId: string;
  readonly translate: Texts.TranslateFunction;
  readonly params: ReadonlyArray<InputParam>;
  readonly paramValues: PropertyValueSet.PropertyValueSet;
  readonly paramsChanged: PropertyValuesChanged;
  readonly calculate: Calculate;
  readonly hideErrors: boolean;
  readonly previewParam: string;
  readonly resultParams: ReadonlyArray<string>;
  readonly roundUpResult?: boolean;
}

export interface StateProps {
  readonly showAdvanced: boolean;
  readonly userSettings: UserSettingsShared.State;
}

export interface Response {
  readonly productTables: ProductTables;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
}

export interface ProductTables {
  readonly ct_ClosedGroups: QP.ClosedGroups;
}

export function CalculatorComponent({
  market,
  language,
  productId,
  productTables,
  params,
  paramValues,
  ct_MarketUnits,
  userSettings,
  translate,
  paramsChanged,
  calculate,
  hideErrors,
  previewParam,
  resultParams,
  roundUpResult,
}: Props): JSX.Element {
  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });

  const hideInvalidValues = false;
  const closedGroups = UserSettingsShared.getClosedGroups(productTables.ct_ClosedGroups, userSettings);
  const previewInputParam = params.find((p) => p.name === previewParam);
  const groupName = previewInputParam !== undefined ? previewInputParam.group : "";
  const showApplyButton = !closedGroups[groupName];
  const shouldHideErrors = hideErrors || !showApplyButton;

  const properties: ReadonlyArray<PromasterPropertiesSelector.Property> = params.map((p) => {
    if (p.type === "Amount") {
      return {
        selector_type: p.selectorType as PromasterPropertiesSelector.PropertySelectorType,
        sort_no: -(params.length - params.indexOf(p)),
        name: p.name,
        group: p.group,
        quantity: p.quantity,
        validation_filter: shouldHideErrors ? PropertyFilter.Empty : p.validationFilter || PropertyFilter.Empty,
        visibility_filter: PropertyFilter.Empty,
        value: [],
        field_name: p.fieldName,
      };
    } else {
      return {
        selector_type: p.selectorType as PromasterPropertiesSelector.PropertySelectorType,
        sort_no: -(params.length - params.indexOf(p)),
        name: p.name,
        group: p.group,
        quantity: "Discrete",
        validation_filter: shouldHideErrors ? PropertyFilter.Empty : p.validationFilter || PropertyFilter.Empty,
        visibility_filter: PropertyFilter.Empty,
        value: p.values.map((v) => ({
          sort_no: v.value,
          value: PropertyValue.fromInteger(v.value),
          property_filter: PropertyFilter.Empty,
        })),
      };
    }
  });

  const fieldNames = params
    .filter((p) => p.type === "Amount")
    .map((p) => {
      if (p.type === "Amount") {
        return {
          property: p.name,
          field_name: p.fieldName,
        };
      } else {
        return {
          property: "",
          field_name: "",
        };
      }
    });

  const propertySelectorTypes = params
    .filter((d) => d.width !== undefined)
    .map((d) => ({
      name: d.name,
      type: d.selectorType || "AmountField",
      width: d.width || 49,
    })) as QP.PropertySelectorTypesTable;

  const previewParamQuantity = (params.find(
    (p) => p.type === "Amount" && p.name === previewParam
  ) as SC.AmountInputParam).quantity;
  const calculateResult = (pvs: PropertyValueSet.PropertyValueSet): Amount.Amount<AnyQuantity> => {
    const result = calculate(pvs);
    const fieldName = fieldNames.find((fn) => fn.property === previewParam);
    if (roundUpResult === true && fieldName && previewParamQuantity) {
      // Round up depending on what unit is select for the preview field.
      const unit = getUnit(fieldName.field_name, previewParamQuantity);
      const airflow = Amount.valueAs(unit, result);
      const rounded = Math.ceil(airflow);
      return Amount.create(rounded, unit);
    } else {
      return result;
    }
  };

  return (
    <div>
      <PropertiesSelectorMultiContainer
        translate={translate}
        market={market}
        language={language}
        productId={productId}
        hideProperties={[]}
        selections={selectionsFromVariant(paramValues)}
        selectionsChanged={(s) => {
          const p = getVariants(s)[0];
          const newValue = verifyFilters(params, p) ? calculateResult(p) : Amount.create(0, customUnits.One);
          const newParams = PropertyValueSet.setAmount(previewParam, newValue, p);
          paramsChanged(newParams);
        }}
        hideInvalidValues={hideInvalidValues}
        translatePropertyName={(propertyName: string) => translate(Texts.createText(propertyName))}
        translatePropertyValue={(propertyName: string, propertyValue: number) =>
          translate(Texts.createText(propertyName + "_" + propertyValue.toString()))
        }
        propertySelectorTypes={propertySelectorTypes}
        properties={properties}
        propertyFieldNames={fieldNames}
        hideEmptyUnitSelectors={true}
      />
      {showApplyButton ? (
        <div className="pl-20 pr-20 mt-16">
          <p className="text-xs italic">{translate(Texts.ventilation_calculator_note())}</p>
          <div className="inline-flex">
            <Button
              clicked={() => {
                const result = calculateResult(paramValues);
                let newParams = paramValues;
                resultParams.forEach((name) => (newParams = PropertyValueSet.setAmount(name, result, newParams)));
                paramsChanged(newParams);
              }}
              label={translate(Texts.apply())}
              disabled={!verifyFilters(params, paramValues)}
            />
          </div>
        </div>
      ) : undefined}
    </div>
  );
}

function verifyFilters(params: ReadonlyArray<InputParam>, pvs: PropertyValueSet.PropertyValueSet): boolean {
  const areValuesValid = params.reduce((sofar, current) => {
    const isValid =
      current.validationFilter !== undefined ? PropertyFilter.isValid(pvs, current.validationFilter) : true;
    return isValid && sofar;
  }, true);
  return areValuesValid;
}
