import * as UserSettingsShared from "shared-lib/user-settings";
import * as C from "shared-lib/calculation";
import * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly m3ItemNo: string;
  readonly variant: string | undefined;
  readonly propsConfig: C.ItemConfig | undefined;
  readonly stateConfig: C.ItemConfig;
  readonly userSettings: UserSettingsShared.State;
}

export interface Response {
  readonly productId: string;
  readonly productTables: ProductTables;
  readonly metaTables: MetaTables;
  readonly translateTables: Texts.TranslationTables;
  readonly calculationResponse: C.Response;
  readonly accessoryTables: AccessoryTables;
}

export interface AccessoryTables {
  readonly [productId: string]: ProductTables;
}

export interface MetaTables {
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_ResultViews: QP.ResultViewsTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly ct_ResultVisualizerParamsTable: QP.ResultVisualizerParamsTableTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
  readonly ct_MarketHiddenFields: QP.MarketHiddenFieldsTable;
}

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly code: QP.CodeTable;
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_CalcParamDefault: QP.CalcParamDefaultTable;
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly ct_Attributes2: QP.AttributesTable;
  readonly ct_AmcaStatements: QP.AmcaStatementsTable;
}

export interface ExternalItem {
  readonly itemNumber: string;
  readonly variant: string;
  readonly items: ReadonlyArray<Item>;
}

export type Item = Alert | Table | Diagram | Base64Diagram;

export interface Alert {
  readonly type: "Error" | "Warning";
  readonly text: string;
}

export interface Table {
  readonly type: "Table";
  readonly columns: ReadonlyArray<string>;
  readonly rows: ReadonlyArray<ReadonlyArray<string>>;
}

export interface Diagram {
  readonly type: "Diagram";
  readonly label: string;
  readonly svg: string;
}

export interface Base64Diagram {
  readonly type: "Base64Diagram";
  readonly label: string;
  readonly format: string;
  readonly data: string;
}

export function createAlertType(type: "error" | "warning", text: string): Alert {
  return {
    type: type === "error" ? "Error" : "Warning",
    text: text,
  };
}

export function isAlert(item: Item): item is Alert {
  return item.type === "Error" || item.type === "Warning";
}
