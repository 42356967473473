import * as React from "react";
import { SearchTableTd, LinkButton, UnitSelector, SearchTableTh, SearchTableTr, withTw } from "client-lib/elements";
import * as UserSettingsClient from "client-lib/user-settings";
import * as UserSettingsShared from "shared-lib/user-settings";
import { ComponentResult } from "shared-lib/search";
import * as Texts from "shared-lib/language-texts";
import { TranslateFunction } from "shared-lib/language-texts";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import * as SC from "shared-lib/system-calculator";
import * as QP from "shared-lib/query-product";
import { Dispatch } from "client-lib/redux-integration";
import * as CUtils from "client-lib/utils";
import { AnyQuantity } from "shared-lib/uom";
import { Quantity, Units } from "uom-units";
import { OutputMapperSuccess } from "shared-lib/system-calculator";
import { FormatNumberFunction, formatNumberFunction } from "shared-lib/utils";

const NoPrint = withTw("div", "print:hidden");
const NoteContainer = withTw("div", "mt-8 w-full text-xs");

export interface ResultTableProps {
  readonly market: string;
  readonly language: string;
  readonly translate: TranslateFunction;
  readonly userSettings: UserSettingsShared.State;
  readonly onClearSavedCalculations: () => void;
  readonly onRemoveSavedCalculation: (id: string) => void;
  readonly dispatch: Dispatch<UserSettingsClient.Action>;
  readonly results: ReadonlyArray<ComponentResult>;
  readonly ct_SavedResultColumns: QP.SavedResultColumnsTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

function CalculationResultNote({ translate }: { readonly translate: TranslateFunction }): React.ReactElement<{}> {
  return (
    <NoteContainer>
      <b>{translate(Texts.frico_note())} </b>
      {translate(Texts.frico_print_note())}
    </NoteContainer>
  );
}

export function CalculationResultTable(props: ResultTableProps): React.ReactElement<ResultTableProps> {
  const {
    results,
    ct_SavedResultColumns,
    translate,
    market,
    language,
    ct_MarketUnits,
    ct_LanguageMapping,
    userSettings,
  } = props;

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });
  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  return (
    <div className="print:overflow-x-visible print:overflow-y-visible">
      <table>
        {renderTableHead(props, translate)}
        <tbody>
          {results.map((r) =>
            renderTableRow(r, props, ct_SavedResultColumns, translate, getUnit, getDecimals, formatNumber)
          )}
        </tbody>
      </table>
      <CalculationResultNote translate={translate} />
    </div>
  );
}

function renderTableHead(props: ResultTableProps, translate: TranslateFunction): React.ReactElement<{}> {
  const { ct_SavedResultColumns } = props;
  let zindex = 9996;
  return (
    <thead>
      <SearchTableTr>
        <SearchTableTh key="product_code">{translate(Texts.name()) + " "}</SearchTableTh>

        {ct_SavedResultColumns.map((c) => renderTableHeadColumn(c, props, translate, --zindex))}

        <SearchTableTh key="remove">
          <NoPrint>
            <LinkButton onClick={() => props.onClearSavedCalculations()}>
              {translate(Texts.remove_all_rows()) + " "}
            </LinkButton>
          </NoPrint>
        </SearchTableTh>
      </SearchTableTr>
    </thead>
  );
}

function renderTableHeadColumn(
  c: QP.SavedResultColumn,
  props: ResultTableProps,
  translate: TranslateFunction,
  zindex: number
): React.ReactElement<{}> {
  const label = translate(Texts.createText(c.language_key));
  return (
    <SearchTableTh style={{ zIndex: zindex }} key={c.result_path}>
      <span title={label}>{label}</span>
      {renderTableHeadColumnUnitSelectorSaved(c, props)}
    </SearchTableTh>
  );
}

function renderTableHeadColumnUnitSelectorSaved(
  c: QP.SavedResultColumn,
  props: ResultTableProps
): React.ReactElement<{}> {
  const { market, userSettings, ct_MarketUnits, dispatch, translate } = props;

  if (props.results.length === 0) {
    return <span />;
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getUnits = UserSettingsShared.getUnits({
    market,
    ct_MarketUnits,
  });

  // Find the aircurtain with most defined results to base the units on
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const validResults = props.results
    .filter((r) => r.results && r.results[Object.keys(r.results)[0]].type === "OutputMapperSuccess")
    .sort(
      (a, b) =>
        Object.keys((b.results[Object.keys(b.results)[0]] as OutputMapperSuccess).result.value).filter(
          (k) => ((b.results[Object.keys(b.results)[0]] as OutputMapperSuccess).result.value as any)[k] !== undefined
        ).length -
        Object.keys((a.results[Object.keys(a.results)[0]] as OutputMapperSuccess).result.value).filter(
          (k) => ((a.results[Object.keys(b.results)[0]] as OutputMapperSuccess).result.value as any)[k] !== undefined
        ).length
    );
  /* eslint-enable @typescript-eslint/no-explicit-any */

  const firstResult = validResults[0];

  const type = Object.keys(firstResult.results)[0];
  const path = type + "." + c.result_path;

  const value = SC.getResultItemValue(type, path, firstResult.results);
  if (!value || typeof value === "string") {
    return <span />;
  }
  const amount = value as Amount.Amount<AnyQuantity>;
  const unit = getUnit(c.field_name || c.language_key, amount.unit.quantity);
  const units = getUnits(c.field_name || c.language_key, amount.unit.quantity);

  return (
    <div className="mr-4 mt-4">
      <UnitSelector
        className="min-w-80"
        unit={unit}
        allowUnitNameOverFlow={true}
        units={units}
        unitChanged={(u) => dispatch(UserSettingsClient.setFieldUnit(c.field_name || c.language_key, u))}
        unitCleared={() => dispatch(UserSettingsClient.clearFieldUnit(c.field_name || c.language_key))}
        useFixedDropdownPos={true}
        noBackgroundColor={true}
        translate={translate}
        small={true}
      />
    </div>
  );
}

function warnWaterPressureDrop(result: SC.ResultItemOutputMap, calcParams: PropertyValueSet.PropertyValueSet): boolean {
  const calculationMethod = PropertyValueSet.getInteger("calcMethodWaterCoil", calcParams);

  if (calculationMethod === undefined || result === undefined) {
    return false;
  }
  const waterPressure = SC.getResultItemAmountValue<Quantity.Pressure>(
    "AirCurtain",
    "AirCurtain.waterPressureDrop",
    result
  );
  if (waterPressure === undefined) {
    return false;
  }

  const waterPressureKpa = Amount.valueAs(Units.KiloPascal, waterPressure);
  return waterPressureKpa > 40 || waterPressureKpa < 1;
}

function renderTableRow(
  result: ComponentResult,
  props: ResultTableProps,
  searchColumns: ReadonlyArray<QP.SavedResultColumn>,
  translate: TranslateFunction,
  getUnit: UserSettingsShared.GetUnitFunction,
  getDecimals: UserSettingsShared.GetDecimalsFunction,
  formatNumber: FormatNumberFunction
): React.ReactElement<{}> | undefined {
  const componentResult = result;
  const warnWaterPressure = warnWaterPressureDrop(result.results, result.calcParams);
  return (
    <tr key={componentResult.id}>
      <SearchTableTd style={{ minWidth: "140px" }}>
        {" "}
        <div>{result.productCode}</div>
      </SearchTableTd>
      {/* <SearchTableTd>{componentResult.m3}</SearchTableTd> */}
      {searchColumns.map((c) => {
        const type = Object.keys(componentResult.results)[0];
        const path = type + "." + c.result_path;
        const value = SC.getResultItemValue(type, path, componentResult.results);

        if (typeof value === "string") {
          return (
            <SearchTableTd key={c.result_path}>{translate(Texts.translatedResultTableValue(value))}</SearchTableTd>
          );
        }

        return (
          <SearchTableTd
            title={
              path === "AirCurtain.waterPressureDrop" && warnWaterPressure === true
                ? translate(Texts.waterpressure_warning())
                : ""
            }
            key={path}
            style={
              path === "AirCurtain.waterPressureDrop" && warnWaterPressure === true
                ? { backgroundColor: "#f3e393" }
                : {}
            }
          >
            {CUtils.renderAmount(value as Amount.Amount<AnyQuantity>, c.field_name || c.result_path || c.language_key, {
              getUnit,
              getDecimals,
              formatNumber,
            })}
          </SearchTableTd>
        );
      })}
      <SearchTableTd>
        <NoPrint>
          <LinkButton onClick={() => props.onRemoveSavedCalculation(componentResult.id)}>
            {translate(Texts.remove()) + " "}
          </LinkButton>
        </NoPrint>
      </SearchTableTd>
    </tr>
  );
}
