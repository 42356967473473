import { CalculatorSuccess, OutputMapperResult, createOutputMapperSuccess } from "../types";
import { ElectricDuctHeater, createElectricDuctHeaterResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<ElectricDuctHeater>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createElectricDuctHeaterResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
