import { PropertyFilter } from "@promaster-sdk/property";
import { retryFetch } from "shared-lib/fetch";
import * as QP from "shared-lib/query-product";

export const tableFromMeasureTool = [
  "ct_Attributes2",
  "ct_SoundData",
  "ct_AirData2",
  "ct_AirLimitsData",
  "ct_DiaqTemplates",
  "code",
  "ct_ItemNo",
  "ct_VariantNo",
  "property",
];

const fetchInit: RequestInit = { mode: "cors", method: "get" };
export interface Response {
  readonly attributes?: ReadonlyArray<{
    readonly attribute: string;
    readonly value: string;
  }>;
  readonly airData?: ReadonlyArray<QP.AirData>;
  readonly airLimitsData?: ReadonlyArray<QP.AirLimitsData>;
  readonly soundData?: ReadonlyArray<QP.SoundData>;
  readonly metaData?: MetaData;
  readonly isActive: boolean;
}

export interface MetaData {
  readonly itemNo: string;
  readonly itemName: string;
  readonly variantNo: string;
  readonly variantName: string;
}

export async function getMeasurementTables(
  variantId: string | undefined,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): Promise<{}> {
  const { isActive, attributes, soundData, airData, airLimitsData, metaData } = await getMeasurement(
    variantId,
    useMeasureToolForMeasurements,
    mtUrl
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mappedTables: any = {};
  if (isActive) {
    const attributesTable = (attributes || []).map(
      (a) =>
        ({
          property_filter: PropertyFilter.Empty,
          collection: "",
          attribute: a.attribute,
          value: a.value,
        } as QP.Attribute)
    );
    const codeTable: QP.CodeTable = [
      {
        property_filter: PropertyFilter.Empty,
        type: "main",
        code: metaData?.itemName || "",
      },
    ];
    const itemNoTable: QP.ItemNoTable = [
      {
        property_filter: PropertyFilter.fromString("model=999999", () => undefined) || PropertyFilter.Empty,
        item_no: metaData?.itemNo || "",
      },
    ];
    const variantNoTable: QP.VariantNoTable = [
      {
        property_filter: PropertyFilter.fromString("variant=999999", () => undefined) || PropertyFilter.Empty,
        variant_no: metaData?.variantNo || "",
      },
    ];
    const propertyTable: QP.PropertyTable = [
      {
        name: "variant",
        group: "main",
        validation_filter: PropertyFilter.Empty,
        visibility_filter: PropertyFilter.Empty,
        quantity: "Discrete",
        def_value: [],
        value: [
          {
            value: {
              type: "integer",
              value: parseInt(metaData?.variantNo || "", 10),
            },
            image: "",
            property_filter: PropertyFilter.Empty,
            description: metaData?.variantName || "",
            translation: [],
            sort_no: 0,
          },
        ],
        translation: [],
        sort_no: 0,
      },
    ];
    const airMeasurement = attributesTable.find((a) => a.attribute === "measurement-number-air")?.value || "n/a";
    const soundMeasurement = attributesTable.find((a) => a.attribute === "measurement-number-sound")?.value || "n/a";
    mappedTables["ct_Attributes2"] = attributesTable;
    mappedTables["ct_SoundData"] = { [soundMeasurement]: soundData || [] };
    mappedTables["ct_AirData2"] = { [airMeasurement]: airData || [] };
    mappedTables["ct_AirLimitsData"] = { [airMeasurement]: airLimitsData || [] };
    mappedTables["code"] = codeTable;
    mappedTables["ct_ItemNo"] = itemNoTable;
    mappedTables["ct_VariantNo"] = variantNoTable;
    mappedTables["property"] = propertyTable;
  } else {
    mappedTables["ct_Attributes2"] = [];
    mappedTables["ct_SoundData"] = {};
    mappedTables["ct_AirData2"] = {};
    mappedTables["ct_AirLimitsData"] = {};
    mappedTables["code"] = [];
    mappedTables["ct_ItemNo"] = [];
    mappedTables["ct_VariantNo"] = [];
    mappedTables["property"] = [];
  }
  return mappedTables;
}

async function getMeasurement(
  variantId: string | undefined,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): Promise<Response> {
  if (!variantId || !useMeasureToolForMeasurements || !mtUrl) {
    return { isActive: false };
  }
  try {
    const response = await retryFetch(`${mtUrl}/${variantId}`, fetchInit);
    const json = await response.json();
    return { ...json, isActive: true };
  } catch (ex) {
    console.log(ex);
  }
  return { isActive: false };
}
