import * as Types from "../types";
import { Input } from "./types";
import { calculate } from "./calculator";
import * as ResultTypes from "../result-items-types";

export async function select(input: Input): Promise<Types.CalculatorResult<ResultTypes.BoxFanNicotra>> {
  const calcParams = input.calcParams;
  const resultInput = { ...input, calcParams };
  // eslint-disable-next-line no-return-await
  return await calculate(resultInput);
}
