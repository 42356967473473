import { createSelector, Selector } from "reselect";
import { diaqConnect } from "client-lib/redux-integration";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import * as UserSettings from "client-lib/user-settings";
import * as C from "shared-lib/calculation";
import { ProductCalculationContainerComponent } from "./container-component";
import { OwnProps, Response, StateProps, ProductProps, ProductResponse, createConfigKey } from "./types";
import { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  UserSettings.stateSelector,
  (userSettings) => ({
    userSettings: userSettings,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    metaTables: QP.tablesByProductId(QP.metaProductId, [
      "ct_ResultItems",
      "ct_ResultViews",
      "ct_MarketUnits",
      "ct_AttributeTemplateMapping",
      "ct_ResultVisualizerParamsTable",
    ]),
    productResponses: productQueries(ownProps, response),
  });
}

function productQueries(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<{}> {
  const map: { [key: string]: QD.DiaqMapQuery<ProductResponse> } = {};
  for (const productProps of ownProps.productsProps) {
    const key = createConfigKey(productProps.productId, productProps.config);
    const productResponse = response?.productResponses[key];
    map[key] = productQuery(productProps, productResponse);
  }
  return QD.createMapQuery(map);
}

function productQuery(
  productProps: ProductProps,
  response: ProductResponse | undefined
): QD.DiaqMapQuery<ProductResponse> {
  return QD.createMapQuery<ProductResponse>({
    productTables: QP.tablesByProductId(productProps.productId, [
      "ct_DiaqTemplates",
      "ct_Accessories",
      "ct_Attributes2",
    ]),
    accessoryTables: accessoryTablesQuery(response),
    codeTables: codeTablesQuery(productProps),
    calculationResponse: C.calculationQuery(productProps, response && response.calculationResponse),
  });
}

function accessoryTablesQuery(response: ProductResponse | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of response.productTables.ct_Accessories) {
    map[accessory.product] = QP.tablesByProductId(accessory.product, [
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_DiaqTemplates",
      "ct_CalcParamDefault",
      "ct_Accessories",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

function codeTablesQuery(ownProps: ProductProps): QD.DiaqMapQuery<{}> {
  const map: { [productId: string]: QP.TablesByProductIdQuery } = {};
  for (const productId of [ownProps.productId, ...ownProps.config.accessories.map((a) => a.productId)]) {
    map[productId] = QP.tablesByProductId(productId, ["property", "code", "ct_ItemNo", "ct_VariantNo"]);
  }
  return QD.createMapQuery(map);
}

export const ProductCalculationContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(ProductCalculationContainerComponent);
