import { EcomCacheState } from "./types";
import {
  VariantNoToPriceQuery,
  VariantNoToPriceResponse,
  ProductDescriptionQuery,
  ProductDescriptionResponse,
} from "../query-types";
import { identifierToKey, productDescriptionsidentifierToKey } from "./functions";

// 12 h cache time
// const maxTime = 12 * 60 * 60 * 1000;

export function selectVariantNoToPrice(
  cache: EcomCacheState,
  query: VariantNoToPriceQuery
): VariantNoToPriceResponse | undefined {
  const entry = cache.variantNoPrices[identifierToKey({ url: query.url, market: query.market })];
  if (!entry) {
    // || Date.now() - entry.time > maxTime) {
    return undefined;
  }
  return entry.prices;
}

export function selectProductDescription(
  cache: EcomCacheState,
  query: ProductDescriptionQuery
): ProductDescriptionResponse | undefined {
  return cache.productDescriptions[
    productDescriptionsidentifierToKey({
      url: query.url,
      market: query.market,
      language: query.language,
      itemNo: query.itemNo,
      variantId: query.variantId,
    })
  ];
}
