import * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import { Curve } from "shared-lib/system-calculator";
import { splineGetPoint } from "shared-lib/interpolation";
import * as CI from "./calculation-inputs";
import * as FEI from "../fan-efficiency-index";

export interface MaxEfficiencyPoint {
  readonly airFlow: Amount.Amount<Quantity.VolumeFlow>;
  readonly pressure: Amount.Amount<Quantity.Pressure>;
}



// Find between what airflows the FEI >= X
const FeiMin = 0.99;
export function createFeiCurves(airData: ReadonlyArray<QP.AirData>): ReadonlyArray<Curve> {
  console.log("FEI");
  const pressureCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Pascal,
    airData.filter((p) => p.param === "Ps_v"),
    [],
 
  );

  const powerCurves = CI.createDataPointCurves(
    Units.LiterPerSecond,
    Units.Watt,
    airData.filter((p) => p.param === "P_v"),
    [],

  );

  if (pressureCurves.length === 0 || powerCurves.length === 0) {
    return [];
  }

  const precision = 100;
  const result: Array<Curve> = [];
  for(const pressureCurve of pressureCurves){

    const powerCurve = powerCurves.find((pc) => pc.id === pressureCurve.id);
    if(!powerCurve){
      continue;
    }

    // for each curve
  // Start from workmin, increment workmin, as soon as FEI >= 1 stop and return point -> feiMinAirflow
  // Start from workmax, decrement workmax, as soon as FEI >= 1 stop and return point -> feiMaxAirflow

 
  let xeMin = 0;
  let xeMax = 0;
 
  const step = pressureCurve.workMax - pressureCurve.workMin;

  for (let i = 0; i <= precision; i++) {
    const x = pressureCurve.workMin + (i * step) / precision;
    const e = calculateFei(x, pressureCurve, powerCurve);
    if (!e) {
      continue;
    }
    if (e >= FeiMin) {
      xeMin = x;
      break;
    }
  }

  for (let i = 0; i <= precision; i++) {
    const x = pressureCurve.workMax - (i * step) / precision;
    const e = calculateFei(x, pressureCurve, powerCurve);
    if (!e) {
      continue;
    }
    if (e >= FeiMin) {
      xeMax = x;
      break;
    }
  }

  if(xeMax !== undefined && xeMin !== undefined){
    result.push({...pressureCurve, workMin: xeMin, workMax: xeMax})
  }


  }

return result;

  



  


  




 
}

function calculateFei(x: number, pressureCurve: Curve, powerCurve: Curve): number | undefined {
  const pressure = calculatePressureForAirflow(x, pressureCurve);
  const power = calculatePowerForAirflow(x, powerCurve);

  if(pressure === undefined || power === undefined){
    return undefined;
  }

  return FEI.fanEfficiencyIndexWireToAir(
    "static",
     1.204, // SEND IN
    x * 0.001, // Should be m3/s
    pressure, // Should be Pa
    power/1000 // SHOULD BE KW
  );
  
}

function calculatePressureForAirflow(x: number, pressureCurve: Curve): number | undefined {
  return splineGetPoint(x, pressureCurve.spline);
}
function calculatePowerForAirflow(x: number, powerCurve: Curve): number | undefined {
  return splineGetPoint(x, powerCurve.spline);
}
