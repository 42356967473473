/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Redux from "redux";
import * as Actions from "./actions";
import { LoadAtomicQueries, AnyQueryStoreState, AnyStoreState, AnyCacheState, AnyQuery } from "./types";
// import { loadQueriesInternal } from "./functions";

const debouncedLoadAllQueuedQueries = debounce(undefined, loadAllQueuedQueries, 50);

export function createQueryMiddleware(
  loadQueries: LoadAtomicQueries<AnyCacheState, AnyQuery>,
  storeKey: string | undefined
): any /* Redux.Middleware */ {
  return (store: Redux.Store<AnyStoreState>) => (next: Redux.Dispatch<AnyStoreState>) => (action: Actions.Action) => {
    // Run everything before becuase we want the state to be updated
    const responseToReturn = next(action);
    const state = getStateFromStore(store, storeKey);

    switch (action.type) {
      case "redux-query/QUEUE_QUERIES":
        if (state.currentlyLoadingQueries.length === 0) {
          debouncedLoadAllQueuedQueries(state, loadQueries, store.dispatch);
        }
        break;
      case "redux-query/LOAD_COMPLETED":
        if (state.queudQueries.length > 0) {
          debouncedLoadAllQueuedQueries(state, loadQueries, store.dispatch);
        }
        break;
      default:
        break;
    }

    return responseToReturn;
  };
}

function getStateFromStore(store: Redux.Store<AnyStoreState>, storeKey: string | undefined): AnyQueryStoreState {
  const storeState = storeKey ? store.getState()[storeKey] : store.getState();
  return storeState;
}

function loadAllQueuedQueries(
  state: AnyQueryStoreState,
  loadQueries: LoadAtomicQueries<AnyCacheState, AnyQuery>,
  dispatch: Redux.Dispatch<AnyStoreState>
): void {
  // Get all unresolved queries
  const unresolvedQueries = state.queudQueries;
  if (unresolvedQueries.length > 0) {
    // Some queries could not be resolved with the current cache, start new cache loading
    dispatch(Actions.loadStarted());
    loadQueries(state.cache, unresolvedQueries)
      .then((updatedCache) => dispatch(Actions.loadCompleted(updatedCache)))
      .catch((e: Error) => {
        throw e;
      });
  }
}

// (From underscore.js)
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds.

function debounce(_this: any, func: Function, wait: number): any {
  let timeout: number | undefined;

  return function (): void {
    const args = arguments;

    const later = function (): void {
      timeout = undefined;
      func.apply(_this, args);
    };

    if (timeout) {
      window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(later, wait);
  };
}
