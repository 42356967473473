/* eslint-disable @typescript-eslint/no-explicit-any */
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import {
  AnyQuery,
  AnyCacheState,
  AnyResponse,
  LoadAtomicQueries,
  SelectComposedQueryResult,
  SelectAtomicQuery,
} from "./types";
import { ComposedQuery } from "./query-types";

/**
 * Resolves a query either from cache or by loading it.
 * Returns a boolean indicating if there were any undefined queries,
 * which means that all queries could not be defined with the
 * available response so the creator of the queries should be called again
 * with the new response.
 * Useful on the server-side.
 * @param cache A cache.
 * @param query A query.
 * @param selectQueries The selectQueries function.
 * @param loadQueries  The loadQueries function.
 */
export async function resolveQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse>(
  cache: AnyCacheState,
  composedQuery: ComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse> | undefined,
  selectQueries: SelectAtomicQuery<AnyCacheState, AnyQuery, AnyResponse>,
  loadQueries: LoadAtomicQueries<AnyCacheState, AnyQuery>
  // eslint-disable-next-line functional/prefer-readonly-type
): Promise<[AnyResponse, AnyCacheState, boolean]> {
  // Try selecting from cache first
  const result = selectComposedQuery(selectQueries, cache, composedQuery);
  if (result.unresolvedQueries.length === 0) {
    return [result.response, cache, result.undefinedQueriesCount > 0];
  }
  const newCache = await loadQueries(cache, result.unresolvedQueries);
  const newResult = selectComposedQuery(selectQueries, newCache, composedQuery);
  return [newResult.response, newCache, newResult.undefinedQueriesCount > 0];
}

/**
 * Selects a composed query from the cache.
 * @param selectAtomicQuery A function that can select atomic queries.
 * @param cache The cache.
 * @param composedQuery The composed query to select.
 * @param level Internal parameter to constrain recursion level.
 */
export function selectComposedQuery<TAtomicQuery extends AnyQuery, TAtomicResponse, TComposedResponse>(
  selectAtomicQuery: SelectAtomicQuery<AnyCacheState, TAtomicQuery, TAtomicResponse>,
  cache: AnyCacheState,
  composedQuery: ComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse> | undefined,
  level: number = 0
): SelectComposedQueryResult<TAtomicQuery, TAtomicResponse> {
  // Fail-safe for recursion
  if (level > 10) {
    throw new Error("Query recursed more than 10 levels");
  }

  // Undefined queries will resolve to undefined responses
  // This is useful becuase unresolved queries means that the client that constructed
  // the query did not have enough information to construct it so it needs to be called again
  // with partial results
  if (composedQuery === undefined) {
    return {
      response: undefined,
      unresolvedQueries: [],
      undefinedQueriesCount: 1,
    };
  }

  switch (composedQuery.type) {
    case "@@ComposedQuery/Map": {
      if (!composedQuery.map) {
        throw new Error("map property need to be set on a MapQuery!");
      }
      if (Array.isArray(composedQuery.map)) {
        //throw new Error("map property cannot be an array on a MapQuery!");
        console.error("map property cannot be an array on a MapQuery!");
      }
      // This happens sometimes so good to have a message for it

      if (
        (composedQuery.map as any)["type"] &&
        ((composedQuery.map as any)["type"] as any).substring(0, "@@Intrinsic".length) === "@@Intrinsic"
      ) {
        throw new Error(
          "A MapQuery cannot have another query as a direct child! The query was: " + JSON.stringify(composedQuery)
        );
      }

      const mapQueryResponses: { [queryName: string]: AnyResponse | undefined } | undefined = {};
      let allUnresolved: Array<AnyResponse> = [];
      let allUndefinedCount: number = 0;
      for (const key of Object.keys(composedQuery.map)) {
        const keyQuery = (composedQuery.map as any)[key];

        if (keyQuery === undefined) {
          // eslint-disable-next-line operator-assignment
          allUndefinedCount = allUndefinedCount + 1;
        } else if (isComposedQuery(keyQuery)) {
          // Recurse for the composed query
          const {
            response: queryResponse,
            unresolvedQueries: unresolvedForThisQuery,
            undefinedQueriesCount: undefinedQueriesCountForThisQuery,
          } = selectComposedQuery(selectAtomicQuery, cache, keyQuery, level + 1);
          allUnresolved = [...allUnresolved, ...unresolvedForThisQuery];
          // eslint-disable-next-line operator-assignment
          allUndefinedCount = allUndefinedCount + undefinedQueriesCountForThisQuery;
          mapQueryResponses[key] = queryResponse;
        } else {
          // This is an atomic query, get the response
          const queryResponse = selectAtomicQuery(cache, keyQuery);
          if (queryResponse === undefined) {
            allUnresolved = [...allUnresolved, keyQuery];
          } else {
            mapQueryResponses[key] = queryResponse;
          }
        }
      }
      return {
        response: mapQueryResponses,
        unresolvedQueries: allUnresolved,
        undefinedQueriesCount: allUndefinedCount,
      };
    }
    case "@@ComposedQuery/Array": {
      // Recurse
      if (!composedQuery.array) {
        throw new Error("array property need to be set on a ArrayQuery!");
      }
      const arrayQueryResponses: Array<AnyResponse | undefined> | undefined = [];
      let allUnresolved: Array<AnyResponse> = [];
      let allUndefinedCount: number = 0;
      for (let index = 0; index < composedQuery.array.length; index++) {
        const keyQuery = composedQuery.array[index];

        if (keyQuery === undefined) {
          // eslint-disable-next-line operator-assignment
          allUndefinedCount = allUndefinedCount + 1;
        } else if (isComposedQuery(keyQuery)) {
          // Recurse for the composed query
          const {
            response: queryResponse,
            unresolvedQueries: unresolvedForThisQuery,
            undefinedQueriesCount: undefinedQueriesCountForThisQuery,
          } = selectComposedQuery(selectAtomicQuery, cache, keyQuery, level + 1);
          allUnresolved = [...allUnresolved, ...unresolvedForThisQuery];
          // eslint-disable-next-line operator-assignment
          allUndefinedCount = allUndefinedCount + undefinedQueriesCountForThisQuery;
          arrayQueryResponses[index] = queryResponse;
        } else {
          // This is an atomic query, get the response
          const queryResponse = selectAtomicQuery(cache, keyQuery);
          if (queryResponse === undefined) {
            allUnresolved = [...allUnresolved, keyQuery];
          } else {
            arrayQueryResponses[index] = queryResponse;
          }
        }
      }
      return {
        response: arrayQueryResponses,
        unresolvedQueries: allUnresolved,
        undefinedQueriesCount: allUndefinedCount,
      };
    }
    case undefined:
      throw new Error(`query.type cannot be undefined. The query was ${JSON.stringify(composedQuery)}`);
    default:
      return exhaustiveCheck(composedQuery, true);
  }
}

/**
 * Type guard to determine if it is a composed query
 */
function isComposedQuery(arg: AnyQuery): arg is ComposedQuery<AnyQuery, AnyResponse, AnyResponse> {
  return arg.type === "@@ComposedQuery/Map" || arg.type === "@@ComposedQuery/Array";
}
