import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as QD from "shared-lib/query-diaq";
import { CustomUnitsLookup } from "shared-lib/uom";
import { Input } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  ResultQuery,
} from "../types";
import * as Messages from "../messages";

const msgSource = "RoenEstWaterCoilInputMapper";

export function getCalcParams(params: string): ReadonlyArray<InputParam> {
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~10000:CubicMeterPerHour",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "inletAirHumidity",
      quantity: "RelativeHumidity",
      defaultValue: Amount.create(params === "heating" ? 90 : 60, Units.PercentHumidity),
      fieldName: "airHumidity",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "inletAirHumidity=0:PercentHumidity~100:PercentHumidity",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "inletAirTemperature",
      quantity: "Temperature",
      defaultValue: Amount.create(params === "heating" ? -20 : 27, Units.Celsius),
      fieldName: "airTemperature",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "inletAirTemperature=-60:Celsius~50:Celsius",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "inletWaterTemperature",
      quantity: "Temperature",
      fieldName: "waterTemperature",
      validationFilter:
        params === "heating"
          ? PropertyFilter.fromStringOrEmpty(
              "inletWaterTemperature=1:Celsius~130:Celsius&inletWaterTemperature>inletAirTemperature",
              CustomUnitsLookup
            )
          : PropertyFilter.fromStringOrEmpty(
              "inletWaterTemperature=1:Celsius~130:Celsius&inletWaterTemperature<inletAirTemperature",
              CustomUnitsLookup
            ),
    },
    {
      type: "Discrete",
      group: "calculationMethod",
      name: "calcMethodWaterCoil",
      values: [
        {
          value: 0,
          name: "outletWaterTemperature",
        },
        {
          value: 1,
          name: "waterFlow",
        },
        {
          value: 2,
          name: "outletAirTemperature",
        },
      ],
    },
    {
      type: "Amount",
      group: "calculationMethod",
      name: "outletWaterTemperature",
      quantity: "Temperature",
      fieldName: "waterTemperature",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=0", CustomUnitsLookup),
      validationFilter:
        params === "heating"
          ? PropertyFilter.fromStringOrEmpty(
              "outletWaterTemperature=1:Celsius~90:Celsius&outletWaterTemperature<inletWaterTemperature&outletWaterTemperature>inletAirTemperature",
              CustomUnitsLookup
            )
          : PropertyFilter.fromStringOrEmpty(
              "outletWaterTemperature=1:Celsius~130:Celsius&outletWaterTemperature>inletWaterTemperature&outletWaterTemperature<inletAirTemperature",
              CustomUnitsLookup
            ),
    },
    {
      type: "Amount",
      group: "calculationMethod",
      name: "waterFlow",
      quantity: "VolumeFlow",
      fieldName: "waterFlow",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=1", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "waterFlow=0.001:CubicMeterPerHour~10000:CubicMeterPerHour",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationMethod",
      name: "outletAirTemperature",
      quantity: "Temperature",
      fieldName: "airTemperature",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=2", CustomUnitsLookup),
      validationFilter:
        params === "heating"
          ? PropertyFilter.fromStringOrEmpty(
              "outletAirTemperature=-14:Celsius~50:Celsius&outletAirTemperature<inletWaterTemperature",
              CustomUnitsLookup
            )
          : PropertyFilter.fromStringOrEmpty(
              "outletAirTemperature=-14:Celsius~50:Celsius&outletAirTemperature>inletWaterTemperature",
              CustomUnitsLookup
            ),
    },
  ];
}

export function getQuery(_: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({});
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {}

export function map({ calcParams, attributes }: ComponentInput): InputMapperSuccess<Input> | InputMapperError {
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const inletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("inletAirTemperature", calcParams);
  const inletAirHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>("inletAirHumidity", calcParams);
  const inletWaterTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("inletWaterTemperature", calcParams);
  const calculationMethod = PropertyValueSet.getInteger("calcMethodWaterCoil", calcParams) || 0;
  const outletWaterTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("outletWaterTemperature", calcParams);
  const waterFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("waterFlow", calcParams);
  const outletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("outletAirTemperature", calcParams);

  if (!airFlow) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "airFlow")]);
  }
  if (!inletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "inletAirTemperature")]);
  }
  if (!inletAirHumidity) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "inletAirHumidity")]);
  }
  if (!inletWaterTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "inletWaterTemperature")]);
  }

  if (calculationMethod === 0 && !outletWaterTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "outletWaterTemperature")]);
  }
  if (calculationMethod === 1 && !waterFlow) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "waterFlow")]);
  }
  if (calculationMethod === 2 && !outletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "outletAirTemperature")]);
  }

  return createInputMapperSuccess({
    airFlow: airFlow,
    inletAirTemperature: inletAirTemperature,
    inletAirHumidity: inletAirHumidity,
    inletWaterTemperature: inletWaterTemperature,
    calculationMethod: calculationMethod,
    outletWaterTemperature: outletWaterTemperature,
    waterFlow: waterFlow,
    outletAirTemperature: outletAirTemperature,

    attributes: attributes,
  });
}
