import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as SC from "shared-lib/system-calculator";
import * as QD from "shared-lib/query-diaq";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import * as DiaqTemplates from "shared-lib/diaq-templates";
import * as Accessories from "shared-lib/accessories";
import { ItemConfig } from "./types";

export interface OwnProps {
  readonly productId: string;
  readonly config: ItemConfig;
  readonly variantId: string | undefined;
}

export interface Response {
  readonly metaTables: MetaTables | undefined;
  readonly productTables: ProductTables | undefined;
  readonly accessoryTables: {
    readonly [productId: string]: ProductTables;
  };
  readonly systemQueryResult: {} | undefined;
}

export interface MetaTables {
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_ResultViews: QP.ResultViewsTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
}

export interface ProductTables {
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly ct_Attributes2: QP.AttributesTable;
}

export function calculationQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    metaTables: QP.tablesByProductId(QP.metaProductId, [
      "ct_ResultItems",
      "ct_ResultViews",
      "ct_AttributeTemplateMapping",
    ]),
    productTables: productTablesQuery(ownProps),
    accessoryTables: accessoryTablesQuery(response),
    systemQueryResult: systemQuery(ownProps, response),
  });
}

function productTablesQuery(ownProps: OwnProps): QD.DiaqMapQuery<ProductTables> {
  return QD.createMapQuery<ProductTables>({
    ct_Accessories: QP.tableByProductId(ownProps.productId, "ct_Accessories"),
    ct_DiaqTemplates: QP.tableByProductId(ownProps.productId, "ct_DiaqTemplates"),
    ct_Attributes2: QP.tableFromMtOrByProductId(ownProps.productId, ownProps.variantId, "ct_Attributes2"),
  });
}

function accessoryTablesQuery(response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of response.productTables.ct_Accessories) {
    map[accessory.product] = QP.tablesByProductId(accessory.product, [
      "ct_DiaqTemplates",
      "ct_Accessories",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

function systemQuery(
  ownProps: OwnProps,
  response: Response | undefined
): QD.DiaqMapQuery<QD.DiaqMapResponse> | undefined {
  if (!response) {
    return undefined;
  }
  const system = buildSystem(ownProps, response);
  if (!system) {
    return undefined;
  }
  const systemQuery = SC.getQueryForSystem(system);

  return systemQuery;
}

export async function calculateSystem(
  ownProps: OwnProps,
  response: Response | undefined
): Promise<{ readonly system: SC.System | undefined; readonly result: SC.ResultItemOutputPerComponent | undefined }> {
  const system = buildSystem(ownProps, response);
  if (!system || !response || !response.systemQueryResult) {
    return { system, result: undefined };
  }
  const result = await SC.calculateSystem(system, response.systemQueryResult, false);
  return { system, result };
}

export function buildSystem(ownProps: OwnProps, response: Response | undefined): SC.System | undefined {
  if (!response) {
    return undefined;
  }
  const { productId, config, variantId } = ownProps;
  const { metaTables, productTables, accessoryTables } = response;

  if (metaTables === undefined || productTables === undefined || accessoryTables === undefined) {
    return undefined;
  }
  const attributes = Attributes.createMap(config.properties, productTables.ct_Attributes2);

  const components: Array<SC.Component> = [];
  components.push({
    id: productId,
    productId: productId,
    propertyValues: config.properties,
    calcParams: config.calcParams,
    attributes: attributes,
    variantId: variantId,
    resultItems: getResultItems(
      config.properties,
      productTables.ct_DiaqTemplates,
      metaTables.ct_ResultItems,
      metaTables.ct_AttributeTemplateMapping,
      attributes,
      productTables.ct_Attributes2
    ),
  });

  const inheritedCalcParams = PropertyValueSet.keepProperties(Accessories.inheritedCalcParams, config.calcParams);
  for (const acc of config.accessories) {
    const accTables = accessoryTables[acc.productId];
    const accAttributes = Attributes.createMap(acc.properties, accTables.ct_Attributes2);
    const accCalcParams = PropertyValueSet.setValues(inheritedCalcParams, acc.calcParams);
    components.push({
      id: acc.id,
      variantId: undefined,
      productId: acc.productId,
      propertyValues: acc.properties,
      calcParams: accCalcParams,
      attributes: accAttributes,
      resultItems: getResultItems(
        acc.properties,
        accTables.ct_DiaqTemplates,
        metaTables.ct_ResultItems,
        metaTables.ct_AttributeTemplateMapping,
        accAttributes,
        accTables.ct_Attributes2
      ),
    });
  }

  const system = SC.createSystem(components, []);

  return system;
}

function getResultItems(
  properties: PropertyValueSet.PropertyValueSet,
  templates: QP.DiaqTemplatesTable,
  resultItems: QP.ResultItemsTable,
  attributeTemplateMapping: QP.AttributeTemplateMappingTable,
  attributes: Attributes.Attributes,
  attributesTable: QP.AttributesTable
): ReadonlyArray<SC.ResultItemDefinition> {
  const templateForVariant = DiaqTemplates.getTemplatesFromAttributes(
    properties,
    templates,
    attributeTemplateMapping,
    attributes
  ).find((t) => t.type === "ResultItems");
  const template = templateForVariant
    ? templateForVariant
    : DiaqTemplates.getAllTemplatesFromAttributes(templates, attributeTemplateMapping, attributesTable).find(
        (t) => t.type === "ResultItems"
      );
  return resultItems
    .filter((i) => template && i.template === template.template)
    .map((i) => ({
      name: i.name,
      type: i.type,
      calculator: i.calculator,
      calculatorParams: i.calculator_params,
    }));
}
