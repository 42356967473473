import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as ProductCodes from "shared-lib/product-codes";
import { Input, CalculationMethod, HeaterLimits } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  ResultQuery,
} from "../types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";

const msgSource = "ElectricDuctHeaterInputMapper";

export function getCalcParams(): ReadonlyArray<InputParam> {
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~10000:CubicMeterPerHour",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "inletAirHumidity",
      quantity: "RelativeHumidity",
      defaultValue: Amount.create(90, Units.PercentHumidity),
      fieldName: "airHumidity",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "inletAirHumidity=0:PercentHumidity~100:PercentHumidity",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "inletAirTemperature",
      quantity: "Temperature",
      defaultValue: Amount.create(-20, Units.Celsius),
      fieldName: "airTemperature",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "inletAirTemperature=-60:Celsius~20:Celsius",
        CustomUnitsLookup
      ),
    },
    {
      type: "Discrete",
      group: "calculationMethod",
      name: "calcMethodElectricHeater",
      values: [
        {
          value: 0,
          name: "outletAirTemperature",
        },
        // {
        //   value: 1,
        //   name: "maxPower"
        // },
        {
          value: 2,
          name: "power",
        },
      ],
    },
    {
      type: "Amount",
      group: "calculationMethod",
      name: "outletAirTemperature",
      quantity: "Temperature",
      fieldName: "airTemperature",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodElectricHeater=0", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "outletAirTemperature=-20:Celsius~60:Celsius&outletAirTemperature>inletAirTemperature",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationMethod",
      name: "power",
      quantity: "Power",
      fieldName: "airPower",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodElectricHeater=2", CustomUnitsLookup),
    },
  ];
}

export function getQuery(productId: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    property: QP.tableByProductId(productId, "property"),
    code: QP.tableByProductId(productId, "code"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
  });
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  // const isAccessory = PropertyValueSet.getInteger("is_accessory", calcParams) === 1;
  const isAccessorySearch = PropertyValueSet.getInteger("is_accessory_search", calcParams) === 1;

  const codes = ProductCodes.getProductCodes(queryResultMap, properties);

  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const inletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("inletAirTemperature", calcParams);
  const inletAirHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>("inletAirHumidity", calcParams);
  const outletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("outletAirTemperature", calcParams);
  const heaterLimits = attributeHeaterLimits(attributes);
  const calcMethod = PropertyValueSet.getInteger("calcMethodElectricHeater", calcParams);
  const calculationMethod: CalculationMethod =
    calcMethod === 0 ? "OutletAirTemperature" : calcMethod === 2 ? "Power" : "MaxPower";
  const power = calcMethod === 2 ? PropertyValueSet.getAmount<Quantity.Power>("power", calcParams) : undefined;

  if (!airFlow) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "airFlow")]);
  }
  if (!attributes || !heaterLimits) {
    console.warn({
      attributes,
      heaterLimits,
    });
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  if (isAccessorySearch) {
    const inletAirTemperature = Amount.create(-20, Units.Celsius);
    const inletAirHumidity = Amount.create(90, Units.PercentHumidity);
    const calculationMethod = "MaxPower";
    const searchCalcParams = PropertyValueSet.setText(
      "calculationMethod",
      calculationMethod,
      PropertyValueSet.setAmount(
        "inletAirHumidity",
        inletAirHumidity,
        PropertyValueSet.setAmount("inletAirTemperature", inletAirTemperature, calcParams)
      )
    );
    return createInputMapperSuccess({
      airFlow: airFlow,
      inletAirTemperature: inletAirTemperature,
      inletAirHumidity: inletAirHumidity,
      calculationMethod: calculationMethod,
      outletAirTemperature: undefined,
      power: undefined,
      codes: codes,
      attributes: attributes,
      heaterLimits: heaterLimits,
      calcParams: searchCalcParams,
    });
  }

  if (!inletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "inletAirTemperature")]);
  }
  if (!inletAirHumidity) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "inletAirHumidity")]);
  }

  if (calculationMethod === "OutletAirTemperature" && !outletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "outletAirTemperature")]);
  }

  if (calculationMethod === "Power" && !power) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "power")]);
  }

  return createInputMapperSuccess({
    airFlow: airFlow,
    inletAirTemperature: inletAirTemperature,
    inletAirHumidity: inletAirHumidity,
    calculationMethod: calculationMethod,
    outletAirTemperature: outletAirTemperature,
    power: power,

    codes: codes,
    attributes: attributes,
    heaterLimits: heaterLimits,
    calcParams: calcParams,
  });
}

function attributeHeaterLimits(attributes: Attributes.Attributes): HeaterLimits | undefined {
  const temp_ambient_max = Attributes.getFloat("air-temp-amb-MAX-BASE-ALL", attributes);
  const temp_outlet_max = Attributes.getFloat("air-temp-outlet-MAX-BASE-ALL", attributes);
  const air_velocity_min = Attributes.getFloat("air-velocity-MIN-BASE-ALL", attributes);
  const air_velocity_max = Attributes.getFloat("air-velocity-MAX-BASE-ALL", attributes);
  const pressure_drop_max = Attributes.getFloat("air-press-pressure-drop-MAX-BASE-ALL", attributes);

  if (
    temp_ambient_max === undefined ||
    temp_outlet_max === undefined ||
    air_velocity_min === undefined ||
    air_velocity_max === undefined ||
    pressure_drop_max === undefined
  ) {
    return undefined;
  }

  return {
    temp_ambient_max: Amount.create(temp_ambient_max, Units.Celsius),
    temp_outlet_max: Amount.create(temp_outlet_max, Units.Celsius),
    air_velocity_min: Amount.create(air_velocity_min, Units.MeterPerSecond),
    air_velocity_max: Amount.create(air_velocity_max, Units.MeterPerSecond),
    pressure_drop_max: Amount.create(pressure_drop_max, Units.Pascal),
  };
}
