import { Unit, UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { AnyQuantity } from "shared-lib/uom";
import * as UserSettings from "shared-lib/user-settings";
import * as Texts from "shared-lib/language-texts";
import * as QP from "shared-lib/query-product";
import { getFilterUnit } from "shared-lib/system-calculator/shared/fan-sound";

interface UnitOverrideParam {
  readonly fieldName: string;
  readonly value: string;
}

export function getAmcaStatements(
  getUnit: UserSettings.GetUnitFunction,
  getExtraText: UserSettings.GetExtraTextFunction,
  translate: Texts.TranslateFunction,
  amcaStatementsTable: QP.AmcaStatementsTable,
  soundFilter: UserSettings.SoundFilter,
  visualizerParams: string,
  language: string,
  variant: PropertyValueSet.PropertyValueSet
): ReadonlyArray<string> {
  // visualizerParam example: soundPower=filter_unit,soundPressureLevelAtDistance=db_a
  const params: ReadonlyArray<UnitOverrideParam> = visualizerParams
    .split(",")
    .map((p) => p.split("="))
    .filter(([fieldName, value]) => !!fieldName && !!value)
    .map(([fieldName, value]) => ({ fieldName, value }));
  const amcaStatements = amcaStatementsTable
    .filter((r) => PropertyFilter.isValid(variant, r.property_filter) && r.language === language)
    .sort((a, b) => a.sequence_number - b.sequence_number)
    .map((s) =>
      s.statement.replace(/(\{[^}]+\})/g, (match: string): string => {
        const fieldName = match.slice(1, -1);
        const fieldUnit: Unit.Unit<AnyQuantity> = getUnit(fieldName, "" as AnyQuantity);
        const fieldUnitName =
          fieldUnit &&
          translate(Texts.unitLabel(fieldUnit), UnitFormat.getUnitFormat(fieldUnit, UnitsFormat)?.label) +
            (getExtraText(fieldName, fieldUnit) || "");
        const overridenUnitName = getOverridenUnit(soundFilter, params, fieldName);
        return overridenUnitName || fieldUnitName || match;
      })
    );
  return amcaStatements;
}

function getOverridenUnit(
  soundFilter: UserSettings.SoundFilter,
  params: ReadonlyArray<UnitOverrideParam>,
  fieldName: string
): string | undefined {
  const paramValue = params.find((p) => p.fieldName === fieldName)?.value;
  if (!paramValue) {
    return undefined;
  }
  switch (paramValue) {
    case "db":
      return "dB";
    case "db_a":
      return "dB(A)";
    case "db_c":
      return "dB(C)";
    case "filter_unit":
      return getFilterUnit(soundFilter);
    default:
      return undefined;
  }
}
