import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as R from "ramda";
import * as Map from "./map";

export type FilterIndex<T extends Row> = BranchNode<T> | LeafNode<T>;

export interface BranchNode<T extends Row> {
  readonly type: "Branch";
  readonly property: string;
  readonly branches: {
    readonly [value: string]: FilterIndex<T>;
  };
}

export interface LeafNode<T extends Row> {
  readonly type: "Leaf";
  readonly rows: ReadonlyArray<T>;
}

export interface Row {
  readonly property_filter: PropertyFilter.PropertyFilter;
}

export function createIndex<T extends Row>(propertyTable: QP.PropertyTable, rows: ReadonlyArray<T>): FilterIndex<T> {
  const propertyMap = Map.makeMap(
    propertyTable,
    (p) => p.name,
    (p) => p
  );
  const properties: { [property: string]: boolean } = {};
  for (const row of rows) {
    for (const property of PropertyFilter.getReferencedProperties(row.property_filter)) {
      properties[property] = true;
    }
  }
  const root = createNode(PropertyValueSet.Empty, propertyMap, R.keys(properties), rows);
  return root;
}

function createNode<T extends Row>(
  parentPvs: PropertyValueSet.PropertyValueSet,
  propertyMap: Map.Map<QP.ProductProperty>,
  properties: ReadonlyArray<string>,
  rows: ReadonlyArray<T>
): FilterIndex<T> {
  if (properties.length === 0 || rows.length < 100) {
    return { type: "Leaf", rows };
  }
  const [propertyName, ...remaining] = properties;
  const property = propertyMap[propertyName];
  if (property.quantity !== "Discrete" || property.value.length === 0) {
    return createNode(parentPvs, propertyMap, remaining, rows);
  }
  const branches: { [value: string]: FilterIndex<T> } = {};
  for (const value of property.value) {
    const valuePvs = PropertyValueSet.set(property.name, value.value, parentPvs);
    const valueRows = rows.filter((r) => PropertyFilter.isValidMatchMissing(valuePvs, r.property_filter));
    branches[value.value.value.toString()] = createNode(valuePvs, propertyMap, remaining, valueRows);
  }
  return {
    type: "Branch",
    property: property.name,
    branches,
  };
}

export function getRow<T extends Row>(
  pvs: PropertyValueSet.PropertyValueSet,
  index: FilterIndex<T> | undefined
): T | undefined {
  const rows = getRows(pvs, index);
  if (rows.length === 0) {
    return undefined;
  }
  return rows[0];
}

export function getRows<T extends Row>(
  pvs: PropertyValueSet.PropertyValueSet,
  index: FilterIndex<T> | undefined
): ReadonlyArray<T> {
  if (index === undefined) {
    return [];
  }
  if (index.type === "Leaf") {
    return index.rows.filter((r) => PropertyFilter.isValid(pvs, r.property_filter));
  }
  const value = PropertyValueSet.getInteger(index.property, pvs);
  if (value === undefined) {
    return [];
  }
  return getRows(pvs, index.branches[value.toString()]);
}
