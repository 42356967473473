import * as QP from "shared-lib/query-product";
import * as SC from "shared-lib/system-calculator";
import { MetaTables, TableResultValue } from "./types";
import { getResultItemValue } from "./table-value";

export interface TableResultRow {
  readonly item: QP.ResultVisualizerParamsTable;
  readonly values: ReadonlyArray<TableResultValue>;
}

export function getTableRows(
  market: string,
  metaTables: MetaTables,
  visualizerParams: string,
  resultItemMaps: ReadonlyArray<SC.ResultItemMap | undefined>
): ReadonlyArray<TableResultRow> {
  const { ct_ResultVisualizerParamsTable, ct_MarketHiddenFields } = metaTables;
  const params = visualizerParams.split(";");
  const rows = ct_ResultVisualizerParamsTable.filter((r) => r.table_name === params[1]);
  const hiddenFieldsInMarket = ct_MarketHiddenFields.filter((r) => r.market === market).map((r) => r.field_name);
  const results = rows
    .filter((r) => hiddenFieldsInMarket.find((h) => h === r.label) === undefined)
    .map((item) => ({
      item: item,
      values: resultItemMaps.map(
        (resultItemMap) => resultItemMap && getResultItemValue(item.result_value_path, resultItemMap)
      ),
    }))
    .filter((r) => r.values.some((v) => v !== undefined));
  return results;
}
