import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Attributes from "../shared/attributes";

export function match(
  _itemNumber: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes,
  _searchVariant: PropertyValueSet.PropertyValueSet,
  _calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  return Attributes.matchAttribute("CONFIGURABLE-FAN", searchAttributes, variantAttributes);
}
