import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Types from "../types";
import { Input } from "./types";
import { calculate } from "./calculator";
import * as ResultTypes from "../result-items-types";
import * as Messages from "../messages";

const source = "SystemairHeatRecoveryUnitSelector";

export async function select(input: Input): Promise<Types.CalculatorResult<ResultTypes.HeatRecoveryUnit>> {
  const { maxSfp, minHeatExchangeEfficiency, supplyAirFlow, extractAirFlow, toleranceMinus, tolerancePlus } = input;
  const result = await calculate(input);
  if (result.type !== "CalculatorSuccess") {
    return result;
  }
  if (maxSfp && result.output.totalSfp) {
    const maxSfpValue = Amount.valueAs(customUnits.KiloWattPerCubicMeterPerSecond, maxSfp);
    const resultSfpValue = Amount.valueAs(customUnits.KiloWattPerCubicMeterPerSecond, result.output.totalSfp);
    if (resultSfpValue > maxSfpValue) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
  }
  if (minHeatExchangeEfficiency && result.output.exchanger && result.output.exchanger.temperatureEfficiency) {
    const minHeatExchangeEfficiencyValue = Amount.valueAs(customUnits.Percent, minHeatExchangeEfficiency);
    const resultTemperatureEfficiency = Amount.valueAs(
      customUnits.Percent,
      result.output.exchanger.temperatureEfficiency
    );
    if (resultTemperatureEfficiency < minHeatExchangeEfficiencyValue) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
  }

  if (supplyAirFlow && result.output.supplyFan.airFlow) {
    const inputSupplyAirFlowLps = Amount.valueAs(customUnits.LiterPerSecond, supplyAirFlow);
    const workingSupplyFlowLps = Amount.valueAs(customUnits.LiterPerSecond, result.output.supplyFan.airFlow);
    const diff = (100.0 * (workingSupplyFlowLps - inputSupplyAirFlowLps)) / inputSupplyAirFlowLps;
    if (toleranceMinus && Amount.valueAs(customUnits.Percent, toleranceMinus) > diff) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
    if (tolerancePlus && diff > Amount.valueAs(customUnits.Percent, tolerancePlus)) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
  }

  if (extractAirFlow && result.output.extractFan.airFlow) {
    const inputExtractAirFlowLps = Amount.valueAs(customUnits.LiterPerSecond, extractAirFlow);
    const workingExtractFlowLps = Amount.valueAs(customUnits.LiterPerSecond, result.output.extractFan.airFlow);
    const diff = (100.0 * (workingExtractFlowLps - inputExtractAirFlowLps)) / inputExtractAirFlowLps;
    if (toleranceMinus && Amount.valueAs(customUnits.Percent, toleranceMinus) > diff) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
    if (tolerancePlus && diff > Amount.valueAs(customUnits.Percent, tolerancePlus)) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]);
    }
  }

  return result;
}
