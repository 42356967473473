import * as React from "react";
import * as CrmClient from "client-lib/crm";
import { Alert, Button, LinkButton } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import { Dispatch } from "client-lib/redux-integration";
import * as EI from "shared-lib/external-item";
import * as CrmShared from "shared-lib/crm";
import * as UserSettingsShared from "shared-lib/user-settings";
import * as C from "shared-lib/calculation";
import { resolveQuery } from "client-lib/redux-query-resolver";

export interface CrmAddButtonProps {
  readonly crmState: CrmClient.State;
  readonly translate: Texts.TranslateFunction;
  readonly dispatch: Dispatch<CrmClient.Action>;
  readonly crmConfig: CrmShared.CrmConfig | undefined;
  readonly market: string;
  readonly variant: string | undefined;
  readonly m3ItemNo: string;
  readonly userSettings: UserSettingsShared.State;
  readonly fullConfig: C.ItemConfig;
  readonly buttonId: string;
  readonly compact?: boolean;
  readonly secondary?: boolean;
}

export function CrmAddButton({
  crmState,
  translate,
  dispatch,
  crmConfig,
  market,
  variant,
  m3ItemNo,
  userSettings,
  fullConfig,
  buttonId,
  compact,
  secondary,
}: CrmAddButtonProps): React.ReactElement<CrmAddButtonProps> {
  const [showDetails, setShowDetails] = React.useState(false);

  if (!crmConfig) {
    return <></>;
  }

  const activeButton = crmState.buttonId === buttonId;

  if (crmState.adding) {
    return (
      <Button
        compact={compact}
        buttonType={secondary ? "secondary" : "primary"}
        iconLeft={activeButton ? "spinner-third" : "plus"}
        spinnerIcon={activeButton}
        label={activeButton ? translate(Texts.adding_to_crm()) : translate(Texts.add_to_crm())}
        clicked={() => null}
        disabled={true}
      />
    );
  }

  return (
    <div>
      <Button
        compact={compact}
        buttonType={secondary ? "secondary" : "primary"}
        iconLeft="plus"
        label={translate(Texts.add_to_crm())}
        clicked={async () => {
          const items = await EI.createExternalItem(
            {
              market: market,
              language: crmConfig.crmQuoteLanguage,
              m3ItemNo: m3ItemNo,
              variant: variant,
              stateConfig: fullConfig,
              propsConfig: undefined,
              userSettings: userSettings,
            },
            resolveQuery
          );

          dispatch(CrmClient.addItems(crmConfig, items, buttonId));
        }}
      />
      {crmState.status && crmState.status.type === "error" && activeButton ? (
        <Alert
          className="mt-8"
          type="error"
          close={() => {
            setShowDetails(false);
            dispatch(CrmClient.dismissError());
          }}
        >
          {translate(Texts.crm_error())}
          {crmState.status.code !== undefined || crmState.status.message !== undefined ? (
            <div className="max-w-sm">
              {showDetails ? (
                <>
                  <p>
                    <b>{translate(Texts.error_details())}</b>
                  </p>
                  <p>
                    {translate(Texts.error_code())}: {crmState.status.code !== undefined ? crmState.status.code : "n/a"}
                  </p>
                  <p>
                    {translate(Texts.error_message())}:{" "}
                    {crmState.status.message !== undefined ? crmState.status.message : "n/a"}
                  </p>
                </>
              ) : (
                <LinkButton onClick={() => setShowDetails(true)}>{translate(Texts.show_details())}</LinkButton>
              )}
            </div>
          ) : undefined}
        </Alert>
      ) : undefined}
      {crmState.status && crmState.status.type === "success" && activeButton ? (
        <Alert className="mt-8" type="success" close={() => dispatch(CrmClient.dismissError())}>
          {translate(Texts.crm_added())}
        </Alert>
      ) : undefined}
    </div>
  );
}
