import * as React from "react";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";
import { LinkButton, PositiveNumberfield } from "client-lib/elements";
import { ProductDescriptionAndTechnicalParameters } from "shared-lib/ecom-api";
import { DispatchProp } from "client-lib/redux-integration";
import * as Style from "shared-lib/style";
import * as Actions from "./actions";
import { State } from "./reducer";

type Props = OwnProps & StateProps & Response & DispatchProp<Actions.Action>;

export interface Item {
  readonly sortNo: number;
  readonly itemName: string;
  readonly m3ItemNo: string;
  readonly variantNo: string;
  readonly quantity: number;
  readonly productId: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
}

export interface AccessoryItem {
  readonly m3ItemNo: string;
  readonly quantity: number;
}

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly translate: Texts.TranslateFunction;
  readonly products: ReadonlyArray<Item>;
  readonly addedAccessories: ReadonlyArray<AccessoryItem>;
  readonly ecomUrl: string;
  readonly specificationDataUrl: string;
  readonly removeVariant: (m3ItemNo: string, accessoriesToRemove: ReadonlyArray<string>) => void;
  readonly updateVariant: (
    itemName: string,
    m3ItemNo: string,
    variantNo: string,
    quantity: number,
    sortNo: number,
    productId: string,
    properties: PropertyValueSet.PropertyValueSet
  ) => void;
}

export interface StateProps {
  readonly state: State;
}

export interface Response {
  readonly productDescriptions: { readonly [variantNo: string]: ProductDescriptionAndTechnicalParameters };
  readonly tablesForProducts: TablesForProducts | undefined;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

export interface TablesForProducts {
  readonly [id: string]: AllProductTables;
}

export interface AllProductTables {
  readonly ct_AccessoryRelations: QP.AccessoryRelationsTable;
}

export function FricoProductTableContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  if (products.length === 0) {
    return <div />;
  }

  const sortedItems = products.slice(0).sort((a, b) => a.sortNo - b.sortNo);

  return (
    <div>
      <div>
        <table>
          {renderTableHeadBasket(translate)}
          {renderTableRows(props, translate, sortedItems)}
          {/* <tbody>{sortedItems.map(r => renderTableRowBasket(r, props, translate))}</tbody> */}
        </table>
      </div>
    </div>
  );
}

function renderTableHeadBasket(translate: Texts.TranslateFunction): React.ReactElement<{}> {
  return (
    <thead>
      <tr>
        <th key="sort_no">{translate(Texts.product_name_text())}</th>
        <th key="m3ItemNo">{translate(Texts.product_no())}</th>
        <th key="qty">{translate(Texts.qty())}</th>
        <th key="remove">{translate(Texts.remove_product())}</th>
      </tr>
    </thead>
  );
}

function renderTableRows(
  props: Props,
  translate: Texts.TranslateFunction,
  items: ReadonlyArray<Item>
): React.ReactElement<{}> {
  const { productDescriptions, state } = props;
  return (
    <tbody>
      {items.map((row) => {
        const isOpen = state.openDescriptions.some((r) => r === row.variantNo);
        return [
          renderTableRow(row, props, translate, isOpen),
          isOpen ? (
            <tr key={`${row.m3ItemNo}_${row.sortNo}_${row.variantNo}_description`}>
              <td colSpan={4}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDescriptions[row.variantNo].description!.product_specification_text,
                  }}
                />
              </td>
            </tr>
          ) : null,
        ];
      })}
    </tbody>
  );
}

function renderTableRow(
  row: Item,
  props: Props,
  translate: Texts.TranslateFunction,
  isOpen: boolean
): React.ReactElement<{}> {
  const { removeVariant, updateVariant, productDescriptions, dispatch } = props;
  return (
    <tr style={isOpen ? { backgroundColor: Style.gray10 } : undefined} key={row.itemName + "-" + row.m3ItemNo}>
      <td>
        {row.itemName}{" "}
        {productDescriptions[row.variantNo] &&
        productDescriptions[row.variantNo].description &&
        productDescriptions[row.variantNo].description!.product_specification_text ? (
          <LinkButton
            style={{ float: "right" }}
            onClick={() =>
              isOpen
                ? dispatch(Actions.closeDescription(row.variantNo))
                : dispatch(Actions.openDescription(row.variantNo))
            }
          >
            {isOpen ? translate(Texts.hide_description()) : translate(Texts.show_description())}
          </LinkButton>
        ) : (
          ""
        )}
      </td>
      <td>{row.m3ItemNo}</td>
      <td>
        <PositiveNumberfield
          value={row.quantity}
          onChange={(v) => {
            updateVariant(row.itemName, row.m3ItemNo, row.variantNo, v, row.sortNo, row.productId, row.properties);
          }}
        />
      </td>
      <td>
        <LinkButton
          onClick={() =>
            removeVariant(row.m3ItemNo, getAccessoriesOnlyExistsOnProduct(row.productId, row.m3ItemNo, props))
          }
        >
          {translate(Texts.remove())}
        </LinkButton>
      </td>
    </tr>
  );
}

function getAccessoriesOnlyExistsOnProduct(
  productId: string,
  productItemNo: string,
  props: Props
): ReadonlyArray<string> {
  const { tablesForProducts, addedAccessories, products } = props;
  const allAccessoriesForProduct = tablesForProducts![productId].ct_AccessoryRelations;

  const addedAccessoriesForProduct = addedAccessories.filter(
    (accessory) =>
      allAccessoriesForProduct.find(
        (y) => y.accessory_item_no === accessory.m3ItemNo && y.item_no === productItemNo
      ) !== undefined
  );

  const accessoriesToRemove = addedAccessoriesForProduct.filter((accessory) => {
    for (const item of products) {
      if (item.m3ItemNo === productItemNo) {
        continue;
      }
      const acc = tablesForProducts![item.productId].ct_AccessoryRelations.find(
        (q) => q.accessory_item_no === accessory.m3ItemNo && q.item_no === item.m3ItemNo
      );
      if (acc !== undefined) {
        return false;
      }
    }
    return true;
  });
  return accessoriesToRemove.map((r) => r.m3ItemNo);
}
