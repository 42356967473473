import { RehydrateAction } from "redux-persist";
import { ImageTooltip } from "./types";

export type Action = SetImageTooltip | StartCalculation | Reset | RehydrateAction;

export interface Reset {
  readonly type: "search-products/RESET";
}

export function reset(): Reset {
  return {
    type: "search-products/RESET",
  };
}

export interface SetImageTooltip {
  readonly type: "search-products/SET_IMAGE_TOOLTIP";
  readonly payload: ImageTooltip | undefined;
}

export function setImageTooltip(toolTip: ImageTooltip | undefined): SetImageTooltip {
  return {
    type: "search-products/SET_IMAGE_TOOLTIP",
    payload: toolTip,
  };
}

export interface StartCalculation {
  readonly type: "search/START_CALCULATION";
}
