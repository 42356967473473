import * as QP from "shared-lib/query-product";
import * as T from "./types";

export function getAllChildComponents(fromComponentId: string, system: T.System): ReadonlyArray<T.Component> {
  const children = getChildComponents(fromComponentId, system);
  for (const child of children) {
    children.push(...getAllChildComponents(child.id, system));
  }
  return children;
}

// eslint-disable-next-line functional/prefer-readonly-type
function getChildComponents(fromComponentId: string, system: T.System): Array<T.Component> {
  const childRelations = system.relations.filter(
    (r) => r.relationType === "CHILD" && r.fromComponentId === fromComponentId
  );
  const children = system.components.filter((c) => !!childRelations.find((r) => r.toComponentId === c.id));
  return children;
}

export interface AllTables {
  readonly [key: string]: {
    readonly property: QP.PropertyTable;
    readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  };
}
