import * as R from "ramda";
import { createSelector, Selector } from "reselect";
import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as Texts from "shared-lib/language-texts";
import * as UserSettings from "client-lib/user-settings";
import { SearchProductsContainerComponent } from "./container-component";
import { StateProps, OwnProps, Response } from "./types";
import { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.searchProducts,
  (s: RootState) => s.search,
  UserSettings.stateSelector,
  UserSettings.showAdvancedSelector,
  UserSettings.showDiagramsSelector,
  (state, searchState, userSettings, showAdvanced, showDiagrams) => ({
    state: state,
    searchState: searchState,
    userSettings: userSettings,
    showAdvanced: showAdvanced,
    showDiagrams: showDiagrams,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_LanguageMapping: QP.tableByProductId(QP.fricoProductId, "ct_LanguageMapping"),
    translateTables:
      response && response.ct_SearchMetaProducts
        ? Texts.getTablesQuery(response.ct_SearchMetaProducts.map((p) => p.product))
        : undefined,
    ct_ResultItems: QP.tableByProductId(QP.metaProductId, "ct_ResultItems"),
    ct_SearchMetaProducts: QP.tableByProductId(QP.metaProductId, "ct_SearchMetaProducts"),
    property: QP.tableByProductId(ownProps.searchProductId, "property"),
    image: QP.tableByProductId(ownProps.searchProductId, "image"),
    images: imagesQuery(response),
    ct_ClosedGroups: QP.tableByProductId(ownProps.searchProductId, "ct_ClosedGroups"),
    ct_SearchAccessories: QP.tableByProductId(ownProps.searchProductId, "ct_SearchAccessories"),
    ct_SearchProducts: QP.tableByProductId(ownProps.searchProductId, "ct_SearchProducts"),
    ct_CalcParamDefault: QP.tableByProductId(ownProps.searchProductId, "ct_CalcParamDefault"),
    ct_SearchColumns: QP.tableByProductId(ownProps.searchProductId, "ct_SearchColumns"),
    ct_SearchSortings: QP.tableByProductId(ownProps.searchProductId, "ct_SearchSortings"),
    ct_SearchViews: QP.tableByProductId(ownProps.searchProductId, "ct_SearchViews"),
    ct_DiaqTemplates: QP.tableByProductId(ownProps.searchProductId, "ct_DiaqTemplates"),
    ct_MarketSettings: QP.tableByProductId(QP.metaProductId, "ct_MarketSettings"),
    ct_AttributeTemplateMapping: QP.tableByProductId(QP.metaProductId, "ct_AttributeTemplateMapping"),
    ct_EcomCategoryToProperty: QP.tableByProductId(ownProps.searchProductId, "ct_EcomCategoryToProperty"),
  });
}

function imagesQuery(response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response || response.image === undefined) {
    return undefined;
  }
  return QD.createMapQuery(
    R.fromPairs(
      response.image.map((i) => [i.image, QD.allowUndefined(QP.blob(i.image))] as R.KeyValuePair<R.Prop, QP.BlobQuery>)
    )
  );
}

export const SearchProductsContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(SearchProductsContainerComponent);
