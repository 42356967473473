import * as R from "ramda";
import * as React from "react";
import * as SC from "shared-lib/system-calculator";
import { Heading4, Combobox } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as FanSound from "shared-lib/system-calculator/shared/fan-sound";
import * as UserSettings from "shared-lib/user-settings";
import * as UserSettingsClient from "client-lib/user-settings";
import { DispatchProp } from "client-lib/redux-integration";
import * as Sound from "shared-lib/system-calculator/shared/sound";
import { isAllDefined, isDefined } from "shared-lib/utils";
import { VisualizerOwnProps } from "../types";
import { OctaveTable } from "../shared";

export type Props = VisualizerOwnProps & StateProps & DispatchProp<UserSettingsClient.Action>;

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export function SoundPressureLevelWithDistanceVisualizerContainerComponent({
  visualizerParams,
  products,
  translate,
  userSettings,
  dispatch,
}: Props): React.ReactElement<Props> {
  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const allResultItemMaps = products.map((p) => p.resultItemMap);
  const allResults = allResultItemMaps.map(
    (resultItemMap) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (R.values(resultItemMap)[0].value as any) as {
        [key: string]: SC.OctaveBands | SC.OctaveBands3rd;
      }
  );

  const items = visualizerParams.split(",");
  const itemsInResult = allResults[0] || {};
  const rows = items.filter((i) => itemsInResult[i]);
  if (rows.length === 0) {
    return <span />;
  }

  if (rows.length === 0) {
    return <span />;
  }

  const singleProduct = products.length === 1;

  const soundDistance = UserSettings.getSoundDistance(userSettings);
  const soundDistanceDouble = parseFloat(soundDistance);
  const damping = Sound.calculateDampingForDistance(soundDistanceDouble);
  const octaveBandsType = singleProduct ? UserSettings.getOctaveBandsType(userSettings) : "Octave";

  const octaveBands = rows.map(
    (i): OctaveTable.OctaveBandProps => ({
      key: i,
      label: Texts.createText(i),
      unitName: "dB(A)",
      octaveBands: allResults
        .map((results) => results[i])
        .map((ob) => FanSound.calcOctaveBandsFrom3rds(ob))
        .map((ob) => FanSound.aWeightOctaveBands(ob))
        .map((ob) => ob && FanSound.applyOneAttenuation(ob, damping))
        .filter(isDefined),
    })
  );

  return (
    <div>
      {<Heading4>{translate(Texts.soundPressureLevelLpa())}</Heading4>}
      <div className="flex flex-col space-y-16">
        <div className="flex items-center">
          <span className="mr-52 w-144">{translate(Texts.distance())}</span>
          <Combobox
            value={soundDistance}
            options={[
              { value: "1", label: "1m" },
              { value: "1.5", label: "1.5m" },
              { value: "3", label: "3m" },
              { value: "5", label: "5m" },
            ]}
            onChange={(newValue) => dispatch(UserSettingsClient.setSoundDistance(newValue))}
          />
        </div>
        <OctaveTable.OctaveTable
          translate={translate}
          octaveBandsType={octaveBandsType}
          octaveBands={octaveBands}
          metaTexts={[
            `${translate(Texts.distance())}: ${soundDistanceDouble}m`,
            `${translate(Texts.directivity())}: ${translate(Texts.spherical_q1())}`,
          ]}
        />
      </div>
    </div>
  );
}
