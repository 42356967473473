import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as QD from "shared-lib/query-diaq";
import { Quantity } from "uom-units";
import { Input } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  ResultQuery,
} from "../types";
import { ProductDataError_NoDataAvailable } from "../messages";
import * as Attributes from "../shared/attributes";

const msgSource = "FilterInputMapper";

export function getCalcParams(): ReadonlyArray<InputParam> {
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
    },
  ];
}

export function getQuery(_: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({});
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {}

export function map({ calcParams, attributes }: ComponentInput): InputMapperSuccess<Input> | InputMapperError {
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const maxAirFlow = Attributes.getFloat("air-flow-pressure-drop-MAX-BASE-ALL", attributes);
  const maxPressure = Attributes.getFloat("air-press-pressure-drop-MAX-BASE-ALL", attributes);
  if (!airFlow) {
    return createInputMapperError([ProductDataError_NoDataAvailable(msgSource)]);
  }

  return createInputMapperSuccess({
    airFlow: airFlow,
    maxAirFlow: maxAirFlow !== undefined ? Amount.create(maxAirFlow, customUnits.CubicMeterPerHour) : undefined,
    maxPressure: maxPressure !== undefined ? Amount.create(maxPressure, customUnits.Pascal) : undefined,
  });
}
