import { retryFetch } from "shared-lib/fetch";

export interface RoenEstInput {
  readonly AirFlow: number;
  readonly AirTemperatureIn: number;
  readonly WaterTempIn: number;
  readonly WaterTempOut: number | null;
  readonly AirHumidity: number;
  readonly Method: number;
  readonly WaterFlow: number | null;
  readonly AirTemperatureOut: number | null;
  readonly Length: number;
  readonly Height: number;
  readonly Rows: number;
  readonly FinSpacing: number;
  readonly Circuits: number;
  readonly FinOption: number;
  readonly Geometry: string;
  readonly RowsMaterial: string;
  readonly FinsMaterial: string;
  readonly FreeTubes: number | null;
  readonly HeaderConf: string;
  readonly Header: string;
  readonly CoilType: number;
  readonly Glycol: number;
  readonly FluidType: number;
}

export interface RoenEstResult {
  readonly AirFlow: number;
  readonly AirHumidityIn: number;
  readonly AirHumidityOut: number;
  readonly AirPressureDrop: number;
  readonly AirTemperatureIn: number;
  readonly AirTemperatureOut: number;
  readonly AirVelocity: number;
  readonly CoilInnerVolume: number;
  readonly CvValue: number;
  readonly KvValue: number;
  readonly Power: number;
  readonly WaterFlow: number;
  readonly WaterPressureDrop: number;
  readonly WaterTemperatureIn: number;
  readonly WaterTemperatureOut: number;
  readonly WaterVelocity: number;
  readonly Message: string | undefined;
  readonly CoilCode: string | undefined;
}

export async function calculate(input: RoenEstInput): Promise<RoenEstResult> {
  try {
    const result: RoenEstResult = await retryFetch("https://design-dllcal.systemair.com/api/roenest/calculate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }).then((r) => r.json());
    return result;
  } catch {
    return { Message: "Calculation request error" } as RoenEstResult;
  }
}
