/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable functional/no-this-expression */
import * as React from "react";
import * as Redux from "redux";
import * as ReactRedux from "react-redux";
import * as R from "ramda";
import * as Core from "../core";
import { MergeProps, MapPropsToQuery, ReduxQueryProp } from "./types";
import * as Actions from "../core/actions";

interface StateProps {
  readonly state: Core.AnyQueryStoreState;
}

interface DispatchProps {
  readonly dispatch: Redux.Dispatch<Core.Action>;
}

interface Props {
  readonly stateProps: StateProps;
  readonly dispatchProps: DispatchProps;
  readonly ownProps: {}; // Original props passed from parent to our wrapped component
}

/**
 * Higer order component that takes a query and injects the response into the wrapped component.
 * It will display a loading component until the query completes so the wrapped component will
 * only be called when the response is available.
 * @param wrappedComponent The component to recieve the injected response prop.
 * @param mapPropsToQuery Function to get the query, will be called multiple times if queries are undefined.
 * @param LoadingIndicator Loading indicator component to show while waiting for response.
 * @param selectQuery Function to select from cache.
 * @param queryStoreKey Optional key in redux store where the query store resides.
 * @param mergeProps Function to merge the response prop with the wrapped component's props.
 */
export function withReduxQuery<
  TAtomicQuery extends Core.AnyQuery,
  TProps,
  TResponse,
  TAtomicResponse,
  TComposedResponse
>(
  wrappedComponent: React.StatelessComponent<any> | React.ComponentClass<any>,
  mapPropsToQuery: MapPropsToQuery<TAtomicQuery, TProps, TResponse, TAtomicResponse, TComposedResponse>,
  LoadingIndicator: React.ReactType,
  selectAtomicQuery: Core.SelectAtomicQuery<Core.AnyCacheState, Core.AnyQuery, Core.AnyResponse | undefined>,
  queryStoreKey: string = "query",
  mergeProps: MergeProps = defaultMergeProps
): any {
  // eslint-disable-next-line functional/no-class
  const withReduxQueryComponent: React.ComponentClass<Props> = class WithReduxQuery extends React.Component<Props, {}> {
    query: Core.MapQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>;
    result: Core.SelectComposedQueryResult<Core.AnyQuery, Core.AnyResponse>;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: Props) {
      super(props);
    }

    UNSAFE_componentWillMount(): void {
      this.updateQueryAndResultAndDispatchQueriesIfNeeded(undefined, this.props, undefined);
    }

    // This may be called multiple times while we are waiting for a query to return a response.
    UNSAFE_componentWillReceiveProps(nextProps: Props): void {
      // Only handle changes (React may call UNSAFE_componentWillReceiveProps even if props has not changed)
      if (nextProps === this.props) {
        return;
      }

      const { cache } = nextProps.stateProps.state;

      // If there are still unresolved responses for the current query we cannot ask for a new query
      this.result = this.selectComposedQuery(cache, this.query);
      if (this.result.unresolvedQueries.length > 0) {
        // But if there are no queries queued or loading then probably the cache was cleared and we need to re-load
        if (
          this.props.stateProps.state.currentlyLoadingQueries.length > 0 ||
          this.props.stateProps.state.queudQueries.length > 0
        ) {
          return;
        }
      }

      // Ask for new query and dispatch if needed
      this.updateQueryAndResultAndDispatchQueriesIfNeeded(this.props, nextProps, this.result.response);
    }

    render(): React.ReactElement<{}> {
      // Check that all queries are defined and resolved
      if (
        this.result.undefinedQueriesCount > 0 ||
        this.result.unresolvedQueries.filter(shouldWaitForResponse).length > 0
      ) {
        return <LoadingIndicator />;
      }

      // Don't pass through state and dispatch props
      // Only pass through the own props (that we were passed from our parent)
      const reduxQueryProp: ReduxQueryProp = {
        clearCache: (x) => this.props.dispatchProps.dispatch(Actions.clearCache(x)),
        response: this.result.response,
      };

      const mergedProps = mergeProps(this.props.ownProps, reduxQueryProp);
      return React.createElement(wrappedComponent as any, mergedProps);
    }

    // eslint-disable-next-line class-methods-use-this
    selectComposedQuery(
      cache: Core.AnyCacheState | undefined,
      composedQuery: Core.ComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>
    ): Core.SelectComposedQueryResult<TAtomicQuery, TAtomicResponse> {
      return Core.selectComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>(
        selectAtomicQuery,
        cache,
        composedQuery
      );
    }

    updateQueryAndResultAndDispatchQueriesIfNeeded(
      oldProps: Props | undefined,
      newProps: Props,
      currentResponse: Core.AnyResponse
    ): void {
      const { cache } = newProps.stateProps.state;

      // if (oldProps !== undefined && !R.equals(oldProps, newProps)) {
      //   console.log(getDisplayName(wrappedComponent, "WithReduxQuery"));
      //   for (let key of Object.keys(oldProps)) {
      //     console.log(key, oldProps[key] === newProps[key]);
      //   }
      //   console.log("------------------------");
      // }

      let response = R.equals(oldProps, newProps) ? currentResponse : undefined;

      // While the response could be fully gotten from cache, but there are still undefined queries,
      // iterate until all queries are specified or there are responses which cannot be gotten from cache
      let iterations = 0;
      do {
        this.query = mapPropsToQuery(newProps.ownProps as any, response);
        this.result = this.selectComposedQuery(cache, this.query);
        response = this.result.response;
        iterations++;
        if (iterations > 10) {
          console.error("The component did not specify it's queries fully in 10 iterations.", {
            query: this.query,
            result: this.result,
          });
          throw new Error("The component did not specify it's queries fully in 10 iterations.");
        }
      } while (this.result.unresolvedQueries.length === 0 && this.result.undefinedQueriesCount > 0);

      // Queue any queries that are defined but are not selectable from cache
      if (this.result.unresolvedQueries.length > 0) {
        newProps.dispatchProps.dispatch(Core.queueQueries(this.result.unresolvedQueries));
      }
    }
  };

  // Set displayname of the HOC
  withReduxQueryComponent.displayName = getDisplayName(wrappedComponent, "WithReduxQuery");

  // Get the query store state
  function mapStateToProps(storeState: { [key: string]: {} }): StateProps {
    const queryStoreState: Core.AnyQueryStoreState = storeState[queryStoreKey] as any;
    return {
      state: queryStoreState,
    };
  }

  // Connect to redux store
  return ReactRedux.connect(mapStateToProps, undefined as any, (stateProps: {}, dispatchProps: {}, ownProps: {}) => ({
    stateProps,
    dispatchProps,
    ownProps,
  }))(withReduxQueryComponent);
}

function defaultMergeProps(ownProps: {}, reduxQueryProp: ReduxQueryProp): {} {
  return {
    ...ownProps,
    reduxQuery: reduxQueryProp,
    response: reduxQueryProp.response,
  };
}

function getDisplayName(
  wrappedComponent: React.StatelessComponent<any> | React.ComponentClass<any>,
  hocName: string
): string {
  const wrappedComponentName = wrappedComponent.displayName || wrappedComponent.name || "Component";
  return `${hocName}(${wrappedComponentName})`;
}

function shouldWaitForResponse(arg: Core.AnyQuery): boolean {
  return !(arg as any).___reduxQueryAllowUndefined;
}
