import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as QEcom from "shared-lib/query-ecom";
import * as R from "ramda";
import { createSelector } from "reselect";
import * as PC from "shared-lib/product-codes";

export interface MarketTablesResponse {
  readonly itemNoToProductId: QP.ItemNoToProductId;
  readonly variantNoToProductId: QP.VariantNoToProductId;
  readonly variantNoToPrice: QEcom.VariantNoToPriceResponse;
}

export function createMarketTablesQuery(url: string, marketName: string): QD.DiaqMapQuery<MarketTablesResponse> {
  return QD.createMapQuery<MarketTablesResponse>({
    itemNoToProductId: QP.itemNoToProductId(),
    variantNoToProductId: QP.variantNoToProductId(),
    variantNoToPrice: QEcom.variantNoToPrice(url, marketName),
  });
}

const getAvailableProducts = createSelector(
  (r: MarketTablesResponse) => r.variantNoToProductId,
  (r: MarketTablesResponse) => r.variantNoToPrice,
  (variantNoToProductId, variantNoToPrice) => {
    const availableProducts = R.fromPairs([
      ...Object.keys(variantNoToProductId)
        .filter((variantNo) => variantNoToPrice[variantNo] !== undefined)
        .map((variantNo) => [variantNoToProductId[variantNo], true] as R.KeyValuePair<R.Prop, boolean>),
    ]);
    return availableProducts;
  }
);

export function filterProductsByMarket(
  response: MarketTablesResponse,
  products: ReadonlyArray<string>
): ReadonlyArray<string> {
  const availableProducts = getAvailableProducts(response);
  return products.filter(
    (p) => availableProducts[p] || QP.offlineBoxFanProductsToSkipMarketCheck.find((ip) => ip === p)
  );
}

export function availableInMarket(response: MarketTablesResponse, code: PC.ProductCodes): boolean {
  return response.variantNoToPrice[code.variantId || ""] !== undefined;
}
