import * as R from "ramda";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as PC from "shared-lib/product-codes";
import { CustomUnitsLookup } from "shared-lib/uom";
import { Input, AccessoryResult } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  DiscreteInputParamValue,
  ResultItemOutputPerComponent,
  ResultItemOutputMap,
  ResultQuery,
  System,
} from "../types";
import * as Messages from "../messages";
import { SpeedControl } from "../result-items-types";
import * as Attributes from "../shared/attributes";
import * as AirDensity from "../shared/air-density";
import * as CalcParams from "../shared/calc-params";

const msgSource = "FantechFanInputMapper";

export function getCalcParams(
  params: string,
  attributes: Attributes.Attributes,
  variant: PropertyValueSet.PropertyValueSet
): ReadonlyArray<InputParam> {
  const filterNoSpeedControl = PropertyFilter.fromString(
    "application=-1&fantype=4&voltage=460&phases=3",
    CustomUnitsLookup
  );
  const showSpeedControl = filterNoSpeedControl ? !PropertyFilter.isValid(variant, filterNoSpeedControl) : true;

  const basics: Array<InputParam> = [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
      quantity: "VolumeFlow",
      fieldName: "airFlow",
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "externalPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "externalPressure=0.1:Pascal~999999:Pascal",
        CustomUnitsLookup
      ),
      quantity: "Pressure",
      fieldName: "airPressure",
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },

    {
      type: "Discrete",
      group: "calculationParams",
      name: "speedControl",
      values: getSpeedControlValues(params, attributes, showSpeedControl),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityTemperature",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod!=0", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityTemperature!=null", CustomUnitsLookup),
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(20, Units.Celsius),
    },
  ];
  if (params !== "search") {
    return basics;
  }
  return [
    ...basics,
    {
      type: "Discrete",
      group: "performanceFilters",
      name: "select",
      values: [
        {
          value: 0,
          name: "sfp",
        },
        {
          value: 1,
          name: "efficiency",
        },
      ],
    },
    {
      type: "Amount",
      group: "performanceFilters",
      name: "maxSfp",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("select=0", CustomUnitsLookup),
      quantity: "SpecificFanPower",
      fieldName: "sfp",
    },
    {
      type: "Amount",
      group: "performanceFilters",
      name: "minEfficiency",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("select=1", CustomUnitsLookup),
      quantity: "Dimensionless",
      fieldName: "efficiency",
    },
    {
      type: "Amount",
      group: "performanceFilters",
      name: "toleranceMinus",
      quantity: "Dimensionless",
      fieldName: "tolerance",
      defaultValue: Amount.create(-5, Units.Percent),
    },
    {
      type: "Amount",
      group: "performanceFilters",
      name: "tolerancePlus",
      quantity: "Dimensionless",
      fieldName: "tolerance",
      defaultValue: Amount.create(10, Units.Percent),
    },
  ];
}

function getSpeedControlValues(
  params: string,
  attributes: Attributes.Attributes,
  showSpeedControl: boolean
): ReadonlyArray<DiscreteInputParamValue> {
  if (params === "search") {
    if (!showSpeedControl) {
      return [
        {
          value: 0,
          name: "default",
        },
        {
          value: 1,
          name: "none",
        },
      ];
    }

    return [
      {
        value: 0,
        name: "default",
      },
      {
        value: 2,
        name: "stepped",
      },
      {
        value: 3,
        name: "stepless",
      },
      {
        value: 1,
        name: "none",
      },
    ];
  } else {
    return CalcParams.getSpeedControls(attributes);
  }
}

export function getQuery(productId: string, variantId: string | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    property: QP.tableByProductId(productId, "property"),
    code: QP.tableByProductId(productId, "code"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
    ct_AirData2: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirData2"),
    ct_AirLimitsData: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirLimitsData"),
    ct_SoundData: QP.tableFromMtOrByProductId(productId, variantId, "ct_SoundData"),
  });
}

export function getResultsQuery(system: System, componentId: string): ReadonlyArray<ResultQuery> {
  return system.components
    .filter((c) => c.id !== componentId && c.resultItems.length > 0)
    .map((c) => {
      const resultItems = c.resultItems.map((i) => i.name);
      return {
        componentId: c.id,
        resultItems: resultItems,
      };
    });
}

export interface Response {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_AirData2: QP.AirDataTable;
  readonly ct_AirLimitsData: QP.AirLimitsDataTable;
  readonly ct_SoundData: QP.SoundDataTable;
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response,
  resultQueryResponse: ResultItemOutputPerComponent
): InputMapperSuccess<Input> | InputMapperError {
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const externalPressure = PropertyValueSet.getAmount<Quantity.Pressure>("externalPressure", calcParams);
  const limitType = PropertyValueSet.getInteger("select", calcParams);
  const maxSfp = PropertyValueSet.getAmount<Quantity.SpecificFanPower>("maxSfp", calcParams);
  const minEfficiency = PropertyValueSet.getAmount<Quantity.Dimensionless>("minEfficiency", calcParams);
  const airDensity = AirDensity.getAirDensityKgPerCubicMeter(calcParams);

  const toleranceMinus = PropertyValueSet.getAmount<Quantity.Dimensionless>("toleranceMinus", calcParams);
  const tolerancePlus = PropertyValueSet.getAmount<Quantity.Dimensionless>("tolerancePlus", calcParams);

  const speedControl = getUserSpeedControl(calcParams);

  const airMeasurement = Attributes.getMeasurementNo("measurement-number-air", attributes);
  const soundMeasurement = Attributes.getMeasurementNo("measurement-number-sound", attributes);
  const airData = (airMeasurement && queryResultMap.ct_AirData2[airMeasurement]) || [];
  const airLimitsData = (airMeasurement && queryResultMap.ct_AirLimitsData[airMeasurement]) || [];
  const soundData = (soundMeasurement && queryResultMap.ct_SoundData[soundMeasurement]) || [];

  const accessories = R.values(resultQueryResponse)
    .map((r) => mapAccessory(r))
    .filter((a) => a !== undefined) as Array<AccessoryResult>;

  if (!airData || !soundData) {
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  const productCodes = PC.getProductCodes(queryResultMap, properties);

  return createInputMapperSuccess({
    airFlow: airFlow,
    externalPressure: externalPressure,
    speedControl: speedControl,
    limitType: limitType,
    maxSfp: maxSfp,
    minEfficiency: minEfficiency,
    airDensity: airDensity,

    tolerancePlus: tolerancePlus,
    toleranceMinus: toleranceMinus,

    accessories: accessories,

    productCodes: productCodes,
    airData: airData,
    airLimitsData: airLimitsData,
    soundData: soundData,
    attributes: attributes,
    calcParams: calcParams,
  });
}

export function getUserSpeedControl(calcParams: PropertyValueSet.PropertyValueSet): SpeedControl | undefined {
  const fromUser = PropertyValueSet.getInteger("speedControl", calcParams);
  if (fromUser === 1) {
    return "None";
  } else if (fromUser === 2) {
    return "Transformer";
  } else if (fromUser === 3) {
    return "Stepless";
  }
  return undefined;
}

function mapAccessory(r: ResultItemOutputMap): AccessoryResult | undefined {
  for (const resultItem of R.values(r)) {
    if (resultItem.type !== "OutputMapperSuccess") {
      continue;
    }
    if (resultItem.result.type === "ElectricDuctHeater" && resultItem.result.value.pressureDropCurve) {
      return {
        type: "ElectricHeater",
        pressureCurve: resultItem.result.value.pressureDropCurve,
        soundAttenuation: undefined,
      };
    } else if (resultItem.result.type === "WaterCoil") {
      return {
        type: "WaterCoil",
        pressureCurve: resultItem.result.value.pressureDropCurve,
        soundAttenuation: undefined,
      };
    } else if (resultItem.result.type === "DxCoil") {
      return {
        type: "DxCoil",
        pressureCurve: resultItem.result.value.pressureDropCurve,
        soundAttenuation: undefined,
      };
    } else if (resultItem.result.type === "Silencer") {
      return {
        type: "Silencer",
        pressureCurve: resultItem.result.value.pressureDropCurve,
        soundAttenuation: resultItem.result.value.soundAttenuation,
      };
    } else if (resultItem.result.type === "Filter") {
      return {
        type: "Filter",
        pressureCurve: resultItem.result.value.pressureDropCurve,
        soundAttenuation: undefined,
      };
    } else if (resultItem.result.type === "Other") {
      return {
        type: "Other",
        pressureCurve: resultItem.result.value.pressureDropCurve,
        soundAttenuation: undefined,
      };
    } else {
      throw new Error("Unknown accessory type " + resultItem.result.type);
    }
  }
  return undefined;
}
