import * as React from "react";

export function TextAreaInput(props: {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly rows?: number;
  readonly disabled?: boolean;
  readonly name?: string;
  readonly className?: string;
}): JSX.Element {
  return (
    <textarea
      className={`w-full form-input mb-32 p-4 pl-8 ${props.className}`}
      disabled={props.disabled}
      required={true}
      value={props.value}
      rows={props.rows ?? 3}
      id={props.name}
      name={props.name}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => props.onChange(e.currentTarget.value)}
    />
  );
}
