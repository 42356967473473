import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as SC from "shared-lib/system-calculator";
import { Result, ComponentResult } from "../types";
import * as AD from "../../system-calculator/shared/air-density";

const maxDifferencePercent = 5;

export function postFilter(
  _: PropertyValueSet.PropertyValueSet,
  results: ReadonlyArray<Result>,
  calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<Result> {
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>("externalPressure", calcParams);
  const airDensity = PropertyValueSet.getAmount<Quantity.Density>("airDensity", calcParams);

  if (!airFlow || !pressure || !airDensity) {
    return results;
  }

  const requiredAirflowM3h = Amount.valueAs(Units.CubicMeterPerHour, airFlow);
  const requiredPressurePa = Amount.valueAs(
    Units.Pascal,
    AD.calculateExternalPressureWithDensity(pressure, airDensity) || Amount.create(0, Units.Pascal)
  );

  return results.filter((r) => withInTolerance(requiredAirflowM3h, requiredPressurePa, r.result));
}

function withInTolerance(airflowM3h: number, pressurePa: number, result: ComponentResult): boolean {
  if (result.results.CentrifugalFan && result.results.CentrifugalFan.type === "OutputMapperSuccess") {
    const calcResult: SC.CentrifugalFan = result.results.CentrifugalFan.result.value as SC.CentrifugalFan;

    const resultAirflow = calcResult.air.airFlow !== undefined ? calcResult.air.airFlow : undefined;
    const resultPressure = calcResult.air.externalPressure !== undefined ? calcResult.air.externalPressure : undefined;

    if (!resultAirflow || !resultPressure) {
      return false;
    }

    const resultAirFlowM3h = Amount.valueAs(Units.CubicMeterPerHour, resultAirflow);
    const resulPressurePa = Amount.valueAs(Units.Pascal, resultPressure);

    const airFlowDiff = Math.abs(airflowM3h - resultAirFlowM3h);
    const pressureDiff = Math.abs(pressurePa - resulPressurePa);

    if (
      (airFlowDiff / airflowM3h) * 100 <= maxDifferencePercent &&
      (pressureDiff / pressurePa) * 100 <= maxDifferencePercent
    ) {
      return true;
    }

    return false;
  }
  return true;
}
