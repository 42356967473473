import { PropertyValueSet } from "@promaster-sdk/property";
import { RehydrateAction } from "redux-persist";
import * as C from "shared-lib/calculation";

export type Action =
  | SetConfig
  | SetProperties
  | SetCalcParams
  | AddAccessory
  | RemoveAccessory
  | ChangeAccessory
  | UpdateAccessory
  | UpdateAccessoryCalcParams
  | CalculationError
  | SetShowComparison
  | RehydrateAction
  | Reset;

export interface Reset {
  readonly type: "product/RESET";
}

export function reset(): Reset {
  return {
    type: "product/RESET",
  };
}

export interface SetConfig {
  readonly type: "product/SET_CONFIG";
  readonly payload: C.ItemConfig;
}

export function setConfig(config: C.ItemConfig): SetConfig {
  return {
    type: "product/SET_CONFIG",
    payload: config,
  };
}

export interface SetProperties {
  readonly type: "product/SET_PROPERTIES";
  readonly payload: PropertyValueSet.PropertyValueSet;
}

export function setProperties(properties: PropertyValueSet.PropertyValueSet): SetProperties {
  return {
    type: "product/SET_PROPERTIES",
    payload: properties,
  };
}

export interface SetCalcParams {
  readonly type: "product/SET_CALC_PARAMS";
  readonly payload: PropertyValueSet.PropertyValueSet;
}

export function setCalcParams(calcParams: PropertyValueSet.PropertyValueSet): SetCalcParams {
  return {
    type: "product/SET_CALC_PARAMS",
    payload: calcParams,
  };
}

export interface AddAccessory {
  readonly type: "product/ADD_ACCESSORY";
  readonly payload: C.Accessory;
}

export function addAccessory(accessory: C.Accessory): AddAccessory {
  return {
    type: "product/ADD_ACCESSORY",
    payload: accessory,
  };
}

export interface RemoveAccessory {
  readonly type: "product/REMOVE_ACCESSORY";
  readonly payload: string;
}

export function removeAccessory(id: string): RemoveAccessory {
  return {
    type: "product/REMOVE_ACCESSORY",
    payload: id,
  };
}

export interface ChangeAccessory {
  readonly type: "product/CHANGE_ACCESSORY";
  readonly payload: C.Accessory | undefined;
}

export function changeAccessory(accessory: C.Accessory | undefined): ChangeAccessory {
  return {
    type: "product/CHANGE_ACCESSORY",
    payload: accessory,
  };
}

export interface UpdateAccessory {
  readonly type: "product/UPDATE_ACCESSORY";
  readonly payload: C.Accessory;
}

export function updateAccessory(accessory: C.Accessory): UpdateAccessory {
  return {
    type: "product/UPDATE_ACCESSORY",
    payload: accessory,
  };
}

export interface UpdateAccessoryCalcParams {
  readonly type: "product/UPDATE_ACCESSORY_CALC_PARAMS";
  readonly payload: {
    readonly accessoryId: string;
    readonly calcParams: PropertyValueSet.PropertyValueSet;
  };
}

export function updateAccessoryCalcParams(
  accessoryId: string,
  calcParams: PropertyValueSet.PropertyValueSet
): UpdateAccessoryCalcParams {
  return {
    type: "product/UPDATE_ACCESSORY_CALC_PARAMS",
    payload: { accessoryId, calcParams },
  };
}

export interface CalculationError {
  readonly type: "product/CALCULATION_ERROR";
  readonly payload: boolean;
}

export function calculationError(error: boolean): CalculationError {
  return {
    type: "product/CALCULATION_ERROR",
    payload: error,
  };
}

export interface SetShowComparison {
  readonly type: "product/SET_SHOW_COMPARISON";
  readonly payload: boolean;
}

export function setShowComparison(showComparison: boolean): SetShowComparison {
  return {
    type: "product/SET_SHOW_COMPARISON",
    payload: showComparison,
  };
}
