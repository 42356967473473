import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as Types from "../types";
import * as Messages from "../messages";
import { HeatRecoveryUnit, FanAirResult } from "../result-items-types";
import * as FanAir from "../shared/fan-air";
import * as FanSound from "../shared/fan-sound";
import * as Utils from "../shared/utils";
import { Input } from "./types";

const source = "FantechHeatRecoveryUnitCalculator";

function calcTotalSfp(
  supplyFan: FanAirResult,
  extractFan: FanAirResult
): Amount.Amount<Quantity.SpecificFanPower> | undefined {
  const totalSfpKwPerCmps =
    supplyFan.airFlow && extractFan.airFlow && supplyFan.power && extractFan.power
      ? (Amount.valueAs(Units.KiloWatt, supplyFan.power) + Amount.valueAs(Units.KiloWatt, extractFan.power)) /
        (Amount.valueAs(Units.CubicMeterPerSecond, supplyFan.airFlow) +
          Amount.valueAs(Units.CubicMeterPerSecond, extractFan.airFlow))
      : undefined;
  return Utils.maybeAmount(totalSfpKwPerCmps, Units.KiloWattPerCubicMeterPerSecond, 1);
}

export async function calculate(input: Input): Promise<Types.CalculatorResult<HeatRecoveryUnit>> {
  const {
    supplyAirFlow,
    supplyPressure,
    extractAirFlow,
    extractPressure,
    speedControl,
    airData,
    airLimitsData,
    soundData,
    productCodes,
    airDensity,
  } = input;

  const messages: Array<Messages.Message> = [];

  const supplyData = airData.filter((p) => p.part === "Supply");
  const supplyLimits = airLimitsData.filter((p) => p.part === "Supply");
  const extractData = airData.filter((p) => p.part === "Extract");
  const extractLimits = airLimitsData.filter((p) => p.part === "Extract");
  const supplyFan = FanAir.calculate(
    speedControl,
    supplyData,
    supplyLimits,
    supplyAirFlow,
    supplyPressure,
    [],
    airDensity,
    undefined,
    false,
    false
  );
  const supplyCurveId = supplyFan.workingPoint && supplyFan.workingPoint.curveId;
  const extractFan = FanAir.calculate(
    speedControl,
    extractData.length > 0 ? extractData : supplyData,
    extractLimits.length > 0 ? extractLimits : supplyLimits,
    extractAirFlow,
    extractPressure,
    [],
    airDensity,
    supplyCurveId,
    false,
    false
  );

  if (!supplyFan.workingPoint || !extractFan.workingPoint) {
    messages.push(Messages.Error_OutsideValidRange(source));
  }

  if (!supplyFan.voltageLow || !extractFan.voltageLow) {
    messages.push(Messages.Warning_LowLoadOutsideValidRange(source));
  }

  if (!supplyFan.voltageHigh || !extractFan.voltageHigh) {
    messages.push(Messages.Warning_HighLoadOutsideValidRange(source));
  }

  const maxAirFlowDifferencePercent = 50; //CI.getFloat("MaxAirFlowDifferencePercent", calculationInputs, 50);
  const flowDifference = Utils.amountPercentDifference(supplyAirFlow, extractAirFlow);
  if (flowDifference > maxAirFlowDifferencePercent) {
    messages.push(Messages.Error_SupplyExhaustAirFlowDifferenceTooHigh(source));
  }

  const maxPressureDifferencePercent = 50; //CI.getFloat("MaxPressureDifferencePercent", calculationInputs, 50);
  const pressureDifference = Utils.amountPercentDifference(supplyPressure, extractPressure);
  if (pressureDifference > maxPressureDifferencePercent) {
    messages.push(Messages.Error_SupplyExhaustPressureDifferenceTooHigh(source));
  }

  const supplyFanIsOutside =
    supplyFan.airFlow !== undefined &&
    supplyFan.desiredAirFlow !== undefined &&
    supplyFan.desiredPointIsOutsideValidArea;
  const extractFanIsOutside =
    extractFan.airFlow !== undefined &&
    extractFan.desiredAirFlow !== undefined &&
    extractFan.desiredPointIsOutsideValidArea;
  if (supplyFanIsOutside || extractFanIsOutside) {
    messages.push(Messages.Warning_PointAdjustedToClosestValid(source));
  }

  const supplySound = FanSound.calcSound(
    speedControl,
    supplyAirFlow,
    supplyPressure,
    airDensity,
    supplyFan,
    soundData,
    "Supply",
    undefined
  );
  const outdoorSound = FanSound.calcSound(
    speedControl,
    supplyAirFlow,
    supplyPressure,
    airDensity,
    supplyFan,
    soundData,
    "Outdoor",
    undefined
  );
  const extractSound = FanSound.calcSound(
    speedControl,
    extractAirFlow,
    extractPressure,
    airDensity,
    extractFan,
    soundData,
    "Extract",
    undefined
  );
  const exhaustSound = FanSound.calcSound(
    speedControl,
    extractAirFlow,
    extractPressure,
    airDensity,
    extractFan,
    soundData,
    "Exhaust",
    undefined
  );
  const surrSupplySound = FanSound.calcSound(
    speedControl,
    supplyAirFlow,
    supplyPressure,
    airDensity,
    supplyFan,
    soundData,
    "Surrounding, supply",
    undefined
  );
  const surrExhaustSound = FanSound.calcSound(
    speedControl,
    extractAirFlow,
    extractPressure,
    airDensity,
    extractFan,
    soundData,
    "Surrounding, exhaust",
    undefined
  );
  const surroundingSound = FanSound.sumResults(surrSupplySound, surrExhaustSound);

  const curveIndex = (supplyFan.workingPoint && supplyFan.workingPoint.curveIndex) || 0;

  return Types.createCalculatorSuccess(
    [
      { value: curveIndex, descending: false },
      { value: supplyFan.distanceWorkingPointToMaxPoint || Infinity, descending: false },
      {
        value: 100 - (supplyFan.efficiency ? Amount.valueAs(Units.Percent, supplyFan.efficiency) : 0),
        descending: false,
      },
      { value: productCodes.code, descending: false },
      { value: productCodes.variant || "", descending: false },
    ],
    {
      supplyFan: supplyFan,
      extractFan: extractFan,
      exchanger: undefined,
      electricHeater: undefined,
      airDensity: airDensity,
      totalSfp: calcTotalSfp(supplyFan, extractFan),
      supplyOutletTemperature: undefined,

      supplySound: supplySound.octaveBands3rd,
      outdoorSound: outdoorSound.octaveBands3rd,
      exhaustSound: exhaustSound.octaveBands3rd,
      extractSound: extractSound.octaveBands3rd,
      surroundingSound: surroundingSound.octaveBands3rd,
    },
    messages
  );
}
