/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable functional/no-this-expression */
/* eslint-disable @typescript-eslint/no-explicit-any */

// Lib imports
import * as R from "ramda";
import * as React from "react";
import * as LZ from "lz-string";
import * as Sentry from "@sentry/react";
import * as stack from "error-stack-parser";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as C from "shared-lib/calculation";
import { getCurrentCalculationProps } from "app";
import { startUpTime } from "startUpTime";
import { store } from "../../store";

const vendorFileName = getVendorFile();
const mainFileName = getMainFileName();

export interface ErrorObject {
  readonly message: string;
  readonly source: string;
  readonly lineno: number;
  readonly colno: number;
  readonly error: Error | undefined;
}

type TemplateFunction = (e: ErrorObject, s: string | undefined) => React.ReactElement<void>;

interface IProps extends React.Props<ErrorHandler> {
  readonly errorPage?: TemplateFunction | undefined;
  readonly store?: any;
}

interface IState {
  readonly error: ErrorObject | undefined;
  readonly showRootState: boolean;
  readonly stateString: string | undefined;
}

function formatState(store: any): string {
  try {
    return LZ.compressToBase64(JSON.stringify(store.getState()));
  } catch (e) {
    console.error("An additional error occured when extracting state:\n" + e);
    return "An additional error occured when extracting state:\n" + e;
  }
}

function getVendorFile(): string | undefined {
  let error = undefined;
  try {
    // Cause an exception in vendor.js
    R.call(undefined as any, 1);
  } catch (e) {
    error = e;
  }
  if (!error) {
    return undefined;
  }

  const parsed = stack.parse(error);
  if (parsed.length === 0) {
    return undefined;
  }

  return fileNameFromUrl(parsed[0].fileName);
}

function getMainFileName(): string | undefined {
  let error = undefined;
  try {
    throw new Error("");
  } catch (e) {
    error = e;
  }
  if (!error) {
    return undefined;
  }

  const parsed = stack.parse(error);
  if (parsed.length === 0) {
    return undefined;
  }

  return fileNameFromUrl(parsed[0].fileName);
}

function fileNameFromUrl(path: string | undefined): string | undefined {
  if (path) {
    const parts = path.split("/");
    const filePart = parts[parts.length - 1];
    return filePart.split("#")[0].split("?")[0];
  } else {
    return undefined;
  }
}

function isErrorInternal(error: Error | undefined, source: string): boolean {
  // if (error === undefined) {
  //   return false;
  // }

  if (mainFileName === undefined || vendorFileName === undefined) {
    return true;
  }

  const errorContainsVendor =
    error !== undefined && error.stack !== undefined ? error.stack.includes(vendorFileName) : false;
  const errorContainsMain =
    error !== undefined && error.stack !== undefined ? error.stack.includes(mainFileName) : false;
  const sourceContainsVendor = source.includes(vendorFileName);
  const sourceContainsMain = source.includes(mainFileName);

  return errorContainsVendor || errorContainsMain || sourceContainsVendor || sourceContainsMain;
}

// eslint-disable-next-line functional/no-class
export class ErrorHandler extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: undefined,
      showRootState: false,
      stateString: undefined,
    };
  }

  showState() {
    return this.setState({
      ...this.state,
      showRootState: !this.state.showRootState,
      // stateString: formatBase64State(formatState(this.props.store)),
      stateString: formatState(this.props.store),
    });
  }

  showError(): React.ReactElement<IProps> {
    if (this.state.error === undefined) {
      return (
        <div>
          <h1>Undefined error</h1>
        </div>
      );
    } else {
      return this.props.errorPage !== undefined ? (
        this.props.errorPage(this.state.error, this.props.store)
      ) : (
        <div>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "#FEE",
            }}
          >
            <div
              style={{
                padding: "8px",
                margin: "8px",
                border: "1px solid #DCC",
                backgroundColor: "#FFF",
              }}
            >
              <h1 style={{ fontSize: "2em" }}>{this.state.error.message}</h1>
              <h3>
                {this.state.error.source}
                <span style={{ color: "#AAA" }}>:</span>
                <span style={{ color: "#888" }}>{this.state.error.lineno}</span>
                <span style={{ color: "#AAA" }}>:</span>
                <span style={{ color: "#888" }}>{this.state.error.colno}</span>
              </h3>
              {this.state.error.error && this.state.error.error.stack ? <pre>{this.state.error.error.stack}</pre> : ""}
              {this.props.store !== undefined && this.state.showRootState ? (
                <div>
                  <pre style={{ color: "#D00" }}>
                    <a
                      href={"data:txt/plain;base64," + this.state.stateString}
                      download={"diaq_state_" + new Date().getTime() + ".lz"}
                    >
                      Download state
                    </a>
                  </pre>
                </div>
              ) : (
                <button onClick={() => this.showState()}>Generate state</button>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  UNSAFE_componentWillMount(): void {
    window.onunhandledrejection = (function (ev: PromiseRejectionEvent) {
      if (window.location.hostname !== "localhost") {
        const stack = ev.reason.stack || "";
        if (stack.includes(vendorFileName) || stack.includes(mainFileName)) {
          Sentry.captureException(ev.reason, {
            tags: { errortype: "internal", uptime: getTimeDiff(startUpTime, new Date()) },
            extra: { config: getCalculationLogInfo() },
          });
        }
      }
    } as unknown) as (this: WindowEventHandlers, ev: PromiseRejectionEvent) => any;

    window.onerror = (message: string, source: string, lineno: number, colno: number, error: Error | undefined) => {
      if (window.location.hostname !== "localhost") {
        if (isErrorInternal(error, source)) {
          Sentry.captureException(error, {
            tags: { errortype: "internal", uptime: getTimeDiff(startUpTime, new Date()) },
            extra: { config: getCalculationLogInfo() },
          });
          this.setState({
            ...this.state,
            error: {
              message: message,
              source: source,
              lineno: lineno,
              colno: colno,
              error: error,
            },
          });
        }
      }
    };
  }

  render() {
    return <div>{this.state.error === undefined ? this.props.children : this.showError()}</div>;
  }
}

interface ConfigLogInfo {
  readonly properties: string | undefined;
  readonly calcParams: string | undefined;
  readonly accessories: ReadonlyArray<string> | undefined;
}
interface CalculationLogInfo {
  readonly m3ItemNo: string | undefined;
  readonly variant: string | undefined;
  readonly propsConfig: ConfigLogInfo;
  readonly stateConfig: ConfigLogInfo;
}

function getCalculationLogInfo(): CalculationLogInfo | undefined {
  // Called from error handler, so ignore potential exceptions
  try {
    const currentCalculationProps = getCurrentCalculationProps();
    const stateConfig = store.getState().ui.product.config;
    const propsConfig = currentCalculationProps?.config
      ? C.deserializeConfig(currentCalculationProps.config, "use-properties")
      : undefined;
    return {
      m3ItemNo: currentCalculationProps?.m3ItemNo,
      variant: currentCalculationProps?.variant,
      stateConfig: {
        properties: stateConfig && PropertyValueSet.toString(stateConfig.properties),
        calcParams: stateConfig && PropertyValueSet.toString(stateConfig.calcParams),
        accessories: stateConfig && stateConfig.accessories.map((a) => a.productId),
      },
      propsConfig: {
        properties: propsConfig && PropertyValueSet.toString(propsConfig.properties),
        calcParams: propsConfig && PropertyValueSet.toString(propsConfig.calcParams),
        accessories: propsConfig && propsConfig.accessories.map((a) => a.productId),
      },
    };
  } catch {
    return undefined;
  }
}

function getTimeDiff(startDate: Date, endDate: Date): string {
  const start = startDate.getTime();
  const end = endDate.getTime();
  const diff = end - start;
  const days = diff / (24 * 3600 * 1000);
  const hours = (days - Math.floor(days)) * 24;
  const minutes = (hours - Math.floor(hours)) * 60;
  const seconds = (minutes - Math.floor(minutes)) * 60;
  return `${Math.floor(days)} ${Math.floor(hours)}:${Math.floor(minutes)}:${Math.round(seconds)}`;
}
