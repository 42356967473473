export type UrlQuery = UrlBlobQuery | UrlJsonQuery;
export type UrlResponse = UrlBlobResponse | UrlJsonResponse;

// Query for a blob on an url, returns UInt8Array
export interface UrlBlobQuery {
  readonly source: "url";
  readonly type: "Url/Blob";
  readonly url: string;
}
export type UrlBlobResponse = Uint8Array;
export function createUrlBlobQuery(url: string): UrlBlobQuery {
  return { source: "url", type: "Url/Blob", url };
}

// Query for json on an url, returns json
export interface UrlJsonQuery {
  readonly source: "url";
  readonly type: "Url/Json";
  readonly url: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UrlJsonResponse = {} | ReadonlyArray<any>;
export function createUrlJsonQuery(url: string): UrlJsonQuery {
  return { source: "url", type: "Url/Json", url };
}
