import { PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as QP from "shared-lib/query-product";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import * as PU from "shared-lib/product-utils";
import * as Attributes from "../shared/attributes";
import { DllInput } from "./types";

export function versionAsString(propertyValue: number | undefined): string {
  if (propertyValue === undefined) {
    return "unknown";
  }
  switch (propertyValue) {
    case 0:
      return "configured";
    case 1:
      return "standard";
    default:
      return "";
  }
}

export function controlAsString(propertyValue: number | undefined): string {
  if (propertyValue === undefined) {
    return "unknown";
  }
  switch (propertyValue) {
    case 0:
      return "sire";
    case 1:
      return "compact";
    case 3:
      return "fc";
    default:
      return "other";
  }
}

export function getVersion(attributes: Attributes.Attributes): number | undefined {
  const version = Attributes.getString("CL-frico-selection-version", attributes);
  if (version === undefined) {
    return undefined;
  }
  // Configured = 0
  // Standard = 1
  switch (version) {
    case "Configured":
      return 0;
    case "Default":
      return 1;
    default:
      return undefined;
  }
}

export function getManueverVoltage(attributes: Attributes.Attributes): Amount.Amount<Quantity.ElectricPotential> {
  const inputVoltageNom = Attributes.getFloat("input-voltage-NOM", attributes);
  const inputVoltageControlMotor = Attributes.getFloat("input-voltage-control-motor", attributes);
  const voltage = inputVoltageNom || inputVoltageControlMotor || 230;
  return Amount.create(voltage, Units.Volt);
}

export function getMaxPower(attributes: Attributes.Attributes): Amount.Amount<Quantity.Power> | undefined {
  const heatingMethod = PU.getHeatingMethod(attributes);
  if (heatingMethod === "ambient") {
    return undefined;
  } else if (heatingMethod === "electric") {
    const values = attributes.byAttribute["input-power-heater-steps-MULTI"];
    if (values) {
      const powerW = values
        .map((v) => Number.parseFloat(v))
        .filter((v) => Number.isFinite(v))
        .reduce((sofar, p) => Math.max(sofar, p), -1);
      if (powerW !== -1) {
        return Amount.create(powerW, Units.Watt);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  } else if (heatingMethod === "water") {
    const powerW = getFloatFromMultiple(attributes, [
      "heat-power-water-full-in60-out40-air18-BASE-MET",
      "heat-power-water-full-in80-out60-air18-BASE-MET",
      "heat-power-water-full-in40-out30-air18-BASE-MET",
      "heat-power-water-full-in40-out30-BASE-MET",
      "heat-power-water-full-in50-out44-air20-BASE-MET",
      "heat-power-water-full-in60-out40-air15-BASE-MET",
      "heat-power-water-full-in60-out40-BASE-MET",
      "heat-power-water-full-in80-out60-air15-BASE-MET",
      "heat-power-water-full-in80-out60-air40-BASE-MET",
      "heat-power-water-full-in80-out60-BASE-MET",
      "heat-power-water-full-output-BASE-ALL",
    ]);
    if (powerW !== undefined) {
      return Amount.create(powerW, Units.Watt);
    } else {
      return undefined;
    }
  } else {
    return exhaustiveCheck(heatingMethod);
  }
}

export function getHeaterVoltage(
  attributes: Attributes.Attributes
): Amount.Amount<Quantity.ElectricPotential> | undefined {
  const heatingMethod = PU.getHeatingMethod(attributes);
  if (heatingMethod === "electric") {
    const voltageV = getFloatFromMultiple(attributes, [
      "input-voltage-NOM",
      "input-voltage-NOM-heater",
      "input-voltage-heater-NOM",
    ]);
    if (voltageV !== undefined) {
      return Amount.create(voltageV, Units.Volt);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function getHeaterPhases(attributes: Attributes.Attributes): number | undefined {
  const value = Attributes.getString("input-phases-heater-NOM", attributes);
  if (value !== undefined) {
    if (value.startsWith("1")) {
      return 1;
    } else if (value.startsWith("3")) {
      return 3;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

function getFloatFromMultiple(
  attributes: Attributes.Attributes,
  attributeNames: ReadonlyArray<string>
): number | undefined {
  return attributeNames.reduce((sofar, attributeName) => {
    if (attributes.byAttribute[attributeName] && attributes.byAttribute[attributeName].length > 1) {
      console.log(`Warning: expected one value, found multiple values for attribute: ${attributeName}`);
    }
    const n = Attributes.getFloat(attributeName, attributes);
    if (n !== undefined) {
      return n;
    } else {
      return sofar;
    }
  }, undefined);
}

export function getDllInputs(attributes: Attributes.Attributes): DllInput | undefined {
  if (Attributes.getString("DLL-input-eurocoil-module", attributes) === "eurocoil") {
    const code = Attributes.getString("DLL-input-eurocoil-code", attributes);
    const geometry = Attributes.getString("DLL-input-eurocoil-geometry", attributes);
    const tubeDiameter = Attributes.getString("DLL-input-eurocoil-tube-diameter", attributes);
    const finHeight = Attributes.getFloat("DLL-input-eurocoil-fin-height", attributes);
    const finLength = Attributes.getFloat("DLL-input-eurocoil-fin-length", attributes);
    const rows = Attributes.getFloat("DLL-input-eurocoil-rows", attributes);
    const finSpacing = Attributes.getFloat("DLL-input-eurocoil-fin-spacing", attributes);
    const circuits = Attributes.getInt("DLL-input-eurocoil-circuits", attributes);
    const unusedTubes = Attributes.getInt("DLL-input-eurocoil-unused-tubes", attributes);
    const finType = Attributes.getString("DLL-input-eurocoil-fin-type", attributes);
    if (
      geometry === undefined ||
      tubeDiameter === undefined ||
      finHeight === undefined ||
      finLength === undefined ||
      rows === undefined ||
      finSpacing === undefined ||
      circuits === undefined ||
      unusedTubes === undefined ||
      finType === undefined
    ) {
      return undefined;
    }
    return {
      type: "eurocoil",
      code,
      geometry,
      tubeDiameter,
      finHeight,
      finLength,
      rows,
      finSpacing,
      circuits,
      unusedTubes,
      finType,
    };
  } else {
    const attributeDataFriterm = [
      { name: "Code", attributeName: "DLL-input-friterm-code", default: "" },
      { name: "CorrectionFactorPower", attributeName: "DLL-input-friterm-factor-capacity", default: 1 },
      { name: "Circuits", attributeName: "DLL-input-friterm-circuits", default: undefined },
      {
        name: "CorrectionFactorWaterPressure",
        attributeName: "DLL-input-friterm-factor-water-pressure-drop",
        default: 1,
      },
      { name: "FinSpacing", attributeName: "DLL-input-friterm-fin-spacing", default: undefined },
      { name: "FinThickness", attributeName: "DLL-input-friterm-fin-thickness", default: undefined },
      { name: "FinsMaterial", attributeName: "DLL-input-friterm-finns-material", default: undefined },
      { name: "Geometry", attributeName: "DLL-input-friterm-geometry", default: undefined },
      { name: "Length", attributeName: "DLL-input-friterm-length", default: undefined },
      { name: "ConnectionIn", attributeName: "DLL-input-friterm-manifold-inlet-diameter", default: undefined },
      { name: "ConnectionMaterial", attributeName: "DLL-input-friterm-manifold-material", default: undefined },
      { name: "ConnectionOut", attributeName: "DLL-input-friterm-manifold-outlet-diameter", default: undefined },
      { name: "Module", attributeName: "DLL-input-friterm-module", default: undefined },
      { name: "Rows", attributeName: "DLL-input-friterm-rows", default: undefined },
      { name: "TubeMaterial", attributeName: "DLL-input-friterm-tube-material", default: undefined },
      { name: "TubeThickness", attributeName: "DLL-input-friterm-tube-thickness", default: undefined },
      { name: "NumberOfTubes", attributeName: "DLL-input-friterm-tubes", default: undefined },
      { name: "BatterySupplier", attributeName: "DLL-input-friterm-module", default: undefined },
    ];

    const attributeDataRoenest = [
      { name: "Circuits", attributeName: "DLL-input-roenest-circuits", default: undefined },
      { name: "FinSpacing", attributeName: "DLL-input-roenest-fins", default: undefined },
      { name: "FinsMaterial", attributeName: "DLL-input-roenest-finns-material", default: undefined },
      { name: "Geometry", attributeName: "DLL-input-roenest-geometry", default: undefined },
      { name: "Height", attributeName: "DLL-input-roenest-height", default: undefined },
      { name: "Length", attributeName: "DLL-input-roenest-length", default: undefined },
      { name: "Rows", attributeName: "DLL-input-roenest-rows", default: undefined },
      { name: "RowsMaterial", attributeName: "DLL-input-roenest-rows-material", default: undefined },
      { name: "FreeTubes", attributeName: "DLL-input-roenest-free-tubes", default: undefined },
      { name: "Header", attributeName: "DLL-input-roenest-header", default: "" },
      { name: "HeaderConf", attributeName: "DLL-input-roenest-header-conf", default: "T" },
      { name: "BatterySupplier", attributeName: "DLL-input-roenest-module", default: undefined },
    ];

    const dllName = attributes.byCollection["DLL-input-friterm-COL"] ? "friterm" : "roenest";
    const attributeData = dllName === "friterm" ? attributeDataFriterm : attributeDataRoenest;

    const inputs = attributeData.map((d) => {
      const value =
        d.default !== undefined
          ? Attributes.getStringOrDefault(d.attributeName, attributes, d.default)
          : Attributes.getString(d.attributeName, attributes);
      if (value === undefined) {
        console.log(`Warning: Missing dll input attribute: ${d.attributeName}`);
      }
      return {
        property_filter: PropertyFilter.Empty,
        name: d.name,
        value: value,
      };
    });
    if (inputs.every((i) => i.value !== undefined)) {
      return {
        type: dllName,
        calculationInputs: inputs as ReadonlyArray<QP.CalculationInputs>,
      };
    } else {
      return undefined;
    }
  }
}

export function getControl(attributes: Attributes.Attributes): number {
  const control = Attributes.getString("CL-frico-selection-control", attributes);

  if (control === undefined) {
    return 2;
  }
  // SIRe = 0
  // Compact = 1
  // Other = 2
  // FC = 3

  switch (control) {
    case "SIRe":
      return 0;
    case "Compact":
      return 1;
    case "FC":
      return 3;
    default:
      return 2;
  }
}
