/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import { SelectButton } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import * as Shared from "./shared";

export interface MultiPropertySelectorProps {
  readonly productId: string;
  readonly property: PromasterPropertiesSelector.Property;
  readonly selections: Shared.Selections;
  readonly selectionsChanged: Shared.SelectionsChanged;
  readonly variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly translate: Texts.TranslateFunction;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly showAdvanced: boolean;
  readonly images: Shared.Images;
}

export function MultiPropertySelector(props: MultiPropertySelectorProps): JSX.Element {
  const {
    productId,
    property,
    selections,
    variants,
    selectionsChanged,
    translate,
    filterPrettyPrint,
    showAdvanced,
    images,
  } = props;
  const values = Shared.getIntegers(property.name, selections) || [];
  return (
    <div className="flex flex-col space-y-4">
      {property.value.map((v) => {
        const intValue = v.value ? PropertyValue.getInteger(v.value) || 0 : 0;
        const translated = translate(Texts.property_value(productId, property.name, intValue));
        const label = `${translated} ${showAdvanced ? `(${intValue})` : ""}`;
        const error = Shared.isFilterValid(variants, v.property_filter)
          ? undefined
          : filterPrettyPrint(v.property_filter);
        const selected = values.some((val) => val === intValue);
        const image = v.image ? images[v.image] : undefined;
        return (
          <SelectButton
            key={intValue}
            label={label}
            selected={selected}
            title={error}
            disabled={!!error}
            onClick={() => {
              const newValues = selected ? values.filter((val) => val !== intValue) : [...values, intValue];
              selectionsChanged(Shared.setIntegers(property.name, newValues, selections));
            }}
            imageUrl={image}
          />
        );
      })}
    </div>
  );
}
