import * as R from "ramda";
import * as SC from "shared-lib/system-calculator";
import { TableResultValue } from "./types";

export function getResultItemValue(path: string, resultItemMap: SC.ResultItemMap): TableResultValue {
  const pathParts = path.split(".");
  if (!pathParts || pathParts.length === 0) {
    return undefined;
  }
  const name = pathParts[0];
  const resultItem = resultItemMap[name];
  if (!resultItem) {
    return resultItem;
  }

  // The first part of the path is the result items name, the rest is the path inside the result items value
  const value = R.path(R.drop(1, pathParts), resultItem.value);
  return value as TableResultValue;
}
