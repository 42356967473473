import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Texts from "shared-lib/language-texts";
import * as C from "shared-lib/calculation";
import * as UserSettings from "shared-lib/user-settings";
import { NavigateToItemNo } from "types";
import * as Crm from "shared-lib/crm";

export type Props = OwnProps & StateProps & Response;

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly translate: Texts.TranslateFunction;
  readonly crm: Crm.CrmConfig | undefined;
  readonly hideErrors: boolean;
  readonly shopUrl?: string;
  readonly productsProps: ReadonlyArray<ProductProps>;
  readonly enablePocDiagram?: boolean;
}

export interface ProductProps {
  readonly productId: string;
  readonly variantId: string | undefined;
  readonly config: C.ItemConfig;
  readonly calcParamsChanged: (calcParams: PropertyValueSet.PropertyValueSet) => void;
  readonly accCalcParamsChanged: (accessoryId: string, calcParams: PropertyValueSet.PropertyValueSet) => void;
  readonly navigateToItemNo?: NavigateToItemNo;
}

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export interface Response {
  readonly metaTables: MetaTables;
  readonly productResponses: ProductResponses;
}

export interface ProductResponses {
  readonly [configKey: string]: ProductResponse;
}

export interface ProductResponse {
  readonly productTables: ProductTables | undefined;
  readonly calculationResponse: C.Response;
  readonly accessoryTables: AccessoryTables;
  readonly codeTables: {
    readonly [productId: string]: CodeTables;
  };
}

interface AccessoryTables {
  readonly [productId: string]: ProductTables;
}

export interface MetaTables {
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_ResultViews: QP.ResultViewsTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
  readonly ct_ResultVisualizerParamsTable: QP.ResultVisualizerParamsTableTable;
}

export interface ProductTables {
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_Accessories: QP.AccessoriesTable;
  readonly ct_Attributes2: QP.AttributesTable;
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export interface CodeTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export function createConfigKey(productId: QP.ProductId, config: C.ItemConfig): string {
  const productPart = createProductKeyPart(productId, config.properties, config.calcParams);
  const accessoriesPart = config.accessories
    .map((a) => createProductKeyPart(a.id, a.properties, a.calcParams))
    .sort()
    .join("_");
  return `${productPart}_${accessoriesPart}`;
}

function createProductKeyPart(
  productId: string,
  properties: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): string {
  return `${productId}_${PropertyValueSet.toString(properties)}_${PropertyValueSet.toString(calcParams)}`;
}

export function getProductResponse(props: Props, productProps: ProductProps): ProductResponse {
  const key = createConfigKey(productProps.productId, productProps.config);
  return props.productResponses[key];
}
