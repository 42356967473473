import { UrlCacheState } from "./types";
import { UrlBlobQuery, UrlBlobResponse, UrlJsonQuery, UrlJsonResponse } from "../query-types";

export function selectUrlBlob(cache: UrlCacheState, query: UrlBlobQuery): UrlBlobResponse | undefined {
  return cache.blobByUrl[query.url];
}

export function selectUrlJson(cache: UrlCacheState, query: UrlJsonQuery): UrlJsonResponse | undefined {
  return cache.jsonByUrl[query.url];
}
