/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import { Amount } from "uom";
import { AnyQuantity } from "shared-lib/uom";
import * as SC from "shared-lib/system-calculator";
import { Result, ResultWithSortNo, SortValueType, SortParams } from "./types";

export function getMatchingAndSortedResults(
  results: ReadonlyArray<Result>,
  overallParams: SortParams,
  groupParams: SortParams | undefined
): ReadonlyArray<ResultWithSortNo> {
  const filteredResults = results.filter(
    (r) => R.values(r.result.results).length > 0 && R.values(r.result.results)[0].type === "OutputMapperSuccess"
  );

  const withSortNo = R.sort((a: Result, b: Result) => {
    const aResult = R.values(a.result.results)[0];
    const aValues = aResult.type === "OutputMapperSuccess" && aResult.sortValues;
    const bResult = R.values(b.result.results)[0];
    const bValues = bResult.type === "OutputMapperSuccess" && bResult.sortValues;
    if (!aValues) {
      return -1;
    } else if (!bValues) {
      return 1;
    } else {
      for (let i = 0; i < Math.min(aValues.length, bValues.length); ++i) {
        const descending = aValues[i].descending === true && bValues[i].descending === true;
        const compareResult = compareValues(aValues[i].value, bValues[i].value, descending);
        if (compareResult !== 0) {
          return compareResult;
        }
      }
      return 0;
    }
  }, filteredResults).map((r, i) => ({ result: r, sortNo: i + 1 }));

  const overallSorted = sortResults(
    withSortNo,
    overallParams.sortPath,
    overallParams.descending,
    overallParams.sortType
  );

  if (groupParams) {
    const grouped = R.groupBy((r) => r.result.productName, overallSorted);
    const groupKeys = R.keys(grouped);
    const sortedGroups = groupKeys.map((key) =>
      sortResults(grouped[key], groupParams.sortPath, groupParams.descending, groupParams.sortType)
    );
    return R.unnest(sortedGroups);
  } else {
    return overallSorted;
  }
}

function sortResults(
  results: ReadonlyArray<ResultWithSortNo>,
  sortPath: string,
  sortDescending: boolean,
  sortType: SC.ResultItemType | undefined
): ReadonlyArray<ResultWithSortNo> {
  return R.sort((a: ResultWithSortNo, b: ResultWithSortNo) => {
    if (sortPath === "sort_no") {
      return compareValues(a.sortNo, b.sortNo, sortDescending);
    } else if (sortPath === "product_code") {
      return compareValues(a.result.result.itemName, b.result.result.itemName, sortDescending);
    } else if (sortPath === "m3") {
      return compareValues(parseInt(a.result.result.m3, 10), parseInt(b.result.result.m3, 10), sortDescending);
    } else if (sortPath === "price") {
      return compareValues(a.result.result.price, b.result.result.price, sortDescending);
    } else if (sortType) {
      const aValue = SC.getResultItemValue<any>(sortType, sortPath, a.result.result.results);
      const bValue = SC.getResultItemValue<any>(sortType, sortPath, b.result.result.results);
      return compareValues(aValue, bValue, sortDescending);
    } else {
      return 0;
    }
  }, results);
}

function compareValues(lhs: SortValueType, rhs: SortValueType, sortDescending: boolean): number {
  if (lhs === rhs) {
    return 0;
  }
  if (lhs === undefined) {
    return -1;
  }
  if (rhs === undefined) {
    return 1;
  }
  const lhsType = typeof lhs;
  const rhsType = typeof rhs;

  // Infinity gets converted null when the results are stringified to JSON on
  // the calculation server and/or by redux-presist.
  const a = rhsType === "number" && lhs === null ? Infinity : lhs;
  const b = lhsType === "number" && rhs === null ? Infinity : rhs;

  const aType = typeof a;
  const bType = typeof b;
  if (aType !== bType) {
    return 0;
  }
  if (aType === "string") {
    const aString = a as string;
    const bString = b as string;
    return sortDescending ? bString.localeCompare(aString) : aString.localeCompare(bString);
  } else if (aType === "object") {
    const aAmount = a as Amount.Amount<AnyQuantity>;
    const bAmount = b as Amount.Amount<AnyQuantity>;
    return sortDescending ? Amount.compareTo(bAmount, aAmount) : Amount.compareTo(aAmount, bAmount);
  } else if (aType === "number") {
    const aNumber = a as number;
    const bNumber = b as number;
    if (aNumber < bNumber) {
      return sortDescending ? 1 : -1;
    } else if (aNumber > bNumber) {
      return sortDescending ? -1 : 1;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
