import * as UserSettingsShared from "shared-lib/user-settings";
import { PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import * as LZ from "lz-string";
import { Amount } from "uom";
import { CustomUnitsLookup } from "shared-lib/uom";

export interface ItemConfig {
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly accessories: ReadonlyArray<Accessory>;
}

export interface ItemConfigWithMeta extends ItemConfig {
  readonly meta?: UserSettingsShared.State;
}

export interface Accessory {
  readonly id: string;
  readonly parentId: string | undefined;
  readonly productId: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
}

export const emptyItemConfig: ItemConfig = {
  properties: PropertyValueSet.Empty,
  calcParams: PropertyValueSet.Empty,
  accessories: [],
};

interface CompactItemConfig {
  readonly properties: string;
  readonly calcParams: string;
  readonly accessories: ReadonlyArray<CompactAccessory>;
  readonly meta?: UserSettingsShared.State;
}

interface CompactAccessory {
  readonly id: string;
  readonly parentId: string | undefined;
  readonly productId: string;
  readonly properties: string;
  readonly calcParams: string;
}

export function serializeConfig(config: ItemConfigWithMeta): string {
  const compact: CompactItemConfig = {
    properties: PropertyValueSet.toString(config.properties),
    calcParams: PropertyValueSet.toString(stripCalcParamsDecimalNumberLimit(config.calcParams)),
    accessories: config.accessories.map((a) => ({
      id: a.id,
      parentId: a.parentId,
      productId: a.productId,
      properties: PropertyValueSet.toString(a.properties),
      calcParams: PropertyValueSet.toString(stripCalcParamsDecimalNumberLimit(a.calcParams)),
    })),
    meta: config.meta,
  };
  const zipped = LZ.compressToEncodedURIComponent(JSON.stringify(compact));
  return zipped;
}

export function deserializeConfig(
  serialized: string,
  propertiesOption: "ignore-properties" | "use-properties"
): ItemConfigWithMeta {
  const unzipped = LZ.decompressFromEncodedURIComponent(serialized);
  const compact: CompactItemConfig = JSON.parse(unzipped);
  const config: ItemConfigWithMeta = {
    properties:
      propertiesOption === "use-properties"
        ? PropertyValueSet.fromString(compact.properties, CustomUnitsLookup)
        : PropertyValueSet.Empty,
    calcParams: PropertyValueSet.fromString(compact.calcParams, CustomUnitsLookup),
    accessories: compact.accessories.map((a) => ({
      id: a.id,
      parentId: a.parentId,
      productId: a.productId,
      properties: PropertyValueSet.fromString(a.properties, CustomUnitsLookup),
      calcParams: PropertyValueSet.fromString(a.calcParams, CustomUnitsLookup),
    })),
    meta: compact.meta,
  };
  return config;
}

function stripCalcParamsDecimalNumberLimit(
  calcParams: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  const calcParamsFullPrecision = PropertyValueSet.getPropertyNames(calcParams).reduce((sofar, propertyName) => {
    const value = PropertyValueSet.get(propertyName, calcParams);
    if (!value) {
      return sofar;
    } else if (value.type === "amount") {
      const amount = value.value;
      const preciseAmount = Amount.create(amount.value, amount.unit);
      const preciseValue = PropertyValue.create("amount", preciseAmount);
      return PropertyValueSet.set(propertyName, preciseValue, sofar);
    } else {
      return PropertyValueSet.set(propertyName, value, sofar);
    }
  }, PropertyValueSet.Empty);
  return calcParamsFullPrecision;
}
