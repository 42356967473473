import * as React from "react";
import { PropertyValue } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import * as Texts from "shared-lib/language-texts";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import { Combobox, ComboboxOption } from "client-lib/elements";
import { Images, ValidateFilterFn } from "./types";

export interface ComboboxPropertySelectorProps {
  readonly productId: string;
  readonly property: PromasterPropertiesSelector.Property;
  readonly value: number | undefined;
  readonly onChange: (newValue: number) => void;
  readonly translate: Texts.TranslateFunction;
  readonly translatePropertyValue?: PromasterPropertiesSelector.TranslatePropertyValue;
  readonly validateFilter: ValidateFilterFn;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly showAdvanced: boolean;
  readonly images?: Images;
  readonly hideInvalidValues?: boolean;
  readonly imageCSSFilter?: string;
}

export function ComboboxPropertySelector(props: ComboboxPropertySelectorProps): JSX.Element {
  const { property, value, hideInvalidValues, imageCSSFilter, onChange } = props;
  const options = property.value.map((i) => buildItem(i, props)).filter((o) => !hideInvalidValues || !o.error);
  return (
    <Combobox
      className="w-1/2 min-w-224"
      imageCSSFilter={imageCSSFilter}
      value={value !== undefined ? value.toString() : undefined}
      onChange={(v) => onChange(parseInt(v, 10))}
      options={options}
    />
  );
}

function buildItem(
  propertyValue: PromasterPropertiesSelector.PropertyValueItem,
  props: ComboboxPropertySelectorProps
): ComboboxOption {
  const {
    productId,
    property,
    showAdvanced,
    filterPrettyPrint,
    translate,
    translatePropertyValue,
    images,
    validateFilter,
  } = props;
  const intValue = propertyValue.value ? PropertyValue.getInteger(propertyValue.value) || 0 : 0;
  const translated = translatePropertyValue
    ? translatePropertyValue(property.name, intValue)
    : translate(Texts.property_value(productId, property.name, intValue));
  const label = `${translated} ${showAdvanced ? `(${intValue})` : ""}`;
  const error = validateFilter(propertyValue.property_filter)
    ? undefined
    : filterPrettyPrint(propertyValue.property_filter);
  const image = propertyValue.image && images ? images[propertyValue.image] : undefined;
  return {
    value: intValue.toString(),
    label: label,
    image: image,
    error: error,
  };
}
