import * as React from "react";
import * as PU from "shared-lib/product-utils";
import { Dispatch } from "client-lib/redux-integration";
import { Button, HToolbar } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import { TranslateFunction } from "shared-lib/language-texts";
import * as Search from "client-lib/search";
import * as SpecificationText from "../../frico-specification-text-epim/actions";
import { Item } from "../../frico-specification-text-epim/types";
import { NavigateToFricoSpecificationText } from "../../../types";

export interface Props {
  readonly translate: Texts.TranslateFunction;
  readonly navigateToSpecificationText: NavigateToFricoSpecificationText;
  readonly dispatch: Dispatch<SpecificationText.Action>;
  readonly selections: PU.Selections;
  readonly calculationState: Search.State;
  readonly crmSavedResults: ReadonlyArray<string>;
}

export function FricoFooter(props: Props): JSX.Element | null {
  const { translate } = props;
  return (
    <div>
      {createDisclaimer(translate)}
      {addToSpecificationTextButton(props, translate)}
    </div>
  );
}

function createDisclaimer(translate: TranslateFunction): React.ReactElement<{}> {
  return (
    <div className="text-xs p-16">
      <div>{translate(Texts.frico_disclaimer_row_1())}</div>
      <div>{translate(Texts.frico_disclaimer_row_2())}</div>
    </div>
  );
}

function createSpecificationItems(
  crmSavedResults: ReadonlyArray<string>,
  calculationState: Search.State
): ReadonlyArray<Item> {
  if (calculationState.type === "Calculating") {
    return [];
  }
  const items = crmSavedResults.reduce((acc, curr) => {
    const savedResult = calculationState.results.find((r) => r.id === curr);
    if (savedResult === undefined) {
      return acc;
    }
    const additionalData =
      (savedResult.result.additionalData &&
        savedResult.result.additionalData.type === "aircurtain" &&
        savedResult.result.additionalData.data) ||
      undefined;
    const multi = additionalData && additionalData.twoSides === 1 ? 2 : 1;
    const quantity = ((additionalData && additionalData.quantity) || 1) * multi;
    const { result } = savedResult;
    acc.push({
      sortNo: -1,
      itemName: result.itemName,
      m3ItemNo: result.m3,
      variantNo: result.variant || "",
      quantity: quantity,
      productId: result.productId,
      properties: result.properties,
      calcParams: result.calcParams,
    });

    return acc;
  }, Array<Item>());

  return items;
}

function addToSpecificationTextButton(props: Props, translate: TranslateFunction): React.ReactElement<{}> | undefined {
  const { crmSavedResults, calculationState, dispatch } = props;
  if (calculationState.type === "Calculating") {
    return undefined;
  }
  return (
    <HToolbar className="print:hidden">
      <Button
        clicked={() => {
          const items = createSpecificationItems(crmSavedResults, calculationState);
          if (items.length > 0) {
            dispatch(SpecificationText.globalAddVariants(items, props.selections));
            document.body.scrollTop = 0;
            if (document.documentElement) {
              document.documentElement.scrollTop = 0;
            }
            props.navigateToSpecificationText();
          }
        }}
        label={translate(Texts.add_to_specification_text())}
      />
    </HToolbar>
  );
}
