import { createSelector, Selector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import * as Texts from "shared-lib/language-texts";
import * as ProductUtils from "shared-lib/product-utils";
import { RootState } from "../../types";
import { FricoHeatingCalculationEpimContainerComponent } from "./container-component";
import { OwnProps, StateProps, Response } from "./types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.fricoHeatingCalculationEpim,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (s: RootState) => s.ui.product.config,
  (state, showAdvanced, userSettings, itemConfig) => ({
    state: state,
    showAdvanced: showAdvanced,
    userSettings: userSettings,
    itemConfig: itemConfig,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_SearchProducts: QP.tableByProductId(QP.fricoProductId, "ct_SearchProducts"),
    translateTables:
      response &&
      response.ct_SearchProducts &&
      Texts.getTablesQuery([QP.fricoProductId, ...response.ct_SearchProducts.map((p) => p.product)]),
    ct_LanguageMapping: QP.tableByProductId(QP.fricoProductId, "ct_LanguageMapping"),
    tablesForProducts: tablesForProductsQuery(getMainProducts(response)),
    ct_SavedResultColumns: QP.tableByProductId(QP.fricoProductId, "ct_SavedResultColumns"),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    marketTables: ProductUtils.createMarketTablesQuery(ownProps.ecomUrl, ownProps.market),
  });
}

function tablesForProductsQuery(products: ReadonlyArray<string> | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (products === undefined) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const product of products) {
    map[product] = QP.tablesByProductId(product, [
      "property",
      "code",
      "ct_ItemNumberStatus",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_ProductScores",
      "ct_ResultItems",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

function getMainProducts(response: Response | undefined): ReadonlyArray<string> | undefined {
  // if (!response || !response.ct_BrowserProducts || !response.marketTables) {
  if (!response || !response.ct_SearchProducts) {
    return undefined;
  }
  // return ProductUtils.filterProductsByMarket(response.marketTables, response.ct_BrowserProducts.map(p => p.product));
  return response.ct_SearchProducts.map((p) => p.product);
}

export const FricoHeatingCalculationEpimContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(FricoHeatingCalculationEpimContainerComponent);
