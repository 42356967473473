import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import { LinkButton } from "client-lib/elements";
import { TranslateFunction } from "shared-lib/language-texts";
import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";

export interface TableRow {
  readonly itemName: string;
  readonly m3ItemNo: string;
  readonly variantNo: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
}

export interface ProductTableProps {
  readonly items: ReadonlyArray<TableRow>;
  readonly translate: TranslateFunction;
  readonly nextSortNo?: number;
  readonly highLightItemNo?: string;
  readonly addedItems: ReadonlyArray<string> | undefined;
  readonly ct_itemNumberStatus: QP.ItemNumberStatusTable;
  readonly productId: string;
  readonly selectedRowCallBack: (
    itemName: string,
    m3ItemNo: string,
    variantNo: string,
    quantity: number,
    sortNo: number | undefined,
    productId: string,
    properties: PropertyValueSet.PropertyValueSet
  ) => void;
}

export function ProductTable(props: ProductTableProps): React.ReactElement<ProductTableProps> {
  const { translate, items } = props;
  const itemsSorted = items.slice(0).sort((a, b) => a.itemName.localeCompare(b.itemName));
  return (
    <div>
      <table>
        {renderTableHead(translate, props)}
        <tbody>{itemsSorted.map((r) => renderTableRow(r, props))}</tbody>
      </table>
    </div>
  );
}

function renderTableHead(translate: TranslateFunction, props: ProductTableProps): React.ReactElement<{}> {
  const { nextSortNo } = props;
  const selectOrAdd = nextSortNo === undefined ? translate(Texts.select_product_fr()) : translate(Texts.add_product());

  return (
    <thead>
      <tr>
        <th key="sort_no">{translate(Texts.product_name_text())}</th>
        <th key="m3ItemNo">{translate(Texts.product_no())}</th>
        <th key="selectOrAdd">{selectOrAdd}</th>
      </tr>
    </thead>
  );
}

function renderTableRow(row: TableRow, props: ProductTableProps): React.ReactElement<{}> {
  const {
    translate,
    highLightItemNo,
    addedItems,
    productId,
    nextSortNo,
    selectedRowCallBack,
    ct_itemNumberStatus,
  } = props;
  const itemStatusRow = ct_itemNumberStatus.find((i) => i.item_no === row.m3ItemNo);
  const itemStatus = (itemStatusRow && itemStatusRow.status) || 0;
  const variant = PropertyValueSet.getInteger("variant", row.properties);
  const variantName = variant ? translate(Texts.property_value(productId, "variant", variant)) : "";
  return (
    <tr
      key={row.itemName + "-" + row.m3ItemNo + "-" + variantName}
      className={highLightItemNo === row.m3ItemNo ? "bg-gray-50" : ""}
    >
      <td className={itemStatus >= 50 ? "text-danger" : ""}>{row.itemName}</td>
      <td>{row.m3ItemNo}</td>
      {nextSortNo !== undefined ? (
        <td>
          {addedItems && addedItems.find((itemNumber) => itemNumber === row.m3ItemNo) === undefined ? (
            <LinkButton
              onClick={() =>
                selectedRowCallBack(row.itemName, row.m3ItemNo, row.variantNo, 1, nextSortNo, productId, row.properties)
              }
            >
              {translate(Texts.add())}
            </LinkButton>
          ) : (
            translate(Texts.added())
          )}
        </td>
      ) : (
        <td>
          {highLightItemNo === row.m3ItemNo ? (
            translate(Texts.selected())
          ) : (
            <LinkButton
              onClick={() =>
                selectedRowCallBack(row.itemName, row.m3ItemNo, row.variantNo, 0, undefined, productId, row.properties)
              }
            >
              {translate(Texts.select())}
            </LinkButton>
          )}
        </td>
      )}
    </tr>
  );
}
