import * as QP from "shared-lib/query-product";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Attributes from "../shared/attributes";
import { getSearchSpeedControl } from "./selector";
import { getUserSpeedControl } from "./input-mapper";

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  if (Attributes.getIntOrDefault("export-promaster-systemair-fan-selector", variantAttributes, 0) !== 1) {
    // console.log("export-promaster-systemair-fan-selector");
    return false;
  }

  const isSearchingStandardFan = searchAttributes.some(
    (a) => a.attribute === "CL-systemair-fan-sele-app-standard" && a.value === "1"
  );
  const isStandardFan = Attributes.matchesAttribute("CL-systemair-fan-sele-app-standard", variantAttributes, "1");
  if (isSearchingStandardFan && isStandardFan) {
    const matchErp = Attributes.matchAttribute("ECO-ErP-ready-MULTI", searchAttributes, variantAttributes);
    const notErpRelevant = Attributes.matchesAttribute("ECO-ErP-ready-MULTI", variantAttributes, "Not ErP relevant");
    if (!notErpRelevant && !matchErp) {
      return false;
    }
  }

  return (
    Attributes.matchCollection("CL-systemair-fan-sele-app-COL", searchAttributes, variantAttributes) &&
    matchMaxAirTemperature(variantAttributes, searchVariant, calcParams) &&
    Attributes.matchInputFrequency(searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("PROP-speed-regulation-type-MULTI", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("enclosure-class-motor", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("insulation-class-motor", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("input-voltage-NOM", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("input-phases", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-product-type-fan", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CL-motor-type", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("td-PROP-fan-sound-insulated", searchAttributes, variantAttributes)
  );
}

export function matchMaxAirTemperature(
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  const maxTemp = PropertyValueSet.getAmount<Quantity.Temperature>("maxTemp", searchVariant);
  if (!maxTemp) {
    return true;
  }
  const application = PropertyValueSet.getInteger("application", searchVariant);
  const maxTempC = application === 120 ? 120 : application === 200 ? 200 : Amount.valueAs(Units.Celsius, maxTemp);
  const speedControl = getSearchSpeedControl(getUserSpeedControl(calcParams), variantAttributes);
  const airTempTransNormalCondition = Attributes.getFloat(
    "air-temp-trans-normal-condition-MAX-BASE-ALL",
    variantAttributes
  );
  const airTempTransSpeedControlled = Attributes.getFloat(
    "air-temp-trans-speed-controlled-MAX-BASE-ALL",
    variantAttributes
  );
  let airTempTrans120min = Attributes.getFloat("air-temp-trans-120min-MAX-BASE-ALL", variantAttributes);
  if (!airTempTrans120min) {
    airTempTrans120min = speedControl === "Transformer" ? airTempTransSpeedControlled : airTempTransNormalCondition;
  }
  if (application === 120 || application === 200) {
    const match = airTempTrans120min !== undefined && maxTempC <= airTempTrans120min;
    // if (!match) {
    //   console.log({ application, maxTempC, airTempTransNormalCondition, airTempTrans120min });
    // }
    return match;
  } else if (speedControl === "Transformer" || speedControl === "Stepless") {
    const match = airTempTransSpeedControlled !== undefined && maxTempC <= airTempTransSpeedControlled;
    // if (!match) {
    //   console.log({ speedControl, maxTempC, airTempTransSpeedControlled });
    // }
    return match;
  } else if (speedControl === "None") {
    const match = airTempTransNormalCondition !== undefined && maxTempC <= airTempTransNormalCondition;
    // if (!match) {
    //   console.log({ speedControl, maxTempC, airTempTransNormalCondition });
    // }
    return match;
  }
  return true;
}
