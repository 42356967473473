import * as Interpolation from "shared-lib/interpolation";
import { AnyQuantity } from "shared-lib/uom";
import * as R from "ramda";
import * as QP from "shared-lib/query-product";
import { Unit } from "uom";
import { Curve } from "../result-items-types";

export function getString(name: string, calculationInputs: QP.CalculationInputsTable, defaultValue?: string): string {
  const input = calculationInputs.find((i) => i.name === name);
  if (!input) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error("Could not find calculation input " + name);
  }
  return input.value;
}

export function getFloat(
  name: string,
  calculationInputs: QP.CalculationInputsTable,
  defaultValue: number | undefined = undefined
): number {
  const input = calculationInputs.find((i) => i.name === name);
  if (!input) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error("Could not find calculation input " + name);
  }
  return parseFloat(input.value);
}

export function getInt(
  name: string,
  calculationInputs: QP.CalculationInputsTable,
  defaultValue: number | undefined = undefined
): number {
  const input = calculationInputs.find((i) => i.name === name);
  if (!input) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error("Could not find calculation input " + name);
  }
  return parseInt(input.value, 10);
}

export function createDataPointCurves(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  points: ReadonlyArray<QP.AirData>,
  limits: ReadonlyArray<QP.AirLimitsData>
): ReadonlyArray<Curve> {
  const groupedPoints = R.groupBy((r) => r.step, points);
  const curves = R.keys(groupedPoints).map((l) => {
    const linePoints = groupedPoints[l];
    const first = linePoints[0];
    const lineLimits = limits.find(
      (li) =>
        li.step_name === first.step && li.voltage_control === first.volt_ctrl && li.voltage_supply === first.volt_supp
    );
    const workMin =
      lineLimits && lineLimits.min_flow !== 0 ? lineLimits.min_flow : Math.min(...linePoints.map((p) => p.flow));
    const workMax =
      lineLimits && lineLimits.max_flow !== 0 ? lineLimits.max_flow : Math.max(...linePoints.map((p) => p.flow));
    return {
      id: l,
      controlVoltage: first.volt_ctrl,
      supplyVoltage: first.volt_supp,
      unitX: unitX,
      unitY: unitY,
      workMin: workMin,
      workMax: workMax,
      spline: Interpolation.splineCreateFromPoints(
        linePoints.map((p) => ({
          x: p.flow,
          y: p.value,
        }))
      ),
    };
  });
  curves.sort((a, b) => {
    const aVal = Interpolation.splineGetPoint((a.spline.xMin + a.spline.xMax) * 0.5, a.spline);
    const bVal = Interpolation.splineGetPoint((b.spline.xMin + b.spline.xMax) * 0.5, b.spline);
    if (aVal === undefined || bVal === undefined) {
      //console.warn("Sorting issues!");
      return 0;
    }
    return aVal - bVal;
  });
  return curves;
}
