import * as Attributes from "./attributes";
import { DiscreteInputParamValue } from "../types";

export function getSpeedControls(attributes: Attributes.Attributes): ReadonlyArray<DiscreteInputParamValue> {
  const controlTypes: Array<DiscreteInputParamValue> = [];
  const none = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "None");
  const transformer = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Transformer");
  const stepless = Attributes.matchesAttribute("PROP-speed-regulation-type-MULTI", attributes, "Stepless");
  if (transformer) {
    controlTypes.push({
      value: 2,
      name: "stepped",
    });
  }
  if (stepless) {
    controlTypes.push({
      value: 3,
      name: "stepless",
    });
  }
  if (none) {
    controlTypes.push({
      value: 1,
      name: "none",
    });
  }
  return controlTypes;
}
