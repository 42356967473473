import { UnitMap } from "uom";
import { UnitDivide, Units } from "uom-units";

export const customUnits = {
  Grain: Units.Grain,
  Gram: Units.Gram,
  MilliGram: Units.MilliGram,
  PoundLb: Units.PoundLb,
  Slug: Units.Slug,
  Tonne: Units.Tonne,
  SquareCentimeter: Units.SquareCentimeter,
  SquareDecimeter: Units.SquareDecimeter,
  SquareFeet: Units.SquareFeet,
  SquareInch: Units.SquareInch,
  SquareMeter: Units.SquareMeter,
  SquareMillimeter: Units.SquareMillimeter,
  CubicCentiMeter: Units.CubicCentiMeter,
  CubicFeet: Units.CubicFeet,
  CubicInch: Units.CubicInch,
  CubicMeter: Units.CubicMeter,
  Gallon: Units.Gallon,
  HundredCubicFeet: Units.HundredCubicFeet,
  Liter: Units.Liter,
  MilliLiter: Units.MilliLiter,
  Steradian: Units.Steradian,
  Becquerel: Units.Becquerel,
  Hertz: Units.Hertz,
  RevolutionsPerHour: Units.RevolutionsPerHour,
  RevolutionsPerMinute: Units.RevolutionsPerMinute,
  Newton: Units.Newton,
  PoundForce: Units.PoundForce,
  Bar: Units.Bar,
  DeciPascal: Units.DeciPascal,
  FeetOfWaterColumn: Units.FeetOfWaterColumn,
  HectoPascal: Units.HectoPascal,
  InchOfMercury: Units.InchOfMercury,
  InchOfWaterColumn: Units.InchOfWaterColumn,
  KiloPascal: Units.KiloPascal,
  MilliBar: Units.MilliBar,
  NewtonPerSquareMeter: Units.NewtonPerSquareMeter,
  Pascal: Units.Pascal,
  PoundForcePerSquareFoot: Units.PoundForcePerSquareFoot,
  PoundForcePerSquareInch: Units.PoundForcePerSquareInch,
  Btu: Units.Btu,
  Joule: Units.Joule,
  KiloBtu: Units.KiloBtu,
  Kilojoule: Units.Kilojoule,
  MegaBtu: Units.MegaBtu,
  Megajoule: Units.Megajoule,
  NewtonMeter: Units.NewtonMeter,
  Therm: Units.Therm,
  GigaWattHour: Units.GigaWattHour,
  KiloWattHour: Units.KiloWattHour,
  MegaWattHour: Units.MegaWattHour,
  WattHour: Units.WattHour,
  WattSecond: Units.WattSecond,
  Coulomb: Units.Coulomb,
  KiloVolt: Units.KiloVolt,
  MilliVolt: Units.MilliVolt,
  Volt: Units.Volt,
  Farad: Units.Farad,
  KiloOhm: Units.KiloOhm,
  Ohm: Units.Ohm,
  Weber: Units.Weber,
  Tesla: Units.Tesla,
  Siemens: Units.Siemens,
  Henry: Units.Henry,
  Lumen: Units.Lumen,
  Lux: Units.Lux,
  BtuPerPoundLb: Units.BtuPerPoundLb,
  Gray: Units.Gray,
  KiloWattHourPerKilogram: Units.KiloWattHourPerKilogram,
  KilojoulePerKilogram: Units.KilojoulePerKilogram,
  Sievert: Units.Sievert,
  Katal: Units.Katal,
  HumidityFactor: Units.HumidityFactor,
  PercentHumidity: Units.PercentHumidity,
  CelsiusWet: Units.CelsiusWet,
  FahrenheitWet: Units.FahrenheitWet,
  KelvinWet: Units.KelvinWet,
  Day: Units.Day,
  Hour: Units.Hour,
  MilliSecond: Units.MilliSecond,
  Minute: Units.Minute,
  Week: Units.Week,
  Year: Units.Year,
  GramPerCubicCentiMeter: Units.GramPerCubicCentiMeter,
  KilogramPerCubicMeter: Units.KilogramPerCubicMeter,
  PoundPerCubicFoot: Units.PoundPerCubicFoot,
  SlugPerCubicFeet: Units.SlugPerCubicFeet,
  OnePerBtu: Units.OnePerBtu,
  OnePerJoule: Units.OnePerJoule,
  OnePerKiloWattHour: Units.OnePerKiloWattHour,
  OnePerKilojoule: Units.OnePerKilojoule,
  OnePerMegaBtu: Units.OnePerMegaBtu,
  OnePerMegajoule: Units.OnePerMegajoule,
  OnePerTherm: Units.OnePerTherm,
  GrainPerHour: Units.GrainPerHour,
  GrainPerSecond: Units.GrainPerSecond,
  GramPerHour: Units.GramPerHour,
  GramPerSecond: Units.GramPerSecond,
  KilogramPerHour: Units.KilogramPerHour,
  KilogramPerSecond: Units.KilogramPerSecond,
  PoundLbPerHour: Units.PoundLbPerHour,
  PoundLbPerSecond: Units.PoundLbPerSecond,
  SlugPerHour: Units.SlugPerHour,
  SlugPerSecond: Units.SlugPerSecond,
  StandardCubicFeetPerMinute: Units.StandardCubicFeetPerMinute,
  StandardCubicMeterPerHour: Units.StandardCubicMeterPerHour,
  StandardCubicMeterPerSecond: Units.StandardCubicMeterPerSecond,
  StandardLiterPerSecond: Units.StandardLiterPerSecond,
  GramPerKiloWattHour: Units.GramPerKiloWattHour,
  KilogramPerKiloWattHour: Units.KilogramPerKiloWattHour,
  PoundLbPerKiloWattHour: Units.PoundLbPerKiloWattHour,
  CubicFeetPerHour: Units.CubicFeetPerHour,
  CubicMeterPerHour: Units.CubicMeterPerHour,
  CubicFeetPerMinute: Units.CubicFeetPerMinute,
  CubicMeterPerSecond: Units.CubicMeterPerSecond,
  GallonsPerHour: Units.GallonsPerHour,
  GallonsPerMinute: Units.GallonsPerMinute,
  HundredCubicFeetPerHour: Units.HundredCubicFeetPerHour,
  LiterPerHour: Units.LiterPerHour,
  LiterPerMinute: Units.LiterPerMinute,
  LiterPerSecond: Units.LiterPerSecond,
  CubicFeetPerMinutePerSquareFeet: Units.CubicFeetPerMinutePerSquareFeet,
  CubicMeterPerSecondPerSquareMeter: Units.CubicMeterPerSecondPerSquareMeter,
  LiterPerSecondPerSquareMeter: Units.LiterPerSecondPerSquareMeter,
  OnePerKilogram: Units.OnePerKilogram,
  OnePerPoundLb: Units.OnePerPoundLb,
  OnePerCubicMeter: Units.OnePerCubicMeter,
  OnePerGallon: Units.OnePerGallon,
  OnePerHundredCubicFeet: Units.OnePerHundredCubicFeet,
  OnePerLiter: Units.OnePerLiter,
  GrainPerSquareFeetHour: Units.GrainPerSquareFeetHour,
  GrainPerSquareFeetSecond: Units.GrainPerSquareFeetSecond,
  GramPerSquareMeterHour: Units.GramPerSquareMeterHour,
  GramPerSquareMeterSecond: Units.GramPerSquareMeterSecond,
  KilogramPerSquareMeterHour: Units.KilogramPerSquareMeterHour,
  KilogramPerSquareMeterSecond: Units.KilogramPerSquareMeterSecond,
  PoundLbPerSquareFeetHour: Units.PoundLbPerSquareFeetHour,
  PoundLbPerSquareFeetSecond: Units.PoundLbPerSquareFeetSecond,
  StandardCubicFootPerMinutePerSquareFeet: Units.StandardCubicFootPerMinutePerSquareFeet,
  StandardCubicMeterPerHourPerSquareMeter: Units.StandardCubicMeterPerHourPerSquareMeter,
  StandardLiterPerSecondPerSquareMeter: Units.StandardLiterPerSecondPerSquareMeter,
  OnePerHour: Units.OnePerHour,
  OnePerSecond: Units.OnePerSecond,
  GrainPerPoundLb: Units.GrainPerPoundLb,
  GramPerKilogram: Units.GramPerKilogram,
  GramPerPoundLb: Units.GramPerPoundLb,
  KilogramPerKilogram: Units.KilogramPerKilogram,
  PoundLbPerPoundLb: Units.PoundLbPerPoundLb,
  LiterPerKiloWattHour: Units.LiterPerKiloWattHour,
  BtuPerCubicFeet: Units.BtuPerCubicFeet,
  KiloJoulePerCubicMeter: Units.KiloJoulePerCubicMeter,
  KiloWattHourPerCubicMeter: Units.KiloWattHourPerCubicMeter,
  KilojoulePerKilogramCelsius: Units.KilojoulePerKilogramCelsius,
  KilojoulePerKilogramKelvin: Units.KilojoulePerKilogramKelvin,
  BtuPerHour: Units.BtuPerHour,
  GigaWatt: Units.GigaWatt,
  HorsePower: Units.HorsePower,
  KiloBtuPerHour: Units.KiloBtuPerHour,
  KiloWatt: Units.KiloWatt,
  MegaWatt: Units.MegaWatt,
  TonCooling: Units.TonCooling,
  VoltAmpere: Units.VoltAmpere,
  Watt: Units.Watt,
  KiloWattPerCubicFootPerMinute: Units.KiloWattPerCubicFootPerMinute,
  KiloWattPerCubicMeterPerSecond: Units.KiloWattPerCubicMeterPerSecond,
  WattPerCubicMeterPerSecond: Units.WattPerCubicMeterPerSecond,
  KilowattPerCelsius: Units.KilowattPerCelsius,
  KilowattPerKelvin: Units.KilowattPerKelvin,
  KilogramSquareMeter: Units.KilogramSquareMeter,
  DecibelLw: Units.DecibelLw,
  Decibel: Units.Decibel,
  MilliAmpere: Units.MilliAmpere,
  FrenchDegree: Units.FrenchDegree,
  MilliGramCalciumPerLiter: Units.MilliGramCalciumPerLiter,
  Integer: Units.Integer,
  Text: Units.Text,
  MilliGramHydrogenCarbonatePerLiter: Units.MilliGramHydrogenCarbonatePerLiter,
  PascalSecond: Units.PascalSecond,
  BtuPerHourPerSquareFeetPerFahrenheit: Units.BtuPerHourPerSquareFeetPerFahrenheit,
  BtuPerHourPerFeetPerFahrenheit: Units.BtuPerHourPerFeetPerFahrenheit,
  WattPerMeterPerKelvin: Units.WattPerMeterPerKelvin,
  WattPerSquareMeterPerKelvin: Units.WattPerSquareMeterPerKelvin,
  GallonsPerMinutePerTonCooling: Units.GallonsPerMinutePerTonCooling,
  LiterPerSecondPerKiloWatt: Units.LiterPerSecondPerKiloWatt,
  SquareRootInchOfWaterColumn: Units.SquareRootInchOfWaterColumn,
  SquareRootPascal: Units.SquareRootPascal,
  CubicFeetPerMinutePerSquareRootInchOfWaterColumn: Units.CubicFeetPerMinutePerSquareRootInchOfWaterColumn,
  LiterPerSecondPerSquareRootPascal: Units.LiterPerSecondPerSquareRootPascal,
  DeltaCelsiusDewPoint: Units.DeltaCelsiusDewPoint,
  DeltaFahrenheitDewPoint: Units.DeltaFahrenheitDewPoint,
  DeltaCelsius: Units.DeltaCelsius,
  DeltaFahrenheit: Units.DeltaFahrenheit,
  BtuPerHourPerSquareFeet: Units.BtuPerHourPerSquareFeet,
  WattPerSquareMeter: Units.WattPerSquareMeter,
  Celsius: Units.Celsius,
  Fahrenheit: Units.Fahrenheit,
  Rankine: Units.Rankine,
  Bit: Units.Bit,
  CelsiusDewPoint: Units.CelsiusDewPoint,
  FahrenheitDewPoint: Units.FahrenheitDewPoint,
  KelvinDewPoint: Units.KelvinDewPoint,
  MilesPerHour: Units.MilesPerHour,
  Ampere: Units.Ampere,
  Candela: Units.Candela,
  CentiMeter: Units.CentiMeter,
  Decimeter: Units.Decimeter,
  Degrees: Units.Degrees,
  FeetPerMinute: Units.FeetPerMinute,
  FeetPerSecond: Units.FeetPerSecond,
  Foot: Units.Foot,
  Inch: Units.Inch,
  Kelvin: Units.Kelvin,
  Kilogram: Units.Kilogram,
  KilometerPerHour: Units.KilometerPerHour,
  Kilometer: Units.Kilometer,
  Meter: Units.Meter,
  MeterPerHour: Units.MeterPerHour,
  MeterPerSecond: Units.MeterPerSecond,
  MeterPerSquareSecond: Units.MeterPerSquareSecond,
  Mole: Units.Mole,
  Mile: Units.Mile,
  Millimeter: Units.Millimeter,
  One: Units.One,
  PPM: Units.PPM,
  Percent: Units.Percent,
  Radian: Units.Radian,
  Second: Units.Second,
  Yard: Units.Yard,
  WattPerCubicMeterPerHour: UnitDivide.powerByVolumeFlow(
    "WattPerCubicMeterPerHour",
    Units.Watt,
    Units.CubicMeterPerHour
  ),
  CubicFeetPerMinutePerWatt: UnitDivide.volumeFlowByPower(
    "CubicFeetPerMinutePerWatt",
    Units.CubicFeetPerMinute,
    Units.Watt
  ),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomUnitsLookup: UnitMap.UnitLookup = (unitString: string) => (customUnits as any)[unitString];
