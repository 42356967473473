/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as R from "ramda";
import * as React from "react";
import * as QP from "shared-lib/query-product";
import { AnyQuantity } from "shared-lib/uom";
import { Dispatch } from "client-lib/redux-integration";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import {
  SearchTable,
  SearchTableTd,
  SearchTableTh,
  SearchTableTr,
  LinkButton,
  CalculationMessages,
  Button,
  UnitSelector,
  withTw,
  Checkbox,
  FricoIcon,
} from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as SC from "shared-lib/system-calculator";
import * as Search from "shared-lib/search";
import * as Statistics from "shared-lib/statistics";
import * as SearchClient from "client-lib/search";
import * as UserSettingsShared from "shared-lib/user-settings";
import * as UserSettings from "client-lib/user-settings";
import * as CrmClient from "client-lib/crm";
import * as C from "shared-lib/calculation";
import * as CrmShared from "shared-lib/crm";
import * as ImageService from "client-lib/image-service";
import { FormatNumberFunction } from "shared-lib/utils";
import * as CUtils from "client-lib/utils";
import { Icon } from "client-lib/elements/icon";
import { NavigateToItemNo } from "../../../types";
import { FanDiagram } from "./fan-diagram";
import { BoxFanDiagram } from "./box-fan-diagram";
import { Base64Diagram } from "./base64-diagram";
import { CentrifugalFanDiagram } from "./centrifugal-fan-diagram";
import { HeatRecoveryUnitDiagrams } from "./heat-recovery-unit-diagrams";
import { ImageViewContainer } from "../../image-view";
import { State, ImageTooltip, SortProps } from "../types";
import { PdfPrintoutDropdown } from "./pdf-printout-dropdown";
import * as Actions from "../actions";
import * as ProductActions from "../../search-products/actions";

const TableContainer = withTw("div", "overflow-auto");
const TableThText = withTw("div", "flex cursor-pointer text-[0.75rem] hover:text-primary-light pr-4 print:text-xs");

const VariantLink = withTw("div", "flex flex-col gap-2", ({ minWidth }: { readonly minWidth?: boolean }) =>
  minWidth ? "min-w-80" : "print:min-w-auto"
);
const TableFooter = withTw("div", "p-16 h-36");
const HeaderImage = withTw("img", "max-w-44 max-h-104 mr-2 object-cover");
const ImageTooltipWrapper = withTw(
  "div",
  "absolute left-24 w-224 h-224 p-2 bg-white border rounded-2 border-gray-200 z-50"
);

const TableColumnText = withTw("div", "mr-2", ({ isFricoWebsite }: { readonly isFricoWebsite: boolean }) =>
  isFricoWebsite ? "max-w-64 overflow-hidden overflow-ellipsis" : ""
);

const Flex = withTw("div", "flex");

const FlexColumn = withTw("div", "flex flex-col");

//

const zIndexMax = 9995;

export interface ResultTableProps {
  readonly state: State;
  readonly sortProps: SortProps;
  readonly crmState: CrmClient.State;
  readonly calculationState: SearchClient.State;
  readonly searchProductId: string;
  readonly navigateToItemNo: NavigateToItemNo | undefined;
  readonly shopUrl: string | undefined;
  readonly getUnit: UserSettingsShared.GetUnitFunction;
  readonly getUnits: UserSettingsShared.GetUnitsFunction;
  readonly getDecimals: UserSettingsShared.GetDecimalsFunction;
  readonly getExtraText: UserSettingsShared.GetExtraTextFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly translate: Texts.TranslateFunction;
  readonly dispatch: Dispatch<ProductActions.Action | Actions.Action | UserSettings.Action | CrmClient.Action>;
  readonly showDiagrams: boolean;
  readonly crm: CrmShared.CrmConfig | undefined;
  readonly hiddenFields: ReadonlyArray<string>;
  readonly searchViews: ReadonlyArray<QP.SearchView>;
  readonly searchColumns: ReadonlyArray<QP.SearchColumn>;
  readonly showPrice: boolean;
  readonly currency: string;
  readonly language: string;
  readonly isFricoWebsite: boolean;
  readonly userSettings: UserSettingsShared.State;
  readonly market: string;
  readonly specificationDataUrl?: string;
  readonly marketUnits: QP.MarketUnitsTable;
  readonly crmSavedResults: ReadonlyArray<string>;
}

export function ResultTable(props: ResultTableProps): React.ReactElement<{}> {
  const { state, sortProps } = props;
  const { imageTooltip } = state;
  const tableElement = React.useRef<HTMLDivElement | null>(null);

  if (props.calculationState.type === "Calculating") {
    return <div />;
  }

  const results = Search.getMatchingAndSortedResults(
    props.calculationState.results,
    {
      sortPath: sortProps.sortPath,
      descending: sortProps.descending,
      sortType: sortProps.sortType,
    },
    sortProps.groupSortParams
  );

  if (results.length === 0) {
    return <div />;
  }

  return (
    <div className="relative" ref={tableElement}>
      {tableElement.current && imageTooltip && (
        <ImageTooltip parentElement={tableElement.current} imageTooltip={imageTooltip} />
      )}
      <TableContainer>
        <div className="w-full">
          {sortProps.groupBy === "Product" ? renderByProduct(results, props) : renderTable(results, props)}
        </div>
      </TableContainer>
    </div>
  );
}

function ImageTooltip({
  parentElement,
  imageTooltip,
}: {
  readonly parentElement: HTMLElement;
  readonly imageTooltip: ImageTooltip;
}): React.ReactElement<{}> | null {
  const [cachedUrls, setCachedUrls] = React.useState<Map<string, boolean>>(new Map());

  const productImage =
    imageTooltip && ImageService.itemNumberToServiceUrl(imageTooltip.itemNumber, undefined, 222, 222);

  //Check if an image for the item number exists
  React.useEffect(() => {
    if (!productImage || cachedUrls.has(productImage)) {
      return;
    }
    new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = productImage;
    }).then((res) => {
      setCachedUrls(new Map([...cachedUrls.set(productImage, res)]));
    });
  });

  const image = productImage && cachedUrls.get(productImage);
  if (!productImage || !image) {
    return null;
  }

  const parentX = parentElement.getBoundingClientRect().x;
  const parentY = parentElement.getBoundingClientRect().y;
  const hoverX = imageTooltip.hoverBoundingRect.x;
  const hoverY = imageTooltip.hoverBoundingRect.y + imageTooltip.hoverBoundingRect.height + 8;

  return (
    <ImageTooltipWrapper
      style={{
        top: hoverY - parentY,
        left: hoverX - parentX,
      }}
    >
      <ImageViewContainer imageUrl={productImage} popOver={true} />
    </ImageTooltipWrapper>
  );
}

function getVisibleColumns(props: ResultTableProps): ReadonlyArray<QP.SearchColumn> {
  const { searchColumns, hiddenFields, sortProps } = props;
  if (searchColumns.length === 0) {
    return [];
  }
  const selectedGroup = searchColumns.find((c) => c.group === sortProps.columnGroup)
    ? sortProps.columnGroup
    : searchColumns[0].group;
  return searchColumns.filter((c) => c.group === selectedGroup && !hiddenFields.find((f) => f === c.language_key));
}

function renderByProduct(
  results: ReadonlyArray<Search.ResultWithSortNo>,
  props: ResultTableProps
): React.ReactElement<{}> {
  const { translate, searchProductId, sortProps } = props;
  const grouped = R.groupBy((r) => r.result.productName, results);
  const searchColumns = getVisibleColumns(props);
  const numColumns = getNumColumns(searchColumns, props);
  const products = R.keys(grouped);

  let zindex = 9996;
  return (
    <div className="break-inside-avoid">
      {R.take(sortProps.numResults, products).map((productName) => {
        //Don't sort in place...
        const first = [...grouped[productName]].sort((a, b) => a.sortNo - b.sortNo)[0];
        const open =
          searchProductId === QP.fricoProductId ||
          searchProductId === QP.centrifugalFanProductId ||
          sortProps.groupsOpen.find((p) => p === productName);
        return (
          <SearchTable className="mt-24" key={first.result.id}>
            {renderTableHead(searchColumns, props, --zindex, productName, first.result.result.m3)}
            <tbody key={productName + "-body"}>
              {(open ? grouped[productName] : R.take(1, grouped[productName])).map((r) =>
                renderTableRow(r, searchColumns, props)
              )}
              <tr>
                <td colSpan={numColumns}>
                  <div>
                    <TableFooter>
                      {searchProductId !== QP.fricoProductId &&
                      searchProductId !== QP.centrifugalFanProductId &&
                      grouped[productName].length > 1 ? (
                        <LinkButton
                          className="print:text-xs"
                          onClick={() => sortProps.setGroupOpen(productName, !open)}
                        >
                          {open
                            ? translate(Texts.collapse())
                            : translate(Texts.moreResults(grouped[productName].length - 1))}
                        </LinkButton>
                      ) : undefined}
                    </TableFooter>
                  </div>
                </td>
              </tr>
            </tbody>
          </SearchTable>
        );
      })}

      {products.length > sortProps.numResults ? renderMoreResults(products.length, props) : undefined}
    </div>
  );
}

function getNumColumns(searchColumns: ReadonlyArray<QP.SearchColumn>, props: ResultTableProps): number {
  const { calculationState, showPrice, isFricoWebsite } = props;
  // Select, Sort no, product code, item no
  let numColumns = 4 + searchColumns.length;
  if (!isFricoWebsite && showPrice === true) {
    numColumns++;
  }

  if (calculationState.type !== "Calculating") {
    const additonalData = calculationState.results[0].result.additionalData;
    if (additonalData !== undefined && additonalData.type === "aircurtain") {
      numColumns++;
    }
  }

  if (props.crm && props.navigateToItemNo) {
    numColumns++;
  }

  return numColumns;
}

function renderTable(results: ReadonlyArray<Search.ResultWithSortNo>, props: ResultTableProps): React.ReactElement<{}> {
  const { sortProps } = props;
  const searchColumns = getVisibleColumns(props);

  return (
    <div>
      <SearchTable>
        {renderTableHead(searchColumns, props, zIndexMax)}
        <tbody>{R.take(sortProps.numResults, results).map((r) => renderTableRow(r, searchColumns, props))}</tbody>
      </SearchTable>
      {results.length > sortProps.numResults ? renderMoreResults(results.length, props) : undefined}
    </div>
  );
}

function renderMoreResults(totalProducts: number, props: ResultTableProps): React.ReactElement<{}> {
  const { translate, sortProps } = props;
  return (
    <div className="mt-24 print:hidden">
      <Button
        clicked={() => sortProps.setNumResults(sortProps.numResults + 15)}
        label={translate(Texts.moreResults(totalProducts - sortProps.numResults))}
      />
    </div>
  );
}

function renderTableHead(
  searchColumns: QP.SearchColumnsTable,
  props: ResultTableProps,
  zindex: number,
  productName?: string,
  itemNumber?: string
): React.ReactElement<{}> {
  const {
    translate,
    calculationState,
    searchViews,
    showDiagrams,
    searchProductId,
    showPrice,
    currency,
    crm,
    navigateToItemNo,
    sortProps,
    isFricoWebsite,
  } = props;

  if (calculationState.type === "Calculating") {
    return <span />;
  }
  const additonalData = calculationState.results[0].result.additionalData;
  const productResult = calculationState.results.find((r) => r.result.m3 === itemNumber);
  return (
    <thead>
      <SearchTableTr>
        {/*         <SearchTableTh key="select" noPrint={true} />

        <SearchTableTh key="sort_no" noPrint={true} className="w-64">
                   <TableThText
            className="alignMiddle "
            onClick={() => sortProps.setSorting(undefined, "sort_no", !sortProps.descending)}
          >
            <Icon
              className="hover:text-primary-light"
              icon={sortProps.descending ? "arrow-up-9-1" : "arrow-down-1-9"}
            />
          </TableThText> 
        </SearchTableTh> */}

        <SearchTableTh colSpan={3} key="product_code" className="print:w-1/6">
          {productName !== undefined ? (
            <div className="flex">
              {itemNumber && searchProductId !== QP.fricoProductId ? (
                <HeaderImage src={ImageService.itemNumberToServiceUrl(itemNumber, undefined, 44, 104)} />
              ) : undefined}

              <div className="flex flex-col cursor-pointer text-style-label-tiny hover:text-primary-light print:text-xs">
                <div
                  className="text-style-label-small-bold whitespace-nowrap pl-4 pr-4"
                  onClick={() => {
                    if (sortProps.groupSortParams && sortProps.groupSortParams.descending) {
                      sortProps.setGroupSorting(undefined);
                    } else {
                      sortProps.setGroupSorting({
                        sortType: undefined,
                        sortPath: "product_code",
                        descending: sortProps.groupSortParams ? !sortProps.groupSortParams.descending : false,
                      });
                    }
                  }}
                >
                  <span className="mr-8 whitespace-nowrap">{productName}</span>
                  <Icon
                    icon={
                      sortProps.groupSortParams?.descending === true
                        ? "sort-down"
                        : sortProps.groupSortParams?.descending === false
                        ? "sort-up"
                        : !sortProps.groupSortParams
                        ? "sort"
                        : undefined
                    }
                  />
                </div>
                {productResult !== undefined ? (
                  <div className="text-neutral-600">{productResult.productDescription}</div>
                ) : null}
              </div>
            </div>
          ) : (
            <div>
              {renderTableHeadColumnText(undefined, "product_code", translate(Texts.name()), props)}
              {productResult !== undefined ? (
                <div className="text-neutral-600">{productResult.productDescription}</div>
              ) : null}
            </div>
          )}

          {showDiagrams ? searchViews.map((v) => <div key={v.visualizer} />) : null}
        </SearchTableTh>

        <SearchTableTh key="m3" className="print:w-1/12">
          {renderTableHeadColumnText(undefined, "m3", translate(Texts.articleNo()), props)}
        </SearchTableTh>

        {!isFricoWebsite && showPrice === true ? (
          <SearchTableTh key="price">
            <TableThText
              className="alignMiddle"
              onClick={() => sortProps.setSorting(undefined, "price", !sortProps.descending)}
            >
              <span className="mr-8">{translate(Texts.price())}</span>
              {sortProps.sortPath === "price" ? (
                <Icon icon={sortProps.descending ? "sort-down" : "sort-up"} />
              ) : undefined}
            </TableThText>
            {<div style={{ height: "40px", alignItems: "center", display: "flex", padding: "0 8px" }}>{currency}</div>}
          </SearchTableTh>
        ) : null}

        {additonalData !== undefined && additonalData.type === "aircurtain" ? ( // Fix sorting
          <SearchTableTh key="cap">
            {renderTableHeadColumnText(undefined, "cap", translate(Texts.frico_capacity()), props)}
          </SearchTableTh>
        ) : undefined}

        {searchColumns.map((c) => renderTableHeadColumnWithText(c, props, --zindex))}

        {crm && navigateToItemNo ? <SearchTableTh noPrint={true} key="CRMADD" /> : undefined}
      </SearchTableTr>

      <SearchTableTr>
        <SearchTableTh colSpan={3} key="product_code" className="print:w-1/6"></SearchTableTh>

        <SearchTableTh key="m3" className="print:w-1/12"></SearchTableTh>

        {!isFricoWebsite && showPrice === true ? (
          <SearchTableTh key="price">
            <TableThText
              className="alignMiddle"
              onClick={() => sortProps.setSorting(undefined, "price", !sortProps.descending)}
            >
              {sortProps.sortPath === "price" ? (
                <Icon icon={sortProps.descending ? "sort-down" : "sort-up"} />
              ) : undefined}
            </TableThText>
          </SearchTableTh>
        ) : null}

        {additonalData !== undefined && additonalData.type === "aircurtain" ? ( // Fix sorting
          <SearchTableTh key="cap"></SearchTableTh>
        ) : undefined}

        {searchColumns.map((c) => renderTableHeadColumnWithSelector(c, props, --zindex))}

        {crm && navigateToItemNo ? <SearchTableTh noPrint={true} key="CRMADD" /> : undefined}
      </SearchTableTr>
    </thead>
  );
}

function renderTableHeadColumnWithText(
  c: QP.SearchColumn,
  props: ResultTableProps,
  zindex: number
): React.ReactElement<{}> {
  const { translate } = props;
  return (
    <SearchTableTh style={{ zIndex: zindex }} key={c.result_path}>
      {renderTableHeadColumnText(c.result_type, c.result_path, translate(Texts.createText(c.language_key)), props)}
    </SearchTableTh>
  );
}

function renderTableHeadColumnWithSelector(
  c: QP.SearchColumn,
  props: ResultTableProps,
  zindex: number
): React.ReactElement<{}> {
  return (
    <SearchTableTh style={{ zIndex: zindex }} key={c.result_path}>
      {renderTableHeadColumnUnitSelector(c, props)}
    </SearchTableTh>
  );
}

function renderTableHeadColumnText(
  resultType: SC.ResultItemType | undefined,
  resultPath: string,
  label: string,
  props: ResultTableProps
): React.ReactElement<{}> {
  const { sortProps, isFricoWebsite } = props;
  return (
    <TableThText title={label} onClick={() => sortProps.setSorting(resultType, resultPath, !sortProps.descending)}>
      <TableColumnText isFricoWebsite={isFricoWebsite}>{label}</TableColumnText>
      {sortProps.sortPath === resultPath ? <Icon icon={sortProps.descending ? "sort-down" : "sort-up"} /> : undefined}
    </TableThText>
  );
}

function renderTableHeadColumnUnitSelector(c: QP.SearchColumn, props: ResultTableProps): React.ReactElement<{}> {
  const { getUnit, getUnits, getExtraText, calculationState, dispatch, translate } = props;
  if (calculationState.type === "Calculating" || calculationState.results.length === 0) {
    return <span />;
  }
  const value = calculationState.results
    .map((r) => SC.getResultItemValue(c.result_type, c.result_path, r.result.results))
    .find((v) => v !== undefined);
  if (value === undefined || typeof value === "string" || typeof value === "number") {
    return <span />;
  }
  const amount = value as Amount.Amount<AnyQuantity>;
  const unit = getUnit(c.field_name || c.language_key, amount.unit.quantity);
  const units = getUnits(c.field_name || c.language_key, amount.unit.quantity);
  const extraText = getExtraText(c.field_name || c.language_key, amount.unit);
  return (
    <div className="flex pb-26">
      <UnitSelector
        className="min-w-[4rem]"
        unit={unit}
        units={units}
        unitChanged={(u) => dispatch(UserSettings.setFieldUnit(c.field_name || c.language_key, u))}
        unitCleared={() => dispatch(UserSettings.clearFieldUnit(c.field_name || c.language_key))}
        useFixedDropdownPos={true}
        translate={translate}
        small={true}
        extraText={extraText}
      />
    </div>
  );
}

function renderTableRow(
  result: Search.ResultWithSortNo,
  searchColumns: QP.SearchColumnsTable,
  props: ResultTableProps
): React.ReactElement<{}> | undefined {
  const componentResult = result.result;
  const {
    searchViews,
    showDiagrams,
    dispatch,
    translate,
    getUnit,
    getDecimals,
    searchProductId,
    showPrice,
    specificationDataUrl,
    crm,
    navigateToItemNo,
    market,
    userSettings,
    calculationState,
    isFricoWebsite,
    crmSavedResults,
  } = props;
  const additionalData =
    (componentResult.result.additionalData &&
      componentResult.result.additionalData.type === "aircurtain" &&
      componentResult.result.additionalData.data) ||
    undefined;

  const searchId = (calculationState.type === "Idle" && calculationState.searchId) || undefined;
  const componentMessages = SC.getMessages(componentResult.result.results);
  const accessoryMessages = result.result.accessoryResults.map((r) => ({
    code: r.productCode,
    messages: SC.getMessages(r.results),
  }));
  const warnWaterPressure = componentMessages.some((m) => m.code === 255);
  const id = `${componentResult.result.m3}-${componentResult.result.productId}-${PropertyValueSet.toString(
    componentResult.result.properties
  )}`;
  const epimVariantId = result.result.result.variant;

  const printoutConfig = C.serializeConfig({
    properties: result.result.result.properties,
    calcParams: result.result.result.calcParams,
    accessories: result.result.accessoryResults.map((a) => ({
      id: a.id,
      parentId: a.id,
      productId: a.productId,
      properties: a.properties,
      calcParams: a.calcParams,
    })),
    meta: userSettings,
  });

  return (
    <SearchTableTr key={id}>
      <SearchTableTd noPrint={true} alignTop={true}>
        {searchProductId === QP.fricoProductId ? (
          <Checkbox
            checked={crmSavedResults.find((r) => r === result.result.id) !== undefined}
            checkedChanged={() => dispatch(ProductActions.saveCrmResult(result.result.id))}
          />
        ) : null}
      </SearchTableTd>

      <SearchTableTd noPrint={true} alignTop={true}>
        {/* {result.sortNo} */}
      </SearchTableTd>

      <SearchTableTd>
        <Flex>
          <FlexColumn>
            {variantLink(
              result.result.result,
              result.result.accessoryResults,
              props,
              searchId,
              result.result.id,
              userSettings
            )}
            {/* {result.result.accessoryResults.map(a => variantLink(a, [], undefined, props))} */}
            {result.result.accessoryResults.map((a) => accessoryName(a))}
          </FlexColumn>
          <PdfPrintoutDropdown
            translate={translate}
            epimVariantId={epimVariantId}
            specificationDataUrl={specificationDataUrl}
            config={printoutConfig}
          />
        </Flex>

        {showDiagrams
          ? searchViews.map((v) => (
              <div className="ml-32" key={v.visualizer}>
                {searchViewColumn(v, componentResult.result, props)}
              </div>
            ))
          : null}
        <CalculationMessages
          componentMessages={componentMessages.filter((m) => m.code !== 255)}
          accessoryMessages={accessoryMessages}
          translate={translate}
          getUnit={getUnit}
          getDecimals={getDecimals}
        />
      </SearchTableTd>
      <SearchTableTd>{componentResult.result.m3}</SearchTableTd>
      {!isFricoWebsite && showPrice === true ? (
        <SearchTableTd>{componentResult.result.price || ""}</SearchTableTd>
      ) : null}
      {additionalData !== undefined ? (
        <SearchTableTd className="capacity-td">
          {additionalData.goodHeatingResult === true ? (
            <Icon className="min-w-20" icon="thumbs-up" title={translate(Texts.recommended_coil())} />
          ) : (
            <span className="pr-20" />
          )}
          {additionalData.capacity === "high" && <FricoIcon icon="high" title={translate(Texts.high_capacity())} />}
          {additionalData.capacity === "low" && <FricoIcon icon="low" title={translate(Texts.low_capacity())} />}
          {additionalData.capacity === "ok" && <FricoIcon icon="ok" title={translate(Texts.ok_capacity())} />}
        </SearchTableTd>
      ) : null}
      {searchColumns.map((c) => {
        const value = SC.getResultItemValue(c.result_type, c.result_path, componentResult.result.results);
        if (value === undefined) {
          return <SearchTableTd key={c.result_path}>-</SearchTableTd>;
        } else if (typeof value === "string") {
          return (
            <SearchTableTd key={c.result_path}>
              {translate(Texts.translatedResultTableValue(value), value)}
            </SearchTableTd>
          );
        } else if (typeof value === "number") {
          return <SearchTableTd key={c.result_path}>{value.toFixed(0)}</SearchTableTd>;
        } else {
          return (
            <SearchTableTd
              className="tabular-nums"
              title={
                c.result_path === "AirCurtain.waterPressureDrop" && warnWaterPressure === true
                  ? translate(Texts.waterpressure_warning())
                  : ""
              }
              key={c.result_path}
              style={
                c.result_path === "AirCurtain.waterPressureDrop" && warnWaterPressure === true
                  ? { backgroundColor: "#f3e393" }
                  : {}
              }
            >
              {CUtils.renderAmount(value as Amount.Amount<AnyQuantity>, c.field_name || c.language_key, props)}
            </SearchTableTd>
          );
        }
      })}
      {crm && navigateToItemNo ? (
        <SearchTableTd noPrint={true} key="CRMADD">
          <CrmClient.CrmAddButton
            crmState={props.crmState}
            translate={translate}
            dispatch={dispatch}
            crmConfig={crm}
            market={market}
            variant={componentResult.result.variant}
            m3ItemNo={componentResult.result.m3}
            userSettings={userSettings}
            fullConfig={{
              properties: componentResult.result.properties,
              calcParams: componentResult.result.calcParams,
              accessories: componentResult.accessoryResults.map((a) => ({
                id: a.id,
                parentId: a.id,
                productId: a.productId,
                properties: a.properties,
                calcParams: a.calcParams,
              })),
            }}
            buttonId={id}
            compact={true}
            secondary={true}
          />
        </SearchTableTd>
      ) : undefined}
    </SearchTableTr>
  );
}

function accessoryName(result: Search.ComponentResult): React.ReactElement<{}> {
  return <div key={result.productCode || "Unkown"}>{result.productCode || "Unkown"}</div>;
}

function variantLink(
  result: Search.ComponentResult,
  accessories: ReadonlyArray<Search.ComponentResult>,
  props: ResultTableProps,
  searchId: string | undefined,
  resultId: string,
  userSettings: UserSettingsShared.State
): React.ReactElement<{}> {
  const { translate, navigateToItemNo, dispatch, language, searchProductId, shopUrl } = props;
  const additionalData =
    (result.additionalData && result.additionalData.type === "aircurtain" && result.additionalData.data) || undefined;
  const config: C.ItemConfigWithMeta = {
    properties: result.properties,
    calcParams: result.calcParams,
    meta: userSettings,
    accessories: accessories.map((a) => ({
      id: a.id,
      parentId: undefined,
      productId: a.productId,
      properties: a.properties,
      calcParams: PropertyValueSet.removeProperty("is_accessory_search", a.calcParams),
    })),
  };
  return (
    <VariantLink key={result.id} minWidth={!!additionalData}>
      <div>
        <LinkButton
          className="print:text-xs !leading-[1.65rem] text-[1em] font-bold"
          onMouseOver={(e) => {
            if (searchProductId === QP.fricoProductId) {
              dispatch(
                Actions.setImageTooltip({
                  productId: result.productId,
                  itemNumber: result.m3,
                  hoverBoundingRect: (e.target as HTMLElement).getBoundingClientRect(),
                })
              );
            }
          }}
          onMouseOut={() =>
            searchProductId === QP.fricoProductId ? dispatch(Actions.setImageTooltip(undefined)) : null
          }
          href={shopUrl ? `${shopUrl}?p=${result.variant}&fullConfig=${C.serializeConfig(config)}` : undefined}
          onClick={async () => {
            if (searchProductId === QP.fricoProductId) {
              dispatch(Actions.setImageTooltip(undefined));
            }

            if (searchId !== undefined) {
              Statistics.registerProductClick(searchId, resultId, result.m3, result.variant);
            }

            if (shopUrl) {
              return;
            }
            const serializedConfig = C.serializeConfig(config);
            if (navigateToItemNo) {
              navigateToItemNo(result.m3, serializedConfig, result.variant, language);
            }
          }}
        >
          {result.itemName || result.productCode || "Unkown"}
        </LinkButton>
        {additionalData !== undefined && additionalData.quantity > 1 ? " x " + additionalData.quantity : null}
        <div className="block text-[0.75rem] leading-[1.65rem]">
          {result.productCode === result.itemName ? null : result.productCode}
        </div>
      </div>
      {/* <small>{result.showProperties.join(", ") === result.productCode ? null : result.showProperties.join(", ")}</small> */}

      {additionalData !== undefined ? airCurtainData(additionalData, translate) : null}
    </VariantLink>
  );
}

function airCurtainData(
  additionalData: Search.AdditionalAirCurtianData,
  translate: Texts.TranslateFunction
): React.ReactElement<{}> {
  return (
    <div>
      <div>
        <small>
          {additionalData.additionalUnitQuantity !== undefined
            ? `${translate(Texts.additional_units())} ${additionalData.additionalUnitQuantity} x
${(additionalData.additionalUnitLengthmm! / 1000).toFixed(2)} m`
            : null}
        </small>
      </div>
      <div>
        <small>{additionalData.twoSides > 0 ? translate(Texts.both_sides()) : null}</small>
      </div>
    </div>
  );
}

function searchViewColumn(
  searchView: QP.SearchView,
  result: Search.ComponentResult,
  props: ResultTableProps
): React.ReactElement<{}> | undefined {
  const { getUnit, translate, getDecimals } = props;
  if (searchView.visualizer === "FanDiagram") {
    return (
      <FanDiagram
        results={result.results}
        getUnit={getUnit}
        getDecimals={getDecimals}
        formatNumber={props.formatNumber}
        translate={translate}
      />
    );
  } else if (searchView.visualizer === "Base64Diagram") {
    return <Base64Diagram results={result.results} />;
  } else if (searchView.visualizer === "BoxFanDiagram") {
    return (
      <BoxFanDiagram
        results={result.results}
        getUnit={getUnit}
        getDecimals={getDecimals}
        formatNumber={props.formatNumber}
        translate={translate}
      />
    );
  } else if (searchView.visualizer === "CentrifugalFanDiagram") {
    return (
      <CentrifugalFanDiagram
        results={result.results}
        getUnit={getUnit}
        getDecimals={getDecimals}
        formatNumber={props.formatNumber}
        translate={translate}
      />
    );
  } else if (searchView.visualizer === "HeatRecoveryUnitDiagrams") {
    return (
      <HeatRecoveryUnitDiagrams
        results={result.results}
        getDecimals={getDecimals}
        getUnit={getUnit}
        formatNumber={props.formatNumber}
        translate={translate}
      />
    );
  } else {
    console.warn("Unknown SearchView visualizer " + searchView.visualizer);
    return undefined;
  }
}
