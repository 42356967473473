/* eslint-disable no-restricted-globals */
import * as React from "react";
import { Alert } from "client-lib/elements";
import { ErrorObject } from "./index";

export function ErrorPage(e: ErrorObject, _s: string | undefined): React.ReactElement<void> {
  console.log(e);
  return (
    <Alert horizontalAlign={true} type="warning">
      <div style={{ fontFamily: "Arial", fontSize: "14px", paddingRight: "20px" }}>
        <h2>An error occured</h2>
        <p>
          Click{" "}
          <a href="#" style={{ color: "gray" }} onClick={() => location.reload()}>
            here
          </a>{" "}
          or reload the page
        </p>
        <p>{e.message}</p>
      </div>
    </Alert>
  );
}
