import * as CrmShared from "shared-lib/crm";
import { ExternalItem } from "shared-lib/external-item";
import { CrmStatus } from "shared-lib/crm";

export type Action = AddItems | ItemsAdded | DismissError;

export interface AddItems {
  readonly type: "crm/ADD_ITEMS";
  readonly payload: {
    readonly params: CrmShared.CrmConfig;
    readonly buttonId: string;
    readonly items: ReadonlyArray<ExternalItem>;
  };
}

export function addItems(params: CrmShared.CrmConfig, items: ReadonlyArray<ExternalItem>, buttonId: string): AddItems {
  return {
    type: "crm/ADD_ITEMS",
    payload: { params, items, buttonId },
  };
}

export interface ItemsAdded {
  readonly type: "crm/ITEMS_ADDED";
  readonly payload: CrmStatus;
}

export function itemsAdded(crmStatus: CrmStatus): ItemsAdded {
  return {
    type: "crm/ITEMS_ADDED",
    payload: crmStatus,
  };
}

export interface DismissError {
  readonly type: "crm/DISMISS_ERROR";
  readonly payload: boolean;
}

export function dismissError(): DismissError {
  return {
    type: "crm/DISMISS_ERROR",
    payload: true,
  };
}
