/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-await */
import * as R from "ramda";
import { Amount } from "uom";
import { PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import { ResultItemsTable } from "shared-lib/query-product";
import { customUnits } from "shared-lib/uom";
import * as QD from "shared-lib/query-diaq";
import {
  System,
  ResultQuery,
  Component,
  InputMapperSuccess,
  CalculatorResult,
  CalculatorSuccess,
  createOutputMapperError,
  OutputMapperResult,
  Calculator,
  InputParam,
  OutputMapperModule,
  ResultItemOutputMap,
  ResultItemOutputPerComponent,
  ResultItemDefinition,
} from "./types";
import { lookupCalculator, lookupSelector, lookupInputMapperModule, lookupOutputMapperModule } from "./registry";
import { Exception } from "./messages";
import * as Attributes from "./shared/attributes";

export function getCalcParams(
  resultItems: ResultItemsTable,
  attributes: Attributes.Attributes,
  variant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<InputParam> {
  return R.uniqBy(
    (p) => p.name,
    R.unnest<InputParam>(
      resultItems.map((i) => {
        const inputMapper = lookupInputMapperModule(i.calculator);
        return inputMapper.getCalcParams(i.calculator_params, attributes, variant, calcParams);
      })
    )
  );
}

// Helper function to create a system for a single product
export function createSystemForProduct2(
  productId: string,
  propertyValues: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet,
  resultItemsTable: ResultItemsTable,
  attributes: Attributes.Attributes,
  variantId: string | undefined
): System {
  const resultItemDefinitions: ReadonlyArray<ResultItemDefinition> = resultItemsTable.map((r) => ({
    name: r.name,
    type: r.type,
    calculator: r.calculator,
    calculatorParams: r.calculator_params,
  }));

  // Use the productId as component id since it is only one component
  const component: Component = {
    id: productId,
    productId: productId,
    propertyValues: propertyValues,
    variantId: variantId,
    calcParams: calcParams,
    resultItems: resultItemDefinitions,
    attributes: attributes,
  };
  return {
    components: [component],
    relations: [],
  };
}

export function getQueryForSystem(system: System): QD.DiaqMapQuery<QD.DiaqMapResponse> {
  // Get input mappper queries for all components in the system
  // We can resolve all queries before starting the system calculation
  // since they are only a function of productId and calculator
  // and they are both known from the start
  const queryMapsPerProductIdAndCalculator: {
    [componentIdAndCalculator: string]: QD.DiaqMapQuery<QD.DiaqMapResponse>;
  } = {};

  for (const component of system.components) {
    for (const resultItem of component.resultItems) {
      const query = getQueryForCalculator(component.productId, resultItem.calculator, component.variantId);
      // The query is a function of (productId, calculator) and is stored on a key that is the combination of the two
      queryMapsPerProductIdAndCalculator[getQueryMapKey(component.productId, resultItem.calculator)] = query;
    }
  }
  return QD.createMapQuery(queryMapsPerProductIdAndCalculator);
}

export interface ProductIdAndCalculators {
  readonly productId: string;
  readonly calculators: ReadonlyArray<string>;
  readonly variantId: string | undefined;
}

export function getQueryForProductIdAndCalculators(
  products: ReadonlyArray<ProductIdAndCalculators>
): QD.DiaqMapQuery<QD.DiaqMapResponse> {
  const queryMapsPerProductIdAndCalculator: {
    [componentIdAndCalculator: string]: QD.DiaqMapQuery<QD.DiaqMapResponse>;
  } = {};

  for (const product of products) {
    for (const calculator of product.calculators) {
      const query = getQueryForCalculator(product.productId, calculator, product.variantId);
      // The query is a function of (productId, calculator) and is stored on a key that is the combination of the two
      queryMapsPerProductIdAndCalculator[getQueryMapKey(product.productId, calculator)] = query;
    }
  }
  return QD.createMapQuery(queryMapsPerProductIdAndCalculator);
}

export function resultItemMapToPropertyValueSet(results: ResultItemOutputMap): PropertyValueSet.PropertyValueSet {
  let pvs: { [property: string]: PropertyValue.PropertyValue } = {};
  for (const name of R.keys(results)) {
    const value = results[name];
    if (value.type !== "OutputMapperSuccess") {
      continue;
    }
    const subPvs = resultItemToPropertyValueSet(value.result.value);
    //const withPrefix = PropertyValueSet.addPrefixToValues(name + "_", subPvs);
    pvs = { ...pvs, ...subPvs };
  }
  return pvs;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function resultItemToPropertyValueSet(results: any): PropertyValueSet.PropertyValueSet {
  let pvs: { [property: string]: PropertyValue.PropertyValue } = {};
  for (const name of R.keys(results)) {
    const value = results[name];
    const type = typeof value;
    if (type === "string") {
      pvs[name] = PropertyValue.fromText(name);
    } else if (type === "number") {
      const amount = Amount.create(value, customUnits.One);
      pvs[name] = PropertyValue.fromAmount(amount);
    } else if (type === "boolean") {
      pvs[name] = PropertyValue.fromInteger(value ? 1 : 0);
    } else if (Array.isArray(value)) {
      // Skip arrays for now
    } else if (type === "object") {
      // Check for amount first
      if (typeof value.value === "number" && typeof value.unit === "object") {
        pvs[name] = PropertyValue.fromAmount(value);
      } else {
        const subPvs = resultItemToPropertyValueSet(value);
        //const withPrefix = PropertyValueSet.addPrefixToValues(name + "_", subPvs);
        pvs = { ...pvs, ...subPvs };
      }
    }
  }
  return pvs;
}

interface ComponentResultItem {
  readonly id: string;
  readonly component: Component;
  readonly resultItem: ResultItemDefinition;
}

export async function calculateSystem(
  system: System,
  systemQueryResponse: QD.DiaqMapResponse,
  select: boolean
): Promise<ResultItemOutputPerComponent | undefined> {
  const itemsToCalculate: Array<ComponentResultItem> = [];
  const dependencies: { [id: string]: ReadonlyArray<ResultQuery> } = {};
  for (const component of system.components) {
    for (const resultItem of component.resultItems) {
      const id = component.id + ";" + resultItem.name;
      itemsToCalculate.push({
        id: id,
        component: component,
        resultItem: resultItem,
      });

      const inputMapper = lookupInputMapperModule(resultItem.calculator);
      const resultItemDependencies = inputMapper.getResultsQuery(system, component.id);
      dependencies[id] = resultItemDependencies;
    }
  }

  const results: ResultItemOutputPerComponent = {};
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const nextToCalculate = itemsToCalculate.find((c) => {
      // Check if it is already calculated
      if (results[c.component.id] && results[c.component.id][c.resultItem.name]) {
        return false;
      }
      // Check that all dependencies are satisfied
      return dependencies[c.id].every((d) =>
        d.resultItems.every((i) => !!(results[d.componentId] && results[d.componentId][i]))
      );
    });
    if (!nextToCalculate) {
      break;
    }
    const component = nextToCalculate.component;
    const resultItem = nextToCalculate.resultItem;

    const inputMapper = lookupInputMapperModule(resultItem.calculator);
    const calculator = select ? lookupSelector(resultItem.calculator) : lookupCalculator(resultItem.calculator);
    const outputMapper = lookupOutputMapperModule(resultItem.calculator);
    const resultsQuery = dependencies[nextToCalculate.id];
    const resultsQueryResponse = resolveResultQuery(results, resultsQuery);

    if (resultsQueryResponse === undefined) {
      throw new Error("Failed to resolve result query");
    }
    const queryMapKey = getQueryMapKey(component.productId, resultItem.calculator);
    const inputMapperQueryResultMap = systemQueryResponse[queryMapKey] as {};
    if (!inputMapperQueryResultMap) {
      return undefined;
    }
    const componentInput = {
      id: component.id,
      productId: component.productId,
      properties: component.propertyValues,
      calcParams: component.calcParams,
      attributes: component.attributes,
    };
    const inputMapperResult = inputMapper.map(
      componentInput,
      inputMapperQueryResultMap,
      resultsQueryResponse,
      resultItem.calculatorParams
    );

    const outputMapperResult = await (async () => {
      switch (inputMapperResult.type) {
        case "InputMapperSuccess":
          return await handleInputMapperSuccess(calculator, outputMapper, inputMapperResult);
        case "InputMapperError":
          return createOutputMapperError(inputMapperResult.messages);
        default:
          return exhaustiveCheck(inputMapperResult, true);
      }
    })();

    const resultItemValueSet = results[component.id];
    (results as any)[component.id] = {
      ...resultItemValueSet,
      [resultItem.name]: outputMapperResult,
    };
  }

  if (!itemsToCalculate.every((c) => !!(results[c.component.id] && results[c.component.id][c.resultItem.name]))) {
    throw new Error(
      "Failed to calculate due to circular dependency for " + itemsToCalculate.map((c) => c.id).join(", ")
    );
  }

  return results;
}

function resolveResultQuery(
  resultItemOutputPerComponent: ResultItemOutputPerComponent,
  resultQuery: ReadonlyArray<ResultQuery>
): ResultItemOutputPerComponent | undefined {
  const results: ResultItemOutputPerComponent = {};
  for (const query of resultQuery) {
    const componentResult = resultItemOutputPerComponent[query.componentId];
    if (!componentResult) {
      return undefined;
    }
    const resultItems: { [resultItem: string]: OutputMapperResult } = {};
    for (const resultItem of query.resultItems) {
      if (!componentResult[resultItem]) {
        return undefined;
      }
      resultItems[resultItem] = componentResult[resultItem];
    }
    (results as any)[query.componentId] = resultItems;
  }
  return results;
}

async function handleInputMapperSuccess(
  calculator: Calculator<any, any>,
  outputMapperModule: OutputMapperModule<any>,
  inputMapperSuccess: InputMapperSuccess<any>
): Promise<OutputMapperResult> {
  const calculatorResult = await doCalculation<{}, {}>(calculator, inputMapperSuccess);
  switch (calculatorResult.type) {
    case "CalculatorSuccess":
      return handleCalculatorSuccess(outputMapperModule, calculatorResult);
    case "CalculatorError":
      return createOutputMapperError(calculatorResult.messages);
    default:
      return exhaustiveCheck(calculatorResult, true);
  }
}

function doCalculation<TInput, TOutput>(
  calculator: Calculator<any, any>,
  inputMapperResult: InputMapperSuccess<TInput>
): Promise<CalculatorResult<TOutput>> {
  const result = calculator(inputMapperResult.input);
  return result;
}

function handleCalculatorSuccess(
  outputMapperModule: OutputMapperModule<any>,
  calculatorSuccess: CalculatorSuccess<any>
): OutputMapperResult {
  const outputMapperResult = doOutputMapping(outputMapperModule, calculatorSuccess);
  switch (outputMapperResult.type) {
    case "OutputMapperSuccess":
      return outputMapperResult;
    case "OutputMapperError":
      return createOutputMapperError(outputMapperResult.messages);
    default:
      return exhaustiveCheck(outputMapperResult, true);
  }
}

function doOutputMapping(
  outputMapperModule: OutputMapperModule<any>,
  calculatorSuccess: CalculatorSuccess<any>
): OutputMapperResult {
  try {
    const outputMapperResult = outputMapperModule.map(calculatorSuccess);
    if (!outputMapperResult) {
      return createOutputMapperError([Exception("calculatorKey", "Output mapper should return a value.")]);
    }
    return outputMapperResult;
  } catch (e) {
    return createOutputMapperError([Exception("calculatorKey", e.message)]);
  }
}

function getQueryMapKey(productId: string, calculator: string): string {
  return productId + ";" + calculator;
}

function getQueryForCalculator(
  productId: string,
  calculator: string,
  variantId: string | undefined
): QD.DiaqMapQuery<QD.DiaqMapResponse> {
  const inputMapperModule = lookupInputMapperModule(calculator);
  const inputMapperQuery = inputMapperModule.getQuery(productId, variantId);
  return inputMapperQuery;
}
