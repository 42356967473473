import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import * as PU from "shared-lib/product-utils";
import { Input, CalculationInput } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  ResultQuery,
} from "../types";
import { ProductDataError_NoDataAvailable } from "../messages";
import * as Attributes from "../shared/attributes";
import * as Shared from "../shared/air-curtain";
import * as A from "./attributes";

const msgSource = "AirCurtainEpimInputMapper";

export function getQuery(_: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({});
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {}

export function getCalcParams(
  calculatorParams: string,
  attributes: Attributes.Attributes,
  _variant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<InputParam> {
  const canCool = Shared.canCool(attributes);
  const paramParts = calculatorParams.split(";");
  const waterCooling =
    paramParts[0] === "cooling" || (canCool && PropertyValueSet.getInteger("operatingMode", calcParams) === 1);
  const searchHeatingMethod = paramParts[1];
  const isInSearch = searchHeatingMethod !== undefined;

  // Air flow
  const steps = Shared.getAirFlowSteps(attributes);

  const airFlowSteps: InputParam = {
    type: "Discrete",
    group: "main",
    name: "airFlowStep",
    values: [
      ...steps.map((s) => ({
        value: s.step,
        name: s.name,
      })),
      {
        value: 0,
        name: "custom",
      },
    ],
  };
  const defaultFlow = Amount.create(1000, Units.CubicMeterPerHour);
  const highestAirflow = steps[0] && steps[0].airFlow;
  const customAirFlow: InputParam = {
    type: "Amount",
    group: "main",
    name: "customAirFlow",
    quantity: "VolumeFlow",
    defaultValue: highestAirflow ? highestAirflow : defaultFlow,
    fieldName: "airFlow",
    validationFilter: PropertyFilter.fromStringOrEmpty("customAirFlow>0:LiterPerSecond", CustomUnitsLookup),
  };
  const airflowParams = !isInSearch ? [airFlowSteps, customAirFlow] : [];

  const operatingMode: InputParam = {
    type: "Discrete",
    group: "main",
    name: "operatingMode",
    values: [
      {
        value: 0,
        name: "heating",
      },
      {
        value: 1,
        name: "cooling",
      },
    ],
  };

  // Air
  const inletAirTemperature: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "inletAirTemperature",
    quantity: "Temperature",
    defaultValue: Amount.create(20, Units.Celsius),
    fieldName: "airTemperature",
    validationFilter: PropertyFilter.fromStringOrEmpty("inletAirTemperature=-20:Celsius~50:Celsius", CustomUnitsLookup),
  };
  const inletAirHumidity: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "inletAirHumidity",
    quantity: "RelativeHumidity",
    defaultValue: Amount.create(70, Units.PercentHumidity),
    fieldName: "airHumidity",
    validationFilter: PropertyFilter.fromStringOrEmpty(
      "inletAirHumidity=0:PercentHumidity~100:PercentHumidity",
      CustomUnitsLookup
    ),
  };
  const outletAirTemperature: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "outletAirTemperature",
    quantity: "Temperature",
    defaultValue: Amount.create(waterCooling ? 15 : 25, Units.Celsius),
    fieldName: "airTemperature",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=2", CustomUnitsLookup),
    validationFilter: waterCooling
      ? PropertyFilter.fromStringOrEmpty(
          "outletAirTemperature=-14:Celsius~50:Celsius&outletAirTemperature>inletWaterTemperatureC&outletAirTemperature<inletAirTemperature",
          CustomUnitsLookup
        )
      : PropertyFilter.fromStringOrEmpty(
          "outletAirTemperature=-14:Celsius~50:Celsius&outletAirTemperature<inletWaterTemperature&outletAirTemperature>inletAirTemperature",
          CustomUnitsLookup
        ),
  };

  // Water
  const caclMethodWaterCoil: InputParam = {
    type: "Discrete",
    group: "calculationParams",
    name: "calcMethodWaterCoil",
    values: [
      {
        value: 0,
        name: "calcMethodWaterCoil_0",
      },
      {
        value: 1,
        name: "calcMethodWaterCoil_1",
      },
      {
        value: 2,
        name: "calcMethodWaterCoil_2",
      },
    ],
  };

  const outletWaterTemperatureHeating: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "outletWaterTemperature",
    quantity: "Temperature",
    defaultValue: Amount.create(60, Units.Celsius),
    fieldName: "airTemperature",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=0&operatingMode!=1", CustomUnitsLookup),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      "outletWaterTemperature=1:Celsius~90:Celsius&outletWaterTemperature<inletWaterTemperature&outletWaterTemperature>inletAirTemperature",
      CustomUnitsLookup
    ),
  };

  const outletWaterTemperatureCooling: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "outletWaterTemperatureC",
    quantity: "Temperature",
    defaultValue: Amount.create(12, Units.Celsius),
    fieldName: "airTemperature",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=0&operatingMode=1", CustomUnitsLookup),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      "outletWaterTemperatureC=1:Celsius~130:Celsius&outletWaterTemperatureC>inletWaterTemperatureC&outletWaterTemperatureC<inletAirTemperature",
      CustomUnitsLookup
    ),
  };

  const waterFlow: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "waterFlow",
    quantity: "VolumeFlow",
    defaultValue: Amount.create(1, Units.LiterPerSecond),
    fieldName: "waterFlow",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("calcMethodWaterCoil=1", CustomUnitsLookup),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      "waterFlow=0.001:CubicMeterPerHour~10000:CubicMeterPerHour",
      CustomUnitsLookup
    ),
  };

  const inletWaterTemperatureHeating: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "inletWaterTemperature",
    quantity: "Temperature",
    defaultValue: Amount.create(80, Units.Celsius),
    fieldName: "airTemperature",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("operatingMode!=1", CustomUnitsLookup),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      "inletWaterTemperature=1:Celsius~130:Celsius&inletWaterTemperature>inletAirTemperature",
      CustomUnitsLookup
    ),
  };

  const inletWaterTemperatureCooling: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "inletWaterTemperatureC",
    quantity: "Temperature",
    defaultValue: Amount.create(7, Units.Celsius),
    fieldName: "airTemperature",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("operatingMode=1", CustomUnitsLookup),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      "inletWaterTemperatureC=1:Celsius~130:Celsius&inletWaterTemperatureC<inletAirTemperature",
      CustomUnitsLookup
    ),
  };

  const fluidType: InputParam = {
    type: "Discrete",
    group: "calculationParams",
    name: "fluidType",
    values: [
      {
        value: 0,
        name: "water",
      },
      {
        value: 1,
        name: "water_ethylene",
      },
      {
        value: 2,
        name: "water_propylene",
      },
    ],
  };
  const glycolPercent: InputParam = {
    type: "Amount",
    group: "calculationParams",
    name: "glycol",
    quantity: "Dimensionless",
    defaultValue: Amount.create(0, Units.One),
    fieldName: "glycol",
    visibilityFilter: PropertyFilter.fromStringOrEmpty("(fluidType=1|fluidType=2)", CustomUnitsLookup),
    validationFilter: PropertyFilter.fromStringOrEmpty("glycol=10:Percent~60:Percent", CustomUnitsLookup),
  };

  const heatingMethod = getHeatingMethod(searchHeatingMethod, attributes);
  if (heatingMethod === "ambient") {
    return [...airflowParams];
  } else if (heatingMethod === "electric") {
    return [...airflowParams, inletAirTemperature, inletAirHumidity];
  } else if (heatingMethod === "water") {
    return [
      ...airflowParams,
      inletAirTemperature,
      caclMethodWaterCoil,
      inletAirHumidity,
      waterFlow,
      outletWaterTemperatureHeating,
      outletWaterTemperatureCooling,
      outletAirTemperature,
      inletWaterTemperatureHeating,
      inletWaterTemperatureCooling,
      fluidType,
      glycolPercent,
      ...(canCool ? [operatingMode] : []),
    ];
  } else {
    return exhaustiveCheck(heatingMethod);
  }
}

export function map(input: ComponentInput): InputMapperSuccess<Input> | InputMapperError {
  const { attributes, calcParams } = input;
  const airFlow = getAirFlow(attributes, calcParams);
  const control = A.getControl(attributes);
  const version = A.getVersion(attributes);
  const manueverVoltage = {
    property_filter: PropertyFilter.Empty,
    voltage: A.getManueverVoltage(attributes),
  };
  const calculationInput = getCalculationInput(calcParams, attributes);
  const customAirFlowIsEmpty =
    PropertyValueSet.getAmount<Quantity.VolumeFlow>("customAirFlow", calcParams) === undefined;

  if (!airFlow || !calculationInput) {
    return createInputMapperError([ProductDataError_NoDataAvailable(msgSource)]);
  }

  return createInputMapperSuccess({
    airFlow,
    control,
    version,
    manueverVoltage,
    calculationInput,
    customAirFlowIsEmpty,
  });
}

function getHeatingMethod(searchHeatingMethod: string, attributes: Attributes.Attributes): PU.HeatingMethod {
  if (searchHeatingMethod === "water" || searchHeatingMethod === "electric" || searchHeatingMethod === "ambient") {
    return searchHeatingMethod;
  } else {
    return PU.getHeatingMethod(attributes);
  }
}

function getCalculationInput(
  calcParams: PropertyValueSet.PropertyValueSet,
  attributes: Attributes.Attributes
): CalculationInput | undefined {
  const heatingMethod = PU.getHeatingMethod(attributes);
  if (heatingMethod === "ambient") {
    return {
      type: "ambient",
    };
  } else if (heatingMethod === "electric") {
    const inletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("inletAirTemperature", calcParams);
    const inletAirHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>("inletAirHumidity", calcParams);
    const heaterVoltage = A.getHeaterVoltage(attributes);
    const heaterPhases = A.getHeaterPhases(attributes);
    const maxPower = A.getMaxPower(attributes);
    if (
      inletAirTemperature === undefined ||
      inletAirHumidity === undefined ||
      heaterVoltage === undefined ||
      heaterPhases === undefined ||
      maxPower === undefined
    ) {
      return undefined;
    }
    const voltage: QP.Voltage = {
      property_filter: PropertyFilter.Empty,
      voltage: heaterVoltage,
      phases: Amount.create(heaterPhases, Units.One),
    };
    return {
      type: "electric",
      inletAirTemperature,
      inletAirHumidity,
      voltage,
      maxPower,
    };
  } else if (heatingMethod === "water") {
    const operatingMode =
      Shared.canCool(attributes) && PropertyValueSet.getInteger("operatingMode", calcParams) === 1
        ? "cooling"
        : "heating";
    const inletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("inletAirTemperature", calcParams);
    const inletAirHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>("inletAirHumidity", calcParams);
    const inletWaterTemperature =
      operatingMode === "cooling"
        ? PropertyValueSet.getAmount<Quantity.Temperature>("inletWaterTemperatureC", calcParams)
        : PropertyValueSet.getAmount<Quantity.Temperature>("inletWaterTemperature", calcParams);
    const calculationMethod = PropertyValueSet.getInteger("calcMethodWaterCoil", calcParams);
    const outletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("outletAirTemperature", calcParams);
    const outletWaterTemperature =
      operatingMode === "cooling"
        ? PropertyValueSet.getAmount<Quantity.Temperature>("outletWaterTemperatureC", calcParams)
        : PropertyValueSet.getAmount<Quantity.Temperature>("outletWaterTemperature", calcParams);
    const fluidType = PropertyValueSet.getInteger("fluidType", calcParams);
    const glycol =
      PropertyValueSet.getAmount<Quantity.Dimensionless>("glycol", calcParams) || Amount.create(0, Units.One);
    const waterFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("waterFlow", calcParams);
    const calculationMode = undefined; // TODO
    const dllInput = A.getDllInputs(attributes);
    if (
      inletAirTemperature === undefined ||
      inletAirHumidity === undefined ||
      inletWaterTemperature === undefined ||
      calculationMethod === undefined ||
      outletWaterTemperature === undefined ||
      waterFlow === undefined ||
      outletAirTemperature === undefined ||
      dllInput === undefined
    ) {
      return undefined;
    }
    return {
      type: "water",
      inletAirTemperature,
      inletAirHumidity,
      inletWaterTemperature,
      calculationMethod,
      outletWaterTemperature,
      waterFlow,
      outletAirTemperature,
      calculationMode,
      fluidType,
      glycol,
      dllInput,
      operatingMode,
    };
  } else {
    return exhaustiveCheck(heatingMethod);
  }
}

function getAirFlow(
  attributes: Attributes.Attributes,
  calcParams: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.VolumeFlow> | undefined {
  const selectedStep = PropertyValueSet.getInteger("airFlowStep", calcParams);
  if (selectedStep !== undefined) {
    const steps = Shared.getAirFlowSteps(attributes);
    const step = steps && steps.find((s) => s.step === selectedStep);
    if (selectedStep === 0) {
      return PropertyValueSet.getAmount<Quantity.VolumeFlow>("customAirFlow", calcParams);
    } else if (step !== undefined) {
      return step.airFlow;
    } else {
      return undefined;
    }
  } else {
    // In search, select the highest airflow
    const steps = Shared.getAirFlowSteps(attributes);
    return steps[0] && steps[0].airFlow;
  }
}
