/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Redux from "redux";
import * as Shared from "shared-lib/crm";
import * as Actions from "./actions";

type AnyStoreState = any;

export function createMiddleware(): any {
  return (store: Redux.Store<AnyStoreState>) => (next: Redux.Dispatch<AnyStoreState>) => (action: Actions.Action) => {
    const responseToReturn = next(action);
    if (action.type === "crm/ADD_ITEMS") {
      const params = action.payload.params;
      const items = action.payload.items;
      Shared.addToCrm(params.crmUrl, params.crmQuoteId, items).then((success) =>
        store.dispatch(Actions.itemsAdded(success))
      );
    }
    return responseToReturn;
  };
}
