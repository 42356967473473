import { createSelector, Selector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as PU from "shared-lib/product-utils";
import { diaqConnect } from "client-lib/redux-integration";
import * as QEcom from "shared-lib/query-ecom";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import { PrintViewContainerComponentEpim, Response, OwnProps, StateProps, Item } from "./container-component";
import { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (showAdvanced, userSettings) => ({
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

function mapPropsToQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    marketTables: PU.createMarketTablesQuery(ownProps.ecomUrl, ownProps.market),
    tablesForProducts: tablesForProductsQuery(ownProps.products),
    tablesForAccessories: tablesForAccessories(ownProps.products, response),
    fricoTables: QP.tablesByProductId(QP.fricoProductId, [
      "property",
      "ct_PropertyFieldNames",
      "ct_PropertySelectorTypes",
      "ct_SavedResultColumns",
    ]),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    productDescription: productDescriptions(ownProps, response),
  });
}

function tablesForAccessories(
  products: ReadonlyArray<Item>,
  response: Response | undefined
): QD.DiaqMapQuery<{}> | undefined {
  if (
    products === undefined ||
    response === undefined ||
    (response.tablesForProducts === undefined && isTablesForProductsQueryLoaded(products, response))
  ) {
    return undefined;
  }

  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const product of products) {
    const accessories = R.uniq(response.tablesForProducts[product.productId].ct_Accessories.map((acc) => acc.product));
    for (const acc of accessories) {
      map[acc] = QP.tablesByProductId(acc, [
        "property",
        "code",
        "ct_ItemNo",
        "ct_VariantNo",
        "ct_Accessories",
        "ct_Attributes2",
      ]);
    }
  }
  return QD.createMapQuery(map);
}

function isTablesForAccessoriesLoaded(products: ReadonlyArray<Item>, response: Response | undefined): boolean {
  if (
    !products ||
    !response ||
    !response.tablesForAccessories ||
    (response.tablesForProducts === undefined && isTablesForProductsQueryLoaded(products, response))
  ) {
    return false;
  }
  for (const product of products) {
    const accessories = R.uniq(response.tablesForProducts[product.productId].ct_Accessories.map((acc) => acc.product));
    for (const acc of accessories) {
      if (!response.tablesForAccessories[acc]) {
        return false;
      }
    }
  }
  return true;
}

function tablesForProductsQuery(products: ReadonlyArray<Item> | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (products === undefined) {
    return undefined;
  }

  const map: { [id: string]: QP.TablesByProductIdQuery } = {};

  for (const product of products) {
    map[product.productId] = QP.tablesByProductId(product.productId, [
      "ct_Accessories",
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_Accessories",
      "ct_Attributes2",
    ]);
  }

  return QD.createMapQuery(map);
}

function isTablesForProductsQueryLoaded(products: ReadonlyArray<Item> | undefined, response: Response): boolean {
  return (
    !!products && !!response?.tablesForProducts && products?.every((p) => !!response.tablesForProducts[p.productId])
  );
}

function productDescriptions(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (
    ownProps.products === undefined ||
    !response ||
    !response.fricoTables ||
    !response.ct_LanguageMapping ||
    !response.marketTables ||
    !response.tablesForProducts ||
    !response.tablesForAccessories ||
    !isTablesForProductsQueryLoaded(ownProps.products, response) ||
    !isTablesForAccessoriesLoaded(ownProps.products, response)
  ) {
    return undefined;
  }
  const language = Texts.getLanguage(ownProps.language, response.ct_LanguageMapping);
  const productsMap: { [id: string]: QEcom.ProductDescriptionQuery } = {};
  for (const product of ownProps.products) {
    productsMap[product.variantNo] = QEcom.productDescription(
      ownProps.specificationDataUrl,
      ownProps.market,
      language,
      product.m3ItemNo,
      product.variantNo
    );
  }

  const accessoriesMap: { [id: string]: QEcom.ProductDescriptionQuery } = {};

  const includedAccessories =
    (response.tablesForProducts &&
      response.marketTables &&
      PU.getFricoIncludedAccessoriesEpim({
        products: ownProps.products,
        response: {
          tablesForProducts: response.tablesForProducts,
          marketTables: response.marketTables,
          tablesForAccessories: response.tablesForAccessories,
        },
        addedAccessories: ownProps.addedAccessories,
      })) ||
    [];

  const quantities =
    response?.tablesForProducts &&
    response?.marketTables &&
    PU.getFricoAccessoryQuantitiesEpimImpl(
      ownProps.products,
      {
        tablesForProducts: response.tablesForProducts,
        tablesForAccessories: response.tablesForAccessories,
        marketTables: response.marketTables,
      },
      includedAccessories,
      ownProps.addedAccessories
    );

  if (!includedAccessories || !quantities) {
    return undefined;
  }

  for (const accessory of includedAccessories) {
    if ((quantities[accessory.itemNo] || 0) === 0) {
      continue;
    }
    accessoriesMap[accessory.variantId] = QEcom.productDescription(
      ownProps.specificationDataUrl,
      ownProps.market,
      language,
      accessory.itemNo,
      accessory.variantId
    );
  }

  return QD.createMapQuery({ ...productsMap, ...accessoriesMap });
}

export const PrintViewContainerEpim = diaqConnect(mapPropsToQuery, mapStateToProps)(PrintViewContainerComponentEpim);
