import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { CustomUnitsLookup } from "shared-lib/uom";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as R from "ramda";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  System,
  ResultQuery,
} from "../types";
import { Input, Response } from "./types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";
import * as AirDensity from "../shared/air-density";

const msgSource = "BoxFanInputMapper";

export function getCalcParams(params: string): ReadonlyArray<InputParam> {
  if (params === "search") {
    return [];
  }
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
      quantity: "VolumeFlow",
      fieldName: "airFlow",
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "externalPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "externalPressure=0.1:Pascal~999999:Pascal",
        CustomUnitsLookup
      ),
      quantity: "Pressure",
      fieldName: "airPressure",
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "soundPressureDistance",
      values: [
        {
          value: 10,
          name: "1.0 m",
        },
        {
          value: 15,
          name: "1.5 m",
        },
        {
          value: 30,
          name: "3.0 m",
        },
        {
          value: 50,
          name: "5.0 m",
        },
      ],
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "freq",
      values: [
        {
          value: 50,
          name: "50",
        },
        {
          value: 60,
          name: "60",
        },
      ],
    },
  ];
}

export function getQuery(productId: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_AccessoryPressureDrop: QP.tableByProductId(productId, "ct_AccessoryPressureDrop"),
    ct_DllLimit: QP.tableByProductId(productId, "ct_DllLimit"),
  });
}

export function getResultsQuery(system: System, componentId: string): ReadonlyArray<ResultQuery> {
  return system.components
    .filter((c) => c.id !== componentId && c.resultItems.length > 0)
    .map((c) => {
      const resultItems = c.resultItems.map((i) => i.name);
      return {
        componentId: c.id,
        resultItems: resultItems,
      };
    });
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  const inputErrors: Array<Messages.Message> = [];

  const { ct_AccessoryPressureDrop, ct_DllLimit } = queryResultMap;

  const frequency = PropertyValueSet.getInteger("freq", properties);

  const fanSeries =
    Attributes.getString("FAN-SERIES", attributes) || Attributes.getString("box-fan-series", attributes);
  const fanSize = Attributes.getString("FAN-SIZE", attributes) || Attributes.getString("box-fan-size", attributes);
  const fanType = Attributes.getString("FAN-TYPE", attributes) || Attributes.getString("box-fan-type", attributes);

  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const externalPressure = PropertyValueSet.getAmount<Quantity.Pressure>("externalPressure", calcParams);
  const voltage = PropertyValueSet.getInteger("voltage", calcParams);
  const phases = PropertyValueSet.getInteger("phases", calcParams);
  const airDensity = AirDensity.getAirDensityKgPerCubicMeter(calcParams);
  const soundPressureDistanceInDecimeter = PropertyValueSet.getInteger("soundPressureDistance", calcParams);
  const soundPressureDistance =
    soundPressureDistanceInDecimeter !== undefined
      ? Amount.create(soundPressureDistanceInDecimeter, Units.Decimeter)
      : undefined;

  if (!airFlow) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "airFlow"));
  }
  if (!fanSeries) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "fanSeries"));
  }
  if (!fanSize) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "fanSize"));
  }
  if (!externalPressure) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "externalPressure"));
  }
  if (!fanType) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "fanType"));
  }
  if (!voltage) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "voltage"));
  }
  if (!phases) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "phases"));
  }
  if (!soundPressureDistance) {
    inputErrors.push(Messages.Error_MissingInput(msgSource, "soundPressureDistance"));
  }
  if (inputErrors.length > 0) {
    return createInputMapperError(inputErrors);
  }

  const dllLimits = ct_DllLimit.filter((l) => PropertyFilter.isValid(properties, l.property_filter));

  const dllLimitsMap = R.fromPairs(dllLimits.map((t) => [t.fan_series, t.fan_sizes] as R.KeyValuePair<string, string>));

  return createInputMapperSuccess({
    airFlow: airFlow,
    externalPressure: externalPressure,
    airDensity: airDensity,
    fanSeries: fanSeries!,
    fanType: fanType!,
    soundPressureDistance: soundPressureDistance,
    queryResultMap: queryResultMap,
    calcParams: calcParams,
    frequency: frequency!,
    fanSize: fanSize!,
    accessoryPressureDrop: getAccessoriesList(ct_AccessoryPressureDrop, properties),
    dllLimits: dllLimitsMap,
    voltage: voltage!,
    phases: phases!,
  });
}

function getAccessoriesList(
  accessoryPressureDrop: QP.AccessoryPressureDropTable,
  properties: PropertyValueSet.PropertyValueSet
): ReadonlyArray<QP.AccessoryPressureDrop> {
  return accessoryPressureDrop.filter((a) => PropertyFilter.isValid(properties, a.property_filter));
}
