/* eslint-disable no-restricted-properties */
import * as R from "ramda";
import * as Vec2 from "./vector2";

export interface Spline {
  readonly xMin: number;
  readonly xMax: number;
  readonly segments: ReadonlyArray<SplineSegment>;
}

export interface SplineSegment {
  readonly xMin: number;
  readonly xMax: number;
  readonly slope: number;
  readonly yMin:number;
  readonly c0: number;
  readonly c1: number;
  readonly a: number;
  readonly b: number;
  readonly c: number;
  readonly d: number;
}

function splineCreate(segments: ReadonlyArray<SplineSegment>): Spline {
  const xMin = Math.min(...segments.map((s) => s.xMin));
  const xMax = Math.max(...segments.map((s) => s.xMax));
  return { xMin, xMax, segments };
}

export function splineGetPoint(x: number, spline: Spline): number | undefined {
  const segment = spline.segments.find((s) => x >= s.xMin && x <= s.xMax);
  if (!segment) {
    return undefined;
  }
  return splineSegmentGetPoint(x, segment);
}

export function splineGetPoints(numPoints: number, spline: Spline): ReadonlyArray<Vec2.Vec2> {
  const step = (spline.xMax - spline.xMin) / (numPoints - 1);
  const points: Array<Vec2.Vec2> = [];
  for (let i = 0; i < numPoints; ++i) {
    const x = spline.xMin + i * step;
    const y = splineGetPoint(x, spline);
    if (y !== undefined) {
      points.push({ x, y });
    }
  }
  return points;
}

export function splineGetPointsPerSegment(numPointsPerSegment: number, spline: Spline): ReadonlyArray<Vec2.Vec2> {
  const points: Array<Vec2.Vec2> = [];
  spline.segments.forEach((segment) => {
    const step = (segment.xMax - segment.xMin) / (numPointsPerSegment - 1);
    for (let i = 0; i < numPointsPerSegment; ++i) {
      const x = segment.xMin + i * step;
      const y = splineGetPoint(x, spline);
      if (y !== undefined) {
        points.push({ x, y });
      }
    }
  });
  return points;
}

function splineSegmentGetPoint(x: number, segment: SplineSegment): number | undefined {
  if (x < segment.xMin || x > segment.xMax) {
    return undefined;
  }
  return segment.a + segment.b * x + segment.c * x * x + segment.d * x * x * x;
}

function calculateSlope(array: ReadonlyArray<Vec2.Vec2>, i: number): number {
  if (array.length <= 1) {
    return 0;
  } else if (array.length === 2) {
    const p0 = array[0];
    const p1 = array[1];
    if (p1.x === p0.y) {
      return 0;
    }
    return (p1.y - p0.y) / (p1.x - p0.x);
  } else if (i === 0) {
    const p0 = array[0];
    const p1 = array[1];
    const p2 = array[2];
    return ((p0.y - p1.y) / (p0.x - p1.x) + (p1.y - p2.y) / (p1.x - p2.x)) / 2.0;
  } else if (i === array.length - 1) {
    const p0 = array[array.length - 2];
    const p1 = array[array.length - 1];
    if (p1.x === p0.y || p1.x - p0.y < 1) {
      return 0;
    }
    return (p1.y - p0.y) / (p1.x - p0.x);
  } else {
    const p0 = array[i - 1];
    const p1 = array[i];
    const p2 = array[i + 1];
    return ((p0.y - p1.y) / (p0.x - p1.x) + (p1.y - p2.y) / (p1.x - p2.x)) / 2.0;
  }
}

export function splineCreateFromPoints(points: ReadonlyArray<Vec2.Vec2>): Spline {
  const num1 = points.length;

  const segments: Array<SplineSegment> = [];

  const array = R.sort((a, b) => a.x - b.x, points);

  for (let i = 0; i < num1 - 1; ++i) {
    const k1 = calculateSlope(array, i);
    const k2 = calculateSlope(array, i + 1);

    const p1 = array[i];
    const p2 = array[i + 1];

    const num2 = (-2.0 * (k2 + 2.0 * k1)) / (p2.x - p1.x) + (6.0 * (p2.y - p1.y)) / Math.pow(p2.x - p1.x, 2.0);

    const num3 = (2.0 * (2.0 * k2 + k1)) / (p2.x - p1.x) - (6.0 * (p2.y - p1.y)) / Math.pow(p2.x - p1.x, 2.0);

    const c = (p2.x * num2 - p1.x * num3) / 2.0 / (p2.x - p1.x);
    const d = (num3 - num2) / 6.0 / (p2.x - p1.x);

    const b =
      (p2.y -
        p1.y -
        c * (Math.pow(p2.x, 2.0) - Math.pow(p1.x, 2.0)) -
        d * (Math.pow(p2.x, 3.0) - Math.pow(p1.x, 3.0))) /
      (p2.x - p1.x);

    const a = p1.y - b * p1.x - c * Math.pow(p1.x, 2.0) - d * Math.pow(p1.x, 3.0);

    segments.push({
      slope:0,
      c0:0,
      c1:0,
      yMin: 0,
      // Above used in new calclation
      a: a,
      b: b,
      c: c,
      d: d,
      xMin: p1.x,
      xMax: p2.x,
    });
  }

  return splineCreate(segments);
}

// export function splineCreateFromPointsOld(points: Array<Vec2.Vec2>): Spline {
//   const ps = R.sort((a, b) => a.x - b.x, points).filter(
//     (point, index, arr) => index === arr.findIndex(t => t.x === point.x && t.y === point.y)
//   );

//   if (ps.length === 0) {
//     return splineCreate([]);
//   }
//   if (ps.length === 1) {
//     return splineCreate([splineSegmentCreate(ps[0].x, ps[0].x, ps[0].y, 0, 0, 0)]);
//   }

//   const segments: Array<SplineSegment> = [];
//   let dPrev = 0;
//   for (let i = 1; i < ps.length; ++i) {
//     const prev = ps[i - 1];
//     const current = ps[i];
//     const dx = current.x - prev.x;
//     const dy = current.y - prev.y;

//     let dCurr = 0;
//     if (i === ps.length - 1) {
//       // Last point
//       dCurr = 3 * dy / (2 * dx) - dPrev / 2;
//     } else {
//       const next = ps[i + 1];
//       const ka = (next.x - current.x) / (next.y - current.y);
//       const kb = dx / dy;
//       dCurr = ka * kb < 0 ? 0 : 2 / (ka + kb);
//     }

//     if (i === 1) {
//       // First point
//       dPrev = 3 * dy / (2 * dx) - dCurr / 2;
//     }
//     const ddPrev = -2 * (dCurr + 2 * dPrev) / dx + 6 * dy / Math.pow(dx, 2);
//     const ddCurr = 2 * (2 * dCurr + dPrev) / dx - 6 * dy / Math.pow(dx, 2);
//     const d = (ddCurr - ddPrev) / (6 * dx);
//     const c = (current.x * ddPrev - prev.x * ddCurr) / (2 * dx);
//     const b =
//       (dy - c * (Math.pow(current.x, 2) - Math.pow(prev.x, 2)) - d * (Math.pow(current.x, 3) - Math.pow(prev.x, 3))) /
//       dx;
//     const a = prev.y - b * prev.x - c * Math.pow(prev.x, 2) - d * Math.pow(prev.x, 3);

//     segments.push(splineSegmentCreate(prev.x, current.x, a, b, c, d));
//     dPrev = dCurr;
//   }
//   return splineCreate(segments);
// }

export function splineSubtract(a: Spline, b: Spline): Spline {
  const newPoints = a.segments.map((s) => {
    const aY = splineSegmentGetPoint(s.xMin, s) || 0;
    const bY = splineGetPoint(s.xMin, b);
    const newY = (aY || 0) - (bY || 0);
    return Vec2.vec2Create(s.xMin, newY);
  });
  if (a.segments.length > 0) {
    const s = a.segments[a.segments.length - 1];
    const aY = splineSegmentGetPoint(s.xMax, s) || 0;
    const bY = splineGetPoint(s.xMax, b);
    const newY = (aY || 0) - (bY || 0);
    newPoints.push(Vec2.vec2Create(s.xMax, newY));
  }
  return splineCreateFromPoints(newPoints);
}

export function findSystemCurveIntersection(k: number, spline: Spline): Vec2.Vec2 | undefined {
  let lowX = spline.xMin;
  let highX = spline.xMax;
  const lowY = splineGetPoint(lowX, spline) || 0;
  const highY = splineGetPoint(highX, spline) || 0;
  const lowK = lowY / (lowX * lowX);
  const highK = highY / (highX * highX);
  if (k > lowK || k < highK) {
    return undefined;
  }
  let x = (highX + lowX) * 0.5;
  let ySystem = k * x * x;
  let ySpline: number | undefined = undefined;
  while (highX - lowX > 0.1) {
    ySpline = splineGetPoint(x, spline);
    if (ySpline === undefined) {
      return undefined;
    }
    if (ySystem < ySpline) {
      lowX = x;
    } else {
      highX = x;
    }
    x = (highX + lowX) * 0.5;
    ySystem = k * x * x;
  }
  if (!ySpline) {
    return undefined;
  }
  return Vec2.vec2Create(x, ySpline);
}

export function findSplineSplineInterSection(a: Spline, b: Spline): Vec2.Vec2 | undefined {
  for (const aSegment of a.segments) {
    const aSplineMinY = splineGetPoint(aSegment.xMin, a);
    const aSplineMaxY = splineGetPoint(aSegment.xMax, a);

    if (aSplineMaxY === undefined || aSplineMinY === undefined) {
      continue;
    }

    const aLineK = (aSplineMaxY - aSplineMinY) / (aSegment.xMax - aSegment.xMin);
    const aLineM = (aSegment.xMax * aSplineMinY - aSegment.xMin * aSplineMaxY) / (aSegment.xMax - aSegment.xMin);

    for (const bSegment of b.segments) {
      const bSplineMaxY = splineGetPoint(bSegment.xMax, b);
      const bSplineMinY = splineGetPoint(bSegment.xMin, b);

      if (bSplineMinY === undefined || bSplineMaxY === undefined) {
        continue;
      }

      const bLineK = (bSplineMaxY - bSplineMinY) / (bSegment.xMax - bSegment.xMin);
      const bLineM = (bSegment.xMax * bSplineMinY - bSegment.xMin * bSplineMaxY) / (bSegment.xMax - bSegment.xMin);

      const x = (bLineM - aLineM) / (aLineK - bLineK);

      const ay = splineGetPoint(x, a);
      const by = splineGetPoint(x, b);

      if (
        x >= aSegment.xMin &&
        x <= aSegment.xMax &&
        x >= bSegment.xMin &&
        x <= bSegment.xMax &&
        ay !== undefined &&
        by !== undefined
      ) {
        return { x: x, y: ay };
      }
    }
  }
  return undefined;
}
