/* eslint-disable no-case-declarations */
import { PropertyFilterAst as Ast, PropertyValue } from "@promaster-sdk/property";
import { Amount, Unit, UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import { AnyQuantity } from "shared-lib/uom";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as Texts from "shared-lib/language-texts";
import { PText } from "shared-lib/language-texts";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import { PropertyFormats } from "./types";

export function createFilterPrettyPrintMessages(
  productId: string,
  translate: Texts.TranslateFunction,
  propertyFormats: PropertyFormats
): PropertyFiltering.FilterPrettyPrintMessages {
  return {
    comparisionOperationMessage: (op: Ast.ComparisonOperationType, left: string, right: string): string =>
      translate(comparisionText(op, left, right)),
    equalsOperationMessage: (op: Ast.EqualsOperationType, left: string, right: string): string =>
      translate(equalsText(op, left, right)),
    rangeMessage: (min: string, max: string) => translate(Texts.between_min_and_max(min, max)),
    andMessage: (): string => translate(Texts.and()),
    orMessage: (): string => translate(Texts.or()),
    propertyNameMessage: (propertyName: string): string =>
      translate(
        Texts.property_name(productId, propertyName),
        translate(Texts.filterPrettyPrintPropertyName(propertyName))
      ),
    propertyValueMessage: (propertyName: string, propertyValue: PropertyValue.PropertyValue): string => {
      switch (propertyValue.type) {
        case "amount":
          const format = propertyFormats[propertyName];
          const unit = format ? format.unit : propertyValue.value.unit;
          const unitLabel = translate(
            Texts.unitLabel(unit as Unit.Unit<AnyQuantity>),
            UnitFormat.getUnitFormat(unit, UnitsFormat)?.label
          );
          if (format) {
            const convertedValue = Amount.valueAs(format.unit, propertyValue.value);
            return convertedValue.toFixed(format.decimalCount) + " " + unitLabel;
          }
          return propertyValue.value.value + " " + unitLabel;
        case "integer":
          return translate(
            Texts.property_value(productId, propertyName, PropertyValue.getInteger(propertyValue) || 999999)
          );
        case "text":
          return propertyValue.value;
        default:
          return exhaustiveCheck(propertyValue);
      }
    },
    nullMessage: (): string => "null",
  };
}

function comparisionText(type: Ast.ComparisonOperationType, left: string, right: string): PText {
  switch (type) {
    case "lessOrEqual":
      return Texts.left_must_be_less_than_or_equal_to_right(left, right);
    case "greaterOrEqual":
      return Texts.left_must_be_greater_than_or_equal_to_right(left, right);
    case "less":
      return Texts.left_must_be_less_than_right(left, right);
    case "greater":
      return Texts.left_must_be_greater_than_right(left, right);
    default:
      throw new Error("Unknown ComparisonOperationType ");
  }
}

function equalsText(type: Ast.EqualsOperationType, left: string, right: string): PText {
  switch (type) {
    case "equals":
      return Texts.left_must_be_right(left, right);
    case "notEquals":
      return Texts.left_must_not_be_right(left, right);
    default:
      throw new Error(`Unknown EqualsOperationType ${type}.`);
  }
}
