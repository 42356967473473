import * as AI from "abstract-image";
import * as R from "ramda";
import * as Axis from "./axis";

const font = "Helvetica";

export type Partial<T> = { [P in keyof T]?: T[P] };

export interface Chart {
  readonly components: ReadonlyArray<ChartComponent>;
  readonly xAxisBottom: Axis.Axis;
  readonly xAxisTop: Axis.Axis | undefined;
  readonly yAxisLeft: Axis.Axis;
  readonly yAxisRight: Axis.Axis | undefined;
  readonly backgroundColor: AI.Color;
  readonly gridColor: AI.Color;
  readonly gridLabelColor: AI.Color;
  readonly gridThickness: number;
  readonly subGrid: boolean;
  readonly subGridColor: AI.Color;
  readonly subGridThickness: number;
  readonly fontSize: number;
  readonly linesAndTextXAxisNoOfDecimals: number;
  readonly linesAndTextYAxisNoOfDecimals: number;
  readonly paddingLeft: number;
  readonly paddingRight: number;
  readonly paddingTop: number;
  readonly paddingBottom: number;
  readonly axisLabelPlacement: "center" | "end" | "compact";
  readonly label: string | undefined;
}

export type ChartProps = Partial<Chart>;

export const chartPadding = { left: 45, right: 50, top: 35, bottom: 35 };
export const chartPaddingCompact = { left: 70, right: 50, top: 10, bottom: 30 };

export function createChart(props: ChartProps): Chart {
  const {
    components = [],
    xAxisBottom = Axis.createLinearAxis(0, 100, ""),
    xAxisTop = undefined,
    yAxisLeft = Axis.createLinearAxis(0, 100, ""),
    yAxisRight = undefined,
    backgroundColor = AI.white,
    gridColor = AI.gray,
    gridLabelColor = AI.black,
    gridThickness = 1,
    subGrid = true,
    subGridColor = AI.lightGray,
    subGridThickness = 1,
    fontSize = 12,
    linesAndTextXAxisNoOfDecimals = 2,
    linesAndTextYAxisNoOfDecimals = 2,
    paddingLeft = chartPadding.left,
    paddingRight = chartPadding.right,
    paddingTop = chartPadding.top,
    paddingBottom = chartPadding.bottom,
    axisLabelPlacement = "end",
    label = undefined,
  } = props || {};
  return {
    components,
    xAxisBottom,
    xAxisTop,
    yAxisLeft,
    yAxisRight,
    backgroundColor,
    gridColor,
    gridLabelColor,
    gridThickness,
    subGrid,
    subGridColor,
    subGridThickness,
    fontSize,
    linesAndTextXAxisNoOfDecimals,
    linesAndTextYAxisNoOfDecimals,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    axisLabelPlacement,
    label,
  };
}

export type ChartComponent = ChartPoint | ChartLine | ChartText | ChartArea;

export type XAxis = "bottom" | "top";
export type YAxis = "left" | "right";

export type ChartPointShape = "circle" | "triangle" | "square";

export interface ChartPoint {
  readonly type: "point";
  readonly shape: ChartPointShape;
  readonly position: AI.Point;
  readonly color: AI.Color;
  readonly stroke: AI.Color;
  readonly labelColor: AI.Color;
  readonly strokeThickness: number;
  readonly size: AI.Size;
  readonly text: string;
  readonly axisLines: ChartPointAxisLines;
  readonly xAxis: XAxis;
  readonly yAxis: YAxis;
}

export type ChartPointAxisLines = "none" | "lines" | "linesAndText";

export type ChartPointProps = Partial<ChartPoint>;

export function createChartPoint(props?: ChartPointProps): ChartPoint {
  const {
    shape = "circle",
    position = AI.createPoint(0, 0),
    color = AI.black,
    stroke = AI.black,
    labelColor = AI.black,
    strokeThickness = 0,
    size = AI.createSize(6, 6),
    text = "",
    axisLines = "linesAndText",
    xAxis = "bottom",
    yAxis = "left",
  } = props || {};
  return {
    type: "point",
    shape,
    position,
    color,
    stroke,
    labelColor,
    strokeThickness,
    size,
    text,
    axisLines,
    xAxis,
    yAxis,
  };
}

export interface ChartLine {
  readonly type: "line";
  readonly points: ReadonlyArray<AI.Point>;
  readonly color: AI.Color;
  readonly thickness: number;
  readonly text: string;
  readonly textPosition: LineTextPosition;
  readonly textBackground: boolean;
  readonly textColor: AI.Color;
  readonly xAxis: XAxis;
  readonly yAxis: YAxis;
  readonly textAnchorPosition?: LineTextAnchorPosition;
}

export type LineTextPosition = "start" | "middle" | "end";
export type LineTextAnchorPosition = "topLeft" | "topRight" | "bottomRight" | "bottomLeft" | "center";

export type ChartLineProps = Partial<ChartLine>;

export function createChartLine(props: ChartLineProps): ChartLine {
  const {
    points = [],
    color = AI.black,
    thickness = 1,
    text = "",
    textPosition = "start",
    textBackground = false,
    textColor = AI.black,
    xAxis = "bottom",
    yAxis = "left",
    textAnchorPosition = "center",
  } = props || {};
  return {
    type: "line",
    points,
    color,
    thickness,
    text,
    textPosition,
    textBackground,
    textColor,
    xAxis,
    yAxis,
    textAnchorPosition,
  };
}

export interface ChartText {
  readonly type: "text";
  readonly color: AI.Color;
  readonly position: AI.Point;
  readonly text: string;
  readonly xAxis: XAxis;
  readonly yAxis: YAxis;
}

export type ChartTextProps = Partial<ChartText>;

export function createChartText(props: ChartTextProps): ChartText {
  const { position = AI.createPoint(0, 0), text = "", xAxis = "bottom", yAxis = "left", color = AI.black } = props;
  return {
    type: "text",
    color,
    position,
    text,
    xAxis,
    yAxis,
  };
}

export interface ChartArea {
  readonly type: "area";
  readonly points: ReadonlyArray<AI.Point>;
  readonly color: AI.Color;
  readonly thickness: number;
  readonly fill: AI.Color;
  readonly text: string;
  readonly xAxis: XAxis;
  readonly yAxis: YAxis;
}

export type ChartAreaProps = Partial<ChartArea>;

export function createChartArea(props: ChartAreaProps): ChartArea {
  const { points = [], color = AI.black, thickness = 1, fill = AI.black, text = "", xAxis = "bottom", yAxis = "left" } =
    props || {};
  return {
    type: "area",
    points,
    color,
    thickness,
    fill,
    text,
    xAxis,
    yAxis,
  };
}

export function inverseTransformPoint(
  point: AI.Point,
  size: AI.Size,
  chart: Chart,
  xAxis: XAxis,
  yAxis: YAxis
): AI.Point | undefined {
  const xMin = chart.paddingLeft;
  const xMax = size.width - chart.paddingRight;
  const yMin = size.height - chart.paddingBottom;
  const yMax = chart.paddingTop;
  const x = Axis.inverseTransformValue(point.x, xMin, xMax, xAxis === "top" ? chart.xAxisTop : chart.xAxisBottom);
  const y = Axis.inverseTransformValue(point.y, yMin, yMax, yAxis === "right" ? chart.yAxisRight : chart.yAxisLeft);
  if (x === undefined || y === undefined) {
    return undefined;
  }
  return AI.createPoint(x, y);
}

export interface RenderChartOptions {
  readonly allowTextOverlap?: boolean;
  readonly hideAxsis?: boolean;
  readonly hideBackgroundBorder?: boolean;
  readonly formatNumber?: (number: string) => string;
}

export function renderChart(chart: Chart, size: AI.Size, options?: RenderChartOptions): AI.AbstractImage {
  const { allowTextOverlap, hideAxsis = false, hideBackgroundBorder = false, formatNumber } = options || {};
  const { width, height } = size;
  const { xAxisBottom, xAxisTop, yAxisLeft, yAxisRight } = chart;
  const padding =
    chart.axisLabelPlacement === "compact"
      ? chartPaddingCompact
      : {
          left: chart.paddingLeft,
          right: chart.paddingRight,
          bottom: chart.paddingBottom,
          top: chart.paddingTop,
        };

  const gridWidth = width - padding.left - padding.right;
  const gridHeight = height - padding.top - padding.bottom;

  const xMin = padding.left;
  const xMax = width - padding.right;
  const yMin = height - padding.bottom;
  const yMax = padding.top;

  const components = [];

  const renderedBackground = generateBackground(xMin, xMax, yMin, yMax, chart, hideBackgroundBorder);
  components.push(renderedBackground);

  if ((!xAxisBottom && !xAxisTop) || (!yAxisLeft && !yAxisRight)) {
    return AI.createAbstractImage(AI.createPoint(0, 0), size, AI.white, [renderedBackground]);
  }

  const renderedAreas = generateAreas(xMin, xMax, yMin, yMax, chart);
  components.push(...renderedAreas);

  if (!hideAxsis) {
    const renderedXAxisBottom = generateXAxisBottom(
      gridWidth,
      xAxisBottom,
      xMin,
      xMax,
      yMin,
      yMax,
      formatNumber,
      chart
    );
    const renderedXAxisTop = generateXAxisTop(gridWidth, xAxisTop, xMin, xMax, yMax, formatNumber, chart);
    const renderedYAxisLeft = generateYAxisLeft(gridHeight, yAxisLeft, xMin, xMax, yMin, yMax, formatNumber, chart);
    const renderedYAxisRight = generateYAxisRight(gridHeight, yAxisRight, xMax, yMin, yMax, formatNumber, chart);
    components.push(renderedXAxisBottom, renderedXAxisTop, renderedYAxisLeft, renderedYAxisRight);
  }

  const renderedLines = generateLines(xMin, xMax, yMin, yMax, chart);
  const renderedPoints = generatePoints(xMin, xMax, yMin, yMax, formatNumber, chart);
  const renderedTexts = generateTexts(xMin, xMax, yMin, yMax, chart);
  const renderedLabel = generateLabel(xMin, xMax, yMin, yMax, chart);
  components.push(...renderedLines, ...renderedPoints, ...renderedTexts, ...renderedLabel);

  const unsortedComponents = [
    ...components.filter((c) => c.type === "group" && c.name === "lineLabel"),
    ...components.filter((c) => c.type !== "text"),
    ...components.filter((c) => c.type === "text"),
  ];
  const nonOverlappingTexts = unoverlapTexts(
    xMin,
    xMax,
    yMin,
    yMax,
    components.filter((c) => c.type === "text") as Array<AI.Text>
  );
  const sortedComponents = [...unsortedComponents.filter((c) => c.type !== "text"), ...nonOverlappingTexts];

  return AI.createAbstractImage(AI.createPoint(0, 0), size, AI.white, allowTextOverlap ? components : sortedComponents);
}

function unoverlapTexts(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  texts: ReadonlyArray<AI.Text>
): ReadonlyArray<AI.Text> {
  const newTexts = [...texts];
  const rects: Array<Rect> = [];
  for (const text of texts) {
    const rect = getTextRect(text);
    rects.push(rect);
  }

  for (let i = 0; i < 20; ++i) {
    const maxOverlap = findMaxOverlap(rects);
    if (maxOverlap === undefined || maxOverlap.dist < 3) {
      break;
    }
    const aText = newTexts[maxOverlap.a];
    const bText = newTexts[maxOverlap.b];
    const aRect = rects[maxOverlap.a];
    const bRect = rects[maxOverlap.b];

    const aCorr = getCorr(xMin, xMax, yMin, yMax, aRect, { x: -maxOverlap.corr.x, y: -maxOverlap.corr.y });
    newTexts[maxOverlap.a] = {
      ...aText,
      position: {
        x: aText.position.x + aCorr.x,
        y: aText.position.y + aCorr.y,
      },
    };
    rects[maxOverlap.a] = {
      ...aRect,
      x: aRect.x + aCorr.x,
      y: aRect.y + aCorr.y,
    };

    const bCorr = getCorr(xMin, xMax, yMin, yMax, bRect, maxOverlap.corr);
    newTexts[maxOverlap.b] = {
      ...bText,
      position: {
        x: bText.position.x + bCorr.x,
        y: bText.position.y + bCorr.y,
      },
    };
    rects[maxOverlap.b] = {
      ...bRect,
      x: bRect.x + bCorr.x,
      y: bRect.y + bCorr.y,
    };
  }

  return newTexts;
}

interface Rect {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
}

interface Vector {
  readonly x: number;
  readonly y: number;
}

interface OverlappingRects {
  readonly a: number;
  readonly b: number;
  readonly dist: number;
  readonly corr: Vector;
}

function findMaxOverlap(rects: ReadonlyArray<Rect>): OverlappingRects | undefined {
  let overlap: OverlappingRects | undefined;
  for (let a = 0; a < rects.length; ++a) {
    for (let b = a + 1; b < rects.length; ++b) {
      const aRect = rects[a];
      const bRect = rects[b];
      const xOverlap = getIntervalOverlap(aRect.x, aRect.x + aRect.width, bRect.x, bRect.x + bRect.width);
      const yOverlap = getIntervalOverlap(aRect.y, aRect.y + aRect.height, bRect.y, bRect.y + bRect.height);
      if (xOverlap === undefined || yOverlap === undefined) {
        continue;
      }
      const corr = Math.abs(xOverlap) > Math.abs(yOverlap) ? { x: 0, y: yOverlap * 0.5 } : { x: xOverlap * 0.5, y: 0 };
      const dist = Math.max(Math.abs(corr.x), Math.abs(corr.y));
      if (overlap === undefined || overlap.dist < dist) {
        overlap = { a, b, dist, corr };
      }
    }
  }
  return overlap;
}

function getIntervalOverlap(aStart: number, aEnd: number, bStart: number, bEnd: number): number | undefined {
  if (aEnd < bStart || bEnd < aStart) {
    return undefined;
  }
  const aMid = 0.5 * (aStart + aEnd);
  const bMid = 0.5 * (bStart + bEnd);
  if (aMid < bMid) {
    return aEnd - bStart;
  } else {
    return aStart - bEnd;
  }
}

function getCorr(xMin: number, xMax: number, yMin: number, yMax: number, rect: Rect, corr: Vector): Vector {
  const newX = Math.max(xMin, Math.min(xMax - rect.width, rect.x + corr.x));
  const newY = Math.max(yMax, Math.min(yMin - rect.height, rect.y + corr.y));
  return { x: newX - rect.x, y: newY - rect.y };
}

function getTextRect(text: AI.Text): Rect {
  const width = 60;
  const height = text.fontSize * 1.5;
  const hGrow = text.horizontalGrowthDirection;
  const vGrow = text.verticalGrowthDirection;
  const textX = text.position.x;
  const textY = text.position.y;
  const x = hGrow === "left" ? textX - width : hGrow === "right" ? textX : textX - 0.5 * width;
  const y = vGrow === "up" ? textY - height : vGrow === "down" ? textY : textY - 0.5 * height;
  return { x, y, width, height };
}

function generateBackground(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart,
  hideBorder: boolean
): AI.Component {
  const topLeft = AI.createPoint(xMin, yMax);
  const bottomRight = AI.createPoint(xMax, yMin);
  return AI.createRectangle(
    topLeft,
    bottomRight,
    chart.gridColor,
    hideBorder ? 0 : chart.gridThickness,
    chart.backgroundColor
  );
}

export function generateXAxisBottom(
  xPixels: number,
  xAxisBottom: Axis.Axis | undefined,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): AI.Component {
  if (!xAxisBottom) {
    return AI.createGroup("XAxisBottom", []);
  }
  const xTicks = Axis.getTicks(xPixels, xAxisBottom);
  const xTextLines = generateXAxisGridLines(
    xMin,
    xMax,
    yMin + 10,
    yMax,
    xTicks.filter((t) => t.main).map((t) => t.value),
    xAxisBottom,
    chart.gridColor,
    chart.gridThickness
  );
  const xLines = chart.subGrid
    ? generateXAxisGridLines(
        xMin,
        xMax,
        yMin,
        yMax,
        xTicks.map((t) => t.value),
        xAxisBottom,
        chart.subGridColor,
        chart.subGridThickness
      )
    : AI.createGroup("", []);
  const xLabels = generateXAxisLabels(
    xMin,
    xMax,
    yMin + 10,
    "down",
    xTicks.filter((t) => t.main).map((t) => t.value),
    xAxisBottom,
    formatNumber,
    chart
  );

  const xLabel = (() => {
    switch (chart.axisLabelPlacement) {
      case "center":
        return AI.createText(
          AI.createPoint((xMin + xMax) * 0.5, yMin + chart.paddingBottom - 5),
          xAxisBottom.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "uniform",
          "up",
          0,
          chart.gridLabelColor
        );

      case "compact":
      case "end":
      default:
        return AI.createText(
          AI.createPoint(xMax + 15, yMin + 10),
          xAxisBottom.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "right",
          "down",
          0,
          chart.gridLabelColor
        );
    }
  })();
  return AI.createGroup("XAxisBottom", [xTextLines, xLines, xLabels, xLabel]);
}

export function generateXAxisTop(
  xPixels: number,
  xAxisTop: Axis.Axis | undefined,
  xMin: number,
  xMax: number,
  yMax: number,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): AI.Component {
  if (!xAxisTop) {
    return AI.createGroup("XAxisTop", []);
  }
  const xTicks = Axis.getTicks(xPixels, xAxisTop);
  const xLines = generateXAxisGridLines(
    xMin,
    xMax,
    yMax - 10,
    yMax,
    xTicks.filter((t) => t.main).map((t) => t.value),
    xAxisTop,
    chart.gridColor,
    chart.gridThickness
  );
  const xLabels = generateXAxisLabels(
    xMin,
    xMax,
    yMax - 13,
    "up",
    xTicks.filter((t) => t.main).map((t) => t.value),
    xAxisTop,
    formatNumber,
    chart
  );
  const xLabel =
    chart.axisLabelPlacement === "end"
      ? AI.createText(
          AI.createPoint(xMax, yMax - 13),
          xAxisTop.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "right",
          "up",
          0,
          chart.gridLabelColor
        )
      : AI.createText(
          AI.createPoint((xMin + xMax) * 0.5, yMax - chart.paddingTop + 5),
          xAxisTop.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "uniform",
          "down",
          0,
          chart.gridLabelColor
        );
  return AI.createGroup("XAxisTop", [xLines, xLabels, xLabel]);
}

export function generateYAxisLeft(
  yPixels: number,
  yAxisLeft: Axis.Axis | undefined,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): AI.Component {
  if (!yAxisLeft) {
    return AI.createGroup("YAxisLeft", []);
  }
  const yTicks = Axis.getTicks(yPixels, yAxisLeft);
  const yLines = chart.subGrid
    ? generateYAxisLines(
        xMin,
        xMax,
        yMin,
        yMax,
        yTicks.map((t) => t.value),
        yAxisLeft,
        chart.subGridColor,
        chart.subGridThickness
      )
    : AI.createGroup("", []);
  const mainYTicks = yTicks.filter((t) => t.main);
  const yTickValues = mainYTicks.map((t) => t.value);
  const yTextLines = generateYAxisLines(
    xMin - 5,
    xMax,
    yMin,
    yMax,
    yTickValues,
    yAxisLeft,
    chart.gridColor,
    chart.gridThickness
  );
  const yLabels = generateYAxisLabels(xMin - 7, yMin, yMax, "left", yTickValues, yAxisLeft, formatNumber, chart);

  const yLabel = (() => {
    switch (chart.axisLabelPlacement) {
      case "center":
        return AI.createText(
          AI.createPoint(5, (yMin + yMax) * 0.5),
          yAxisLeft.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          -90,
          "center",
          "uniform",
          "down",
          0,
          chart.gridLabelColor
        );
      case "compact": {
        const yLabelPositions = mainYTicks.map((tick) => ({
          pos: AI.createPoint(xMin - 7, Axis.transformValue(tick.value, yMin, yMax, yAxisLeft)),
          value: tick.value,
        }));

        const labelPosition = yMax + chart.fontSize / 2;
        const margin = chart.fontSize * 1;
        const collision = yLabelPositions.find(
          (label) => label.pos.y < labelPosition + margin && label.pos.y > labelPosition - margin
        );

        const offset = collision ? collision.value.toString().length * chart.fontSize * 0.8 : 0;

        return AI.createText(
          AI.createPoint(collision ? xMin - 7 - offset : xMin - 7, labelPosition),
          yAxisLeft.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "left",
          "up",
          0,
          chart.gridLabelColor
        );
      }
      case "end":
      default:
        return AI.createText(
          AI.createPoint(xMin - 7, yMax - 16),
          yAxisLeft.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "left",
          "up",
          0,
          chart.gridLabelColor
        );
    }
  })();

  return AI.createGroup("YAxisLeft", [yLines, yTextLines, yLabels, yLabel]);
}

export function generateYAxisRight(
  yPixels: number,
  yAxisRight: Axis.Axis | undefined,
  xMax: number,
  yMin: number,
  yMax: number,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): AI.Component {
  if (!yAxisRight) {
    return AI.createGroup("YAxisRight", []);
  }
  const yTicks = Axis.getTicks(yPixels, yAxisRight);
  const yLines = generateYAxisLines(
    xMax - 5,
    xMax + 5,
    yMin,
    yMax,
    yTicks.map((t) => t.value),
    yAxisRight,
    chart.gridColor,
    chart.gridThickness
  );
  const yLabels = generateYAxisLabels(
    xMax + 7,
    yMin,
    yMax,
    "right",
    yTicks.filter((t) => t.main).map((t) => t.value),
    yAxisRight,
    formatNumber,
    chart
  );
  const yLabel =
    chart.axisLabelPlacement === "end"
      ? AI.createText(
          AI.createPoint(xMax + 35, yMax - 16),
          yAxisRight.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          0,
          "center",
          "uniform",
          "up",
          0,
          chart.gridLabelColor
        )
      : AI.createText(
          AI.createPoint(xMax + chart.paddingRight, (yMin + yMax) * 0.5),
          yAxisRight.label,
          font,
          chart.fontSize,
          chart.gridLabelColor,
          "normal",
          90,
          "center",
          "uniform",
          "down",
          0,
          chart.gridLabelColor
        );

  return AI.createGroup("YAxisRight", [yLines, yLabels, yLabel]);
}

export function generateLines(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart
): ReadonlyArray<AI.Component> {
  return R.unnest<AI.Component>(
    chart.components.map((l) => {
      if (l.type !== "line") {
        return [];
      }
      const xAxis = l.xAxis === "top" ? chart.xAxisTop : chart.xAxisBottom;
      const yAxis = l.yAxis === "right" ? chart.yAxisRight : chart.yAxisLeft;
      const transformedPoints = l.points.map((p) => Axis.transformPoint(p, xMin, xMax, yMin, yMax, xAxis, yAxis));
      const subPoints = subdivideLine(transformedPoints, 10);
      const points = subPoints.filter((p) => p.x >= xMin && p.x <= xMax && p.y <= yMin && p.y >= yMax);
      if (points.length < 2) {
        return [];
      }
      const textPosition = getLineTextPosition(points, l.textPosition);
      const textBackground = l.textBackground ? l.color : undefined;
      const textColor = textBackground && textBackground !== AI.white ? AI.white : l.textColor || AI.black;
      return [
        AI.createPolyLine(points, l.color, l.thickness),
        ...generateText(
          xMin,
          xMax,
          yMin,
          yMax,
          l.text,
          textPosition,
          chart,
          l.textAnchorPosition,
          textBackground,
          textColor
        ),
      ];
    })
  );
}

function getLineTextPosition(points: ReadonlyArray<AI.Point>, textPosition: LineTextPosition): AI.Point {
  if (textPosition === "start") {
    return points[0];
  } else if (textPosition === "middle") {
    return points[Math.floor(points.length / 2)];
  } else {
    return points[points.length - 1];
  }
}

export function subdivideLine(points: ReadonlyArray<AI.Point>, maxDist: number): ReadonlyArray<AI.Point> {
  const newPoints = [];
  for (let i = 0; i < points.length - 1 && newPoints.length < 9999; ++i) {
    const p0 = points[i];
    const p1 = points[i + 1];
    const deltaX = p1.x - p0.x;
    const deltaY = p1.y - p0.y;
    const deltaLength = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const deltaXNormalized = deltaLength === 0 ? 0 : deltaX / deltaLength;
    const deltaYNormalized = deltaLength === 0 ? 0 : deltaY / deltaLength;
    const divisions = Math.ceil(deltaLength / maxDist);
    const step = divisions === 0 ? 1 : deltaLength / divisions;
    for (let s = 0; s < deltaLength; s += step) {
      const newX = p0.x + deltaXNormalized * s;
      const newY = p0.y + deltaYNormalized * s;
      newPoints.push(AI.createPoint(newX, newY));
    }
  }
  if (points.length > 0) {
    newPoints.push(points[points.length - 1]);
  }
  return newPoints;
}

export function generateTexts(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart
): ReadonlyArray<AI.Component> {
  return R.unnest<AI.Component>(
    chart.components.map((c) => {
      if (c.type !== "text") {
        return [];
      }
      return generateText(xMin, xMax, yMin, yMax, c.text, c.position, chart, undefined, AI.transparent, c.color);
    })
  );
}

function generateText(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  text: string,
  position: AI.Point,
  chart: Chart,
  lineTextAnchorPosition?: LineTextAnchorPosition,
  backgroundColor?: AI.Color,
  textColor?: AI.Color
): ReadonlyArray<AI.Component> {
  const xMid = 0.5 * (xMax + xMin);
  const yMid = 0.5 * (yMax + yMin);
  const horizontalGrowth =
    lineTextAnchorPosition === "bottomRight" || lineTextAnchorPosition === "topRight"
      ? "left"
      : lineTextAnchorPosition === "bottomLeft" || lineTextAnchorPosition === "topLeft"
      ? "right"
      : lineTextAnchorPosition === "center"
      ? "uniform"
      : position.x < xMid
      ? "right"
      : "left";
  const verticalGrowth =
    lineTextAnchorPosition === "bottomRight" || lineTextAnchorPosition === "bottomLeft"
      ? "up"
      : lineTextAnchorPosition === "topRight" || lineTextAnchorPosition === "topLeft"
      ? "down"
      : lineTextAnchorPosition === "center"
      ? "uniform"
      : position.y > yMid
      ? "up"
      : "down";
  if (text === "" || position.x < xMin || position.x > xMax || position.y > yMin || position.y < yMax) {
    return [];
  }

  const commaAdjustment = text.includes(",") ? 0 : 2;
  const adjustedXposition =
    horizontalGrowth === "left" ? position.x - 1 : horizontalGrowth === "right" ? position.x + 1 : position.x;
  const adjustedYposition =
    verticalGrowth === "up" ? position.y - 2 : verticalGrowth === "down" ? position.y - 2 : position.y;

  const backgroundTopLeftX =
    horizontalGrowth === "left"
      ? adjustedXposition - (chart.fontSize / 2) * text.length - 3
      : horizontalGrowth === "right"
      ? adjustedXposition - 1
      : position.x;
  const backgroundTopLeftY =
    verticalGrowth === "up"
      ? adjustedYposition - chart.fontSize + 2
      : verticalGrowth === "down"
      ? adjustedYposition + 2
      : position.y;
  const backgroundBottomRightX =
    horizontalGrowth === "left"
      ? adjustedXposition
      : horizontalGrowth === "right"
      ? adjustedXposition + (chart.fontSize / 2) * text.length - 1 + commaAdjustment
      : position.x;
  const backgroundBottomRightY =
    verticalGrowth === "up"
      ? adjustedYposition + 2
      : verticalGrowth === "down"
      ? adjustedYposition + chart.fontSize + 2
      : position.y;

  return [
    AI.createRectangle(
      AI.createPoint(backgroundTopLeftX, backgroundTopLeftY),
      AI.createPoint(backgroundBottomRightX, backgroundBottomRightY),
      backgroundColor || AI.transparent,
      2,
      backgroundColor || AI.transparent
    ),
    AI.createText(
      AI.createPoint(adjustedXposition, adjustedYposition),
      text,
      font,
      chart.fontSize,
      textColor || AI.black,
      "normal",
      0,
      "center",
      horizontalGrowth,
      verticalGrowth,
      0,
      textColor || AI.black
    ),
  ];
}

export function generatePoints(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): ReadonlyArray<AI.Component> {
  return R.unnest<AI.Component>(
    chart.components.map((p) => {
      if (p.type !== "point") {
        return [];
      }
      const xAxis = p.xAxis === "top" ? chart.xAxisTop : chart.xAxisBottom;
      const yAxis = p.yAxis === "right" ? chart.yAxisRight : chart.yAxisLeft;
      const position = Axis.transformPoint(p.position, xMin, xMax, yMin, yMax, xAxis, yAxis);
      if (position.x < xMin || position.x > xMax || position.y > yMin || position.y < yMax) {
        return [];
      }
      const components = [];
      if (p.axisLines === "lines" || p.axisLines === "linesAndText") {
        components.push(
          AI.createPolyLine([AI.createPoint(xMin, position.y), position, AI.createPoint(position.x, yMin)], p.color, 1)
        );
      }
      if (p.axisLines === "linesAndText") {
        components.push(
          ...generateText(
            xMin,
            xMax,
            yMin,
            yMax,
            formatNumberLinesAndText(p.position.x, chart.linesAndTextXAxisNoOfDecimals, formatNumber),
            AI.createPoint(position.x, yMin),
            chart,
            undefined,
            AI.transparent,
            p.labelColor
          )
        );
        components.push(
          ...generateText(
            xMin,
            xMax,
            yMin,
            yMax,
            formatNumberLinesAndText(p.position.y, chart.linesAndTextYAxisNoOfDecimals, formatNumber),
            AI.createPoint(xMin, position.y),
            chart,
            undefined,
            AI.transparent,
            p.labelColor
          )
        );
      }
      components.push(generatePointShape(p, position));
      components.push(
        ...generateText(xMin, xMax, yMin, yMax, p.text, position, chart, undefined, AI.transparent, p.labelColor)
      );
      return components;
    })
  );
}

function generatePointShape(p: ChartPoint, position: AI.Point): AI.Component {
  const halfWidth = p.size.width * 0.5;
  const halfHeight = p.size.height * 0.5;
  if (p.shape === "triangle") {
    const trianglePoints = [
      AI.createPoint(position.x, position.y + halfHeight),
      AI.createPoint(position.x - halfWidth, position.y - halfHeight),
      AI.createPoint(position.x + halfWidth, position.y - halfHeight),
    ];
    return AI.createPolygon(trianglePoints, AI.black, 0, p.color);
  } else if (p.shape === "square") {
    const topLeft = AI.createPoint(position.x - halfWidth, position.y - halfHeight);
    const bottomRight = AI.createPoint(position.x + halfWidth, position.y + halfHeight);
    return AI.createRectangle(topLeft, bottomRight, AI.black, 0, p.color);
  } else {
    const topLeft = AI.createPoint(position.x - halfWidth, position.y - halfHeight);
    const bottomRight = AI.createPoint(position.x + halfWidth, position.y + halfHeight);
    return AI.createEllipse(topLeft, bottomRight, p.stroke, p.strokeThickness, p.color);
  }
}

export function generateAreas(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  chart: Chart
): ReadonlyArray<AI.Component> {
  return R.unnest<AI.Component>(
    chart.components.map((a) => {
      if (a.type !== "area") {
        return [];
      }
      const xAxis = a.xAxis === "top" ? chart.xAxisTop : chart.xAxisBottom;
      const yAxis = a.yAxis === "right" ? chart.yAxisRight : chart.yAxisLeft;
      const transformedPoints = a.points.map((p) => Axis.transformPoint(p, xMin, xMax, yMin, yMax, xAxis, yAxis));
      const subPoints = subdivideLine(transformedPoints, 10);
      const points = subPoints.filter((p) => p.x >= xMin && p.x <= xMax && p.y <= yMin && p.y >= yMax);
      if (points.length < 3) {
        return [];
      }
      const areaXMin = Math.min(...points.map((p) => p.x));
      const areaXMax = Math.max(...points.map((p) => p.x));
      const areaYMin = Math.min(...points.map((p) => p.y));
      const areaYMax = Math.max(...points.map((p) => p.y));
      const textPosition = AI.createPoint(0.5 * (areaXMin + areaXMax), 0.5 * (areaYMin + areaYMax));
      return [
        AI.createPolygon(points, a.color, a.thickness, a.fill),
        ...generateText(xMin, xMax, yMin, yMax, a.text, textPosition, chart),
      ];
    })
  );
}

export function generateLabel(
  _: number,
  xMax: number,
  __: number,
  yMax: number,
  chart: Chart
): ReadonlyArray<AI.Component> {
  if (!chart.label) {
    return [];
  }
  const position = AI.createPoint(xMax - 15, yMax + 10);
  return [
    AI.createText(
      position,
      chart.label,
      font,
      25,
      AI.black,
      "normal",
      0,
      "right",
      "left",
      "down",
      10,
      chart.backgroundColor
    ),
  ];
}

export function generateXAxisGridLines(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  xTicks: ReadonlyArray<number>,
  xAxis: Axis.Axis,
  color: AI.Color,
  thickness: number
): AI.Component {
  const xLines = xTicks.map((l) => {
    const x = Axis.transformValue(l, xMin, xMax, xAxis);
    const start = AI.createPoint(x, yMin);
    const end = AI.createPoint(x, yMax);
    return AI.createLine(start, end, color, thickness);
  });

  return AI.createGroup("Lines", xLines);
}

export function generateXAxisLabels(
  xMin: number,
  xMax: number,
  y: number,
  growVertical: AI.GrowthDirection,
  xTicks: ReadonlyArray<number>,
  xAxis: Axis.Axis,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): AI.Component {
  const xLabels = xTicks.map((l) => {
    const position = AI.createPoint(Axis.transformValue(l, xMin, xMax, xAxis), y);
    return AI.createText(
      position,
      _formatNumber(l, formatNumber),
      font,
      chart.fontSize,
      chart.gridLabelColor,
      "normal",
      0,
      "center",
      "uniform",
      growVertical,
      0,
      chart.gridLabelColor
    );
  });
  return AI.createGroup("Labels", xLabels);
}

export function generateYAxisLines(
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number,
  yTicks: ReadonlyArray<number>,
  yAxis: Axis.Axis,
  color: AI.Color,
  thickness: number
): AI.Component {
  const yLines = yTicks.map((l) => {
    const y = Axis.transformValue(l, yMin, yMax, yAxis);
    const start = AI.createPoint(xMin, y);
    const end = AI.createPoint(xMax, y);
    return AI.createLine(start, end, color, thickness);
  });
  return AI.createGroup("Lines", yLines);
}

export function generateYAxisLabels(
  x: number,
  yMin: number,
  yMax: number,
  growHorizontal: AI.GrowthDirection,
  yTicks: ReadonlyArray<number>,
  yAxis: Axis.Axis,
  formatNumber: ((number: string) => string) | undefined,
  chart: Chart
): AI.Component {
  const yLabels = yTicks.map((l) => {
    const position = AI.createPoint(x, Axis.transformValue(l, yMin, yMax, yAxis));
    return AI.createText(
      position,
      _formatNumber(l, formatNumber),
      font,
      chart.fontSize,
      chart.gridLabelColor,
      "normal",
      0,
      "center",
      growHorizontal,
      "uniform",
      0,
      chart.gridLabelColor
    );
  });
  return AI.createGroup("Labels", yLabels);
}

function _formatNumber(n: number, formatNumber: ((number: string) => string) | undefined): string {
  const formatter = formatNumber ? formatNumber : (value: string) => value;
  if (n >= 10000000) {
    return `${formatter(numberToString(n / 1000000))}m`;
  }
  if (n >= 10000) {
    return `${formatter(numberToString(n / 1000))}k`;
  }
  return formatter(numberToString(n));
}

function numberToString(n: number): string {
  return parseFloat(n.toPrecision(5)).toString();
}

function formatNumberLinesAndText(
  n: number,
  d: number,
  formatNumber: ((number: string) => string) | undefined
): string {
  const value = n.toFixed(d).toString();
  return formatNumber ? formatNumber(value) : value;
}
