import { withTw } from "./with-tw";

export const ResultViewTable = withTw("table", ({ equalWidths }: OctaveBandsTableProps) =>
  equalWidths ? "table-fixed" : ""
);

export interface ResultViewCellProps {
  readonly textAlign?: "left" | "center" | "right";
}

export const ResultViewTableTh = withTw(
  "th",
  "p-4",
  ({ textAlign }: ResultViewCellProps) => `text-${textAlign || "left"}`
);

export const ResultViewTableTd = withTw(
  "td",
  "p-4",
  ({ textAlign }: ResultViewCellProps) => `text-${textAlign || "left"}`
);

export interface OctaveBandsTableProps {
  readonly equalWidths?: boolean;
}

export const OctaveBandsTable = withTw("table", ({ equalWidths }: OctaveBandsTableProps) =>
  equalWidths ? "table-fixed" : ""
);

export const OctaveBandsTableTh = withTw(
  "th",
  "p-4",
  ({ textAlign }: ResultViewCellProps) => `text-${textAlign || "left"}`
);

export const OctaveBandsTableTd = withTw(
  "td",
  "p-4",
  ({ textAlign }: ResultViewCellProps) => `text-${textAlign || "left"}`
);
