import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as Types from "../types";
import * as Messages from "../messages";
import { WaterCoil } from "../result-items-types";
import * as Friterm from "../dll/friterm";
import * as PressureDrop from "../shared/pressure-drop";
import { Input } from "./types";
import * as Area from "../shared/area";
import * as Attributes from "../shared/attributes";
import { validateMaxAirFlowAndPressureAttr } from "../shared/validate-max-airflow-and-pressure";

const source = "FritermWaterCoilCalculator";

const probeFaceVelocities = [1, 3];

export async function calculate(input: Input): Promise<Types.CalculatorResult<WaterCoil>> {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    inletWaterTemperature,
    calculationMethod,
    outletWaterTemperature,
    waterFlow,
    outletAirTemperature,
    codes,
    waterCoilLimits,
    attributes,
    calcParams,
  } = input;

  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const inletWaterTemperatureC = Amount.valueAs(customUnits.Celsius, inletWaterTemperature);
  const outletWaterTemperatureC = outletWaterTemperature
    ? Amount.valueAs(customUnits.Celsius, outletWaterTemperature)
    : null;
  const waterFlowLps = waterFlow ? Amount.valueAs(customUnits.LiterPerSecond, waterFlow) : null;
  const outletAirTemperatureC = outletAirTemperature ? Amount.valueAs(customUnits.Celsius, outletAirTemperature) : null;

  try {
    const fritermInput = {
      airFlow: airFlowLps, // l/s
      airTemperatureIn: inletAirTemperatureC, // C
      waterTempIn: inletWaterTemperatureC, // C
      waterTempOut: calculationMethod === 0 ? outletWaterTemperatureC : null, // C
      airHumidity: inletAirHumidityP, // %
      method: calculationMethod, // 0 = Outlet water tempererature supplied, 1 = water flow supplied, 2 output air temperature supplied
      geometry: Attributes.getStringOrThrow("DLL-input-friterm-geometry", attributes),
      waterFlow: calculationMethod === 1 ? waterFlowLps : null, // l/s
      airTemperatureOut: calculationMethod === 2 ? outletAirTemperatureC : null, // if method = 2 // C
      numberOfTubes: Attributes.getIntOrThrow("DLL-input-friterm-tubes", attributes),
      tubeMaterial: Attributes.getStringOrThrow("DLL-input-friterm-tube-material", attributes),
      finsMaterial: Attributes.getStringOrThrow("DLL-input-friterm-finns-material", attributes),
      tubeThickness: Attributes.getFloatOrThrow("DLL-input-friterm-tube-thickness", attributes),
      finThickness: Attributes.getFloatOrThrow("DLL-input-friterm-fin-thickness", attributes),
      finSpacing: Attributes.getFloatOrThrow("DLL-input-friterm-fin-spacing", attributes),
      length: Attributes.getFloatOrThrow("DLL-input-friterm-length", attributes),
      rows: Attributes.getIntOrThrow("DLL-input-friterm-rows", attributes),
      circuits: Attributes.getIntOrThrow("DLL-input-friterm-circuits", attributes),
      coilType: getCoilType(inletAirTemperatureC, inletWaterTemperatureC),
      // connectionMaterial: Attributes.getStringOrThrow("DLL-input-friterm-connection-material", attributes),
      // connectionIn: Attributes.getFloatOrThrow("DLL-input-friterm-connection-in", attributes),
      // connectionOut: Attributes.getFloatOrThrow("DLL-input-friterm-connection-out", attributes),
      correctionFactorWaterPressure: Attributes.getFloatOrDefault(
        "DLL-input-friterm-factor-water-pressure-drop",
        attributes,
        1
      ),
      correctionFactorPower: Attributes.getFloatOrDefault("DLL-input-friterm-factor-capacity", attributes, 1),
      manifoldMaterial: Attributes.getStringOrThrow("DLL-input-friterm-manifold-material", attributes),
      manifoldInletDiameter: Attributes.getFloatOrThrow("DLL-input-friterm-manifold-inlet-diameter", attributes),
      manifoldOutletDiameter: Attributes.getFloatOrThrow("DLL-input-friterm-manifold-outlet-diameter", attributes),
      ErrorThrowException: 422,
    };
    const result = await Friterm.calculate(fritermInput);
    if (result.message !== undefined) {
      return Types.createCalculatorError([Messages.Exception(source, result.message)]);
    }

    const areaM2 = Area.getArea(attributes);
    if (areaM2 === undefined) {
      return Types.createCalculatorError([Messages.Exception(source, "Could not get face area data")]);
    }

    let pressureDropCurve = undefined;
    if (input.isAccessory) {
      const altCalls = probeFaceVelocities.map((vMPS) =>
        Friterm.calculate({ ...fritermInput, airFlow: areaM2 * vMPS * 1000 })
      );
      const altResults = await Promise.all(altCalls);

      pressureDropCurve = PressureDrop.createPowerPressureCurve(
        0,
        5000,
        altResults.map((r) => Interpolation.vec2Create(r.airFlow, r.airPressureDrop))
      );
    }

    const refOutTemp = inletWaterTemperatureC < inletAirTemperatureC ? 18 : 25;
    const targetOutTemp = (calculationMethod === 2 ? outletAirTemperatureC : refOutTemp) || 20;

    const messages: Array<Messages.Message> = [];

    if (result.airVelocity > Amount.valueAs(customUnits.MeterPerSecond, waterCoilLimits.max_air_velocity)) {
      messages.push(Messages.Error_AirVelocityTooHigh(source, waterCoilLimits.max_air_velocity));
    }

    if (result.airPressureDrop > Amount.valueAs(customUnits.Pascal, waterCoilLimits.max_air_pressure_drop)) {
      messages.push(Messages.Error_AirPressureDropTooHigh(source, waterCoilLimits.max_air_pressure_drop));
    }

    if (result.waterPressureDrop > Amount.valueAs(customUnits.KiloPascal, waterCoilLimits.max_water_pressure_drop)) {
      messages.push(Messages.Error_WaterPressureDropTooHigh(source, waterCoilLimits.max_water_pressure_drop));
    }

    messages.push(...validateMaxAirFlowAndPressureAttr(source, attributes, airFlow, result.airPressureDrop));

    return Types.createCalculatorSuccess(
      [
        { value: Math.abs(targetOutTemp - result.airTemperatureOut), descending: false },
        { value: codes.code, descending: false },
      ],
      {
        airFlow: airFlow,
        recomendedKv: Amount.create(result.kvValue, customUnits.One, 2),
        airPressureDrop: Amount.create(result.airPressureDrop, customUnits.Pascal, 1),
        outletAirTemperature: Amount.create(result.airTemperatureOut, customUnits.Celsius, 1),
        outletAirHumidity: Amount.create(result.airHumidityOut, customUnits.PercentHumidity, 1),
        waterFlow: Amount.create(result.waterFlow, customUnits.LiterPerSecond, 4),
        inletWaterTemperature: Amount.create(result.waterTemperatureIn, customUnits.Celsius, 1),
        outletWaterTemperature: Amount.create(result.waterTemperatureOut, customUnits.Celsius, 1),
        waterVelocity: Amount.create(result.waterVelocity, customUnits.MeterPerSecond, 2),
        airVelocity: Amount.create(result.airVelocity, customUnits.MeterPerSecond, 2),
        waterPressureDrop: Amount.create(result.waterPressureDrop, customUnits.KiloPascal, 2),
        power: Amount.create(result.power, customUnits.KiloWatt, 2),
        inletAirTemperature: inletAirTemperature,
        inletAirHumidity: inletAirHumidity,
        connectionSizeIn: Attributes.getStringOrThrow("DLL-input-friterm-connection-in", attributes),
        connectionSizeOut: Attributes.getStringOrThrow("DLL-input-friterm-connection-out", attributes),
        coilCode: undefined,
        pressureDropCurve: pressureDropCurve,
      },
      messages,
      calcParams
    );
  } catch (e) {
    return Types.createCalculatorError([Messages.Exception(source, e.toString())]);
  }
}

function getCoilType(inletAirTemperatureC: number, inletWaterTemperatureC: number): number {
  return inletWaterTemperatureC < inletAirTemperatureC ? 2 : 1;
}
