import { createSelector, Selector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import { RootState } from "../../types";
import { CalcParamsSelectorContainerComponent, StateProps, Response, OwnProps } from "./container-component";

function mapPropsToQuery(props: OwnProps, __: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    productTables: QP.tablesByProductId(props.productId, ["ct_DiaqTemplates", "ct_ClosedGroups"]),
    ct_ResultItems: QP.tableByProductId(QP.metaProductId, "ct_ResultItems"),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    ct_AttributeTemplateMapping: QP.tableByProductId(QP.metaProductId, "ct_AttributeTemplateMapping"),
    ct_LanguageMapping: QP.tableByProductId(QP.metaProductId, "ct_LanguageMapping"),
  });
}

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (showAdvanced, userSettings) => ({
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

export const CalcParamsSelectorContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(CalcParamsSelectorContainerComponent);
