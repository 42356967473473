/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ReduxQuery from "shared-lib/redux-query/core";
import * as QP from "shared-lib/query-product";
import * as QU from "shared-lib/query-url";
import * as QI from "shared-lib/query-identity";
import * as QECOM from "shared-lib/query-ecom";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import { DiaqCacheState, DiaqCachePromiseMap, DiaqMapQuery, DiaqAtomicQuery, DiaqAtomicResponse } from "./query-types";

// Re-export this function so the caller don't need to import redux-query
export { allowUndefined } from "shared-lib/redux-query/react";

const initialDiaqCacheState: DiaqCacheState = {
  product: undefined,
  url: undefined,
  ecom: undefined,
};

/**
 * Sends the selectQuery call to the correct source.
 */
export function selectAtomicQuery(
  cache: DiaqCacheState = initialDiaqCacheState,
  query: DiaqAtomicQuery
): ReduxQuery.AnyResponse | undefined {
  switch (query.source) {
    case "product":
      return QP.selectQuery(cache.product, query);
    case "url":
      return QU.selectQuery(cache.url, query);
    case "identity":
      return QI.selectQuery(undefined, query);
    case "ecom":
      return QECOM.selectQuery(cache.ecom, query);
    default:
      return exhaustiveCheck(query, true);
  }
}

export function createLoadQueries(
  pimAddress: string,
  markerName: string,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): ReduxQuery.LoadAtomicQueries<DiaqCacheState, DiaqAtomicQuery> {
  return (cache: DiaqCacheState | undefined, queries: ReadonlyArray<DiaqAtomicQuery>) =>
    loadAtomicQueries(pimAddress, markerName, useMeasureToolForMeasurements, mtUrl, cache, queries);
}

function loadAtomicQueries(
  pimAddress: string,
  markerName: string,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined,
  cache: DiaqCacheState = initialDiaqCacheState,
  queries: ReadonlyArray<DiaqAtomicQuery>
): Promise<DiaqCacheState> {
  const bySource = {
    product: queries.filter((q) => q.source === "product") as ReadonlyArray<QP.ProductQuery>,
    url: queries.filter((q) => q.source === "url") as ReadonlyArray<QU.UrlQuery>,
    ecom: queries.filter((q) => q.source === "ecom") as ReadonlyArray<QECOM.EcomQuery>,
  };

  const promisesMap: DiaqCachePromiseMap = {
    product: QP.loadQueries(
      pimAddress,
      markerName,
      cache.product,
      bySource.product,
      useMeasureToolForMeasurements,
      mtUrl
    ),
    url: QU.loadQueries(cache.url, bySource.url),
    ecom: QECOM.loadQueries(cache.ecom, bySource.ecom),
  };

  return loadCacheFromPromiseMap(cache, promisesMap);
}

function loadCacheFromPromiseMap(
  cache: DiaqCacheState = initialDiaqCacheState,
  promisesMap: DiaqCachePromiseMap
): Promise<DiaqCacheState> {
  type Promises = [Promise<QP.ProductCacheState>, Promise<QU.UrlCacheState>, Promise<QECOM.EcomCacheState>];
  type ResolvedPromises = [QP.ProductCacheState, QU.UrlCacheState, QECOM.EcomCacheState];
  const promises: Promises = [promisesMap.product, promisesMap.url, promisesMap.ecom];
  const cachePromise: Promise<DiaqCacheState> = Promise.all<any>(promises).then(
    (values: ResolvedPromises): DiaqCacheState => ({
      product: values[0] || cache.product,
      url: values[1] || cache.url,
      ecom: values[2] || cache.ecom,
    })
  );

  return cachePromise;
}

export function createMapQuery<TComposedResponse>(
  map: ReduxQuery.MapOfQueriesWithSameKeysAsResponse<DiaqAtomicQuery, DiaqAtomicResponse, TComposedResponse>
): DiaqMapQuery<TComposedResponse> {
  return ReduxQuery.createMapQuery<DiaqAtomicQuery, DiaqAtomicResponse, TComposedResponse>(map);
}
