import * as R from "ramda";
import { Serialize, Unit } from "uom";
import { State, FieldUnitSettings } from "./types";

export function fromItemConfigMeta(meta: State | undefined): State {
  return meta || {};
}

export function setFieldUnit<T>(state: State, fieldName: string, unit: Unit.Unit<T>): State {
  return setValue("fieldUnits", fieldName, Serialize.unitToString(unit), state);
}

function setValue(group: string, key: string, value: string, state: State): State {
  const oldGroup = state[group] || {};
  const newGroup = R.assoc(key, value, oldGroup);
  return R.assoc(group, newGroup, state);
}

export function getFieldUnitsFromUriComponent(param: string): FieldUnitSettings | undefined {
  const json = decodeURIComponent(param);
  try {
    const fieldUnits = JSON.parse(json);
    return fieldUnits;
  } catch {
    return undefined;
  }
}
