import * as React from "react";
import * as SC from "shared-lib/system-calculator";
import * as Texts from "shared-lib/language-texts";
import { Alert } from "client-lib/elements";
import { GetDecimalsFunction, GetUnitFunction } from "shared-lib/user-settings";

export interface CalculationMessagesProps {
  readonly componentMessages: ReadonlyArray<SC.Message>;
  readonly accessoryMessages: ReadonlyArray<AccessoryMessages>;
  readonly translate: Texts.TranslateFunction;
  readonly getUnit: GetUnitFunction;
  readonly getDecimals: GetDecimalsFunction;
  readonly horizontalAlign?: boolean;
}

export interface AccessoryMessages {
  readonly code: string;
  readonly messages: ReadonlyArray<SC.Message>;
}

export function CalculationMessages(props: CalculationMessagesProps): React.ReactElement<CalculationMessagesProps> {
  const { componentMessages, accessoryMessages, horizontalAlign, translate, getDecimals, getUnit } = props;

  const allMessages = [
    ...componentMessages.map((m) => ({ code: undefined, message: m })),
    ...accessoryMessages.flatMap((m) => m.messages.map((message) => ({ code: m.code, message }))),
  ]
    .map((m) => SC.renderMessage(m.code, m.message, translate, getUnit, getDecimals))
    .sort((a, b) => (a.type < b.type ? -1 : 1));

  return (
    <div>
      {allMessages.map((m, index) => (
        <Alert type={m.type} horizontalAlign={horizontalAlign} key={index}>
          {m.text}
        </Alert>
      ))}
    </div>
  );
}
