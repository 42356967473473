import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as Types from "../types";
import * as Messages from "../messages";
import { DxCoil } from "../result-items-types";
import * as Friterm from "../dll/friterm";
import * as PressureDrop from "../shared/pressure-drop";
import { Input } from "./types";
import * as Area from "../shared/area";
import * as Attributes from "../shared/attributes";
import { validateMaxAirFlowAndPressureAttr } from "../shared/validate-max-airflow-and-pressure";

const source = "FritermDxCoilCalculator";

const probeFaceVelocities = [1, 3];

export async function calculate(input: Input): Promise<Types.CalculatorResult<DxCoil>> {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    calculationMethod,
    outletAirTemperature,
    condensingTemperature,
    evaporatingTemperature,
    codes,
    waterCoilLimits,
    attributes,
    calcParams: newCalcParams,
  } = input;

  if (!condensingTemperature || !evaporatingTemperature) {
    return Types.createCalculatorError([Messages.Exception(source, "Missing DX temps")]);
  }

  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const outletAirTemperatureC = outletAirTemperature ? Amount.valueAs(customUnits.Celsius, outletAirTemperature) : null;
  const condensingTemperatureC = Amount.valueAs(customUnits.Celsius, condensingTemperature);
  const evaporatingTemperatureC = Amount.valueAs(customUnits.Celsius, evaporatingTemperature);

  try {
    const fritermInput: Omit<Friterm.FritermInput, "dllPassword"> = {
      airFlow: airFlowLps, // l/s
      airTemperatureIn: inletAirTemperatureC, // C
      airHumidity: inletAirHumidityP, // %
      method: calculationMethod, // 0 = Outlet water tempererature supplied, 1 = water flow supplied, 2 output air temperature supplied
      geometry: Attributes.getStringOrThrow("DLL-input-friterm-geometry", attributes),
      airTemperatureOut: calculationMethod === 2 ? outletAirTemperatureC : null, // if method = 2 // C
      numberOfTubes: Attributes.getIntOrThrow("DLL-input-friterm-tubes", attributes),
      tubeMaterial: Attributes.getStringOrThrow("DLL-input-friterm-tube-material", attributes),
      finsMaterial: Attributes.getStringOrThrow("DLL-input-friterm-finns-material", attributes),
      tubeThickness: Attributes.getFloatOrThrow("DLL-input-friterm-tube-thickness", attributes),
      finThickness: Attributes.getFloatOrThrow("DLL-input-friterm-fin-thickness", attributes),
      finSpacing: Attributes.getFloatOrThrow("DLL-input-friterm-fin-spacing", attributes),
      length: Attributes.getFloatOrThrow("DLL-input-friterm-length", attributes),
      rows: Attributes.getIntOrThrow("DLL-input-friterm-rows", attributes),
      circuits: Attributes.getIntOrThrow("DLL-input-friterm-circuits", attributes),
      coilType: 3, // 3 = evaporator
      // connectionMaterial: Attributes.getStringOrThrow("DLL-input-friterm-connection-material", attributes),
      // connectionIn: Attributes.getFloatOrThrow("DLL-input-friterm-connection-in", attributes),
      // connectionOut: Attributes.getFloatOrThrow("DLL-input-friterm-connection-out", attributes),
      correctionFactorWaterPressure: Attributes.getFloatOrDefault(
        "DLL-input-friterm-factor-water-pressure-drop",
        attributes,
        1
      ),
      correctionFactorPower: Attributes.getFloatOrDefault("DLL-input-friterm-factor-capacity", attributes, 1),
      manifoldMaterial: Attributes.getStringOrThrow("DLL-input-friterm-manifold-material", attributes),
      manifoldInletDiameter: Attributes.getFloatOrThrow("DLL-input-friterm-manifold-inlet-diameter", attributes),
      manifoldOutletDiameter: Attributes.getFloatOrThrow("DLL-input-friterm-manifold-outlet-diameter", attributes),
      fluidType: 34, // R410A
      fluidEvaporationTemperature: evaporatingTemperatureC,
      fldTemperatureCondensation: condensingTemperatureC,
      fluidSubCoolingTemperature: 2, // TODO
      fluidSuperHeatingTemperature: 5, // TODO
      ErrorThrowException: 422,
    };
    const result = await Friterm.calculate(fritermInput);
    if (result.message !== undefined) {
      return Types.createCalculatorError([Messages.Exception(source, result.message)]);
    }

    const areaM2 = Area.getArea(attributes);
    if (areaM2 === undefined) {
      return Types.createCalculatorError([Messages.Exception(source, "Could not get face area data")]);
    }

    let pressureDropCurve = undefined;
    if (input.isAccessory) {
      const altCalls = probeFaceVelocities.map((vMPS) =>
        Friterm.calculate({ ...fritermInput, airFlow: areaM2 * vMPS * 1000 })
      );
      const altResults = await Promise.all(altCalls);

      pressureDropCurve = PressureDrop.createPowerPressureCurve(
        0,
        5000,
        altResults.map((r) => Interpolation.vec2Create(r.airFlow, r.airPressureDropDry))
      );
    }

    const messages: Array<Messages.Message> = [];

    if (result.airVelocity > Amount.valueAs(customUnits.MeterPerSecond, waterCoilLimits.max_air_velocity)) {
      messages.push(Messages.Error_AirVelocityTooHigh(source, waterCoilLimits.max_air_velocity));
    }

    if (result.airPressureDropDry > Amount.valueAs(customUnits.Pascal, waterCoilLimits.max_air_pressure_drop)) {
      messages.push(Messages.Error_AirPressureDropTooHigh(source, waterCoilLimits.max_air_pressure_drop));
    }

    if (result.waterPressureDrop > Amount.valueAs(customUnits.KiloPascal, waterCoilLimits.max_water_pressure_drop)) {
      messages.push(Messages.Error_WaterPressureDropTooHigh(source, waterCoilLimits.max_water_pressure_drop));
    }

    messages.push(...validateMaxAirFlowAndPressureAttr(source, attributes, airFlow, result.airPressureDropDry));

    return Types.createCalculatorSuccess(
      [{ value: codes.code, descending: false }],
      {
        airVelocity: Amount.create(result.airVelocity, customUnits.MeterPerSecond, 2),
        airFlow: airFlow,
        airPressureDrop: Amount.create(result.airPressureDropDry, customUnits.Pascal, 1),
        outletAirTemperature: Amount.create(result.airTemperatureOut, customUnits.Celsius, 1),
        outletAirHumidity: Amount.create(result.airHumidityOut, customUnits.PercentHumidity, 1),
        evaporatingTemperature: evaporatingTemperature,
        condensingTemperature: condensingTemperature,
        power: Amount.create(result.power, customUnits.KiloWatt, 2),
        inletAirTemperature: inletAirTemperature,
        inletAirHumidity: inletAirHumidity,
        connectionSizeIn: Attributes.getStringOrThrow("DLL-input-friterm-connection-in", attributes),
        connectionSizeOut: Attributes.getStringOrThrow("DLL-input-friterm-connection-out", attributes),
        coilCode: undefined,
        pressureDropCurve: pressureDropCurve,
        refrigerantType: undefined,
        refrigerantPressureDrop: Amount.create(result.waterPressureDrop, customUnits.Pascal, 1),
        refrigerantMassFlow: Amount.create(result.waterMassFlow, customUnits.KilogramPerHour, 1),
      },
      messages,
      newCalcParams
    );
  } catch (e) {
    return Types.createCalculatorError([Messages.Exception(source, e.toString())]);
  }
}
