import { customUnits } from "shared-lib/uom";
import * as Api from "../../promaster-api";
import * as Types from "../table-types";

export const productSearchMetaProduct: Api.Mapping<Types.SearchMetaProduct> = {
  product: "string",
};

export const productSearchProduct: Api.Mapping<Types.SearchProduct> = {
  product: "string",
  property_filter: "PropertyFilter",
};

export const hiddenEcomCategories: Api.Mapping<Types.HiddenEcomCategory> = {
  category_id: "string",
};

export const productSearchColumn: Api.Mapping<Types.SearchColumn> = {
  property_filter: "PropertyFilter",
  group: "string",
  result_type: "string",
  result_path: "string",
  language_key: "string",
  field_name: "string",
};

export const itemNo: Api.Mapping<Types.ItemNo> = {
  property_filter: "PropertyFilter",
  item_no: "string",
};

export const m3ItemNo: Api.Mapping<Types.M3ItemNo> = {
  ordering_code: "string",
  m3_item_no: "string",
};

export const ecomCategory: Api.Mapping<Types.EcomCategory> = {
  sort_no: "number",
  id: "string",
  parent_id: "string",
};

export const ecomCategoryToProperty: Api.Mapping<Types.EcomCategoryToProperty> = {
  ecom_category_id: "string",
  variant: "string",
};

export const resultItems: Api.Mapping<Types.ResultItems> = {
  template: "string",
  name: "string",
  type: "string",
  calculator: "string",
  calculator_params: "string",
};

export const resultViews: Api.Mapping<Types.ResultViews> = {
  template: "string",
  view: "string",
  result_item: "string",
  visualizer: "string",
  visualizer_params: "string",
};

export const resultVisualizerParamsTable: Api.Mapping<Types.ResultVisualizerParamsTable> = {
  table_name: "string",
  label: "string",
  result_value_path: "string",
  field_name: "string",
  icon: "string",
};

export const productDiaqTemplates: Api.Mapping<Types.DiaqTemplate> = {
  property_filter: "PropertyFilter",
  type: "string",
  template: "string",
};

export const calcParamDefault: Api.Mapping<Types.CalcParamDefault> = {
  property_filter: "PropertyFilter",
  market: "string",
  calc_param: "string",
  value: "number",
  unit: "Unit",
};

export const maxPower: Api.Mapping<Types.MaxPower> = {
  property_filter: "PropertyFilter",
  max_power: { type: "Amount", unit: customUnits.Watt },
};

export const voltage: Api.Mapping<Types.Voltage> = {
  property_filter: "PropertyFilter",
  voltage: { type: "Amount", unit: customUnits.Volt },
  phases: { type: "Amount", unit: customUnits.One },
};

export const calculationInputs: Api.Mapping<Types.CalculationInputs> = {
  property_filter: "PropertyFilter",
  name: "string",
  value: "string",
};

export const airData: Api.Mapping<Types.AirData> = {
  meas: "string",
  part: "string",
  param: "string",
  step: "string",
  volt_ctrl: "number",
  volt_supp: "number",
  flow: "number",
  value: "number",
};

export const manueverVoltage: Api.Mapping<Types.ManueverVoltage> = {
  property_filter: "PropertyFilter",
  voltage: { type: "Amount", unit: customUnits.Volt },
};

export const airLimitsData: Api.Mapping<Types.AirLimitsData> = {
  measurement: "string",
  part: "string",
  step_name: "string",
  voltage_control: "number",
  voltage_supply: "number",
  min_flow: "number",
  max_flow: "number",
  nom_flow: "number",
};

export const scoreGuideData: Api.Mapping<Types.ScoreGuideData> = {
  property_filter: "PropertyFilter",
  value: "number",
};

export const scoreTypeData: Api.Mapping<Types.ScoreTypeData> = {
  property_filter: "PropertyFilter",
  mounting: "string",
};

export const airFlowStepData: Api.Mapping<Types.AirFlowStepData> = {
  property_filter: "PropertyFilter",
  air_flow: { type: "Amount", unit: customUnits.CubicMeterPerHour },
};

export const productScoreData: Api.Mapping<Types.ProductScoreData> = {
  product: "string",
  property_filter: "PropertyFilter",
  mounting: "string",
  min: "number",
  max: "number",
  high: "number",
  low: "number",
};

export const searchView: Api.Mapping<Types.SearchView> = {
  result_type: "string",
  visualizer: "string",
};

export const soundData: Api.Mapping<Types.SoundData> = {
  measurement: "string",
  step_name: "string",
  voltage_control: "number",
  sound_quantity: "string",
  Q_v: "number",
  Ps_v: "number",
  r_v: "number",
  snd_50: "number",
  snd_63: "number",
  snd_80: "number",
  snd_100: "number",
  snd_125: "number",
  snd_160: "number",
  snd_200: "number",
  snd_250: "number",
  snd_315: "number",
  snd_400: "number",
  snd_500: "number",
  snd_630: "number",
  snd_800: "number",
  snd_1000: "number",
  snd_1250: "number",
  snd_1600: "number",
  snd_2000: "number",
  snd_2500: "number",
  snd_3150: "number",
  snd_4000: "number",
  snd_5000: "number",
  snd_6300: "number",
  snd_8000: "number",
  snd_10000: "number",
};

export const ganymedRangeProducts: Api.Mapping<Types.GanymedRangeProducts> = {
  range_name: "string",
  product: "string",
};

export const accessories: Api.Mapping<Types.Accessory> = {
  property_filter: "PropertyFilter",
  type: "string",
  accessory_type: "string",
  product: "string",
  variant: "PropertyValueSet",
  max_amount: "number",
};

export const length: Api.Mapping<Types.LengthData> = {
  property_filter: "PropertyFilter",
  length: { type: "Amount", unit: customUnits.Millimeter },
};

export const verticalLimit: Api.Mapping<Types.VerticalLimit> = {
  double_width: { type: "Amount", unit: customUnits.Meter },
  max_width: { type: "Amount", unit: customUnits.Meter },
};

export const propertySelectorType: Api.Mapping<Types.PropertySelectorType> = {
  name: "string",
  type: "string",
  width: "number",
};

export const categoryLevel: Api.Mapping<Types.CategoryLevel> = {
  sort_no: "number",
  level_1: "string",
  level_2: "string",
  product: "string",
};

export const attribute: Api.Mapping<Types.Attribute> = {
  property_filter: "PropertyFilter",
  collection: "string",
  attribute: "string",
  value: "string",
};

export const searchAttribute: Api.Mapping<Types.SearchAttribute> = {
  property_filter: "PropertyFilter",
  collection: "string",
  attribute: "string",
  value: "string",
};

export const browserProduct: Api.Mapping<Types.BrowserProduct> = {
  sort_no: "number",
  product: "string",
  heating_calculation: "number",
  specification_text: "number",
  specification_text_only_item_no: "string",
};

export const searchSorting: Api.Mapping<Types.SearchSorting> = {
  result_type: "string",
  result_path: "string",
  direction: "string",
};

export const marketUnit: Api.Mapping<Types.MarketUnit> = {
  market: "string",
  field_name: "string",
  unit: "Unit",
  decimals: "number",
  type: "string",
  extra_text: "string",
};

export const marketHiddenField: Api.Mapping<Types.MarketHiddenField> = {
  market: "string",
  field_name: "string",
};

export const savedResultColumn: Api.Mapping<Types.SavedResultColumn> = {
  result_path: "string",
  language_key: "string",
  field_name: "string",
};

export const tempEfficiencyCorrection: Api.Mapping<Types.TempEfficiencyCorrection> = {
  property_filter: "PropertyFilter",
  air_flow: "number",
  correction: "number",
};

export const closedGroup: Api.Mapping<Types.ClosedGroup> = {
  group: "string",
};

export const fricoAccessory: Api.Mapping<Types.FricoAccessory> = {
  sort_no: "number",
  item_no: "string",
  variant_id: "string",
  name: "string",
  category: "number",
  control: "string",
  default_quantity: "number",
  type_control_item_numbers: "string",
  included_by_default: "number",
};

export const accessoryRelation: Api.Mapping<Types.AccessoryRelation> = {
  item_no: "string",
  accessory_item_no: "string",
};

export const curtainFilterDimension: Api.Mapping<Types.CurtainFilterDimension> = {
  property_filter: "PropertyFilter",
  min_height: { type: "Amount", unit: customUnits.Meter },
  max_height: { type: "Amount", unit: customUnits.Meter },
  min_width: { type: "Amount", unit: customUnits.Meter },
  max_width: { type: "Amount", unit: customUnits.Meter },
};

export const itemNumberStatus: Api.Mapping<Types.ItemNumberStatus> = {
  item_no: "string",
  status: "number",
};

export const waterCoilLimits: Api.Mapping<Types.WaterCoilLimits> = {
  property_filter: "PropertyFilter",
  max_air_velocity: { type: "Amount", unit: customUnits.MeterPerSecond },
  max_air_pressure_drop: { type: "Amount", unit: customUnits.Pascal },
  max_water_pressure_drop: { type: "Amount", unit: customUnits.KiloPascal },
};

export const marketSetting: Api.Mapping<Types.MarketSetting> = {
  market: "string",
  setting: "string",
  value: "string",
};

export const searchAccessory: Api.Mapping<Types.SearchAccessory> = {
  property_filter: "PropertyFilter",
  accessory_type: "string",
};

export const searchExcludeProperty: Api.Mapping<Types.SearchExcludeProperty> = {
  property: "string",
};

export const divisionCurrency: Api.Mapping<Types.DivisionCurrency> = {
  division_number: "string",
  currency: "string",
};

export const marketPriceList: Api.Mapping<Types.MarketPriceList> = {
  market: "string",
  display_price: "string",
};

export const propertyFieldName: Api.Mapping<Types.PropertyFieldName> = {
  property: "string",
  field_name: "string",
};

export const variantNo: Api.Mapping<Types.VariantNo> = {
  property_filter: "PropertyFilter",
  variant_no: "string",
};

export const boxFanAccessory: Api.Mapping<Types.BoxFanAccessory> = {
  id: "string",
  name: "string",
  family: "string",
  item_number: "string",
  accessory_type: "string",
  size: "string",
  motor_ids: "string",
  cost_price: "string",
  sales_price: "string",
};

export const boxFanCurve: Api.Mapping<Types.Curve> = {
  id: "string",
  fan_id: "string",
  order: "string",
  type: "string",
  name: "string",
};

export const boxFanCurvePoint: Api.Mapping<Types.CurvePoint> = {
  curve_id: "string",
  x: "string",
  y: "string",
};

export const boxFanFan: Api.Mapping<Types.Fan> = {
  id: "string",
  name: "string",
  family: "string",
  size: "string",
  item_number: "string",
  madam_family: "string",
  min_motor_power: "number",
  max_motor_power: "number",
};

export const boxFanMotor: Api.Mapping<Types.Motor> = {
  id: "string",
  power: "number",
  current: "number",
  two_speed_power: "number",
  max_power_demand: "number",
  efficiency: "number",
};

export const boxFanStandardFan: Api.Mapping<Types.StandardFan> = {
  property_filter: "PropertyFilter",
  id: "string",
  fan_id: "string",
  motor_id: "string",
  item_number: "string",
  rpm: { type: "Amount", unit: customUnits.RevolutionsPerMinute },
  curve_id: "string",
  cost_price: "string",
  sales_price: "string",
  is60Hz: "string",
};

export const boxFanFanCondition: Api.Mapping<Types.FanCondition> = {
  property_filter: "PropertyFilter",
  condition: "string",
};

export const languageMapping: Api.Mapping<Types.LanguageMapping> = {
  iso_code: "string",
  language: "string",
  m3_language: "string",
  decimal_separator: "string",
  thousand_separator: "string",
};

export const accessoryPressureDrop: Api.Mapping<Types.AccessoryPressureDrop> = {
  property_filter: "PropertyFilter",
  pressure_drop: { type: "Amount", unit: customUnits.Pascal },
  name: "string",
};

export const filterFunctions: Api.Mapping<Types.FilterFunctions> = {
  property_filter: "PropertyFilter",
  pre_filter: "string",
  post_filter: "string",
};

export const centrifugalFan: Api.Mapping<Types.CentrifugalFan> = {
  property_filter: "PropertyFilter",
  product_name: "string",
  outlet_area: "number",
  min_motor_power: "number",
  max_motor_power: "number",
  base_price: "number",
  transmission_loss_percentage: "number",
  class_2_rpm: "number",
  class_3_rpm: "number",
  feg: "number",
  base_weight: "number",
  impeller_diameter: "number",
};

export const dllLimit: Api.Mapping<Types.DllLimit> = {
  property_filter: "PropertyFilter",
  fan_series: "string",
  fan_sizes: "string",
};

export const centrifugalMotor: Api.Mapping<Types.CentrifugalMotor> = {
  id: "number",
  power: "number",
  current: "number",
  starting_current: "number",
  speed_rpm: "number",
  voltage: "number",
  frequency: "number",
  base_weight: "number",
  efficiency: "number",
  performance_factor_cos_phi: "number",
  efficiency_class: "string",
  pole: "number",
};

export const attributeTemplateMapping: Api.Mapping<Types.AttributeTemplateMapping> = {
  selector_attribute: "string",
  selector_attribute_value: "string",
  dll_attribute_prefix: "string",
  result_items: "string",
  result_views: "string",
};

export const dllDbmCoilsFluidType: Api.Mapping<Types.DllDbmCoilsFluidType> = {
  value: "number",
  fluid_type: "string",
};

export const amcaStatements: Api.Mapping<Types.AmcaStatements> = {
  property_filter: "PropertyFilter",
  sequence_number: "number",
  statement: "string",
  language: "string",
};
