import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { diaqConnect } from "client-lib/redux-integration";
import { createSelector } from "reselect";
import { MultiColumnTableVisualizerContainerComponent, Response } from "./container-component";
import { VisualizerOwnProps } from "../types";
import { RootState } from "../../../types";

const mapStateToProps = createSelector(
  (p: RootState) => p.settings.user,
  (userSettings) => ({
    userSettings: userSettings,
  })
);

function mapPropsToQuery(_: VisualizerOwnProps): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    metaTables: QP.tablesByProductId(QP.metaProductId, [
      "ct_ResultVisualizerParamsTable",
      "ct_MarketUnits",
      "ct_MarketHiddenFields",
    ]),
  });
}

export const MultiColumnTableVisualizerContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(MultiColumnTableVisualizerContainerComponent);
