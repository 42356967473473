import * as React from "react";
import { withTw } from "client-lib/elements";
import { SelectorType } from "shared-lib/query-product";

interface PropertyProps {
  readonly selectorType?: SelectorType;
  readonly isHidden?: boolean;
  readonly children?: React.ReactNode;
}

export const Property = withTw(
  "div",
  (p: PropertyProps) => (p.isHidden ? "hidden" : "flex"),
  "pt-8 pb-8",
  (p: PropertyProps) => {
    if (p.selectorType === "MultiHorizontal") {
      return "w-full";
    } else if (p.selectorType === "RadioGroup" || p.selectorType === "Multi") {
      return "flex-col justify-start items-stretch property-selector-property";
    } else if (p.selectorType === "Checkbox") {
      return "flex-row justify-between items-center property-selector-property";
    } else {
      return "flex-row justify-between items-center property-selector-property";
    }
  }
);
