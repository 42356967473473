import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { AnyQuantity } from "shared-lib/uom";
import * as QD from "shared-lib/query-diaq";
import { Message } from "./messages";
import { ResultItem } from "./result-items-types";
import * as Attributes from "./shared/attributes";

export interface System {
  readonly components: ReadonlyArray<Component>;
  readonly relations: ReadonlyArray<Relation>;
}

export function createSystem(components: ReadonlyArray<Component>, relations: ReadonlyArray<Relation>): System {
  return {
    components,
    relations,
  };
}

export interface Component {
  readonly id: string;
  readonly productId: string;
  readonly variantId: string | undefined;
  readonly propertyValues: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly resultItems: ReadonlyArray<ResultItemDefinition>;
  readonly attributes: Attributes.Attributes;
}

export function createComponent(
  id: string,
  productId: string,
  propertyValues: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet,
  resultItems: ReadonlyArray<ResultItemDefinition>,
  attributes: Attributes.Attributes,
  variantId: string | undefined
): Component {
  return {
    id,
    productId,
    propertyValues,
    calcParams,
    resultItems,
    attributes,
    variantId,
  };
}

export interface Relation {
  readonly relationType: string;
  readonly fromComponentId: string;
  readonly toComponentId: string;
}

export interface ResultItemDefinition {
  readonly name: string;
  readonly type: ResultItemType;
  readonly calculator: ResultItemCalculator;
  readonly calculatorParams: string;
}

export function createResultItem(
  name: string,
  type: ResultItemType,
  calculator: ResultItemCalculator,
  calculatorParams: string
): ResultItemDefinition {
  return {
    name,
    type,
    calculator,
    calculatorParams,
  };
}

export type ResultItemType = string;
export type ResultItemCalculator = string;

export interface ResultItemOutputMap {
  readonly [name: string]: OutputMapperResult;
}

export interface ResultItemOutputPerComponent {
  readonly [componentId: string]: ResultItemOutputMap;
}

export type CalculatorKey = string;
export type InputMapperKey = string;

export type CalculatorResult<TOutput> = CalculatorSuccess<TOutput> | CalculatorError;
export type InputMapperResult<TInput> = InputMapperSuccess<TInput> | InputMapperError;

export type Matcher = (
  itemNumber: string,
  searchAttributes: ReadonlyArray<SearchAttribute>,
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
) => boolean;
export type Calculator<TInput, TOutput> = (inputItems: TInput) => Promise<CalculatorResult<TOutput>>;

export interface SearchAttribute {
  readonly collection: string;
  readonly attribute: string;
  readonly value: string;
}

export interface ComponentInput {
  readonly id: string;
  readonly productId: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly attributes: Attributes.Attributes;
}

export type InputParam = BaseInputParam & (AmountInputParam | DiscreteInputParam);

export interface BaseInputParam {
  readonly group: string;
  readonly name: string;
  readonly selectorType?: string;
  readonly width?: number;
  readonly visibilityFilter?: PropertyFilter.PropertyFilter;
  readonly validationFilter?: PropertyFilter.PropertyFilter;
  readonly calculate?: CalculateParamsFunction;
}

export type CalculateParamsFunction = (params: PropertyValueSet.PropertyValueSet) => PropertyValueSet.PropertyValueSet;

export interface AmountInputParam {
  readonly type: "Amount";
  readonly defaultValue?: Amount.Amount<AnyQuantity>;
  readonly quantity: AnyQuantity;
  readonly fieldName: string;
  readonly validationFilter?: PropertyFilter.PropertyFilter;
}

export interface DiscreteInputParam {
  readonly type: "Discrete";
  readonly values: ReadonlyArray<DiscreteInputParamValue>;
}

export interface DiscreteInputParamValue {
  readonly value: number;
  readonly name: string;
}

export interface InputMapperModule<TInput, TResponse> {
  readonly getCalcParams: (
    calculatorParams: string,
    attributes: Attributes.Attributes,
    variant: PropertyValueSet.PropertyValueSet,
    calcParams: PropertyValueSet.PropertyValueSet
  ) => ReadonlyArray<InputParam>;
  readonly getQuery: (productId: string, variantId: string | undefined) => QD.DiaqMapQuery<QD.DiaqMapResponse>;
  // eslint-disable-next-line functional/prefer-readonly-type
  readonly getResultsQuery: (system: System, componentId: string) => ReadonlyArray<ResultQuery>;
  readonly map: (
    componentInput: ComponentInput,
    response: TResponse,
    resultQueryResponse: ResultItemOutputPerComponent,
    calculatorParams: string
  ) => InputMapperResult<TInput>;
}

export interface ResultQuery {
  readonly componentId: string;
  // eslint-disable-next-line functional/prefer-readonly-type
  readonly resultItems: Array<string>;
}

export interface OutputMapperModule<TOutput> {
  readonly map: (result: CalculatorSuccess<TOutput>) => OutputMapperResult;
}

export interface CalculatorError {
  readonly type: "CalculatorError";
  readonly messages: ReadonlyArray<Message>;
}

export function createCalculatorError(messages: ReadonlyArray<Message>): CalculatorError {
  return {
    type: "CalculatorError",
    messages: messages,
  };
}

export interface SortValue {
  readonly value: number | string;
  readonly descending: boolean;
}

export interface CalculatorSuccess<TOutput> {
  readonly type: "CalculatorSuccess";
  readonly sortValues: ReadonlyArray<SortValue>;
  readonly output: TOutput;
  readonly messages: ReadonlyArray<Message>;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
}

export function createCalculatorSuccess<TOutput>(
  sortValues: ReadonlyArray<SortValue>,
  output: TOutput,
  messages: ReadonlyArray<Message> = [],
  calcParams: PropertyValueSet.PropertyValueSet = PropertyValueSet.Empty
): CalculatorSuccess<TOutput> {
  return {
    type: "CalculatorSuccess",
    sortValues: sortValues,
    output: output,
    messages: messages,
    calcParams: calcParams,
  };
}

export interface InputMapperError {
  readonly type: "InputMapperError";
  readonly messages: ReadonlyArray<Message>;
}

export function createInputMapperError(messages: ReadonlyArray<Message>): InputMapperError {
  return {
    type: "InputMapperError",
    messages: messages,
  };
}

export interface InputMapperSuccess<TInput> {
  readonly type: "InputMapperSuccess";
  readonly messages: ReadonlyArray<Message>;
  readonly input: TInput;
}

export function createInputMapperSuccess<TInput>(
  input: TInput,
  messages: ReadonlyArray<Message> = []
): InputMapperSuccess<TInput> {
  return {
    type: "InputMapperSuccess",
    input: input,
    messages: messages,
  };
}

export type OutputMapperResult = OutputMapperSuccess | OutputMapperError;

export interface OutputMapperError {
  readonly type: "OutputMapperError";
  readonly messages: ReadonlyArray<Message>;
}

export function createOutputMapperError(messages: ReadonlyArray<Message>): OutputMapperError {
  return {
    type: "OutputMapperError",
    messages: messages,
  };
}

export interface OutputMapperSuccess {
  readonly type: "OutputMapperSuccess";
  readonly sortValues: ReadonlyArray<SortValue>;
  readonly result: ResultItem;
  readonly messages: ReadonlyArray<Message>;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
}

export function createOutputMapperSuccess(
  sortValues: ReadonlyArray<SortValue>,
  result: ResultItem,
  messages: ReadonlyArray<Message> = [],
  calcParams: PropertyValueSet.PropertyValueSet = PropertyValueSet.Empty
): OutputMapperSuccess {
  return {
    type: "OutputMapperSuccess",
    sortValues: sortValues,
    result: result,
    messages: messages,
    calcParams: calcParams,
  };
}
