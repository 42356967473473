import { createSelector, Selector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { RootState } from "../../../types";
import { VisualizerOwnProps } from "../types";
import { Base64DiagramVisualizerContainerComponent, StateProps, Response } from "./container-component";

function mapPropsToQuery(_: VisualizerOwnProps, __: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
  });
}

const mapStateToProps: Selector<RootState, VisualizerOwnProps, StateProps> = createSelector(
  UserSettings.stateSelector,
  UserSettings.showBoxFanRpmSelector,
  UserSettings.showBoxFanEfficiencySelector,
  UserSettings.showBoxFanMotorPowerSelector,
  (userSettings, showRpm, showEfficiency, showMotorPower) => ({
    userSettings: userSettings,
    showRpm: showRpm,
    showEfficiency: showEfficiency,
    showMotorPower: showMotorPower,
  })
);

export const Base64DiagramVisualizerContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(Base64DiagramVisualizerContainerComponent);
