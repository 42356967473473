import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import { AnyQuantity } from "shared-lib/uom";
import { ResultViewTable, ResultViewTableTd, UnitSelector, Heading4 } from "client-lib/elements";
import { Amount } from "uom";
import * as QP from "shared-lib/query-product";
import { FormatNumberFunction, formatNumberFunction } from "shared-lib/utils";
import * as UserSettings from "shared-lib/user-settings";
import * as Shared from "shared-lib/result-views";
import * as UserSettingsClient from "client-lib/user-settings";
import { DispatchProp } from "client-lib/redux-integration";
import { VisualizerOwnProps } from "../types";
import { renderResultTableRowIcon } from "../shared/shared";

export type Props = VisualizerOwnProps & Response & StateProps & DispatchProp<UserSettingsClient.Action>;
const resultTdFill = { width: "2px", height: "2px" };

export interface Response {
  readonly metaTables: {
    readonly ct_MarketUnits: QP.MarketUnitsTable;
    readonly ct_ResultVisualizerParamsTable: QP.ResultVisualizerParamsTableTable;
    readonly ct_MarketHiddenFields: QP.MarketHiddenFieldsTable;
    readonly ct_LanguageMapping: QP.LanguageMappingTable;
  };
}

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export function TableVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { market, language, visualizerParams, translate, metaTables, userSettings, products } = props;
  const { ct_MarketUnits, ct_LanguageMapping } = metaTables;

  const [width, setWidth] = React.useState<number | undefined>(undefined);

  const tableRef = React.useRef<HTMLDivElement>(null);
  const updateDimensions = (): void => {
    if (tableRef.current) {
      setWidth(tableRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    updateDimensions();
  }, [tableRef.current]);

  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const getUnit = UserSettings.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getUnits = UserSettings.getUnits({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const getDecimals = UserSettings.getDecimals({ market, ct_MarketUnits });
  const params = visualizerParams.split(";");
  const results = Shared.getTableRows(
    market,
    metaTables,
    visualizerParams,
    products.map((p) => p?.resultItemMap)
  );
  if (results.length === 0) {
    return <span />;
  }

  // Need to use resizer since chrome doesnt support "calc()" on colgroup
  // Unit selector: max-width: 144px; min-width: 100px
  // Label + Value: max-width: 600px;
  const unitSelectorWidth = width && Math.max(Math.min(width * 0.2, 144), 100);
  const emptyColumnWidth = width && unitSelectorWidth && Math.max(width - 600 - unitSelectorWidth, 0);

  const singleProduct = props.products.length === 1;

  return (
    <div ref={tableRef}>
      <ResultViewTable equalWidths={true}>
        {!singleProduct ? (
          <colgroup key="colgroup">
            <col key={"label"} className="w-[11%]"></col>
            <col />

            {props.products.map((_p, idx) => (
              <React.Fragment key={idx}>
                <col key={`left_empty`} className={idx === 0 ? "w-[5%]" : "w-1/6"} />
                <col key={`value`} />
              </React.Fragment>
            ))}
            <col className="w-[1%]" />
            <col key={"selector"} className="w-[9%]"></col>
            {<col key={"empty"} className="w-[16.5%]"></col>}
          </colgroup>
        ) : (
          <colgroup key="colgroup">
            <col key={"single_label"}></col>
            <col key={"single_value"}></col>
            <col key={"single_selector"} style={width ? { width: unitSelectorWidth + "px" } : {}}></col>
            <col key={"single_empty"} style={width ? { width: emptyColumnWidth + "px" } : {}}></col>
          </colgroup>
        )}
        <thead>
          <tr>
            <React.Fragment key="heading">
              <th colSpan={singleProduct ? 4 : 7}>
                <Heading4>{translate(Texts.createText(params[0]))}</Heading4>
              </th>
            </React.Fragment>
          </tr>
        </thead>
        <tbody>
          {results.map((r) =>
            renderResultTableRow(r.item, r.values, getUnit, getUnits, getDecimals, formatNumber, props)
          )}
        </tbody>
      </ResultViewTable>
    </div>
  );
}

function renderResultTableRow(
  item: QP.ResultVisualizerParamsTable,
  values: ReadonlyArray<Shared.TableResultValue>,
  getUnit: UserSettings.GetUnitFunction,
  getUnits: UserSettings.GetUnitsFunction,
  getDecimals: UserSettings.GetDecimalsFunction,
  formatNumber: FormatNumberFunction,
  props: Props
): React.ReactElement<{}> {
  const { translate } = props;
  const singleProduct = props.products.length === 1;
  const renderedValues = values.map((v, i) =>
    renderResultValue(`result_value_${i}`, item, v, getUnit, getDecimals, formatNumber, props)
  );
  const renderedValuesWithPadding = singleProduct
    ? renderedValues
    : renderedValues.map((v, idx) => (
        <React.Fragment key={idx}>
          <td />
          {v}
        </React.Fragment>
      ));
  return (
    <tr key={item.label}>
      <ResultViewTableTd colSpan={singleProduct ? 1 : 2}>
        <div className="inline-flex">
          <span className="min-w-26">{renderResultTableRowIcon(item.icon)}</span>
          <span>{translate(Texts.createText(item.label))}</span>
        </div>
      </ResultViewTableTd>
      {renderedValuesWithPadding}
      {!singleProduct && <td />}
      {renderUnitSelector(item, values, getUnit, getUnits, props)}
      {<ResultViewTableTd key="empty" />}
    </tr>
  );
}

function renderUnitSelector(
  item: QP.ResultVisualizerParamsTable,
  values: ReadonlyArray<Shared.TableResultValue>,
  getUnit: UserSettings.GetUnitFunction,
  getUnits: UserSettings.GetUnitsFunction,
  props: Props
): React.ReactElement<{}> {
  const { translate, dispatch } = props;
  const amount = values.find((value) => typeof value === "object" && !(value instanceof Array)) as
    | Amount.Amount<AnyQuantity>
    | undefined;
  const quantity = amount && amount.unit.quantity;
  if (quantity) {
    const unit = getUnit(item.field_name, quantity);
    const units = getUnits(item.field_name, quantity);
    return (
      <ResultViewTableTd>
        <UnitSelector
          className="max-w-144 float-right"
          noBackgroundColor={true}
          noBorder={true}
          unit={unit}
          units={units}
          unitChanged={(u) => dispatch(UserSettingsClient.setFieldUnit(item.field_name, u))}
          unitCleared={() => dispatch(UserSettingsClient.clearFieldUnit(item.field_name))}
          translate={translate}
        />
      </ResultViewTableTd>
    );
  } else {
    return (
      <ResultViewTableTd>
        <div style={resultTdFill} />
      </ResultViewTableTd>
    );
  }
}

function renderResultValue(
  key: string,
  item: QP.ResultVisualizerParamsTable,
  value: Shared.TableResultValue,
  getUnit: UserSettings.GetUnitFunction,
  getDecimals: UserSettings.GetDecimalsFunction,
  formatNumber: FormatNumberFunction,
  props: Props
): React.ReactElement<{}> {
  const { translate } = props;
  const textAlign = "right";
  if (value === undefined) {
    return (
      <ResultViewTableTd key={key} textAlign={textAlign}>
        -
      </ResultViewTableTd>
    );
  } else if (typeof value === "string") {
    return (
      <ResultViewTableTd key={key} textAlign={textAlign}>
        {translate(Texts.createText(value), value)}
      </ResultViewTableTd>
    );
  } else if (value instanceof Array) {
    return (
      <ResultViewTableTd key={key} textAlign={textAlign}>
        <p>{value.map((v) => translate(Texts.createText(v), v)).join(", ")}</p>
      </ResultViewTableTd>
    );
  } else {
    const amount = value as Amount.Amount<AnyQuantity>;
    const unit = getUnit(item.field_name, amount.unit.quantity);
    const decimals = getDecimals(item.field_name, unit);
    return (
      <ResultViewTableTd key={key} textAlign={textAlign}>
        {formatNumber.format(Amount.valueAs(unit, amount).toFixed(decimals).toString())}
      </ResultViewTableTd>
    );
  }
}
