import * as React from "react";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import { UnitsFormat } from "uom-units";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as Texts from "shared-lib/language-texts";
import * as QP from "shared-lib/query-product";
import * as UserSettings from "shared-lib/user-settings";
import { FormatNumberFunction } from "shared-lib/utils";
import { Images, Group, PropertiesColumns } from "client-lib/properties-selector-elements";
import { filterPrettyPrintIndented } from "@promaster-sdk/property-filter-pretty";
import { createFilterPrettyPrintMessages } from "./filter-pretty-print-messages";
import { PropertySelector } from "./property-selector";
import { PropertyFormats, OnUnitChangedFn, OnUnitClearedFn } from "./types";

export interface PropertyGroupProps {
  readonly productId: string;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly group: string;
  readonly extraTitle?: string;
  readonly properties: ReadonlyArray<PromasterPropertiesSelector.Property>;
  readonly allProperties: ReadonlyArray<PromasterPropertiesSelector.Property>;
  readonly onChanged: PromasterPropertiesSelector.OnPropertiesChanged;
  readonly onUnitChanged: OnUnitChangedFn;
  readonly onUnitCleared: OnUnitClearedFn;
  readonly translate: Texts.TranslateFunction;
  readonly translatePropertyName?: PromasterPropertiesSelector.TranslatePropertyName;
  readonly translatePropertyValue?: PromasterPropertiesSelector.TranslatePropertyValue;
  readonly formatNumber: FormatNumberFunction;
  readonly selectorTypes: QP.PropertySelectorTypesTable;
  readonly propertyFormats: PropertyFormats;
  readonly getUnits: UserSettings.GetUnitsFunction;
  readonly showAdvanced: boolean;
  readonly images: Images;
  readonly hideInvalidValues?: boolean;
  readonly hideEmptyUnitSelectors?: boolean;
  readonly comboBoxImageCSSFilter?: string;
  readonly closed: boolean | undefined;
  readonly onToggleClosed: () => void;
}

export function PropertyGroup(props: PropertyGroupProps): JSX.Element {
  const {
    productId,
    group,
    properties,
    propertyValueSet,
    onChanged,
    onUnitChanged,
    onUnitCleared,
    formatNumber,
    translate,
    selectorTypes,
    propertyFormats,
    getUnits,
    showAdvanced,
    images,
    hideInvalidValues,
    comboBoxImageCSSFilter,
    translatePropertyName,
    translatePropertyValue,
    hideEmptyUnitSelectors,
    closed,
    onToggleClosed,
    extraTitle,
  } = props;
  const visibleProperties = properties.filter((p) => PropertyFilter.isValid(propertyValueSet, p.visibility_filter));
  if (visibleProperties.length === 0) {
    return <span />;
  }
  const filterPrettyPrint = (filter: PropertyFilter.PropertyFilter): string =>
    filterPrettyPrintIndented(
      createFilterPrettyPrintMessages(productId, translate, propertyFormats),
      2,
      " ",
      filter,
      UnitsFormat,
      CustomUnitsLookup
    );
  const groupTitle = translate(Texts.property_group(group));
  const title = extraTitle ? `${groupTitle} - ${extraTitle}` : groupTitle;
  return (
    <Group header={title} closed={closed} onToggleClosed={onToggleClosed}>
      <PropertiesColumns>
        {visibleProperties.map((p) => (
          <PropertySelector
            key={p.name}
            productId={productId}
            property={p}
            onChanged={onChanged}
            index={0}
            propertyValueSet={propertyValueSet}
            translate={translate}
            translatePropertyName={translatePropertyName}
            translatePropertyValue={translatePropertyValue}
            getUnits={getUnits}
            propertyFormats={propertyFormats}
            selectorType={selectorTypes.find((t) => t.name === p.name)}
            fieldName={p.field_name}
            onUnitChanged={onUnitChanged}
            onUnitCleared={onUnitCleared}
            formatNumber={formatNumber}
            filterPrettyPrint={filterPrettyPrint}
            showAdvanced={showAdvanced}
            images={images}
            hideInvalidValues={hideInvalidValues}
            hideEmptyUnitSelectors={hideEmptyUnitSelectors}
            comboBoxImageCSSFilter={comboBoxImageCSSFilter}
          />
        ))}
      </PropertiesColumns>
    </Group>
  );
}
