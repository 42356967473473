import { diaqConnect } from "client-lib/redux-integration";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import { createSelector, Selector } from "reselect";
import { RootState } from "types";
import * as UserSettings from "client-lib/user-settings";
import { CalculatorComponent, OwnProps, StateProps, Response } from "./container-component";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (showAdvanced, userSettings) => ({
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

function mapPropsToQuery(props: OwnProps, __: Response): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    productTables: QP.tablesByProductId(props.productId, ["ct_ClosedGroups"]),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
  });
}

export const CalculatorContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(CalculatorComponent);
