import { diaqConnect } from "client-lib/redux-integration";
import * as QD from "shared-lib/query-diaq";
import { createSelector } from "reselect";
import { SoundPressureLevelVisualizerContainerComponent } from "./container-component";
import { RootState } from "../../../types";

const mapStateToProps = createSelector(
  (p: RootState) => p.settings.user,
  (userSettings) => ({
    userSettings: userSettings,
  })
);

const mapPropsToQuery = (): QD.DiaqMapQuery<unknown> => QD.createMapQuery({});

export const SoundPressureLevelVisualizerContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(SoundPressureLevelVisualizerContainerComponent as any);
