import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as Types from "../types";
import * as Messages from "../messages";
import { OctaveBands, Silencer } from "../result-items-types";
import * as PressureDrop from "../shared/pressure-drop";
import { Input } from "./types";
import { validateMaxAirFlowAndPressure } from "../shared/validate-max-airflow-and-pressure";

const source = "SilencerCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<Silencer>> {
  const { airFlow, maxAirFlow, maxPressure, attenuation, silencerType } = input;

  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const messages: Array<Messages.Message> = [];

  const points =
    maxAirFlow && maxPressure
      ? [
          Interpolation.vec2Create(
            Amount.valueAs(customUnits.LiterPerSecond, maxAirFlow),
            Amount.valueAs(customUnits.Pascal, maxPressure)
          ),
        ]
      : [Interpolation.vec2Create(0, 0)];
  const minFlow = 0;
  const maxFlow = Math.max(...points.map((p) => p.x)) * 3;

  const pressureDropCurve = PressureDrop.createPowerPressureCurve(minFlow, maxFlow, points);
  const pressureDropPa = Interpolation.splineGetPoint(airFlowLps, pressureDropCurve.spline) || 0;

  const soundAttenuation: OctaveBands = {
    type: "Octave",
    hz63: attenuation.lw63,
    hz125: attenuation.lw125,
    hz250: attenuation.lw250,
    hz500: attenuation.lw500,
    hz1000: attenuation.lw1k,
    hz2000: attenuation.lw2k,
    hz4000: attenuation.lw4k,
    hz8000: attenuation.lw8k,
    total: undefined,
  };

  messages.push(...validateMaxAirFlowAndPressure(source, maxAirFlow, maxPressure, airFlow, pressureDropPa));

  return Types.createCalculatorSuccess(
    [],
    {
      airFlow: airFlow,
      pressureDrop: Amount.create(pressureDropPa, customUnits.Pascal, 1),
      soundAttenuation: soundAttenuation,
      silencerType: silencerType,

      pressureDropCurve: pressureDropCurve,
    },
    messages
  );
}
