import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Attributes from "../shared/attributes";

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  if (!searchAttributes) {
    console.log(searchAttributes, variantAttributes, searchVariant, calcParams);
  }

  return true;
}

export function matchApplication(
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes,
  searchVariant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  if (!searchAttributes) {
    console.log(searchAttributes, variantAttributes, searchVariant, calcParams);
  }

  return true;
}
