import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import { ImageViewContainerComponent, Response, OwnProps } from "./container-component";
import { RootState } from "../../types";

function mapPropsToQuery(props: OwnProps): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    imageSrc: QP.blob(props.imageUrl),
  });
}

const mapStateToProps = (_: RootState, __: OwnProps): unknown => ({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ImageViewContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(ImageViewContainerComponent as any);
