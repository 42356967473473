import * as SC from "shared-lib/system-calculator";
import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Result } from "../types";

const breakPointCfm = 270;
const breakPointInWg = 0.4;
export function postFilter(
  searchVariant: PropertyValueSet.PropertyValueSet,
  results: ReadonlyArray<Result>,
  _calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<Result> {
  if (PropertyValueSet.hasProperty("application_commercial", searchVariant)) {
    return results.filter((item) => {
      if (item.result.results && item.result.results.HeatRecoveryUnit.type !== "OutputMapperError") {
        const result: SC.HeatRecoveryUnitResultItem = item.result.results.HeatRecoveryUnit
          .result as SC.HeatRecoveryUnitResultItem;
        const resultItem: SC.HeatRecoveryUnit = result.value;
        const { supplyFan, extractFan } = resultItem;

        if (
          supplyFan.airFlow === undefined ||
          supplyFan.externalPressure === undefined ||
          extractFan.airFlow === undefined ||
          extractFan.externalPressure === undefined
        ) {
          return false;
        }

        return (
          Amount.valueAs(customUnits.CubicFeetPerMinute, supplyFan.airFlow) > breakPointCfm &&
          Amount.valueAs(customUnits.CubicFeetPerMinute, extractFan.airFlow) > breakPointCfm &&
          Amount.valueAs(customUnits.InchOfWaterColumn, supplyFan.externalPressure) > breakPointInWg &&
          Amount.valueAs(customUnits.InchOfWaterColumn, extractFan.externalPressure) > breakPointInWg
        );
      }
      return false;
    });
  } else if (PropertyValueSet.hasProperty("application_fresh_air", searchVariant)) {
    return results.filter((item) => {
      if (item.result.results && item.result.results.HeatRecoveryUnit.type !== "OutputMapperError") {
        const result: SC.HeatRecoveryUnitResultItem = item.result.results.HeatRecoveryUnit
          .result as SC.HeatRecoveryUnitResultItem;
        const resultItem: SC.HeatRecoveryUnit = result.value;
        const { supplyFan, extractFan } = resultItem;

        if (
          supplyFan.airFlow === undefined ||
          supplyFan.externalPressure === undefined ||
          extractFan.airFlow === undefined ||
          extractFan.externalPressure === undefined
        ) {
          return false;
        }

        return (
          Amount.valueAs(customUnits.CubicFeetPerMinute, supplyFan.airFlow) <= breakPointCfm &&
          Amount.valueAs(customUnits.CubicFeetPerMinute, extractFan.airFlow) <= breakPointCfm &&
          Amount.valueAs(customUnits.InchOfWaterColumn, supplyFan.externalPressure) <= breakPointInWg &&
          Amount.valueAs(customUnits.InchOfWaterColumn, extractFan.externalPressure) <= breakPointInWg
        );
      }
      return false;
    });
  } else {
    return results;
  }
}
