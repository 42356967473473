/* eslint-disable @typescript-eslint/no-explicit-any */
// Lib imports
import * as Redux from "redux";
import * as ReduxQuery from "shared-lib/redux-query/core";
import * as QD from "shared-lib/query-diaq";
import { createLogger } from "redux-logger";
import * as Crm from "client-lib/crm";
import * as UserSettings from "client-lib/user-settings";
import * as Compare from "client-lib/compare";
import createSagaMiddleware from "redux-saga";
import { persistStore, Persistor } from "redux-persist";
import { clientConfig } from "config";
// App imports
import { persistedRootReducer } from "./reducer";
import { rootSaga } from "./saga";
import { RootState } from "./types";

declare const window: any;
export const { store, persistor } = createStore();

function createStore(): {
  readonly store: Redux.Store<RootState>;
  readonly persistor: Persistor;
} {
  // Create middlewares
  const sagaMiddleware = createSagaMiddleware();
  const diaqLoadQueries = QD.createLoadQueries(
    clientConfig.pimAddress,
    clientConfig.markerName,
    clientConfig.useMeasureToolData,
    clientConfig.measureToolDataUrl
  );
  const queryMiddleware = ReduxQuery.createQueryMiddleware(diaqLoadQueries, "query");
  const crmMiddleware = Crm.createMiddleware();
  const userSettingsMiddleware = UserSettings.createMiddleware();
  const compareMiddleware = Compare.createMiddleware();

  // Create store
  const recoverState = (): RootState => ({} as RootState);
  const logger = createLogger({ collapsed: true });

  const localStore: Redux.Store<RootState> = Redux.createStore<RootState>(
    persistedRootReducer,
    recoverState(),
    Redux.compose(
      process.env.NODE_ENV === "development"
        ? Redux.applyMiddleware(
            sagaMiddleware,
            queryMiddleware,
            crmMiddleware,
            userSettingsMiddleware,
            compareMiddleware,
            logger
          )
        : Redux.applyMiddleware(
            sagaMiddleware,
            queryMiddleware,
            crmMiddleware,
            userSettingsMiddleware,
            compareMiddleware
          ),

      window.devToolsExtension ? window.devToolsExtension() : (f: any) => f
    ) as any
  );

  const localPersistor = persistStore(localStore);

  // Start the root saga
  const saga = sagaMiddleware.run(rootSaga);
  // add error-catching in sagas, to propigate it out to the main error handler
  // we need to run it through a setTimeout which lives outside the promise.
  saga.done.catch((e: Error) => {
    setTimeout(() => {
      throw e;
    });
  });
  return { store: localStore, persistor: localPersistor };
}
