import * as React from "react";
import * as PU from "shared-lib/product-utils";
import * as Texts from "shared-lib/language-texts";
import { ComboboxOption, Combobox } from "client-lib/elements";
import * as PC from "shared-lib/product-codes";
import { TranslateFunction } from "shared-lib/language-texts";
import { Item, TablesForProducts } from "../types";

export interface VariantSelectorProps {
  readonly translate: TranslateFunction;
  readonly selectedItem: Item | undefined;
  readonly selectionChanged: (itemNo: string) => void;
  readonly tablesForProducts: TablesForProducts;
  readonly addedItems: ReadonlyArray<Item>;
}

export function VariantSelector(props: VariantSelectorProps): React.ReactElement<VariantSelectorProps> {
  const { selectedItem, translate, selectionChanged, tablesForProducts, addedItems } = props;

  if (addedItems.length === 0 || tablesForProducts === undefined) {
    return <div />;
  }

  const filteredItems = addedItems.filter((r) => {
    const m3Variant = PC.getVariantFromM3ItemNo(tablesForProducts[r.productId], r.m3ItemNo, r.variantNo);

    if (m3Variant === undefined) {
      return false;
    }

    return !PU.isAmbient(tablesForProducts![r.productId].ct_Attributes2, m3Variant);
  });

  const itemOptions: Array<ComboboxOption> = filteredItems.map((i) => {
    return {
      value: i.m3ItemNo,
      label: i.itemName,
      onClick: () => selectionChanged(i.m3ItemNo),
    };
  });

  if (itemOptions.length === 0) {
    return <div />;
  }

  const selected = filteredItems.find((r) => r.m3ItemNo === ((selectedItem && selectedItem.m3ItemNo) || ""));

  const options = !selected
    ? [
        {
          value: "select_product",
          label: translate(Texts.select_product_fr()),
        },
        ...itemOptions,
      ]
    : itemOptions;

  return (
    <Combobox
      className="w-224"
      value={selected?.m3ItemNo || options[0].value}
      options={options}
      onChange={(value) => {
        const m3ItemNo = itemOptions.find((o) => o.value === value)?.value;
        if (m3ItemNo) {
          selectionChanged(m3ItemNo);
        }
      }}
    />
  );
}
