import { SharedConfig } from "shared-lib/shared-config";
import { appInfo } from "shared-lib/version";

export interface ClientPartConfig {
  readonly calculateOnServer: boolean;
  readonly calculationServer: string | undefined; // if undefined will use location.host
  readonly useMeasureToolData: boolean;
  readonly addOuterPadding: boolean;
  readonly measureToolDataUrl: string | undefined;
}

export type ClientConfig = ClientPartConfig & SharedConfig;

// eslint-disable-next-line no-restricted-globals
const searchParams = new URL(location.href).searchParams;
const userMeasureToolParam = searchParams.get("use_measure_tool_data");
const measureToolDataUrlParam = searchParams.get("measure_tool_data_url") || undefined;
const addOuterPadding = searchParams.get("add_outer_padding") === "true" || false;

const shared: SharedConfig = {
  // eslint-disable-next-line no-restricted-globals
  markerName: appInfo.markerName !== "{marker_name}" ? appInfo.markerName : location.hostname,
  pimAddress: "https://stdselection-api.systemair.com/rest/v3/4380457a-defd-4c28-87ea-6eb5353b1b08",
  imageServiceUrl: "https://public-api.promaster.systemair.com/image",
};

const defaultConfig: ClientConfig = {
  calculateOnServer: true,
  useMeasureToolData: userMeasureToolParam === "1" && !!measureToolDataUrlParam,
  measureToolDataUrl: measureToolDataUrlParam,
  addOuterPadding: addOuterPadding,
  calculationServer: appInfo.serverUrl !== "{server_url}" ? appInfo.serverUrl : "",
  ...shared,
};

const developmentConfig: ClientConfig = {
  ...shared,
  calculateOnServer: false,
  useMeasureToolData: userMeasureToolParam === "1" && !!measureToolDataUrlParam,
  measureToolDataUrl: measureToolDataUrlParam,
  addOuterPadding: addOuterPadding,
  calculationServer: "http://localhost:4001",
};

function getClientConfig(): ClientConfig & SharedConfig {
  if (appInfo.clientEnvironment === "{client_environment}") {
    return developmentConfig;
  }
  return defaultConfig;
}
export const clientConfig = getClientConfig();
