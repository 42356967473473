/* eslint-disable functional/no-this-expression */
import * as React from "react";
import * as ProductUtils from "shared-lib/product-utils";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Alert, Spinner, Button, HToolbar } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as PC from "shared-lib/product-codes";
import * as C from "shared-lib/calculation";
import { ItemConfig } from "shared-lib/calculation";
import { ResultItemOutputPerComponent } from "shared-lib/system-calculator";
import * as R from "ramda";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import { updateAirCurtainCalcParams } from "shared-lib/system-calculator/shared/air-curtain";
import * as DiaqTemplates from "shared-lib/diaq-templates";
import { CrmAddButton } from "client-lib/crm";
import * as Compare from "client-lib/compare";
import { getFieldUnitsFromCookie } from "client-lib/user-settings/cookie";
import { setFieldUnitConfig } from "client-lib/user-settings";
import * as Accessories from "shared-lib/accessories";
import { clientConfig } from "config";
import { Props } from "./types";
import * as Actions from "./actions";
import { CalcParamsSelectorContainer } from "../calc-params-selector/index";
import { SearchPropertiesSelectorContainer } from "../search-properties-selector/index";
import { selectionsFromVariant, getVariants } from "../properties-selector-multi/index";
import { VersionInfoContainer } from "../version-info";
import { AccessoriesTable } from "./components/accessories-table";
import { ProductCalculationContainer } from "../product-calculation";
import * as Postmessage from "../../postmessage-api";
import { ProductProps } from "../product-calculation/types";

// eslint-disable-next-line functional/no-class
export function ProductContainerComponent(props: Props): React.ReactElement<Props> {
  const {
    market,
    language,
    productId,
    m3ItemNo,
    state,
    productTables,
    dispatch,
    translateTables,
    metaTables,
    accessoryTables,
    saveCalculationCallBack,
    calculationExists,
    crm,
    hideName,
    variant,
    navigateToItemNo,
    shopUrl,
    variantNoToPrice,
    ct_DivisionCurrency,
    dontSendReadyEvent,
    config,
  } = props;

  const [prevProps, setPrevProps] = React.useState(props);

  const updateConfig = (): void => {
    const newConfig = config || {
      properties: PropertyValueSet.Empty,
      calcParams: PropertyValueSet.Empty,
      accessories: [],
    };
    dispatch(Actions.setConfig(newConfig));
    if (!dontSendReadyEvent) {
      const configWithMeta = {
        ...newConfig,
      };
      Postmessage.postReadyEvent(C.serializeConfig(configWithMeta));
    }
  };

  React.useEffect(() => {
    const fieldUnits = { ...getFieldUnitsFromCookie(), ...props.config.meta?.fieldUnits };
    if (fieldUnits) {
      dispatch(setFieldUnitConfig(fieldUnits));
    }

    updateConfig();
  }, []);

  React.useEffect(() => {
    updateConfig();
  }, [props.m3ItemNo, props.variant]);

  React.useEffect(() => {
    const fullConfigCurr = getfullConfig(props);
    const fullConfigPrev = getfullConfig(prevProps);

    const newPvs = updateAirCurtainCalcParams(
      fullConfigPrev,
      fullConfigCurr,
      props.productTables,
      props.state.setInitialAirFlowStep
    );
    if (newPvs !== undefined) {
      props.dispatch(Actions.setCalcParams(newPvs));
    }

    setPrevProps(props);
  }, [props]);

  if (!translateTables || !productTables) {
    return <Spinner />;
  }

  const translate = Texts.translateFunctionSelector(translateTables, language);

  const fullConfig = ProductUtils.getFullConfig(
    {
      market: market,
      m3ItemNo: m3ItemNo,
      variant: variant,
      propsConfig: props.config,
      stateConfig: state.config,
      metaTables: metaTables,
      productTables: productTables,
      accessoryTables: accessoryTables,
    },
    clientConfig.useMeasureToolData
  );

  if (!fullConfig || isAmbient(fullConfig.properties)) {
    return (
      <Alert horizontalAlign={true} type="neutral">
        {translate(Texts.no_data_available())}
      </Alert>
    );
  }

  const codes = PC.getProductCodes(productTables, fullConfig.properties);
  const attributes = Attributes.createMap(fullConfig.properties, productTables.ct_Attributes2);

  const calcParamsValid = C.ValidateCalcParams.validate(
    metaTables,
    productTables,
    accessoryTables,
    fullConfig,
    attributes
  );

  const productSelectedForCompare =
    props.comparedProduct &&
    Compare.createComparedKey(props.comparedProduct) ===
      Compare.createComparedKey({ productId: productId, itemConfig: fullConfig });

  const currencyRow = ct_DivisionCurrency.find((r) => r.division_number === market);
  const currency = (currencyRow && currencyRow.currency) || "";
  const price = codes.variantId !== undefined ? variantNoToPrice[codes.variantId] : undefined;
  const airMeasurement = Attributes.getMeasurementNo("measurement-number-air", attributes);
  const soundMeasurement = Attributes.getMeasurementNo("measurement-number-sound", attributes);
  const itemInfo = {
    itemName: translate(Texts.item_name(productId, fullConfig.properties), codes.code),
    itemNo: codes.itemNo,
    variantName: translate(Texts.property_value(productId, "variant", parseInt(codes.variantId || "", 10)), ""),
    airMeasurement: airMeasurement,
    soundMeasurement: soundMeasurement,
  };

  const comparedProduct = areProductsComparable(props, fullConfig) ? props.comparedProduct : undefined;
  const comparisonIsAvailable = isComparisonAvailable(props, fullConfig) && !clientConfig.useMeasureToolData;
  const showComparison =
    comparisonIsAvailable && props.state.showComparison && areProductsComparable(props, fullConfig);
  const comparedProductsProps: ReadonlyArray<ProductProps> = [
    {
      productId: productId,
      variantId: undefined,
      config: fullConfig,
      calcParamsChanged: (c: PropertyValueSet.PropertyValueSet) =>
        dispatch(Actions.setCalcParams(PropertyValueSet.setValues(c, fullConfig.calcParams))),
      accCalcParamsChanged: (accessoryId: string, c: PropertyValueSet.PropertyValueSet) =>
        dispatch(Actions.updateAccessoryCalcParams(accessoryId, c)),
      navigateToItemNo: navigateToItemNo,
    },
    ...(comparedProduct && showComparison
      ? [
          {
            productId: comparedProduct.productId,
            config: comparedProduct.itemConfig,
            variantId: undefined,
            calcParamsChanged: () => undefined,
            accCalcParamsChanged: () => undefined,
          },
        ]
      : []),
  ];

  return (
    <div key={m3ItemNo} className="space-y-24">
      {hideName ? undefined : <VersionInfoContainer itemInfo={itemInfo} />}
      {crmControls(props, fullConfig, translate, codes, price, currency)}
      {comparisonIsAvailable ? (
        <div className={`flex ${clientConfig.addOuterPadding ? "px-40" : ""}`}>
          <HToolbar className="ml-auto">
            <Button
              clicked={() => dispatch(Compare.addProduct(productId, fullConfig))}
              label={translate(Texts.save_for_comparison())}
              disabled={productSelectedForCompare}
              buttonType={"secondary"}
              small={true}
            />
            {comparedProduct ? (
              <Button
                clicked={() => dispatch(Actions.setShowComparison(!showComparison))}
                label={
                  showComparison ? translate(Texts.back_to_selection()) : translate(Texts.compare_with_saved_product())
                }
                disabled={!comparedProduct}
                buttonType={"secondary"}
                small={true}
              />
            ) : null}
          </HToolbar>
        </div>
      ) : null}
      {renderSearchSection(props, translate, fullConfig, attributes, calcParamsValid)}
      {calcParamsValid ? (
        saveCalculationCallBack === undefined ? (
          <ProductCalculationContainer
            key={"multi"}
            market={market}
            language={language}
            hideErrors={state.hideErrors}
            crm={crm}
            translate={translate}
            shopUrl={shopUrl}
            productsProps={comparedProductsProps}
          />
        ) : null
      ) : (
        <Alert type="error">{translate(Texts.invalid_parameters())}</Alert>
      )}
      {state.calculationError === true ? (
        <Alert type="error">{translate(Texts.generic_error_calculation())}</Alert>
      ) : null}
      {calculationExists && calculationExists === true ? (
        <Alert type="warning">{translate(Texts.calculation_exists())}</Alert>
      ) : null}
    </div>
  );
}

function areProductsComparable(props: Props, fullConfig: C.ItemConfig): boolean {
  const comparedConfig = props.comparedProduct?.itemConfig;
  const comparedTables = props.comparedProductTables;
  if (!comparedConfig || !comparedTables) {
    return false;
  }

  const currentProductAttributes = Attributes.createMap(fullConfig.properties, props.productTables.ct_Attributes2);
  const currentProductTemplate = DiaqTemplates.getTemplatesFromAttributes(
    fullConfig.properties,
    props.productTables.ct_DiaqTemplates,
    props.metaTables.ct_AttributeTemplateMapping,
    currentProductAttributes
  ).find((t) => t.type === "ResultViews");

  const comparedProductAttributes = Attributes.createMap(comparedConfig.properties, comparedTables.ct_Attributes2);
  const comparedProductTemplate = DiaqTemplates.getTemplatesFromAttributes(
    comparedConfig.properties,
    comparedTables.ct_DiaqTemplates,
    props.metaTables.ct_AttributeTemplateMapping,
    comparedProductAttributes
  ).find((t) => t.type === "ResultViews");
  if (!currentProductTemplate || !comparedProductTemplate) {
    return false;
  }

  return currentProductTemplate.template === comparedProductTemplate.template;
}

function isComparisonAvailable(props: Props, fullConfig: C.ItemConfig): boolean {
  const currentProductAttributes = Attributes.createMap(fullConfig.properties, props.productTables.ct_Attributes2);
  const currentProductTemplate = DiaqTemplates.getTemplatesFromAttributes(
    fullConfig.properties,
    props.productTables.ct_DiaqTemplates,
    props.metaTables.ct_AttributeTemplateMapping,
    currentProductAttributes
  ).find((t) => t.type === "ResultViews");
  return props.saveCalculationCallBack === undefined && !!currentProductTemplate;
}

function renderSearchSection(
  props: Props,
  translate: Texts.TranslateFunction,
  fullConfig: C.ItemConfig,
  attributes: Attributes.Attributes,
  calcParamsValid: boolean
): React.ReactElement {
  const {
    market,
    productId,
    state,
    productTables,
    dispatch,
    metaTables,
    saveCalculationCallBack,
    updateVariant,
    hideInvalidValues,
    language,
  } = props;

  const template = DiaqTemplates.getTemplatesFromAttributes(
    fullConfig.properties,
    productTables.ct_DiaqTemplates,
    metaTables.ct_AttributeTemplateMapping,
    attributes
  ).find((t) => t.type === "ResultItems");
  const resultItems = metaTables.ct_ResultItems.filter((i) => template && i.template === template.template);
  const setting = metaTables.ct_MarketSettings.find((s) => s.market === market && s.setting === "EnableAccessories");
  const accessoriesEnabled = setting && setting.value.split(",").find((v) => !!resultItems.find((i) => i.type === v));

  return (
    <>
      <SearchPropertiesSelectorContainer
        translate={translate}
        market={market}
        hideFirstGroupTitle={true}
        language={language}
        productId={productId}
        hideProperties={PC.getItemNoPropertyNames(productTables.ct_ItemNo, productTables.ct_VariantNo)}
        selections={selectionsFromVariant(fullConfig.properties)}
        selectionsChanged={(s) => dispatch(Actions.setProperties(getVariants(s)[0]))}
        hideInvalidValues={hideInvalidValues}
      />
      <CalcParamsSelectorContainer
        translate={translate}
        market={market}
        language={language}
        hideErrors={state.hideErrors}
        productId={productId}
        variant={fullConfig.properties}
        calcParams={fullConfig.calcParams}
        calcParamsChanged={(c) => {
          dispatch(Actions.setCalcParams(c));
          if (updateVariant) {
            updateVariant(c);
          }
        }}
        attributes={attributes}
        hideInvalidValues={hideInvalidValues}
      />
      {accessoriesEnabled && <AccessoriesTable {...props} fullConfig={fullConfig} translate={translate} />}
      {accessoriesEnabled &&
        fullConfig.accessories.map((a, i) => (
          <CalcParamsSelectorContainer
            key={i}
            translate={translate}
            market={market}
            language={language}
            hideErrors={state.hideErrors}
            productId={a.productId}
            variant={a.properties}
            calcParams={a.calcParams}
            calcParamsChanged={(c) => {
              dispatch(Actions.updateAccessoryCalcParams(a.id, c));
            }}
            attributes={Attributes.createMap(a.properties, props.accessoryTables[a.productId].ct_Attributes2)}
            hideInvalidValues={hideInvalidValues}
            hiddenParams={Accessories.inheritedCalcParams}
            extraGroupTitle={Accessories.getAccessoryName(translate, props.accessoryTables[a.productId], a.properties)}
            singleGroup={true}
          />
        ))}
      {saveCalculationCallBack !== undefined ? (
        <HToolbar className="pl-20">
          <Button
            disabled={!calcParamsValid}
            label={translate(Texts.calculate())}
            clicked={() => {
              performCalculation(props, fullConfig).then((r) => {
                if (r !== undefined) {
                  const result = R.values(R.values(r)[0])[0];
                  if (result.type === "OutputMapperError") {
                    dispatch(Actions.calculationError(true));
                  } else {
                    const newCalcParams = PropertyValueSet.setValues(result.calcParams, fullConfig.calcParams);
                    dispatch(Actions.setCalcParams(newCalcParams));
                    saveCalculationCallBack(fullConfig, r);
                  }
                }
              });
            }}
          />
          <Button
            label={translate(Texts.reset())}
            clicked={() => {
              dispatch(Actions.setConfig(C.emptyItemConfig));
            }}
          />
        </HToolbar>
      ) : null}
    </>
  );
}

function crmControls(
  props: Props,
  fullConfig: ItemConfig,
  translate: Texts.TranslateFunction,
  codes: PC.ProductCodes,
  price: number | undefined,
  currency: string
): JSX.Element | undefined {
  const { crm, crmState, market, m3ItemNo, variant, userSettings, dispatch, navigateToItemNo } = props;
  if (!crm || !navigateToItemNo) {
    return undefined;
  }

  // Z I assume this is never more than one since the ct_variantno table has the filter variantno=1122,
  // And since each variantno is unique this will always be 1
  // const variants = productTables.ct_VariantNo.filter(r =>
  //   PropertyFilter.isValid(fullConfig.properties, r.property_filter)
  // );
  return (
    <div className="flex justify-between mb-24">
      {itemHeader(props, codes, translate, fullConfig, price, currency)}
      <HToolbar>
        <Button buttonType="secondary" label={translate(Texts.back())} clicked={() => window.history.go(-1)} />
        {/* {variants.length > 1 ? (
        <Dropdown
          label={translate(Texts.select_variant())}
          items={variants.map(v => ({
            key: v.variant_no,
            label: translate(Texts.property_value(productId, "variant", parseInt(v.variant_no, 10))),
            onClick: () => navigateToItemNo(m3ItemNo, serializeConfig(emptyItemConfig), v.variant_no, language)
          }))}
        />
      ) : (
        undefined
      )} */}
        <CrmAddButton
          crmState={crmState}
          translate={translate}
          dispatch={dispatch}
          crmConfig={crm}
          market={market}
          variant={variant}
          m3ItemNo={m3ItemNo}
          userSettings={userSettings}
          fullConfig={fullConfig}
          buttonId={"global"}
        />
      </HToolbar>
    </div>
  );
}

function itemHeader(
  props: Props,
  codes: PC.ProductCodes,
  translate: Texts.TranslateFunction,
  fullConfig: C.ItemConfig,
  price: number | undefined,
  currency: string
): React.ReactElement<{}> {
  const { productId } = props;
  return (
    <div>
      <div>
        ItemName: <b>{translate(Texts.item_name(productId, fullConfig.properties), codes.code)}</b>
      </div>
      <div>
        VariantName: <b>{translate(Texts.property_value(productId, "variant", parseInt(codes.variantId || "", 10)))}</b>
      </div>
      <div>
        ItemNo: <b>{codes.itemNo}</b>
      </div>
      <div>
        Price: <b>{price !== undefined ? `${price} ${currency}` : "Unknown"}</b>
      </div>
    </div>
  );
}

function isAmbient(variant: PropertyValueSet.PropertyValueSet): boolean {
  const heatingMethod = PropertyValueSet.getInteger("heatingMethod", variant);
  return heatingMethod !== undefined && heatingMethod === 2;
}

async function performCalculation(
  props: Props,
  itemConfig: ItemConfig
): Promise<ResultItemOutputPerComponent | undefined> {
  const { calculationResponse } = props;
  const input: C.OwnProps = {
    productId: props.productId,
    config: itemConfig,
    variantId: props.variant,
  };
  return (await C.calculateSystem(input, calculationResponse)).result;
}

function getfullConfig(props: Props): C.ItemConfig | undefined {
  const { market, productTables, state, m3ItemNo, variant, metaTables, accessoryTables } = props;
  const fullConfig = ProductUtils.getFullConfig(
    {
      market: market,
      m3ItemNo: m3ItemNo,
      variant: variant,
      propsConfig: props.config,
      stateConfig: state.config,
      metaTables: metaTables,
      productTables: productTables,
      accessoryTables: accessoryTables,
    },
    clientConfig.useMeasureToolData
  );

  return fullConfig;
}
