import * as AC from "shared-lib/abstract-chart";
import { AnyQuantity } from "shared-lib/uom";
import * as AI from "abstract-image";
import { Amount, Unit, UnitFormat } from "uom";
import { Quantity, UnitsFormat } from "uom-units";
import * as Style from "shared-lib/style";
import * as Interpolation from "shared-lib/interpolation";
import * as R from "ramda";
import * as Texts from "shared-lib/language-texts";
import { Curve, SpeedControl, FanAirResult } from "../result-items-types";

const numPoints = 100;

export interface FanProps {
  readonly fan: FanAirResult;
  readonly highlighted: boolean;
}

export interface FanChartsProps {
  readonly fanProps: ReadonlyArray<FanProps>;
  readonly flowUnit: Unit.Unit<Quantity.VolumeFlow>;
  readonly pressureUnit: Unit.Unit<Quantity.Pressure>;
  readonly translate: Texts.TranslateFunction;
  readonly showLineLabels: boolean;
  readonly style: Style.DiagramStyle;
}

export interface FanCharts {
  readonly pressure: AC.Chart;
}

export function generateChartsMultiple({
  fanProps,
  flowUnit,
  pressureUnit,
  translate,
  showLineLabels,
  style,
}: FanChartsProps): FanCharts {
  const speedControl = speedControlToUse(fanProps);
  const [minX, maxX] = minMaxXForFans(fanProps, flowUnit);
  const curveProps = fanProps.map((p) => ({
    desiredX: undefined, //fan.desiredAirFlow,
    desiredY: undefined, //fan.desiredExternalPressure,
    workX: undefined, //fan.airFlow,
    workY: undefined, //fan.externalPressure,
    originalCurves: [], //fan.pressureCurves,
    curves: p.fan.adjustedPressureCurves,
    valid: p.fan.airFlow !== undefined,
    highlighted: p.highlighted,
  }));
  const props = {
    speedControl: speedControl,
    showSystemLine: false,
    showTexts: true,
    minX: minX,
    maxX: maxX,
    unitX: flowUnit,
    translate: translate,
    showLineLabels: showLineLabels,
    unitY: pressureUnit,
    style: style,
  };
  const pressure = generateChart({
    ...props,
    curveProps: curveProps,
  });
  return {
    pressure,
  };
}

export function fanCurvesPointIntersection(
  flowUnit: Unit.Unit<Quantity.VolumeFlow>,
  pressureUnit: Unit.Unit<Quantity.Pressure>,
  fanProps: ReadonlyArray<FanProps>,
  point: AI.Point
): FanProps | undefined {
  const speedControl = speedControlToUse(fanProps);
  if (speedControl === "None") {
    return fanProps.find((p) => {
      const curves = p.fan.adjustedPressureCurves;
      const curve = curves[curves.length - 1];
      return !!curve && curveIntersection(flowUnit, pressureUnit, curve, point.x, point.y);
    });
  } else if (speedControl === "Transformer") {
    return fanProps.find((p) => {
      const curves = p.fan.adjustedPressureCurves;
      return curves.some((curve) => curveIntersection(flowUnit, pressureUnit, curve, point.x, point.y));
    });
  } else if (speedControl === "Stepless") {
    return fanProps.find((p) => {
      const curves = p.fan.adjustedPressureCurves;
      const bottomCurve = curves[0];
      const topCurve = curves[curves.length - 1];
      return (
        !!bottomCurve && !!topCurve && areaIntersection(flowUnit, pressureUnit, bottomCurve, topCurve, point.x, point.y)
      );
    });
  } else {
    return undefined;
  }
}

function curveIntersection(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  chartX: number,
  chartY: number
): boolean {
  const x = Amount.valueAs(curve.unitX, Amount.create(chartX, unitX));
  const y = Amount.valueAs(curve.unitY, Amount.create(chartY, unitY));
  const curveY = Interpolation.splineGetPoint(x, curve.spline);
  if (curveY === undefined) {
    return false;
  }
  const yDist = Math.abs(curveY - y);
  return yDist < 10; // TODO: convert 10 to pixels
}

function areaIntersection(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  bottomCruve: Curve,
  topCurve: Curve,
  chartX: number,
  chartY: number
): boolean {
  const x = Amount.valueAs(topCurve.unitX, Amount.create(chartX, unitX));
  const y = Amount.valueAs(topCurve.unitY, Amount.create(chartY, unitY));
  const bottomY = Interpolation.splineGetPoint(x, bottomCruve.spline);
  const topY = Interpolation.splineGetPoint(x, topCurve.spline);
  if (bottomY === undefined && topY === undefined) {
    return false;
  }
  const belowTop = topY === undefined || y <= topY;
  const aboveBottom = bottomY === undefined || y >= bottomY;
  return belowTop && aboveBottom;
}

function speedControlToUse(fanProps: ReadonlyArray<FanProps>): SpeedControl {
  return fanProps.every((p) => p.fan.speedControl === fanProps[0].fan.speedControl)
    ? fanProps[0].fan.speedControl
    : "None";
}

function minMaxXForFans(
  fanProps: ReadonlyArray<FanProps>,
  flowUnit: Unit.Unit<Quantity.VolumeFlow>
): readonly [Amount.Amount<Quantity.VolumeFlow>, Amount.Amount<Quantity.VolumeFlow>] {
  const minX = Math.min(
    ...fanProps
      .map((p) => (p.fan.minAirFlow ? Amount.valueAs(flowUnit, p.fan.minAirFlow) : NaN))
      .filter((af) => Number.isFinite(af))
  );
  const minXAmount = Amount.create(minX, flowUnit);
  const maxX = Math.max(
    ...fanProps
      .map((p) => (p.fan.maxAirFlow ? Amount.valueAs(flowUnit, p.fan.maxAirFlow) : NaN))
      .filter((af) => Number.isFinite(af))
  );
  const maxXAmount = Amount.create(maxX, flowUnit);
  return [minXAmount, maxXAmount];
}

interface FanChartCurveProps {
  readonly desiredX?: Amount.Amount<AnyQuantity>;
  readonly desiredY?: Amount.Amount<AnyQuantity>;
  readonly workX?: Amount.Amount<AnyQuantity>;
  readonly workY?: Amount.Amount<AnyQuantity>;
  readonly originalCurves?: ReadonlyArray<Curve>;
  readonly curves: ReadonlyArray<Curve>;
  readonly valid: boolean;
  readonly highlighted: boolean;
}

interface CurveStyle {
  readonly originalAreaColor: AI.Color;
  readonly actualAreaColor: AI.Color;
  readonly invalidAreaColor: AI.Color;
  readonly originalLine: Style.DiagramLineStyle;
  readonly actualLine: Style.DiagramLineStyle;
  readonly systemLine: Style.DiagramLineStyle;
  readonly powerLine: Style.DiagramLineStyle;
  readonly workPointColor: AI.Color;
  readonly invalidColor: AI.Color;
}

type FanChartCurvePropsWithStyle = FanChartCurveProps & { readonly curveStyle: CurveStyle };

interface FanChartProps {
  readonly speedControl: SpeedControl;
  readonly showTexts: boolean;
  readonly showSystemLine: boolean;
  readonly minX?: Amount.Amount<AnyQuantity>;
  readonly maxX?: Amount.Amount<AnyQuantity>;
  readonly unitX: Unit.Unit<AnyQuantity>;
  readonly unitY: Unit.Unit<AnyQuantity>;
  readonly translate: Texts.TranslateFunction;
  readonly showLineLabels: boolean;
  readonly curveProps: ReadonlyArray<FanChartCurveProps>;
  readonly style: Style.DiagramStyle;
}

function generateChart(props: FanChartProps): AC.Chart {
  const {
    speedControl,
    showTexts,
    showSystemLine,
    minX,
    maxX,
    unitX,
    unitY,
    translate,
    showLineLabels,
    style,
    curveProps,
  } = props;
  const curvePropsStyled = sortAndApplyCurveStyle(style, curveProps);
  const xAxisUnitLabel = translate(Texts.unitLabel(unitX), UnitFormat.getUnitFormat(unitX, UnitsFormat)?.label);
  const xAxis =
    minX && maxX && AC.createLinearAxis(Amount.valueAs(unitX, minX), Amount.valueAs(unitX, maxX), xAxisUnitLabel);
  const allCurves = R.unnest<Curve>(curvePropsStyled.map((p) => p.curves));
  const yAxis = createLinearAxisY(allCurves, unitY, translate);
  const components = xAxis
    ? R.unnest<AC.ChartComponent>(
        curvePropsStyled.map((p) => {
          const { desiredX, desiredY, valid, workX, workY, curves, originalCurves = [], curveStyle, highlighted } = p;
          const {
            originalLine,
            actualLine,
            invalidColor,
            originalAreaColor,
            actualAreaColor,
            invalidAreaColor,
            workPointColor,
          } = curveStyle;
          const showLineLabelsForCurve = showLineLabels && highlighted;
          return [
            ...generateCurves(speedControl, originalLine, unitX, unitY, originalCurves, false, invalidColor),
            ...generateCurves(speedControl, actualLine, unitX, unitY, curves, showLineLabelsForCurve, invalidColor),
            ...generateSystemLine(showSystemLine, xAxis, yAxis, valid, desiredX, desiredY, unitX, unitY, curveStyle),
            ...generateArea(speedControl, originalAreaColor, unitX, unitY, originalCurves),
            ...generateArea(speedControl, actualAreaColor, unitX, unitY, curves),
            ...generateInvalidAreas(speedControl, invalidAreaColor, unitX, unitY, curves),
            ...generatePoint(false, workPointColor, false, desiredX, desiredY, unitX, unitY),
            ...generatePoint(showTexts, workPointColor, true, workX, workY, unitX, unitY),
          ];
        })
      )
    : [];
  const chart = AC.createChart({
    components: components,
    xAxisBottom: xAxis,
    yAxisLeft: yAxis,
    backgroundColor: style.backgroundColor,
    gridColor: style.grid.lineColor,
    gridThickness: style.grid.lineThickness,
  });

  return chart;
}

function generateSystemLine(
  showSystemLine: boolean,
  xAxis: AC.Axis,
  yAxis: AC.Axis,
  valid: boolean,
  workX: Amount.Amount<AnyQuantity> | undefined,
  workY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  style: { readonly systemLine: Style.DiagramLineStyle; readonly invalidColor: AI.Color }
): ReadonlyArray<AC.ChartComponent> {
  if (!workX || !workY || !showSystemLine) {
    return [];
  }
  const xWork = Amount.valueAs(unitX, workX);
  const yWork = Amount.valueAs(unitY, workY);
  const k = yWork / (xWork * xWork);
  const step = (xAxis.max - xAxis.min) / numPoints;
  const points = R.range(0, numPoints)
    .map((i) => {
      const x = xAxis.min + i * step;
      const y = k * x * x;
      return AI.createPoint(x, y);
    })
    .filter((p) => p.y >= yAxis.min && p.y <= yAxis.max);

  const maxPoint = AI.createPoint(Math.sqrt(yAxis.max / k), yAxis.max);
  if (maxPoint.x >= xAxis.min && maxPoint.x <= xAxis.max) {
    points.push(maxPoint);
  }

  return [
    AC.createChartLine({
      points: points,
      thickness: style.systemLine.lineThickness,
      color: valid ? style.systemLine.lineColor : style.invalidColor,
    }),
  ];
}

function generatePoint(
  showLabels: boolean,
  color: AI.Color,
  filled: boolean,
  pX: Amount.Amount<AnyQuantity> | undefined,
  pY: Amount.Amount<AnyQuantity> | undefined,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>
): ReadonlyArray<AC.ChartComponent> {
  if (!pX || !pY) {
    return [];
  }
  const x = Amount.valueAs(unitX, pX);
  const y = Amount.valueAs(unitY, pY);
  const axisLines = showLabels ? "linesAndText" : "none";
  if (filled) {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: color,
        position: AI.createPoint(x, y),
        size: AI.createSize(8, 8),
        axisLines: axisLines,
      }),
    ];
  } else {
    return [
      AC.createChartPoint({
        shape: "circle",
        color: AI.transparent,
        stroke: color,
        strokeThickness: 2,
        position: AI.createPoint(x, y),
        size: AI.createSize(12, 12),
        axisLines: axisLines,
      }),
    ];
  }
}

function createLinearAxisY(
  curves: ReadonlyArray<Curve>,
  unitY: Unit.Unit<AnyQuantity>,
  translate: Texts.TranslateFunction
): AC.Axis {
  const ys = R.unnest<Amount.Amount<AnyQuantity>>(
    curves.map((c) => Interpolation.splineGetPoints(8, c.spline).map((v) => Amount.create(v.y, c.unitY)))
  ).map((y) => Amount.valueAs(unitY, y));
  const min = 0; //Math.min(...ys) * 0.9;
  const max = Math.max(...ys) * 1.1;
  const unitLabel = translate(Texts.unitLabel(unitY), UnitFormat.getUnitFormat(unitY, UnitsFormat)?.label);
  return AC.createLinearAxis(min, max, unitLabel);
}

function generateCurves(
  speedControl: SpeedControl,
  style: Style.DiagramLineStyle,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>,
  labels: boolean,
  invalidColor: AI.Color
): ReadonlyArray<AC.ChartComponent> {
  if (curves.length === 0) {
    return [];
  }
  if (speedControl === "None") {
    return generateCurve(unitX, unitY, curves[curves.length - 1], undefined, style, invalidColor);
  } else if (speedControl === "Transformer") {
    const showControl = labels && R.uniq(curves.map((c) => c.controlVoltage)).length > 1;
    const showSupply = labels && R.uniq(curves.map((c) => c.supplyVoltage)).length > 1;
    return R.unnest<AC.ChartComponent>(
      curves.map((curve) =>
        generateCurve(
          unitX,
          unitY,
          curve,
          showControl
            ? curve.controlVoltage.toString() + "V"
            : showSupply
            ? curve.supplyVoltage.toString() + "V"
            : undefined,
          style,
          invalidColor
        )
      )
    );
  } else {
    return [];
  }
}

function generateCurve(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  label: string | undefined,
  style: Style.DiagramLineStyle,
  invalidColor: AI.Color
): ReadonlyArray<AC.ChartComponent> {
  const workPoints = generateCurvePoints(unitX, unitY, curve, curve.workMin, curve.workMax);
  const curves = [
    AC.createChartLine({
      points: workPoints,
      color: style.lineColor,
      thickness: style.lineThickness,
      text: label,
      textAnchorPosition: "bottomLeft",
      textPosition: "start",
    }),
  ];
  if (curve.workMin > curve.spline.xMin) {
    const lowInvalidPoints = [
      ...generateCurvePoints(unitX, unitY, curve, curve.spline.xMin, curve.workMin),
      workPoints[0],
    ];
    curves.push(
      AC.createChartLine({
        points: lowInvalidPoints,
        color: invalidColor,
        thickness: style.lineThickness,
      })
    );
  }
  if (curve.workMax < curve.spline.xMax) {
    const highInvalidPoints = [
      workPoints[workPoints.length - 1],
      ...generateCurvePoints(unitX, unitY, curve, curve.workMax, curve.spline.xMax),
    ];
    curves.push(
      AC.createChartLine({
        points: highInvalidPoints,
        color: invalidColor,
        thickness: style.lineThickness,
      })
    );
  }
  return curves;
}

function generateArea(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>
): ReadonlyArray<AC.ChartArea> {
  if (speedControl !== "Stepless" || curves.length === 0) {
    return [];
  }
  const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, curves[0]));
  const topPoints = generateCurvePoints(unitX, unitY, curves[curves.length - 1]);
  const rightPoints = R.reverse(
    curves.map((c) =>
      createPoint(unitX, unitY, c, c.spline.xMax, Interpolation.splineGetPoint(c.spline.xMax, c.spline) || 0)
    )
  );
  const leftPoints = curves.map((c) =>
    createPoint(unitX, unitY, c, c.spline.xMin, Interpolation.splineGetPoint(c.spline.xMin, c.spline) || 0)
  );
  const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
  return [
    AC.createChartArea({
      points: points,
      color: color,
      fill: color,
    }),
  ];
}

function generateInvalidAreas(
  speedControl: SpeedControl,
  color: AI.Color,
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curves: ReadonlyArray<Curve>
): ReadonlyArray<AC.ChartArea> {
  if (speedControl !== "Stepless" || curves.length === 0) {
    return [];
  }
  const areas: Array<AC.ChartArea> = [];

  const lowCurve = curves[0];
  const highCurve = curves[curves.length - 1];
  if (lowCurve.workMin > lowCurve.spline.xMin || highCurve.workMin > highCurve.spline.xMin) {
    const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, lowCurve, lowCurve.spline.xMin, lowCurve.workMin));
    const topPoints = generateCurvePoints(unitX, unitY, highCurve, highCurve.spline.xMin, highCurve.workMin);
    const rightPoints = R.reverse(
      curves.map((c) => createPoint(unitX, unitY, c, c.workMin, Interpolation.splineGetPoint(c.workMin, c.spline) || 0))
    );
    const leftPoints = curves.map((c) =>
      createPoint(unitX, unitY, c, c.spline.xMin, Interpolation.splineGetPoint(c.spline.xMin, c.spline) || 0)
    );
    const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
    areas.push(
      AC.createChartArea({
        points: points,
        color: color,
        fill: color,
      })
    );
  }
  if (lowCurve.workMax < lowCurve.spline.xMax || highCurve.workMax > highCurve.spline.xMax) {
    const bottomPoints = R.reverse(generateCurvePoints(unitX, unitY, lowCurve, lowCurve.workMax, lowCurve.spline.xMax));
    const topPoints = generateCurvePoints(unitX, unitY, highCurve, highCurve.workMax, highCurve.spline.xMax);
    const leftPoints = curves.map((c) =>
      createPoint(unitX, unitY, c, c.workMax, Interpolation.splineGetPoint(c.workMax, c.spline) || 0)
    );
    const rightPoints = R.reverse(
      curves.map((c) =>
        createPoint(unitX, unitY, c, c.spline.xMax, Interpolation.splineGetPoint(c.spline.xMax, c.spline) || 0)
      )
    );
    const points = [...topPoints, ...rightPoints, ...bottomPoints, ...leftPoints];
    areas.push(
      AC.createChartArea({
        points: points,
        color: color,
        fill: color,
      })
    );
  }
  return areas;
}

function generateCurvePoints(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  xMin: number = curve.spline.xMin,
  xMax: number = curve.spline.xMax
): ReadonlyArray<AI.Point> {
  return Interpolation.splineGetPoints(numPoints, curve.spline)
    .filter((p) => p.x >= xMin && p.x <= xMax)
    .map((p) => createPoint(unitX, unitY, curve, p.x, p.y));
}

function createPoint(
  unitX: Unit.Unit<AnyQuantity>,
  unitY: Unit.Unit<AnyQuantity>,
  curve: Curve,
  x: number,
  y: number
): AI.Point {
  const chartX = Amount.valueAs(unitX, Amount.create(x, curve.unitX));
  const chartY = Amount.valueAs(unitY, Amount.create(y, curve.unitY));
  return AI.createPoint(chartX, chartY);
}

function sortAndApplyCurveStyle(
  style: Style.DiagramStyle,
  curveProps: ReadonlyArray<FanChartCurveProps>
): ReadonlyArray<FanChartCurvePropsWithStyle> {
  const curvesWithStyle = curveProps.map((p, i) => ({ ...p, curveStyle: styleForCruve(style, i, p.highlighted) }));
  const highLighted = curvesWithStyle.find((p) => p.highlighted);
  if (!highLighted) {
    return curvesWithStyle;
  }
  const notHighlighted = curvesWithStyle.filter((p) => p !== highLighted);
  return [...notHighlighted, highLighted];
}

function styleForCruve(style: Style.DiagramStyle, curveIndex: number, highlighted: boolean): CurveStyle {
  const actualAreaColorOpaque = style.actualAreaColorMulti[curveIndex % style.actualAreaColorMulti.length];
  const actualLineThin = style.actualLineMulti[curveIndex % style.actualLineMulti.length];
  const actualAreaColor = {
    ...actualAreaColorOpaque,
    a: highlighted ? 150 : 50,
  };
  const actualLine = {
    ...actualLineThin,
    lineThickness: highlighted ? actualLineThin.lineThickness * 4.0 : actualLineThin.lineThickness,
  };
  return {
    originalAreaColor: style.originalAreaColor,
    actualAreaColor: actualAreaColor,
    invalidAreaColor: style.invalidAreaColor,
    originalLine: style.originalLine,
    actualLine: actualLine,
    systemLine: style.systemLine,
    powerLine: style.powerLine,
    workPointColor: style.workPointColor,
    invalidColor: style.invalidColor,
  };
}
