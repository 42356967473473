import * as React from "react";
import { FarIconName } from "client-lib/font-awesome-icons";
import { withTw } from "./with-tw";
import { Icon } from "./icon";

export type ButtonType = "primary" | "secondary" | "danger" | "link" | "secondary-bg";

export interface ButtonProps {
  readonly clicked?: () => void;
  readonly iconLeft?: FarIconName;
  readonly label?: string;
  readonly iconRight?: FarIconName;
  readonly disabled?: boolean;
  readonly buttonType?: ButtonType | undefined;
  readonly spinnerIcon?: boolean;
  readonly compact?: boolean;
  readonly small?: boolean;
}

export type StyledButtonProps = Pick<ButtonProps, "compact" | "small" | "buttonType" | "disabled">;

export const StyledButton = withTw(
  "button",
  "flex flex-row items-center justify-between space-x-8 font-bold btn",
  ({ compact, small }: StyledButtonProps) =>
    compact ? "text-sm space-x-4 h-26 pl-8 pr-8" : small ? "space-x-6 h-36 pl-26 pr-26" : "space-x-8 h-52 pl-40 pr-40",
  ({ buttonType, disabled }: StyledButtonProps) => {
    if (disabled) {
      return !buttonType || buttonType === "primary"
        ? "btn-primary-disabled"
        : buttonType === "secondary"
        ? "btn-secondary-disabled"
        : buttonType === "secondary-bg"
        ? "btn-negative-disabled"
        : buttonType === "danger"
        ? "btn-danger-disabled"
        : buttonType === "link"
        ? "btn-link-disabled"
        : "";
    } else {
      return !buttonType || buttonType === "primary"
        ? "btn-primary"
        : buttonType === "secondary"
        ? "btn-secondary"
        : buttonType === "secondary-bg"
        ? "btn-negative"
        : buttonType === "danger"
        ? "btn-danger"
        : buttonType === "link"
        ? "btn-link"
        : "";
    }
  }
);

export function Button({
  clicked,
  iconLeft,
  label,
  iconRight,
  disabled,
  buttonType,
  spinnerIcon,
  compact,
  small,
}: ButtonProps): React.ReactElement<ButtonProps> {
  return (
    <div className="inline-flex">
      <StyledButton
        small={small}
        compact={compact}
        buttonType={buttonType}
        disabled={disabled !== null && disabled === true}
        onClick={() => {
          if (clicked) {
            clicked();
          }
        }}
      >
        {iconLeft && <Icon prefix="far" className={spinnerIcon ? "fa-spin" : "font-normal"} icon={iconLeft} />}
        {label && <span className="whitespace-nowrap">{label}</span>}
        {iconRight && <Icon prefix="far" className={spinnerIcon ? "fa-spin" : undefined} icon={iconRight} />}
      </StyledButton>
    </div>
  );
}
