import * as R from "ramda";
import * as EcomApi from "shared-lib/ecom-api";
import { CacheLoadRequest, EcomCacheState, UrlAndMarket, UrlMarketlanguageItem, PriceListEntry } from "./types";
import { identifierToKey, productDescriptionsidentifierToKey } from "./functions";

export async function executeLoadRequest(
  cache: EcomCacheState,
  cacheLoadRequest: CacheLoadRequest
): Promise<EcomCacheState> {
  const variantNoPromises = cacheLoadRequest.variantNoIdentifiers.map((a) =>
    EcomApi.getVariantNoPriceList(a.url, a.market)
  );
  const productDescriptionPromises = cacheLoadRequest.productDescriptionsIdentifiers.map((a) =>
    EcomApi.getProductDescriptionAndTechnicalParameters(a.url, a.market, a.language, a.itemNo, a.variantId)
  );

  const variantNoResults = await Promise.all(variantNoPromises);
  const productDescriptionResults = await Promise.all(productDescriptionPromises);
  const time = Date.now();

  const newVariantNoPrices = R.fromPairs(
    variantNoResults.map(
      (r, index) =>
        [identifierToKey(cacheLoadRequest.variantNoIdentifiers[index]), { time: time, prices: r }] as R.KeyValuePair<
          R.Prop,
          PriceListEntry
        >
    )
  );

  const newProductDescriptions = R.fromPairs(
    productDescriptionResults.map(
      (r, index) =>
        [
          productDescriptionsidentifierToKey(cacheLoadRequest.productDescriptionsIdentifiers[index]),
          r,
        ] as R.KeyValuePair<R.Prop, EcomApi.ProductDescriptionAndTechnicalParameters>
    )
  );

  const amendedCache = {
    ...cache,
    variantNoPrices: {
      ...cache.variantNoPrices,
      ...newVariantNoPrices,
    },
    productDescriptions: {
      ...cache.productDescriptions,
      ...newProductDescriptions,
    },
  };
  return amendedCache;
}

// Remove everything that is already in the cache
export function optimizeLoadRequest(cache: EcomCacheState, cacheLoadRequest: CacheLoadRequest): CacheLoadRequest {
  const neededItemNoIdentifiers: Array<UrlAndMarket> = [];

  const uniqVariantNoIdentifiers = R.uniqBy(identifierToKey, cacheLoadRequest.variantNoIdentifiers);
  const neededVariantNoIdentifiers: Array<UrlAndMarket> = [];
  for (const identifier of uniqVariantNoIdentifiers) {
    const existing = cache.variantNoPrices[identifierToKey(identifier)];
    if (existing === undefined) {
      neededVariantNoIdentifiers.push(identifier);
    }
  }

  const uniqProductDescriptionIdentifiers = R.uniqBy(
    productDescriptionsidentifierToKey,
    cacheLoadRequest.productDescriptionsIdentifiers
  );
  const neededProductDescriptionIdentifiers: Array<UrlMarketlanguageItem> = [];
  for (const identifier of uniqProductDescriptionIdentifiers) {
    const existing = cache.productDescriptions[productDescriptionsidentifierToKey(identifier)];
    if (existing === undefined) {
      neededProductDescriptionIdentifiers.push(identifier);
    }
  }

  return {
    itemNoIdentifiers: neededItemNoIdentifiers,
    variantNoIdentifiers: neededVariantNoIdentifiers,
    productDescriptionsIdentifiers: neededProductDescriptionIdentifiers,
  };
}
