/* eslint-disable functional/no-this-expression */
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as C from "shared-lib/calculation";
import * as SC from "shared-lib/system-calculator";
import * as Accessories from "shared-lib/accessories";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import * as DiaqTemplates from "shared-lib/diaq-templates";

export interface ProductTables {
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_Attributes2: QP.AttributesTable;
}

export interface MetaTables {
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
}

export interface AccessoryTables {
  readonly [productId: string]: ProductTables;
}

export function validate(
  metaTables: MetaTables,
  productTables: ProductTables,
  accessoryTables: AccessoryTables,
  fullConfig: C.ItemConfig,
  attributes: Attributes.Attributes
): boolean {
  {
    const template = DiaqTemplates.getTemplatesFromAttributes(
      fullConfig.properties,
      productTables.ct_DiaqTemplates,
      metaTables.ct_AttributeTemplateMapping,
      attributes
    ).find((t) => t.type === "ResultItems");

    const resultItems = metaTables.ct_ResultItems.filter((i) => template && i.template === template.template);
    const calcParamDefs = SC.getCalcParams(resultItems, attributes, fullConfig.properties, fullConfig.calcParams);
    if (!validateCalcParams(calcParamDefs, fullConfig.calcParams)) {
      return false;
    }
  }

  const inheritedCalcParams = PropertyValueSet.keepProperties(Accessories.inheritedCalcParams, fullConfig.calcParams);
  for (const accessory of fullConfig.accessories) {
    const accessoryAttributes = Attributes.createMap(
      accessory.properties,
      accessoryTables[accessory.productId].ct_Attributes2
    );

    const template = DiaqTemplates.getTemplatesFromAttributes(
      accessory.properties,
      accessoryTables[accessory.productId].ct_DiaqTemplates,
      metaTables.ct_AttributeTemplateMapping,
      accessoryAttributes
    ).find((t) => t.type === "ResultItems");
    const resultItems = metaTables.ct_ResultItems.filter((i) => template && i.template === template.template);

    const calcParams = PropertyValueSet.merge(inheritedCalcParams, accessory.calcParams);
    const calcParamDefs = SC.getCalcParams(resultItems, accessoryAttributes, accessory.properties, calcParams);
    if (!validateCalcParams(calcParamDefs, calcParams)) {
      return false;
    }
  }

  return true;
}

function validateCalcParams(
  defs: ReadonlyArray<SC.InputParam>,
  calcParams: PropertyValueSet.PropertyValueSet
): boolean {
  const calcParamsValid = defs.every((cp) => {
    const param = calcParams[cp.name];
    if (param !== undefined) {
      if (PropertyFilter.isValid(calcParams, cp.visibilityFilter || PropertyFilter.Empty)) {
        // only if visible
        return PropertyFilter.isValid(calcParams, cp.validationFilter || PropertyFilter.Empty);
      }
    }
    return true;
  });
  return calcParamsValid;
}
