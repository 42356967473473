/* eslint-disable no-restricted-globals */
import { take, put, call } from "redux-saga/effects";
import * as uuid from "uuid";
import { delay } from "redux-saga";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Statistics from "shared-lib/statistics";
import * as Search from "shared-lib/search";
import { retryFetch } from "shared-lib/fetch";
import { clientConfig } from "config";
import { StartCalculation, calculationFinished } from "./actions";
import { resolveQuery } from "../redux-query-resolver";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* saga() {
  while (true) {
    const action: StartCalculation = yield take("search/START_CALCULATION");

    yield delay(0);

    const searchId = uuid();
    // console.time("Calculation");
    const start = performance.now();

    const allResults: ReadonlyArray<Search.Result> = yield clientConfig.calculateOnServer
      ? call(calculateProductVariantsOnServer, action.payload.ownProps)
      : call(calculateProductVariantsOnClient, action.payload.ownProps);

    yield put(
      calculationFinished(
        action.payload.source,
        allResults,
        action.payload.ownProps.searchProductId,
        action.payload.ownProps.properties,
        searchId
      )
    );
    const executionTime = performance.now() - start;

    Statistics.registerSearch(action, allResults, executionTime, searchId);

    console.log(`Calculation: ${executionTime}ms`);
  }
}

export async function calculateProductVariantsOnClient(
  ownProps: Search.OwnProps
): Promise<ReadonlyArray<Search.Result>> {
  const response = await resolveQuery(Search.searchQuery, {
    ecomUrl: ownProps.ecomUrl,
    searchProductId: ownProps.searchProductId,
    properties: [],
    calcParams: PropertyValueSet.Empty,
    attributes: [],
    accessories: [],
    market: ownProps.market,
    language: ownProps.language,
    crm: ownProps.crm,
    overallSortParams: {
      sortPath: "",
      sortType: undefined,
      descending: false,
    },
    groupSortParams: undefined,
  });
  return Search.calculateProductVariants(ownProps, response);
}

async function calculateProductVariantsOnServer(ownProps: Search.OwnProps): Promise<ReadonlyArray<Search.Result>> {
  const response = await retryFetch(`${clientConfig.calculationServer}/calculate/product/multiple`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ownProps),
  });
  // console.time("Decode");
  // eslint-disable-next-line no-return-await
  return response.json();
  // const json = await response.json();
  // return Search.decode(json);
}
