import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import * as SC from "shared-lib/system-calculator";
import { Heading3, ResultRowContainer } from "client-lib/elements";
import * as UserSettingsShared from "shared-lib/user-settings";
import { formatNumberFunction, isAllDefined } from "shared-lib/utils";
import { HeatRecoveryUnitResultItem } from "shared-lib/system-calculator/result-items-types";
import * as FanDiagram from "shared-lib/system-calculator/shared/fan-diagram";
import * as QP from "shared-lib/query-product";
import * as Style from "shared-lib/style";
import { ViewerDiagramContainer } from "../../viewer-diagram";
import { VisualizerOwnProps, VisualizerProductProps } from "../types";

export type Props = VisualizerOwnProps & StateProps & Response;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

const diagramWidth = 580;
const diagramHeight = 320;

export function HeatRecoveryUnitDiagramsVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const renderedDiagrams = products.map((p, idx) => renderHeatRecoveryUnitDiagramsVisualizer(idx, props, p, idx !== 0));
  const supplyHeading = translate(Texts.supply()) + " - " + translate(Texts.performanceCurve());
  const extractHeading = translate(Texts.extract()) + " - " + translate(Texts.performanceCurve());
  if (renderedDiagrams.length === 1) {
    const supply = renderedDiagrams[0] ? renderedDiagrams[0][0] : undefined;
    const extract = renderedDiagrams[0] ? renderedDiagrams[0][1] : undefined;
    if (!performance || !extract) {
      return <div />;
    }
    return (
      <div className="flex flex-row">
        <div className="w-1/2">
          <Heading3>{supplyHeading}</Heading3>
          {supply}
        </div>
        <div className="w-1/2">
          <Heading3>{extractHeading}</Heading3>
          {extract}
        </div>
      </div>
    );
  } else {
    const supply = renderedDiagrams.map((diagrams) => (diagrams ? diagrams[0] : <div />));
    const extract = renderedDiagrams.map((diagrams) => (diagrams ? diagrams[1] : <div />));
    return (
      <div>
        <ResultRowContainer heading={supplyHeading}>{supply}</ResultRowContainer>
        <ResultRowContainer heading={extractHeading}>{extract}</ResultRowContainer>
      </div>
    );
  }
}

function renderHeatRecoveryUnitDiagramsVisualizer(
  key: number,
  { translate, market, language, userSettings, ct_MarketUnits, ct_LanguageMapping, showDownload }: Props,
  { resultItemMap, calcParams, calcParamsChanged }: VisualizerProductProps,
  disableHoverPoint: boolean
): readonly [React.ReactElement<Props>, React.ReactElement<Props>] | undefined {
  const maybeHru: HeatRecoveryUnitResultItem = R.head(
    R.values(R.filter((ri: SC.ResultItem) => ri.type === "HeatRecoveryUnit", resultItemMap))
  ) as HeatRecoveryUnitResultItem;
  const hru = maybeHru && maybeHru.value;

  if (!hru) {
    return undefined;
  }
  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });
  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);

  const { supply, extract } = FanDiagram.generateSupplyExtractCharts({
    supplyFan: hru.supplyFan,
    extractFan: hru.extractFan,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels: true,
    style: Style.diagramGanymed,
  });

  const onSupplyDiagramClick = (x: number, y: number): void => {
    const withFlow = PropertyValueSet.setAmount(
      "supplyAirFlow",
      Amount.create(x, flowUnit, flowUnitDecimals),
      calcParams
    );
    const withPressure = PropertyValueSet.setAmount(
      "supplyPressure",
      Amount.create(y, pressureUnit, pressureUnitDecimals),
      withFlow
    );
    calcParamsChanged(withPressure);
  };

  const onExtractDiagramClick = (x: number, y: number): void => {
    const withFlow = PropertyValueSet.setAmount(
      "extractAirFlow",
      Amount.create(x, flowUnit, flowUnitDecimals),
      calcParams
    );
    const withPressure = PropertyValueSet.setAmount(
      "extractPressure",
      Amount.create(y, pressureUnit, pressureUnitDecimals),
      withFlow
    );
    calcParamsChanged(withPressure);
  };
  return [
    <ViewerDiagramContainer
      key={`supplyExternalPressure_${key}`}
      id="supplyExternalPressure"
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={pressureUnitDecimals}
      chart={supply.pressure}
      maxWidth={diagramWidth}
      maxHeight={diagramHeight}
      showDownload={showDownload}
      onClick={!disableHoverPoint ? onSupplyDiagramClick : undefined}
      formatNumber={formatNumber}
    />,
    <ViewerDiagramContainer
      key={`extractExternalPressure_${key}`}
      id="extractExternalPressure"
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={pressureUnitDecimals}
      chart={extract.pressure}
      maxWidth={diagramWidth}
      showDownload={showDownload}
      maxHeight={diagramHeight}
      onClick={!disableHoverPoint ? onExtractDiagramClick : undefined}
      formatNumber={formatNumber}
    />,
  ];
}
