import { AnyQuantity } from "shared-lib/uom";
import * as Texts from "shared-lib/language-texts";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Serialize, Unit } from "uom";
import * as QP from "shared-lib/query-product";
import { MessageCode } from "../system-calculator";
import {
  createPropertyNameText,
  createText,
  createPropertyValueText,
  createPropertyDescriptionText,
  createMessageCodeText,
} from "./types";

// ------ General texts. ------ ------
export const and = (): Texts.PText => createText("and");
export const between = (): Texts.PText => createText("between");
export const or = (): Texts.PText => createText("or");
export const between_min_and_max = (min: string, max: string): Texts.PText =>
  createText("between_min_and_max", { min, max });
export const should_be_between_min_max = (min: string, max: string): Texts.PText =>
  createText("should_be_between_min_max", { min, max });
export const left_must_be_less_than_or_equal_to_right = (left: string, right: string): Texts.PText =>
  createText("left_must_be_less_than_or_equal_to_right", { left, right });
export const left_must_be_greater_than_or_equal_to_right = (left: string, right: string): Texts.PText =>
  createText("left_must_be_greater_than_or_equal_to_right", { left, right });
export const left_must_be_less_than_right = (left: string, right: string): Texts.PText =>
  createText("left_must_be_less_than_right", { left, right });
export const left_must_be_greater_than_right = (left: string, right: string): Texts.PText =>
  createText("left_must_be_greater_than_right", { left, right });
export const left_must_be_right = (left: string, right: string): Texts.PText =>
  createText("left_must_be_right", { left, right });
export const left_must_not_be_right = (left: string, right: string): Texts.PText =>
  createText("left_must_not_be_right", { left, right });
export const parameter = (): Texts.PText => createText("parameter");
export const value = (): Texts.PText => createText("value");
export const value_must_be_numeric = (): Texts.PText => createText("value_must_be_numeric");
export const value_is_required = (): Texts.PText => createText("value_is_required");
export const frico_capacity = (): Texts.PText => createText("frico_capacity");
export const frico_disclaimer_row_1 = (): Texts.PText => createText("frico_disclaimer_row_1");
export const frico_disclaimer_row_2 = (): Texts.PText => createText("frico_disclaimer_row_2");
export const description = (): Texts.PText => createText("description");
export const description_for = (): Texts.PText => createText("description_for");
export const techincal_data = (): Texts.PText => createText("technical_data");
export const description_for_accessory = (): Texts.PText => createText("description_for_accessory");
export const frico_print_note = (): Texts.PText => createText("frico_print_note");
export const frico_note = (): Texts.PText => createText("frico_note");
export const frico_search_criteria = (): Texts.PText => createText("frico_search_criteria");
export const frico_show_search_criteria = (): Texts.PText => createText("frico_show_search_criteria");
export const frico_hide_search_criteria = (): Texts.PText => createText("frico_hide_search_criteria");
export const select_product_category = (): Texts.PText => createText("select_product_category");
export const crm_added = (): Texts.PText => createText("crm_added");
export const back = (): Texts.PText => createText("back");
export const show_details = (): Texts.PText => createText("show_details");
export const error_details = (): Texts.PText => createText("error_details");
export const error_code = (): Texts.PText => createText("error_code");
export const error_message = (): Texts.PText => createText("error_message");

// ------ Specific texts ------
export const octave_bands = (): Texts.PText => createText("octave_bands");
export const octave_bands_option = (): Texts.PText => createText("octave_bands_option");
export const third_octave_bands_option = (): Texts.PText => createText("third_octave_bands_option");

export const amca_statements = (): Texts.PText => createText("amca_statements");
export const airPressureDrop = (): Texts.PText => createText("airPressureDrop");

export const showRpmCurves = (): Texts.PText => createText("showRpmCurves");
export const showEfficiencyCurves = (): Texts.PText => createText("showEfficiencyCurves");
export const showMotorPowerCurves = (): Texts.PText => createText("showMotorPowerCurves");
export const showStandardFanAlternatives = (): Texts.PText => createText("showStandardFanAlternatives");

export const search = (): Texts.PText => createText("search");
export const abort = (): Texts.PText => createText("abort");
export const supply = (): Texts.PText => createText("supply");
export const extract = (): Texts.PText => createText("extract");
export const performanceCurve = (): Texts.PText => createText("performanceCurve");
export const powerCurve = (): Texts.PText => createText("powerCurve");
export const power = (): Texts.PText => createText("power");
export const current = (): Texts.PText => createText("current");
export const rpm = (): Texts.PText => createText("rpm");
export const total = (): Texts.PText => createText("total");
export const moreResults = (numResults: number): Texts.PText =>
  createText("moreResults", { numResults: numResults.toString() });
export const product = (): Texts.PText => createText("product");
export const none = (): Texts.PText => createText("none");
export const groupBy = (): Texts.PText => createText("groupBy");
export const groupByProduct = (): Texts.PText => createText("groupByProduct");
export const sortBy = (): Texts.PText => createText("sortBy");
export const bestFit = (): Texts.PText => createText("bestFit");
export const other = (): Texts.PText => createText("other");
export const collapse = (): Texts.PText => createText("collapse");
export const accessories = (): Texts.PText => createText("accessories");
export const add_accessory = (): Texts.PText => createText("add_accessory");
export const add_accessory_type = (type: string): Texts.PText => createText("add_accessory_" + type);
export const accessory_type = (type: string): Texts.PText => createText("accessory_type_" + type);
export const change_item = (): Texts.PText => createText("change_item");
export const cancel = (): Texts.PText => createText("cancel");
export const remove = (): Texts.PText => createText("remove");
export const calculation_exists = (): Texts.PText => createText("calculation_exists");
export const sound_power_level = (): Texts.PText => createText("sound_power_level");
export const acoustic_filter = (): Texts.PText => createText("acoustic_filter");
export const no_filter = (): Texts.PText => createText("no_filter");
export const a_filter = (): Texts.PText => createText("a_filter");
export const c_filter = (): Texts.PText => createText("c_filter");
export const columns = (): Texts.PText => createText("columns");
export const generic_error_calculation = (): Texts.PText => createText("generic_error_calculation");
export const add_to_specification_text = (): Texts.PText => createText("add_to_specification_text");
export const price = (): Texts.PText => createText("price");
export const reset_user_settings = (): Texts.PText => createText("reset_user_settings");
export const add_to_crm = (): Texts.PText => createText("add_to_crm");
export const adding_to_crm = (): Texts.PText => createText("adding_to_crm");
export const crm_error = (): Texts.PText => createText("crm_error");
export const select_variant = (): Texts.PText => createText("select_variant");

export const recommended_coil = (): Texts.PText => createText("recommended_coil");
export const low_capacity = (): Texts.PText => createText("low_capacity");
export const ok_capacity = (): Texts.PText => createText("ok_capacity");
export const high_capacity = (): Texts.PText => createText("high_capacity");
export const waterpressure_warning = (): Texts.PText => createText("waterpressure_warning");
export const show_diagrams = (): Texts.PText => createText("show_diagrams");
export const descending = (): Texts.PText => createText("Descending");
export const ascending = (): Texts.PText => createText("Ascending");
export const unitLabel = (unit: Unit.Unit<AnyQuantity>): Texts.PText =>
  createText(Serialize.unitToString(unit).toLowerCase());
export const high = (): Texts.PText => createText("high");
export const low = (): Texts.PText => createText("low");
export const ok = (): Texts.PText => createText("ok");
export const air_curtain_capacity = (): Texts.PText => createText("air_curtain_capacity");

export const project_name = (): Texts.PText => createText("project_name");
export const comments = (): Texts.PText => createText("comments");
export const no_data_available = (): Texts.PText => createText("no_data_available");
export const show_description = (): Texts.PText => createText("show_description");
export const hide_description = (): Texts.PText => createText("hide_description");

export const step1 = (): Texts.PText => createText("step1");
export const step2 = (): Texts.PText => createText("step2");
export const step3 = (): Texts.PText => createText("step3");
export const step4 = (): Texts.PText => createText("step4");
export const step5 = (): Texts.PText => createText("step5");
export const select_product = (): Texts.PText => createText("select_product");
export const input_data = (): Texts.PText => createText("input_data");
export const verify_result = (): Texts.PText => createText("verify_result");
export const browse_by_product = (): Texts.PText => createText("browse_by_product");
export const fill_input = (): Texts.PText => createText("fill_input");
export const make_print = (): Texts.PText => createText("make_print");

export const create_pdf = (): Texts.PText => createText("create_pdf");
export const print = (): Texts.PText => createText("print");

export const selected = (): Texts.PText => createText("selected");
export const select = (): Texts.PText => createText("select");

export const product_name_text = (): Texts.PText => createText("product_name_text");
export const product_no = (): Texts.PText => createText("product_no");
export const select_product_fr = (): Texts.PText => createText("select_product_fr");
export const add_product = (): Texts.PText => createText("add_product");

export const find_add_products = (): Texts.PText => createText("find_add_products");

export const define_products_quantity = (): Texts.PText => createText("define_products_quantity");
export const change_default_quantity = (): Texts.PText => createText("change_default_quantity");

export const execute_heating_calculation = (): Texts.PText => createText("execute_heating_calculation");
export const choose_between_products = (): Texts.PText => createText("choose_between_products");

export const define_accessories = (): Texts.PText => createText("define_accessories");
export const choose_between_control = (): Texts.PText => createText("choose_between_control");

export const add_project_description = (): Texts.PText => createText("add_project_description");
export const enter_project_name_comment = (): Texts.PText => createText("enter_project_name_comment");

export const accessory_name = (): Texts.PText => createText("accessory_name");
export const accessory_no = (): Texts.PText => createText("accessory_no");

export const add = (): Texts.PText => createText("add");
export const added = (): Texts.PText => createText("added");

export const qty = (): Texts.PText => createText("qty");
export const remove_product = (): Texts.PText => createText("remove_product");

export const product_finder = (): Texts.PText => createText("product_finder");
export const area = (): Texts.PText => createText("area");
export const group = (): Texts.PText => createText("group");
export const family = (): Texts.PText => createText("family");
export const enter_item_or_alias = (): Texts.PText => createText("enter_item_or_alias");
export const enter_item_or_alias_example = (): Texts.PText => createText("enter_item_or_alias_example");
export const no_product_found = (): Texts.PText => createText("no_product_found");

export const reset = (): Texts.PText => createText("reset");
export const create = (): Texts.PText => createText("create");
export const calculate = (): Texts.PText => createText("calculate");
export const apply = (): Texts.PText => createText("apply");

export const additional_units = (): Texts.PText => createText("additional_units");
export const both_sides = (): Texts.PText => createText("both_sides");
export const invalid_parameters = (): Texts.PText => createText("invalid_parameters");
export const save_calculation = (): Texts.PText => createText("save_calculation");
export const remove_all_rows = (): Texts.PText => createText("remove_all_rows");
export const specification_category = (category: number): Texts.PText => createText("spec_cat_" + category);
export const specification_category_description = (category: number): Texts.PText =>
  createText("spec_cat_desc_" + category);
export const back_to_specification_text = (): Texts.PText => createText("back_to_specification_text");
export const specification_text = (): Texts.PText => createText("specification_text");
export const project_comments = (): Texts.PText => createText("project_comments");
export const project_description = (): Texts.PText => createText("project_description");
export const product_text = (): Texts.PText => createText("product_text");
export const heating_calculation = (): Texts.PText => createText("heating_calculation");
export const heating_type_extra_text = (): Texts.PText => createText("heating_type_extra_text");
export const equivalent_absorptions_area = (): Texts.PText => createText("equivalent_absorptions_area");
export const sound_pressure_level_reverberant_field = (): Texts.PText =>
  createText("sound_pressure_level_reverberant_field");
export const diagram_operating_point_warning = (): Texts.PText => createText("diagram_operating_point_warning");

export const translatedResultTableValue = (val: string): Texts.PText => createText(val);
export const fricoCategoryLevel = (categoryKey: string): Texts.PText => createText(categoryKey);
export const name = (): Texts.PText => createText("name");
export const articleNo = (): Texts.PText => createText("articleNo");
export const capacity = (): Texts.PText => createText("capacity");
export const accessoryCodes = (): Texts.PText => createText("accessoryCodes");
export const messages = (): Texts.PText => createText("messages");
export const no_result_found = (): Texts.PText => createText("no_results_found");
export const found_products = (numResults: number): Texts.PText =>
  createText("found_products", { numResults: numResults.toString() });
export const calculating = (): Texts.PText => createText("calculating");
export const aborting = (): Texts.PText => createText("aborting");
export const distance = (): Texts.PText => createText("distance");
export const soundPressureLevelLpa = (): Texts.PText => createText("soundPressureLevelLpa");
export const directivity = (): Texts.PText => createText("directivity");
export const spherical_q1 = (): Texts.PText => createText("spherical_q1");

export const full = (): Texts.PText => createText("full");
export const compact = (): Texts.PText => createText("compact");

export const filterPrettyPrintPropertyName = (propertyName: string): Texts.PText =>
  createText(propertyName, undefined, QP.metaProductId);

export const save_for_comparison = (): Texts.PText => createText("save_for_comparison");
export const compare_with_saved_product = (): Texts.PText => createText("compare_with_saved_product");
export const back_to_selection = (): Texts.PText => createText("back_to_selection");
export const saved_for_comparison = (): Texts.PText => createText("saved_for_comparison");

export const ventilation_calculator_note = (): Texts.PText => createText("ventilation_calculator_note");
// ------ Products ------
export const product_name = (productId: string, variant?: PropertyValueSet.PropertyValueSet): Texts.PText =>
  createText("product_name", {}, productId, variant);
export const item_name = (productId: string, variant: PropertyValueSet.PropertyValueSet): Texts.PText =>
  createText("item_name", {}, productId, variant);
export const frico_accessory = (itemNo: string): Texts.PText =>
  createText("accessory_" + itemNo, {}, QP.fricoProductId);

export const frico_category = (id: string): Texts.PText => createText("ecom_name_" + id, {}, QP.fricoProductId);
export const expired = (): Texts.PText => createText("expired");
export const item_variant = (productId: string, variant: PropertyValueSet.PropertyValueSet): Texts.PText =>
  createText("item_variant", {}, productId, variant);

export const product_description = (productId: string): Texts.PText => createText("product_description", {}, productId);
export const property_group = (groupName: string): Texts.PText => createText(groupName);
export const property_name = (productId: string, propertyName: string): Texts.PPropertyNameText =>
  createPropertyNameText(productId, propertyName);
export const property_desc = (productId: string, propertyName: string): Texts.PPropertyDescriptionText =>
  createPropertyDescriptionText(productId, propertyName);
export const property_value = (
  productId: string,
  propertyName: string,
  propertyValue: number
): Texts.PPropertyValueText => createPropertyValueText(productId, propertyName, propertyValue);
// ------ Messages ------
export const message_code = (code: MessageCode, source: string, params: Texts.MessageParams): Texts.PMessageCodeText =>
  createMessageCodeText(code, source, params);
