/* eslint-disable functional/no-this-expression */
import * as React from "react";
import { FalIconName } from "client-lib/font-awesome-icons";
import { withTw } from "./with-tw";
import { StyledButton } from "./button";
import { Icon } from "./icon";

const Container = withTw("div", "relative");
const Background = withTw("div", "fixed top-0 left-0 w-full h-full");
const Options = withTw("div", "z-50 w-auto absolute top-36 flex flex-col text-sm dropdown-item-container pt-8 pb-8");
const ItemLink = withTw(
  "a",
  "font-normal whitespace-nowrap cursor-pointer text-sm pl-16 pr-16 pt-8 pb-8 hover:bg-brand-50 hover:text-primary-light"
);
const ItemText = withTw(
  "div",
  "font-normal whitespace-nowrap cursor-pointer text-sm pl-16 pr-16 pt-8 pb-8 hover:bg-brand-50 hover:text-primary-light"
);

export interface DropdownButtonProps {
  readonly className?: string;
  readonly leftIcon?: FalIconName;
  readonly rightIcon?: FalIconName;
  readonly label?: string;
  readonly items: ReadonlyArray<DropdownButtonItem>;
}

export interface DropdownButtonItem {
  readonly value: string;
  readonly label: string;
  readonly icon?: string;
  readonly url?: string;
  readonly onClick?: () => void;
}

interface State {
  readonly isOpen: boolean;
}

// eslint-disable-next-line functional/no-class
export class DropdownButton extends React.Component<DropdownButtonProps, State> {
  constructor(props: DropdownButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render(): JSX.Element {
    const { className, leftIcon, rightIcon, label, items }: DropdownButtonProps = this.props;

    const itemsList = this.state.isOpen ? (
      <Options className="w-full">
        {items.map((i) =>
          i.url ? (
            <ItemLink
              key={i.value}
              target="_blank"
              href={i.url}
              onClick={() => {
                this.setState({ isOpen: false });
                if (i.onClick) {
                  i.onClick();
                }
              }}
            >
              {i.label}
            </ItemLink>
          ) : (
            <ItemText
              key={i.value}
              onClick={() => {
                this.setState({ isOpen: false });
                if (i.onClick) {
                  i.onClick();
                }
              }}
            >
              {i.label}
            </ItemText>
          )
        )}
      </Options>
    ) : undefined;

    return (
      <Container className={className}>
        {this.state.isOpen ? <Background onClick={() => this.setState({ isOpen: false })} /> : undefined}
        <StyledButton
          compact={true}
          buttonType={"secondary"}
          onClick={(e) => {
            const newIsOpen = !this.state.isOpen;
            this.setState({ isOpen: newIsOpen });
            if (!newIsOpen) {
              e.currentTarget.blur();
            }
          }}
        >
          {leftIcon && <Icon icon={leftIcon} />}
          {label && <span className="whitespace-nowrap">{label}</span>}
          <Icon icon={rightIcon || "chevron-down"} />
        </StyledButton>
        {this.state.isOpen ? itemsList : undefined}
      </Container>
    );
  }
}
