import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { AnyQuantity } from "shared-lib/uom";
import * as R from "ramda";

export interface Selections {
  readonly [property: string]: PropertySelection;
}

export type PropertySelection = AmountSelection | TextSelection | DiscreteSelection;

export interface AmountSelection {
  readonly type: "Amount";
  readonly value: Amount.Amount<AnyQuantity>;
}

export interface TextSelection {
  readonly type: "Text";
  readonly value: string;
}

export interface DiscreteSelection {
  readonly type: "Discrete";
  readonly values: ReadonlyArray<number>;
}

export function getAmount(name: string, selections: Selections): Amount.Amount<AnyQuantity> | undefined {
  const value = selections[name];
  return value && value.type === "Amount" ? value.value : undefined;
}

export function setAmount(
  name: string,
  amount: Amount.Amount<AnyQuantity> | undefined,
  selections: Selections
): Selections {
  if (!amount) {
    return R.dissoc(name, selections);
  }
  return { ...selections, [name]: { type: "Amount", value: amount } };
}

export function getText(name: string, selections: Selections): string | undefined {
  const value = selections[name];
  return value && value.type === "Text" ? value.value : undefined;
}

export function setText(name: string, text: string, selections: Selections): Selections {
  return { ...selections, [name]: { type: "Text", value: text } };
}

export function getIntegers(name: string, selections: Selections): ReadonlyArray<number> | undefined {
  const value = selections[name];
  return value && value.type === "Discrete" ? value.values : undefined;
}

export function setIntegers(name: string, values: ReadonlyArray<number>, selections: Selections): Selections {
  return { ...selections, [name]: { type: "Discrete", values: values } };
}

export function selectionsFromVariant(variant: PropertyValueSet.PropertyValueSet): Selections {
  const selections: { [property: string]: PropertySelection } = {};
  for (const property of PropertyValueSet.getPropertyNames(variant)) {
    const value = PropertyValueSet.getValue(property, variant);
    if (value.type === "text") {
      selections[property] = {
        type: "Text",
        value: value.value,
      };
    } else if (value.type === "integer") {
      selections[property] = {
        type: "Discrete",
        values: [value.value],
      };
    } else if (value.type === "amount") {
      selections[property] = {
        type: "Amount",
        value: value.value as Amount.Amount<AnyQuantity>,
      };
    }
  }
  return selections;
}

export function getVariants(selections: Selections): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  let variants = [PropertyValueSet.Empty];
  for (const name of R.keys(selections)) {
    const value = selections[name];
    if (value.type === "Amount") {
      variants = variants.map((v) => PropertyValueSet.setAmount(name, value.value, v));
    } else if (value.type === "Text") {
      variants = variants.map((v) => PropertyValueSet.setText(name, value.value, v));
    } else {
      if (value.values.length === 0) {
        continue;
      }
      const newVariants = [];
      for (const v of value.values) {
        for (const variant of variants) {
          newVariants.push(PropertyValueSet.setInteger(name, v, variant));
        }
      }
      variants = newVariants;
    }
  }
  return variants;
}

export function isFilterValid(
  variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  filter: PropertyFilter.PropertyFilter
): boolean {
  return variants.some((v) => PropertyFilter.isValid(v, filter));
}
