import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import { GetDecimalsFunction, GetUnitFunction } from "shared-lib/user-settings";
import { createSelector } from "reselect";
import {
  TranslationTables,
  ProductPropertyTexts,
  ProductsWithPropertyTexts,
  propertyTextCombiner,
} from "./property-texts-selector";
import {
  TranslateFunction,
  PText,
  PLangText,
  PPropertyNameText,
  PPropertyDescriptionText,
  PPropertyValueText,
  PMessageCodeText,
} from "./types";
import { exhaustiveCheck } from "../exhaustive-check";
import { replaceCurlyProps } from "./replace-curly";
import { findTranslation } from "./find-translation";

export function getLanguage(locale: string, languageMapping: QP.LanguageMappingTable): string {
  const mapping = languageMapping.find((l) => l.iso_code === locale);
  return mapping ? mapping.language : locale;
}

const defaultLocale = "en"; //FIXME: It might be better if this is dynamically set from promaster
//       by getting the lowest language from GANYMED_META or so.

export const translateCombiner = (translationTables: TranslationTables, locale: string): TranslateFunction => {
  const productsWithPropertyTexts = propertyTextCombiner(translationTables) as ProductsWithPropertyTexts;
  const language = getLanguage(locale, translationTables[QP.metaProductId].ct_LanguageMapping);
  return (
    text: PLangText,
    defaultText?: string,
    getUnit?: GetUnitFunction,
    getDecimals?: GetDecimalsFunction
  ): string => {
    if (language === "keys") {
      switch (text.type) {
        case "text":
          return `{${text.key}}`;
        case "property_name":
          return `{property_name: ${text.property}}`;
        case "property_description":
          return `{property_description: ${text.property}}`;
        case "property_value":
          return `{property_value: ${text.property} ${text.value}}`;
        case "message_code":
          return `{message: ${text.code}}`;
        default:
          return exhaustiveCheck(text, true);
      }
    }
    const product = translationTables && translationTables[text.productId];
    const textTable = product && product.text;
    const product2 = productsWithPropertyTexts && productsWithPropertyTexts[text.productId];
    const propertyTextTable = product2 && product2.propertyTexts;
    switch (text.type) {
      case "text":
        return translateText(textTable, language, text, defaultText);
      case "property_name":
        return translatePropertyName(textTable, propertyTextTable, text, language, defaultText);
      case "property_description":
        return translatePropertyDescription(textTable, propertyTextTable, text, language, defaultText);
      case "property_value":
        return translatePropertyValue(textTable, propertyTextTable, text, language, defaultText);
      case "message_code":
        return translateMessageCode(textTable, language, text, getUnit, getDecimals);
      default:
        return exhaustiveCheck(text, true);
    }
  };
};

function translateText(textTable: QP.TextTable, locale: string, text: PText, defaultText?: string): string {
  const translation = findTranslation(textTable, locale, text.key, text.variant);
  if (translation) {
    const withParams = Object.keys(text.params).reduce((t, p) => t.replace(`{${p}}`, text.params[p]), translation);
    return withParams;
  } else if (defaultText !== undefined) {
    return defaultText;
  }
  const defaultTranslation = findTranslation(textTable, defaultLocale, text.key, text.variant);
  if (defaultTranslation) {
    const withParams = Object.keys(text.params).reduce(
      (t, p) => t.replace(`{${p}}`, text.params[p]),
      defaultTranslation
    );
    return withParams;
  } else {
    return `{${text.key}}`;
  }
}

function translatePropertyName(
  textTable: QP.TextTable,
  propertyTextTable: ProductPropertyTexts,
  text: PPropertyNameText,
  locale: string,
  defaultText?: string
): string {
  const propTexts = propertyTextTable && propertyTextTable[text.property];
  const standard = propTexts ? propTexts.standard[locale] : undefined;
  if (standard) {
    return standard;
  }
  const translation = findTranslation(textTable, locale, `p_standard_${text.property}`, PropertyValueSet.Empty);
  if (translation) {
    return translation;
  }
  if (defaultText) {
    return defaultText;
  }
  const defaultStandard = propTexts ? propTexts.standard[defaultLocale] : undefined;
  if (defaultStandard) {
    return defaultStandard;
  } else {
    return `{${text.property}}`;
  }
}

function translatePropertyDescription(
  textTable: QP.TextTable,
  propertyTextTable: ProductPropertyTexts,
  text: PPropertyDescriptionText,
  locale: string,
  defaultText?: string
): string {
  const propTexts2 = propertyTextTable && propertyTextTable[text.property];
  const long = propTexts2 ? propTexts2.long[locale] : undefined;
  if (long) {
    return long;
  }
  const translation = findTranslation(textTable, locale, `p_long_${text.property}`, PropertyValueSet.Empty);
  if (translation) {
    return translation;
  }

  if (defaultText) {
    return defaultText;
  }
  const defaultLong = propTexts2 ? propTexts2.long[defaultLocale] : undefined;
  if (defaultLong) {
    return defaultLong;
  } else {
    return "";
  }
}

function translatePropertyValue(
  textTable: QP.TextTable,
  propertyTextTable: ProductPropertyTexts,
  text: PPropertyValueText,
  locale: string,
  defaultText?: string
): string {
  const propTexts3 = propertyTextTable && propertyTextTable[text.property];
  const valTexts = propTexts3 ? propTexts3.values[text.value] : undefined;
  const value = valTexts ? valTexts.translation[locale] : undefined;
  if (value) {
    return value;
  }
  const translation = findTranslation(textTable, locale, `pv_${text.property}_${text.value}`, PropertyValueSet.Empty);
  if (translation) {
    return translation;
  }

  if (defaultText) {
    return defaultText;
  }
  const defaultValue = valTexts ? valTexts.translation[defaultLocale] : undefined;
  if (defaultValue) {
    return defaultValue;
  } else if (valTexts && valTexts.description) {
    return valTexts.description;
  } else {
    return `{${text.property}_${text.value}}`;
  }
}

function translateMessageCode(
  textTable: QP.TextTable,
  locale: string,
  text: PMessageCodeText,
  getUnit?: GetUnitFunction,
  getDecimals?: GetDecimalsFunction
): string {
  const key = "message_" + text.code;
  const translation = findTranslation(textTable, locale, key, PropertyValueSet.Empty);
  if (translation) {
    const withParams = replaceCurlyProps(translation, text.params, textTable, locale, getUnit, getDecimals);
    return withParams;
  }
  const defaultTranslation = findTranslation(textTable, defaultLocale, key, PropertyValueSet.Empty);
  if (defaultTranslation) {
    const withParams = replaceCurlyProps(defaultTranslation, text.params, textTable, locale, getUnit, getDecimals);
    return withParams;
  } else {
    return `{${key}}`;
  }
}

export type TranslateFunctionSelector = (tables: TranslationTables, language: string) => TranslateFunction;

// Select for the translator function
export const translateFunctionSelector: TranslateFunctionSelector = createSelector(
  (tables: TranslationTables) => tables,
  (_: TranslationTables, language: string) => language,
  translateCombiner
);
