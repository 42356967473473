import * as React from "react";
import { FalIconName } from "client-lib/font-awesome-icons";
import { withTw } from "./with-tw";
import { Icon } from "./icon";

interface ContainerProps {
  readonly type: AlertType;
}

const Container = withTw(
  "span",
  "inline-flex align-baseline items-center pt-4 pb-4 pl-16 pr-16 rounded-2",
  ({ type }: ContainerProps) => `${getColor(type)} ${getBackgroundColor(type)} `
);

function getColor(type: AlertType): string {
  switch (type) {
    case "neutral":
      return "text-info";
    case "data":
      return "text-info";
    case "info":
      return "text-info";
    case "warning":
      return "text-warning";
    case "error":
      return "text-danger";
    case "success":
      return "text-success";
    default:
      return "text-info";
  }
}

function getBackgroundColor(type: AlertType): string {
  switch (type) {
    case "neutral":
      return "bg-info-light";
    case "data":
      return "bg-info-light";
    case "info":
      return "bg-info-light";
    case "warning":
      return "bg-warning-light";
    case "error":
      return "bg-danger-light";
    case "success":
      return "bg-success-light";
    default:
      return "bg-info-light";
  }
}

export type AlertType = "neutral" | "data" | "info" | "warning" | "error" | "success";

export interface AlertProps {
  readonly type: AlertType;
  readonly horizontalAlign?: boolean;
  readonly close?: () => void;
  readonly children?: React.ReactNode;
  readonly className?: string;
}

export function Alert({
  type,
  children,
  close,
  horizontalAlign,
  className,
}: AlertProps): React.ReactElement<AlertProps> {
  const icon = getIcon(type);
  return (
    <div className={className} style={horizontalAlign ? { textAlign: "center" } : { paddingBottom: "8px" }}>
      <Container type={type}>
        {icon && <Icon className="mr-6" icon={icon} />}
        <div>{children}</div>
        {close ? (
          <div className="cursor-pointer ml-8" onClick={() => close()}>
            <Icon icon="xmark" />
          </div>
        ) : undefined}
      </Container>
    </div>
  );
}

function getIcon(type: AlertType): FalIconName | undefined {
  switch (type) {
    case "neutral":
      return undefined;
    case "data":
      return "table";
    case "info":
      return "circle-info";
    case "warning":
      return "triangle-exclamation";
    case "error":
      return "face-frown";
    case "success":
      return "circle-check";
    default:
      throw new Error("Unknown AlertType");
  }
}
