export interface ThemeColors {
  readonly base: string;
  readonly brand50: string;
  readonly brand600: string;
  readonly brand800: string;
  readonly brand900: string;
  readonly primaryLight: string;
  readonly primaryDefault: string;
  readonly primaryDark: string;
  readonly secondaryLight: string;
  readonly secondaryDefault: string;
  readonly secondaryDark: string;
  readonly hoverShadow: string;
  readonly activeShadow: string;
}

// Same colors as tailwind config in the Systemair style package
export const systemairColors: ThemeColors = {
  base: "#002543",
  brand50: "#f0f8ff",
  brand600: "#004985",
  brand800: "#003662",
  brand900: "#002543",
  primaryLight: "#037bc7",
  primaryDefault: "#004985",
  primaryDark: "#003662",
  secondaryLight: "#e5f6fb",
  secondaryDefault: "#00a7d8",
  secondaryDark: "#007fa5",
  hoverShadow: "#037bc74c",
  activeShadow: "#037bc733",
};

export const fricoColors: ThemeColors = {
  base: "#420007",
  brand50: "#fffdfe",
  brand600: "#8f000d",
  brand800: "#64000a",
  brand900: "#420007",
  primaryLight: "#ed1b2c", // Frico theme color
  primaryDefault: "#d3001b",
  primaryDark: "#b00002",
  secondaryLight: "#fffbfd",
  secondaryDefault: "#f70044",
  secondaryDark: "#c20033",
  hoverShadow: "#ed1b2c4c",
  activeShadow: "#ed1b2c33",
};

export function applyThemeColors(themeColors: ThemeColors = systemairColors): void {
  setColor("base", themeColors.base);
  setColor("brand-50", themeColors.brand50);
  setColor("brand-600", themeColors.brand600);
  setColor("brand-800", themeColors.brand800);
  setColor("brand-900", themeColors.brand900);
  setColor("primary-light", themeColors.primaryLight);
  setColor("primary-default", themeColors.primaryDefault);
  setColor("primary-dark", themeColors.primaryDark);
  setColor("secondary-light", themeColors.secondaryLight);
  setColor("secondary-default", themeColors.secondaryDefault);
  setColor("secondary-dark", themeColors.secondaryDark);
  setColor("hover-shadow", themeColors.hoverShadow);
  setColor("active-shadow", themeColors.activeShadow);
}

function setColor(field: string, color: string): void {
  document.documentElement.style.setProperty(`--theme-color-${field}`, color);
}
