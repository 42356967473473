import * as R from "ramda";
import * as React from "react";
import { DispatchProp } from "client-lib/redux-integration";
import { PropertiesSelector } from "client-lib/properties-selector";
import { Unit } from "uom";
import { PropertyValueSet, PropertyFilter, PropertyValue } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import * as Texts from "shared-lib/language-texts";
import * as UserSettingsClient from "client-lib/user-settings";
import * as UserSettingsShared from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as SC from "shared-lib/system-calculator";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import { CalculatePropertyFunction } from "client-lib/properties-selector/types";
import { AnyQuantity } from "shared-lib/uom";
import * as DiaqTemplates from "shared-lib/diaq-templates";
import { formatNumberFunction } from "shared-lib/utils";

type Props = OwnProps & StateProps & Response & DispatchProp<UserSettingsClient.Action>;

export interface OwnProps {
  readonly market: string;
  readonly language: string;
  readonly translate: Texts.TranslateFunction;
  readonly productId: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly calcParams: PropertyValueSet.PropertyValueSet;
  readonly calcParamsChanged: PropertyValuesChanged;
  readonly attributes: Attributes.Attributes;
  readonly hideInvalidValues: boolean;
  readonly hideErrors: boolean;
  readonly ParentCalcParamDefinitions?: ReadonlyArray<SC.InputParam>;
  readonly hiddenParams?: ReadonlyArray<string>;
  readonly extraGroupTitle?: string;
  readonly singleGroup?: boolean;
}

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
  readonly showAdvanced: boolean;
}

export interface Response {
  readonly productTables: ProductTables;
  readonly ct_ResultItems: QP.ResultItemsTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_AttributeTemplateMapping: QP.AttributeTemplateMappingTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

export interface ProductTables {
  readonly ct_DiaqTemplates: QP.DiaqTemplatesTable;
  readonly ct_ClosedGroups: QP.ClosedGroups;
}

export type PropertyValuesChanged = (propertyValueSet: PropertyValueSet.PropertyValueSet) => void;

export function CalcParamsSelectorContainerComponent({
  market,
  language,
  productId,
  productTables,
  variant,
  calcParams,
  calcParamsChanged,
  attributes,
  dispatch,
  showAdvanced,
  translate,
  userSettings,
  ct_ResultItems,
  ct_MarketUnits,
  ct_LanguageMapping,
  hideInvalidValues,
  hideErrors,
  ct_AttributeTemplateMapping,
  hiddenParams,
  extraGroupTitle,
  singleGroup,
}: Props): React.ReactElement<Props> | null {
  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getUnits = UserSettingsShared.getUnits({
    market,
    ct_MarketUnits,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const closedGroups = UserSettingsShared.getClosedGroups(productTables.ct_ClosedGroups, userSettings);

  const template = DiaqTemplates.getTemplatesFromAttributes(
    variant,
    productTables.ct_DiaqTemplates,
    ct_AttributeTemplateMapping,
    attributes
  ).find((t) => t.type === "ResultItems");
  const resultItems = ct_ResultItems.filter((i) => template && i.template === template.template);
  const params = SC.getCalcParams(resultItems, attributes, variant, calcParams).filter(
    (p) => !hiddenParams || !hiddenParams.includes(p.name)
  );

  if (params.length === 0) {
    return null;
  }

  const singleGroupName = singleGroup ? "calculationParams" : undefined;

  const calcParamProperties: ReadonlyArray<PromasterPropertiesSelector.Property> = params.map((p) => {
    if (p.type === "Amount") {
      return {
        selector_type: p.selectorType as PromasterPropertiesSelector.PropertySelectorType,
        sort_no: -(params.length - params.indexOf(p)),
        name: p.name,
        group: singleGroupName ?? p.group,
        quantity: p.quantity,
        validation_filter: hideErrors ? PropertyFilter.Empty : p.validationFilter || PropertyFilter.Empty,
        visibility_filter: p.visibilityFilter || PropertyFilter.Empty,
        value: p.defaultValue
          ? [
              {
                sort_no: -(params.length - params.indexOf(p)),
                value: PropertyValue.fromAmount(p.defaultValue),
                property_filter: PropertyFilter.Empty,
              },
            ]
          : [],
        field_name: p.fieldName,
      };
    } else {
      return {
        selector_type: p.selectorType as PromasterPropertiesSelector.PropertySelectorType,
        sort_no: -(params.length - params.indexOf(p)),
        name: p.name,
        group: singleGroupName ?? p.group,
        quantity: "Discrete",
        validation_filter: hideErrors ? PropertyFilter.Empty : p.validationFilter || PropertyFilter.Empty,
        visibility_filter: p.visibilityFilter || PropertyFilter.Empty,
        value: p.values.map((v) => ({
          sort_no: v.value,
          value: PropertyValue.fromInteger(v.value),
          property_filter: PropertyFilter.Empty,
        })),
      };
    }
  });

  const propertyCalculateFunctions = R.fromPairs(
    params
      .filter((d) => d.calculate !== undefined)
      .map((d) => {
        return [d.name, d.calculate] as R.KeyValuePair<string, CalculatePropertyFunction>;
      })
  );

  const calcParamFormats = R.fromPairs(
    params
      .filter((d) => d.type === "Amount")
      .map((d) => {
        if (d.type !== "Amount") {
          throw new Error("Not an amount");
        }
        const unit = getUnit(d.fieldName, d.quantity);
        const decimalCount = getDecimals(d.fieldName, unit);
        return [d.name, { unit, decimalCount }] as R.KeyValuePair<string, PromasterPropertiesSelector.AmountFormat>;
      })
  );

  const propertySelectorTypes = params
    .filter((d) => d.width !== undefined || d.selectorType === "Checkbox")
    .map((d) => ({
      name: d.name,
      type: d.selectorType || "AmountField",
      width: d.width || 49,
    })) as QP.PropertySelectorTypesTable;

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  return (
    <PropertiesSelector
      productId={productId}
      propertyValueSet={calcParams}
      productProperties={calcParamProperties}
      onChanged={calcParamsChanged}
      onUnitChanged={(p: string, u: Unit.Unit<AnyQuantity>) => {
        const def = params.find((d) => d.name === p);
        dispatch(UserSettingsClient.setFieldUnit((def && def.type === "Amount" && def.fieldName) || p, u));
      }}
      onUnitCleared={(p: string) => {
        const def = params.find((d) => d.name === p);
        dispatch(UserSettingsClient.clearFieldUnit((def && def.type === "Amount" && def.fieldName) || p));
      }}
      translatePropertyName={(propertyName: string) => translate(Texts.createText(propertyName))}
      translatePropertyValue={(propertyName: string, propertyValue: number) =>
        translate(Texts.createText(propertyName + "_" + propertyValue.toString()))
      }
      formatNumber={formatNumber}
      translate={translate}
      propertySelectorTypes={propertySelectorTypes}
      hideInvalidValues={hideInvalidValues}
      hideEmptyUnitSelectors={true}
      getUnits={getUnits}
      propertyFormats={calcParamFormats}
      propertyCalculateFunctions={propertyCalculateFunctions}
      showAdvanced={showAdvanced}
      hideProperties={[]}
      images={{}}
      closedGroups={closedGroups}
      onToggleGroupClosed={(g: string) => dispatch(UserSettingsClient.setGroupClosed(g, !closedGroups[g]))}
      extraGroupTitle={extraGroupTitle}
    />
  );
}
