/* eslint-disable no-case-declarations */
import { PropertyFilterAst as Ast, PropertyValue } from "@promaster-sdk/property";
import { Amount, UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import * as PropertyFiltering from "@promaster-sdk/property-filter-pretty";
import * as Texts from "shared-lib/language-texts";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import * as UserSettings from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import { AnyQuantity } from "shared-lib/uom";

export function createFilterPrettyPrintMessages(
  productId: string,
  translate: Texts.TranslateFunction,
  fieldNames: QP.PropertyFieldNamesTable,
  getUnit: UserSettings.GetUnitFunction,
  getDecimals: UserSettings.GetDecimalsFunction
): PropertyFiltering.FilterPrettyPrintMessages {
  return {
    comparisionOperationMessage: (op: Ast.ComparisonOperationType, left: string, right: string): string =>
      translate(comparisionText(op, left, right)),
    equalsOperationMessage: (op: Ast.EqualsOperationType, left: string, right: string): string =>
      translate(equalsText(op, left, right)),
    rangeMessage: (min: string, max: string) => translate(Texts.between_min_and_max(min, max)),
    andMessage: (): string => translate(Texts.and()),
    orMessage: (): string => translate(Texts.or()),
    propertyNameMessage: (propertyName: string): string =>
      translate(
        Texts.property_name(productId, propertyName),
        translate(Texts.filterPrettyPrintPropertyName(propertyName))
      ),
    propertyValueMessage: (propertyName: string, propertyValue: PropertyValue.PropertyValue): string => {
      switch (propertyValue.type) {
        case "amount":
          const fieldName = fieldNames.find((n) => n.property === propertyName) || { field_name: propertyName };
          const unit = getUnit(fieldName.field_name, propertyValue.value.unit.quantity as AnyQuantity);
          const decimals = getDecimals(fieldName.field_name, unit);
          const unitLabel = translate(Texts.unitLabel(unit), UnitFormat.getUnitFormat(unit, UnitsFormat)?.label);
          const value = Amount.valueAs(unit, propertyValue.value as Amount.Amount<AnyQuantity>);
          return value.toFixed(decimals) + " " + unitLabel;
        case "integer":
          return translate(
            Texts.property_value(productId, propertyName, PropertyValue.getInteger(propertyValue) || 999999)
          );
        case "text":
          return propertyValue.value;
        default:
          return exhaustiveCheck(propertyValue);
      }
    },
    nullMessage: (): string => "null",
  };
}

function comparisionText(type: Ast.ComparisonOperationType, left: string, right: string): Texts.PText {
  switch (type) {
    case "lessOrEqual":
      return Texts.left_must_be_less_than_or_equal_to_right(left, right);
    case "greaterOrEqual":
      return Texts.left_must_be_greater_than_or_equal_to_right(left, right);
    case "less":
      return Texts.left_must_be_less_than_right(left, right);
    case "greater":
      return Texts.left_must_be_greater_than_right(left, right);
    default:
      throw new Error("Unknown ComparisonOperationType");
  }
}

function equalsText(type: Ast.EqualsOperationType, left: string, right: string): Texts.PText {
  switch (type) {
    case "equals":
      return Texts.left_must_be_right(left, right);
    case "notEquals":
      return Texts.left_must_not_be_right(left, right);
    default:
      throw new Error(`Unknown EqualsOperationType ${type}.`);
  }
}
