import * as Shared from "shared-lib/user-settings";
import { createSelector } from "reselect";
import { RootState } from "../../types";

export const stateSelector = createSelector(
  (s: RootState) => s.settings.user,
  (s) => s
);

export const showAdvancedSelector = createSelector((s: RootState) => s.settings.user, Shared.getShowAdvanced);

export const showDiagramsSelector = createSelector((s: RootState) => s.settings.user, Shared.getShowDiagrams);

export const showBoxFanRpmSelector = createSelector((s: RootState) => s.settings.user, Shared.getShowBoxFanRpm);

export const showBoxFanEfficiencySelector = createSelector(
  (s: RootState) => s.settings.user,
  Shared.getShowBoxFanEfficiency
);

export const showBoxFanMotorPowerSelector = createSelector(
  (s: RootState) => s.settings.user,
  Shared.getShowBoxFanMotorPower
);

// export const showStandardFanAlternativesSelector = createSelector(
//   (s: RootState) => s.settings.user,
//   Shared.getShowStandardFanAlternatives
// );
