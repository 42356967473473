/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/* eslint-disable @typescript-eslint/no-explicit-any */

const cacheMaxAxe = 1000 * 60 * 60 * 24; // in ms, 1000*60*60*24 = 24 hours

type DateCachedObject = {
  readonly expiryDate: number; // Unix timestamp
  readonly data: any;
};

export function memoize<F extends (...args: Array<any>) => any>(func: F): F {
  if (typeof func !== "function") {
    throw new TypeError("Can only memoize functions");
  }

  const string = func.toString().trim();
  const isAsync = !!string.match(/__awaiter/);

  const cache: Map<any, DateCachedObject> = new Map();

  if (!isAsync) {
    return function (...args: ReadonlyArray<unknown>) {
      const key = JSON.stringify(args);

      const cachedData = cache.get(key);
      if (cachedData) {
        if (cachedData.expiryDate > Date.now()) {
          return cachedData.data;
        } else {
          cache.delete(key);
        }
      }

      const result = func(...args);
      cache.set(key, createDateCachedObject(result));
      return result;
    } as F;
  }

  const promises: Map<any, any> = new Map();
  return async function (...args: ReadonlyArray<unknown>) {
    const key = JSON.stringify(args);

    const cachedData = cache.get(key);
    if (cachedData) {
      if (cachedData.expiryDate > Date.now()) {
        console.log(cachedData.data);
        return cachedData.data;
      } else {
        cache.delete(key);
      }
    }

    if (promises.has(key)) {
      return promises.get(key);
    }
    const promise = func(...args);
    promises.set(key, promise);

    try {
      const resultUncached = await promise;

      cache.set(key, createDateCachedObject(resultUncached));
      promises.delete(key);
      return resultUncached;
    } catch (e) {
      promises.delete(key);
      throw new Error(e);
    }
  } as F;
}

function createDateCachedObject(data: any): DateCachedObject {
  return { expiryDate: Date.now() + cacheMaxAxe, data };
}
