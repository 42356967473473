import * as React from "react";
import * as UserSettings from "shared-lib/user-settings";
import { clientConfig } from "config";
import { formatNumberFunction } from "shared-lib/utils";
import { ResultTable } from "./components/result-table";
import { Props } from "./types";

export function SearchResultTableContainerComponent(props: Props): React.ReactElement<Props> {
  const {
    calculationState,
    translate,
    state,
    dispatch,
    language,
    market,
    ct_MarketUnits,
    ct_DivisionCurrency,
    userSettings,
    navigateToItemNo,
    crm,
    showDiagrams,
    ct_MarketHiddenFields,
    ct_MarketPriceList,
    ct_LanguageMapping,
    searchProductId,
    shopUrl,
    isFricoWebsite,
    crmState,
    specificationDataUrl,
    searchViews,
    searchColumns,
    sortProps,
    crmSavedResults,
  } = props;
  if (calculationState.type === "Calculating") {
    return <span />;
  }

  const getUnit = UserSettings.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getUnits = UserSettings.getUnits({
    market,
    ct_MarketUnits,
  });
  const getDecimals = UserSettings.getDecimals({
    market,
    ct_MarketUnits,
  });
  const getExtraText = UserSettings.getExtraText({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const hiddenFields = ct_MarketHiddenFields.filter((r) => r.market === market).map((r) => r.field_name);
  const currencyRow = ct_DivisionCurrency.find((r) => r.division_number === market);
  const currency = (currencyRow && currencyRow.currency) || "";
  const showPrice =
    (ct_MarketPriceList && ct_MarketPriceList.some((r) => r.market === market && r.display_price === "1")) ||
    crm !== undefined ||
    false;
  return (
    <div className={clientConfig.addOuterPadding ? "px-40" : ""}>
      <ResultTable
        language={language}
        searchProductId={searchProductId}
        state={state}
        calculationState={calculationState}
        hiddenFields={hiddenFields}
        translate={translate}
        showDiagrams={showDiagrams}
        searchViews={searchViews}
        searchColumns={searchColumns}
        dispatch={dispatch}
        getUnit={getUnit}
        specificationDataUrl={specificationDataUrl}
        getUnits={getUnits}
        currency={currency}
        crm={crm}
        showPrice={showPrice}
        getDecimals={getDecimals}
        getExtraText={getExtraText}
        formatNumber={formatNumber}
        navigateToItemNo={navigateToItemNo}
        shopUrl={shopUrl}
        isFricoWebsite={isFricoWebsite}
        market={market}
        userSettings={userSettings}
        crmState={crmState}
        marketUnits={ct_MarketUnits}
        sortProps={sortProps}
        crmSavedResults={crmSavedResults}
      />
    </div>
  );
}
