import * as R from "ramda";
import { State, Value, GroupValues, setFieldUnit } from "shared-lib/user-settings";
import { Action } from "./actions";

const initialState: State = {
  general: {
    showAdvanced: false,
    showDiagrams: true,
  },
  boxFan: {
    showRpm: true,
    showStandardFanAlternatives: false,
  },
  fieldFormats: {},
};

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "user-settings/RESET":
      return initialState;

    case "user-settings/SET_SHOW_ADVANCED":
      return setValue("general", "showAdvanced", action.payload, state);

    case "user-settings/SET_SOUND_ABSORPTION_AREA":
      return setValue("general", "soundAbsorptionArea", action.payload, state);

    case "user-settings/SET_SHOW_DIAGRAMS":
      return setValue("general", "showDiagrams", action.payload, state);

    case "user-settings/SET_SOUND_FILTER":
      return setValue("general", "soundFilter", action.payload, state);

    case "user-settings/SET_OCTAVE_BANDS_TYPE":
      return setValue("general", "octaveBandsType", action.payload, state);

    case "user-settings/SET_GROUP_CLOSED":
      return setValue("closedGroups", action.payload.group, action.payload.closed, state);

    case "user-settings/SET_FIELD_UNIT":
      return setFieldUnit(state, action.payload.fieldName, action.payload.unit);

    case "user-settings/CLEAR_FIELD_UNIT":
      return deleteValue("fieldUnits", action.payload, state);

    case "user-settings/SET_FIELD_UNIT_CONFIG":
      return setValues("fieldUnits", action.payload.config, state);

    case "user-settings/SET_SHOW_BOXFAN_RPM":
      return setValue("boxFan", "showBoxFanRpm", action.payload, state);

    case "user-settings/SET_SHOW_BOXFAN_EFFICIENCY":
      return setValue("boxFan", "showBoxFanEfficiency", action.payload, state);

    case "user-settings/SET_SHOW_BOXFAN_MOTOR_POWER":
      return setValue("boxFan", "showBoxFanMotorPower", action.payload, state);

    case "user-settings/SET_SHOW_STANDARD_FAN_ALTERNATIVES":
      return setValue("boxFan", "showStandardFanAlternatives", action.payload, state);

    case "user-settings/SET_SOUND_DISTANCE":
      return setValue("general", "soundDistance", action.payload, state);

    default:
      return state;
  }
}

// function setGroup(group: string, values: GroupValues, state: State): State {
//   return R.assoc(group, values, state);
// }

function setValues(group: string, values: GroupValues, state: State): State {
  const oldGroup = state[group] || {};
  const newGroup = R.merge(oldGroup, values);
  const result = R.assoc(group, newGroup, state);
  return result;
}

function setValue(group: string, key: string, value: Value, state: State): State {
  const oldGroup = state[group] || {};
  const newGroup = R.assoc(key, value, oldGroup);
  return R.assoc(group, newGroup, state);
}

function deleteValue(group: string, key: string, state: State): State {
  const oldGroup = state[group] || {};
  const newGroup = R.dissoc(key, oldGroup);
  return R.assoc(group, newGroup, state);
}
