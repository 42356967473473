import { createSelector, Selector } from "reselect";
import { diaqConnect } from "client-lib/redux-integration";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import * as UserSettings from "client-lib/user-settings";
import { ProgressSearchButtonContainerComponent } from "./container-component";
import { OwnProps, StateProps, Response } from "./types";
import { RootState } from "../../types";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.search,
  (s: RootState) => s.ui.searchProducts.descending,
  (s: RootState) => s.ui.searchProducts.sortPath,
  (s: RootState) => s.ui.searchProducts.sortType,
  (s: RootState) => s.ui.searchProducts.groupSortParams,
  UserSettings.showAdvancedSelector,
  UserSettings.showDiagramsSelector,
  (searchState, descending, sortPath, sortType, groupSortParams, showAdvanced, showDiagrams) => ({
    searchState: searchState,
    showAdvanced: showAdvanced,
    showDiagrams: showDiagrams,
    sortPath: sortPath,
    descending: descending,
    sortType: sortType,
    groupSortParams: groupSortParams,
  })
);

function mapPropsToQuery(ownProps: OwnProps): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    source: QP.dataSource(),
    property: QP.tableByProductId(ownProps.searchProductId, "property"),
    ct_ResultItems: QP.tableByProductId(QP.metaProductId, "ct_ResultItems"),
    ct_SearchAttributes: QP.tableByProductId(ownProps.searchProductId, "ct_SearchAttributes"),
    ct_SearchProducts: QP.tableByProductId(ownProps.searchProductId, "ct_SearchProducts"),
    ct_DiaqTemplates: QP.tableByProductId(ownProps.searchProductId, "ct_DiaqTemplates"),
  });
}

export const ProgressSearchButtonContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(ProgressSearchButtonContainerComponent);
