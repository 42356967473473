import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount, Serialize, UnitFormat } from "uom";
import { UnitsFormat } from "uom-units";
import { GetDecimalsFunction, GetUnitFunction } from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import { MessageParams } from "./types";
import { findTranslation } from "./find-translation";

/**
 *
 * {propertyName}
 * {propertyName:Unit}
 * {propertyName:Unit:DecimalCount}
 */
export function replaceCurlyProps(
  text: string,
  params: MessageParams,
  textTable: QP.TextTable,
  locale: string,
  getUnit?: GetUnitFunction,
  getDecimals?: GetDecimalsFunction
): string {
  return text.replace(/({.+?})/g, (curly) => {
    const paramName = curly.substring(1, curly.length - 1);
    const param = params[paramName];

    if (!param) {
      return curly;
    } else if (param.type === "amount") {
      const amount = param.value;
      const unit = getUnit ? getUnit(param.fieldName, amount.unit.quantity) : amount.unit;
      const decimals = getDecimals ? getDecimals(param.fieldName, unit) : amount.decimalCount;
      const value = Amount.valueAs(unit, amount).toFixed(decimals);
      const unitNameKey = Serialize.unitToString(unit).toLowerCase();
      const unitName =
        findTranslation(textTable, locale, unitNameKey, PropertyValueSet.Empty) ||
        UnitFormat.getUnitFormat(unit, UnitsFormat)?.label;
      return `${value} ${unitName}`;
    } else if (param.type === "field") {
      const translatedFieldName =
        findTranslation(textTable, locale, param.fieldName, PropertyValueSet.Empty) || `${param.fieldName}`;
      return translatedFieldName;
    } else if (param.type === "text") {
      return param.value;
    }
    return curly;
  });
}
