/* eslint-disable prefer-object-spread */
/**
 * Adds a flag to the query that the response can be returned directly to the caller without waiting
 * for the response to arrive from the server. Useful for example in the react wrapper component
 * so that the wrapped child component can recieve a response before it is fully loaded.
 * @param query The query to set the flag for.
 */
import { AnyQuery } from "../core";
import { AllowUndefined } from "./types";

export function allowUndefined<TAtomicQuery extends AnyQuery>(
  query: TAtomicQuery | undefined
): (TAtomicQuery & AllowUndefined) | undefined {
  if (!query) {
    return undefined;
  }
  return Object.assign({}, query, { ___reduxQueryAllowUndefined: true });
}
