import { take, select } from "redux-saga/effects";
import { RootState } from "types";
import * as C from "shared-lib/calculation";
import * as Postmessage from "./postmessage-api";

// Used to post a message only when an actual change has occured

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* saga() {
  while (true) {
    yield take([
      "product/SET_CALC_PARAMS",
      "product/UPDATE_ACCESSORY_CALC_PARAMS",
      "product/ADD_ACCESSORY",
      "product/REMOVE_ACCESSORY",
      "product/CHANGE_ACCESSORY",
      "product/UPDATE_ACCESSORY",
      "user-settings/SET_FIELD_UNIT",
      "user-settings/CLEAR_FIELD_UNIT",
      "user-settings/SET_SOUND_FILTER",
      "user-settings/SET_SOUND_DISTANCE",
    ]) as unknown;

    const rootState: RootState = yield select();

    const configWithMeta = {
      ...rootState.ui.product.config,
      meta: rootState.settings.user,
    };

    Postmessage.postInputChangedEvent(C.serializeConfig(configWithMeta));
  }
}
