import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as ProductCodes from "shared-lib/product-codes";
import * as Attributes from "../shared/attributes";
import * as Messages from "../messages";
import { Input, ParamsToUpdate } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  ResultQuery,
} from "../types";

const msgSource = "DbmWaterCoilInputMapper";

export function getCalcParams(
  params: string,
  _attributes: Attributes.Attributes,
  _variant: PropertyValueSet.PropertyValueSet,
  calcParams: PropertyValueSet.PropertyValueSet
): ReadonlyArray<InputParam> {
  const isAccessory = PropertyValueSet.getInteger("is_accessory", calcParams) === 1;
  const {
    calculationParamsGroup,
    inletAirHumidity,
    inletAirTemperature,
    inletWaterTemperature,
    calculationMethodGroup,
    calcMethodWaterCoil,
    outletWaterTemperature,
    waterFlow,
    outletAirTemperature,
  } = getParamNames(params, isAccessory);
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "airFlow=0.1:CubicMeterPerHour~10000:CubicMeterPerHour",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: calculationParamsGroup,
      name: inletAirHumidity,
      quantity: "RelativeHumidity",
      defaultValue: Amount.create(params === "heating" ? 90 : 60, Units.PercentHumidity),
      fieldName: "airHumidity",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `${inletAirHumidity}=0:PercentHumidity~100:PercentHumidity`,
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: calculationParamsGroup,
      name: inletAirTemperature,
      quantity: "Temperature",
      defaultValue: Amount.create(params === "heating" ? -20 : 27, Units.Celsius),
      fieldName: "airTemperature",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `${inletAirTemperature}=-60:Celsius~50:Celsius`,
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: calculationParamsGroup,
      name: inletWaterTemperature,
      quantity: "Temperature",
      fieldName: "waterTemperature",
      defaultValue: Amount.create(params === "heating" ? 80 : 7, Units.Celsius),
      validationFilter:
        params === "heating"
          ? PropertyFilter.fromStringOrEmpty(
              `${inletWaterTemperature}=1:Celsius~130:Celsius&${inletWaterTemperature}>${inletAirTemperature}`,
              CustomUnitsLookup
            )
          : PropertyFilter.fromStringOrEmpty(
              `${inletWaterTemperature}=1:Celsius~130:Celsius&${inletWaterTemperature}<${inletAirTemperature}`,
              CustomUnitsLookup
            ),
    },
    {
      type: "Discrete",
      group: calculationMethodGroup,
      name: calcMethodWaterCoil,
      values: [
        {
          value: 0,
          name: "outletWaterTemperature",
        },
        {
          value: 1,
          name: "waterFlow",
        },
        {
          value: 2,
          name: "outletAirTemperature",
        },
      ],
    },
    {
      type: "Amount",
      group: calculationMethodGroup,
      name: outletWaterTemperature,
      quantity: "Temperature",
      fieldName: "waterTemperature",
      defaultValue: Amount.create(params === "heating" ? 60 : 12, Units.Celsius),
      visibilityFilter: PropertyFilter.fromStringOrEmpty(`${calcMethodWaterCoil}=0`, CustomUnitsLookup),
      validationFilter:
        params === "heating"
          ? PropertyFilter.fromStringOrEmpty(
              `${outletWaterTemperature}=1:Celsius~90:Celsius&${outletWaterTemperature}<${inletWaterTemperature}&${outletWaterTemperature}>${inletAirTemperature}`,
              CustomUnitsLookup
            )
          : PropertyFilter.fromStringOrEmpty(
              `${outletWaterTemperature}=1:Celsius~130:Celsius&${outletWaterTemperature}>${inletWaterTemperature}&${outletWaterTemperature}<${inletAirTemperature}`,
              CustomUnitsLookup
            ),
    },
    {
      type: "Amount",
      group: calculationMethodGroup,
      name: waterFlow,
      quantity: "VolumeFlow",
      fieldName: "waterFlow",
      visibilityFilter: PropertyFilter.fromStringOrEmpty(`${calcMethodWaterCoil}=1`, CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        `${waterFlow}=0.001:CubicMeterPerHour~10000:CubicMeterPerHour`,
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: calculationMethodGroup,
      name: outletAirTemperature,
      quantity: "Temperature",
      fieldName: "airTemperature",
      visibilityFilter: PropertyFilter.fromStringOrEmpty(`${calcMethodWaterCoil}=2`, CustomUnitsLookup),
      validationFilter:
        params === "heating"
          ? PropertyFilter.fromStringOrEmpty(
              `${outletAirTemperature}=-14:Celsius~50:Celsius&${outletAirTemperature}<${inletWaterTemperature}`,
              CustomUnitsLookup
            )
          : PropertyFilter.fromStringOrEmpty(
              `${outletAirTemperature}=-14:Celsius~50:Celsius&${outletAirTemperature}>${inletWaterTemperature}`,
              CustomUnitsLookup
            ),
    },
  ];
}

export function getQuery(productId: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    property: QP.tableByProductId(productId, "property"),
    code: QP.tableByProductId(productId, "code"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_WaterCoilLimits: QP.tableByProductId(productId, "ct_WaterCoilLimits"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
  });
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_WaterCoilLimits: QP.WaterCoilLimitsTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  response: Response,
  _resultQuery: unknown,
  calculatorParams: string
): InputMapperSuccess<Input> | InputMapperError {
  const isAccessory = PropertyValueSet.getInteger("is_accessory", calcParams) === 1;
  const isAccessorySearch = PropertyValueSet.getInteger("is_accessory_search", calcParams) === 1;
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  if (!airFlow) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, "airFlow")]);
  }

  const waterCoilLimits = response.ct_WaterCoilLimits.find((r) =>
    PropertyFilter.isValid(properties, r.property_filter)
  );

  const codes = ProductCodes.getProductCodes(response, properties);

  if (!waterCoilLimits) {
    console.warn({
      waterCoilLimits,
    });
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  const p = getParamNames(calculatorParams, isAccessory);
  if (isAccessorySearch) {
    const inletAirTemperature = Amount.create(calculatorParams === "heating" ? -20 : 27, Units.Celsius);
    const inletAirHumidity = Amount.create(calculatorParams === "heating" ? 90 : 60, Units.PercentHumidity);
    const inletWaterTemperature = Amount.create(calculatorParams === "heating" ? 80 : 7, Units.Celsius);
    const calculationMethod = 0;
    const outletWaterTemperature = Amount.create(calculatorParams === "heating" ? 60 : 12, Units.Celsius);

    return createInputMapperSuccess({
      airFlow: airFlow,
      inletAirTemperature,
      inletAirHumidity,
      inletWaterTemperature,
      calculationMethod,
      outletWaterTemperature,
      waterFlow: undefined,
      outletAirTemperature: undefined,
      attributes: attributes,
      codes: codes,
      waterCoilLimits: waterCoilLimits,
      paramsToUpdate: {
        waterFlow: false,
        waterFlowParam: p.waterFlow,
        outletWaterTemperature: true,
        outletWaterTemperatureParam: p.outletWaterTemperature,
        outletAirTemperature: PropertyValueSet.getAmount(p.outletAirTemperature, calcParams) === undefined,
        outletAirTemperatureParam: p.outletAirTemperature,

        inletAirTemperature: true,
        inletAirTemperatureParam: p.inletAirTemperature,
        inletAirHumidity: true,
        inletAirHumidityParam: p.inletAirHumidity,
        inletWaterTemperature: true,
        inletWaterTemperatureParam: p.inletWaterTemperature,
        calculationMethod: true,
        calculationMethodParam: p.calcMethodWaterCoil,
      },
    });
  }

  const inletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.inletAirTemperature, calcParams);
  const inletAirHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>(p.inletAirHumidity, calcParams);
  const inletWaterTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.inletWaterTemperature, calcParams);
  const calculationMethod = PropertyValueSet.getInteger(p.calcMethodWaterCoil, calcParams) || 0;
  const outletWaterTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.outletWaterTemperature, calcParams);
  const waterFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>(p.waterFlow, calcParams);
  const outletAirTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(p.outletAirTemperature, calcParams);

  if (!inletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.inletAirTemperature)]);
  }
  if (!inletAirHumidity) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.inletAirHumidity)]);
  }
  if (!inletWaterTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.inletWaterTemperature)]);
  }

  if (calculationMethod === 0 && !outletWaterTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.outletWaterTemperature)]);
  }
  if (calculationMethod === 1 && !waterFlow) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.waterFlow)]);
  }
  if (calculationMethod === 2 && !outletAirTemperature) {
    return createInputMapperError([Messages.Error_MissingInput(msgSource, p.outletAirTemperature)]);
  }

  const paramsToUpdate: ParamsToUpdate = {
    waterFlow: PropertyValueSet.getAmount(p.waterFlow, calcParams) === undefined,
    waterFlowParam: p.waterFlow,
    outletWaterTemperature: PropertyValueSet.getAmount(p.outletWaterTemperature, calcParams) === undefined,
    outletWaterTemperatureParam: p.outletWaterTemperature,
    outletAirTemperature: PropertyValueSet.getAmount(p.outletAirTemperature, calcParams) === undefined,
    outletAirTemperatureParam: p.outletAirTemperature,
  };

  return createInputMapperSuccess({
    airFlow: airFlow,
    inletAirTemperature: inletAirTemperature,
    inletAirHumidity: inletAirHumidity,
    inletWaterTemperature: inletWaterTemperature,
    calculationMethod: calculationMethod,
    outletWaterTemperature: outletWaterTemperature,
    waterFlow: waterFlow,
    outletAirTemperature: outletAirTemperature,
    attributes: attributes,
    codes: codes,
    waterCoilLimits: waterCoilLimits,
    paramsToUpdate,
  });
}

function getParamNames(
  params: string,
  isAccessory: boolean
): {
  readonly calculationParamsGroup: string;
  readonly inletAirHumidity: string;
  readonly inletAirTemperature: string;
  readonly inletWaterTemperature: string;
  readonly calculationMethodGroup: string;
  readonly calcMethodWaterCoil: string;
  readonly outletWaterTemperature: string;
  readonly waterFlow: string;
  readonly outletAirTemperature: string;
} {
  const suffix = !isAccessory ? "" : params === "heating" ? "H" : "C";
  return {
    calculationParamsGroup: `calculationParams${suffix}`,
    inletAirHumidity: `inletAirHumidity${suffix}`,
    inletAirTemperature: `inletAirTemperature${suffix}`,
    inletWaterTemperature: `inletWaterTemperature${suffix}`,
    calculationMethodGroup: `calculationMethod${suffix}`,
    calcMethodWaterCoil: `calcMethodWaterCoil${suffix}`,
    outletWaterTemperature: `outletWaterTemperature${suffix}`,
    waterFlow: `waterFlow${suffix}`,
    outletAirTemperature: `outletAirTemperature${suffix}`,
  };
}
