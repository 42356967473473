import { PropertyValueSet } from "@promaster-sdk/property";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import * as Types from "../types";
import { AirCurtain } from "../result-items-types";
import { Input } from "./types";
import { calculateAmbient } from "./calculate-ambient";
import { calculateElectric } from "./calculate-electric";
import { calculateWater } from "./calculate-water";

const source = "AirCurtainCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<AirCurtain>> {
  const { calculationInput } = input;

  const result =
    calculationInput.type === "water"
      ? await calculateWater(source, input, calculationInput)
      : calculationInput.type === "electric"
      ? calculateElectric(input, calculationInput)
      : calculationInput.type === "ambient"
      ? calculateAmbient(input, calculationInput)
      : exhaustiveCheck(calculationInput);

  if (result?.type === "ok") {
    let outCalcParams = result.outCalcParams;
    if (input.customAirFlowIsEmpty) {
      outCalcParams = PropertyValueSet.setAmount("customAirFlow", input.airFlow, outCalcParams);
    }
    return Types.createCalculatorSuccess([], result.airCurtain, result.messages, outCalcParams);
  } else {
    return Types.createCalculatorError(result.messages);
  }
}
