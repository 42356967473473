/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Redux from "redux";
//import { setCompareCookie, deleteCompareCookie } from "./cookie";
import * as Actions from "./actions";

type AnyStoreState = any;

export function createMiddleware(): any {
  return (_store: Redux.Store<AnyStoreState>) => (next: Redux.Dispatch<AnyStoreState>) => (action: Actions.Action) => {
    const responseToReturn = next(action);
    if (action.type === "compare/ADD_PRODUCT" && action.payload) {
      //  setCompareCookie(action.payload);
    } else if (action.type === "compare/REMOVE_PRODUCT") {
      //      deleteCompareCookie();
    }
    return responseToReturn;
  };
}
