import { PropertyValueSet } from "@promaster-sdk/property";
import * as QD from "shared-lib/query-diaq";
import { Quantity } from "uom-units";
import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import { Input, SoundAttenuation } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperSuccess,
  InputParam,
  createInputMapperError,
  ResultQuery,
} from "../types";
import * as Attributes from "../shared/attributes";
import { ProductDataError_NoDataAvailable } from "../messages";

const source = "SilencerInputMapper";

export function getCalcParams(): ReadonlyArray<InputParam> {
  return [
    {
      type: "Amount",
      group: "calculationParams",
      name: "airFlow",
      fieldName: "airFlow",
      quantity: "VolumeFlow",
    },
  ];
}

export function getQuery(_: string): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({});
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {}

export function map({
  properties,
  calcParams,
  attributes,
}: ComponentInput): InputMapperSuccess<Input> | InputMapperError {
  const airFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airFlow", calcParams);
  const attenuation = getAttenuation(attributes);

  if (!airFlow || !attenuation) {
    return createInputMapperError([ProductDataError_NoDataAvailable(source)]);
  }

  const maxAirFlow = Attributes.getFloat("air-flow-pressure-drop-MAX-BASE-ALL", attributes);
  const maxPressure = Attributes.getFloat("air-press-pressure-drop-MAX-BASE-ALL", attributes);
  const type = PropertyValueSet.getText("type", properties);

  return createInputMapperSuccess({
    airFlow: airFlow,
    silencerType: type,
    maxAirFlow: maxAirFlow !== undefined ? Amount.create(maxAirFlow, customUnits.CubicMeterPerHour) : undefined,
    maxPressure: maxPressure !== undefined ? Amount.create(maxPressure, customUnits.Pascal) : undefined,

    attenuation: attenuation,
  });
}

function getAttenuation(attributes: Attributes.Attributes): SoundAttenuation | undefined {
  const lw63 = Attributes.getFloat("sound-power-attenuation-octave-63", attributes);
  const lw125 = Attributes.getFloat("sound-power-attenuation-octave-125", attributes);
  const lw250 = Attributes.getFloat("sound-power-attenuation-octave-250", attributes);
  const lw500 = Attributes.getFloat("sound-power-attenuation-octave-500", attributes);
  const lw1k = Attributes.getFloat("sound-power-attenuation-octave-1k", attributes);
  const lw2k = Attributes.getFloat("sound-power-attenuation-octave-2k", attributes);
  const lw4k = Attributes.getFloat("sound-power-attenuation-octave-4k", attributes);
  const lw8k = Attributes.getFloat("sound-power-attenuation-octave-8k", attributes);
  if (
    lw63 === undefined ||
    lw125 === undefined ||
    lw250 === undefined ||
    lw500 === undefined ||
    lw1k === undefined ||
    lw2k === undefined ||
    lw4k === undefined ||
    lw8k === undefined
  ) {
    return undefined;
  }
  return { lw63, lw125, lw250, lw500, lw1k, lw2k, lw4k, lw8k };
}
