/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable functional/no-let */
import { retryFetch } from "shared-lib/fetch";

export interface YilidaInput {
  readonly Airflow: number; // m3/h
  readonly Pressure: number; // PA
  readonly SoundDistance: number; // m
  readonly Fantype: string; // from Database BoxfanselectionDB in the Desktop application for Boxfan selection, table Fanseries, column FanType
  readonly Density: number; // kg/m3, only needed if Fantype = KVD
  readonly Frequency: number; // hz , only needed if Fantype = KVD
  readonly DllLimits: { readonly [fanSeries: string]: string };
}

export interface YilidaSoundResult {
  readonly Hz63: number;
  readonly Hz125: number;
  readonly Hz250: number;
  readonly Hz500: number;
  readonly Hz1000: number;
  readonly Hz2000: number;
  readonly Hz4000: number;
  readonly Hz8000: number;
}

export interface YilidaResult {
  readonly AirFlow: number; // m3/h
  readonly Series: string;
  readonly SubSeries: string;
  readonly Motor: string;
  readonly MotorPower: number;
  readonly Size: string;
  readonly StaticPressure: number; // PA
  readonly TotalPressure: number; // PA
  readonly Power: number; // KW
  readonly Efficiency: number; // %
  readonly Speed: number; // RPM
  readonly AirVelocity: number; // m/s
  readonly MotorCurrent: number; // A
  readonly MotorVoltage: number; // V
  readonly SoundOutlet: YilidaSoundResult;
  readonly SoundPowerOutletDB: number;
  readonly SoundPowerOutletDBA: number;
  readonly SoundPressureLevel: YilidaSoundResult;
  readonly SoundPressureDB: number;
  readonly SoundPressureDBA: number;
  readonly UnitBreakOut: YilidaSoundResult;
  readonly UnitBreakOutDB: number;
  readonly UnitBreakOutDBA: number;
  readonly DiagramBase64Png: string | null;
}

// Example of working Input for KVD
// {
// "Airflow":1000,
// "Pressure": 300,
// "SoundDistance": 1,
// "FanType": "KVD",
// "Density": 1.2,
// "Frequency":60
//}

// Example of Regular Input
// {
// "Airflow":1000,
// "Pressure": 300,
// "SoundDistance": 1,
// "Fantype": "KVB",
// }

interface DeferedPromise {
  readonly resolve: (result: ReadonlyArray<YilidaResult>) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly reject: (rejectReason: any) => void;
}

interface PromiseMap {
  // eslint-disable-next-line functional/prefer-readonly-type
  [key: string]: Array<DeferedPromise>;
}
const promisesToSettle: PromiseMap = {};

// eslint-disable-next-line functional/prefer-readonly-type
let resultCache: { [key: string]: ReadonlyArray<YilidaResult> } = {};
export async function calculate(input: YilidaInput): Promise<ReadonlyArray<YilidaResult>> {
  const key = JSON.stringify(input);

  if (resultCache[key] !== undefined) {
    return resultCache[key];
  }

  if (promisesToSettle[key] !== undefined) {
    const promise = new Promise(
      (resolve: (result: ReadonlyArray<YilidaResult>) => void, reject: (error: any) => void) => {
        promisesToSettle[key].push({
          resolve,
          reject,
        });
      }
    );

    return promise;
  }

  promisesToSettle[key] = [];

  const result: ReadonlyArray<YilidaResult> = await retryFetch(
    "https://design-dllcal.systemair.com/api/yilida/calculate",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input),
    }
  )
    .then((r) => r.json())
    .catch((error) => {
      settlePromises(promisesToSettle[key], error, undefined);
    });

  settlePromises(promisesToSettle[key], undefined, result);
  resultCache[key] = result;
  if (Object.keys(resultCache).length > 10) {
    // Hard clear
    resultCache = {};
  }
  delete promisesToSettle[key];
  return result;
}

function settlePromises(
  // eslint-disable-next-line functional/prefer-readonly-type
  promises: Array<DeferedPromise>,
  error: any,
  result: ReadonlyArray<YilidaResult> | undefined
): void {
  while (promises.length > 0) {
    const promise = promises.pop();

    if (promise) {
      const { resolve, reject } = promise;
      if (error === undefined && result) {
        resolve(result);
      } else {
        reject(error);
      }
    }
  }
}
