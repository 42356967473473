import * as React from "react";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import * as Texts from "shared-lib/language-texts";
import { Checkbox } from "client-lib/elements";
import { Images, ValidateFilterFn } from "./types";
import { PropertyLabel } from "./property-label";

export interface CheckboxPropertySelectorLabelProps {
  readonly label: string;
  readonly property: PromasterPropertiesSelector.Property;
}

export function CheckboxPropertySelectorLabel(props: CheckboxPropertySelectorLabelProps): JSX.Element {
  const { label, property } = props;
  return (
    <PropertyLabel
      label={
        <label className="hover:text-brand-600 cursor-default block" htmlFor={`${property.group}_${property.name}`}>
          {label}
        </label>
      }
    />
  );
}

export interface CheckboxPropertySelectorProps {
  readonly productId: string;
  readonly property: PromasterPropertiesSelector.Property;
  readonly value: number | undefined;
  readonly onChange: (newValue: number) => void;
  readonly images?: Images;
  readonly translate: Texts.TranslateFunction;
  readonly validateFilter: ValidateFilterFn;
  readonly filterPrettyPrint: FilterPrettyPrint;
}

export function CheckboxPropertySelector(props: CheckboxPropertySelectorProps): JSX.Element {
  const { property, value, images, onChange, validateFilter, filterPrettyPrint } = props;
  const checked = value === 1;
  const checkedValue = property.value.find((v) => v.value.value === 1);
  const uncheckedValue = property.value.find((v) => v.value.value !== 1);
  const checkedInt = checkedValue && checkedValue.value.type === "integer" ? checkedValue.value.value : 1;
  const uncheckedInt = uncheckedValue && uncheckedValue.value.type === "integer" ? uncheckedValue.value.value : -1;
  const image = checkedValue && checkedValue.image && images ? images[checkedValue.image] : undefined;

  const currentValue = checked ? checkedValue : uncheckedValue;
  const error = currentValue
    ? validateFilter(currentValue.property_filter)
      ? undefined
      : filterPrettyPrint(currentValue.property_filter)
    : undefined;

  const otherValue = checked ? uncheckedValue : checkedValue;
  const disabled = otherValue && !validateFilter(otherValue.property_filter);

  return (
    <div className="w-1/2 min-w-224 flex flex-row  items-center">
      <Checkbox
        className="mr-8"
        id={`${property.group}_${property.name}`}
        checked={checked}
        disabled={disabled}
        error={error}
        checkedChanged={(newChecked) => onChange(newChecked ? checkedInt : uncheckedInt)}
      />
      {image ? <img src={image} /> : <span />}
    </div>
  );
}
