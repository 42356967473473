import { Unsubscribe } from "redux";
import * as Core from "shared-lib/redux-query/core";
import * as QD from "shared-lib/query-diaq";
import { store } from "../../store";

export async function resolveQuery<TProps, TResponse>(
  mapPropsToQuery: (props: TProps, response: TResponse | undefined) => QD.DiaqMapQuery<TResponse>,
  props: TProps
): Promise<TResponse> {
  let cache = store.getState().query.cache;
  let unsub: Unsubscribe | undefined;
  let query: QD.DiaqMapQuery<TResponse>;
  let result: Core.SelectComposedQueryResult<Core.AnyQuery, Core.AnyResponse>;
  let response: Core.AnyResponse | undefined = undefined;
  let queryIsResolved = false;
  await new Promise<void>((resolve) => {
    const doIteration = (): void => {
      // iterate until all queries are specified or there are responses which cannot be gotten from cache
      let iterations = 0;
      do {
        query = mapPropsToQuery(props, response);
        result = selectComposedQuery(cache, query);
        response = result.response;
        iterations++;
        if (iterations > 10) {
          console.error("resolveQuery: The component did not specify it's queries fully in 10 iterations.", {
            query: query,
            result: result,
          });
          throw new Error("resolveQuery: The component did not specify it's queries fully in 10 iterations.");
        }
      } while (result.unresolvedQueries.length === 0 && result.undefinedQueriesCount > 0);

      // Queue any queries that are defined but are not selectable from cache
      if (result.unresolvedQueries.length > 0) {
        store.dispatch(Core.queueQueries(result.unresolvedQueries));
      }

      queryIsResolved =
        result.undefinedQueriesCount === 0 && result.unresolvedQueries.filter(shouldWaitForResponse).length === 0;
    };

    unsub = store.subscribe(() => {
      const newCache = store.getState().query.cache;
      if (cache === newCache) {
        return;
      }
      cache = newCache;
      doIteration();
      if (queryIsResolved) {
        resolve();
      }
    });

    doIteration();
    if (queryIsResolved) {
      resolve();
    }
  });
  if (unsub) {
    unsub();
  }
  if (!response) {
    throw new Error("resolveQuery: Failed to resovle query");
  }
  return response;
}

function selectComposedQuery<TAtomicQuery extends Core.AnyQuery, TAtomicResponse, TComposedResponse>(
  cache: Core.AnyCacheState | undefined,
  composedQuery: Core.ComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>
): Core.SelectComposedQueryResult<TAtomicQuery, TAtomicResponse> {
  const selectAtomicQuery: Core.SelectAtomicQuery<Core.AnyCacheState, Core.AnyQuery, Core.AnyResponse | undefined> =
    QD.selectAtomicQuery;
  return Core.selectComposedQuery<TAtomicQuery, TAtomicResponse, TComposedResponse>(
    selectAtomicQuery,
    cache,
    composedQuery
  );
}

function shouldWaitForResponse(arg: Core.AnyQuery): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !(arg as any).___reduxQueryAllowUndefined;
}
