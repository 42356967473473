import { Amount } from "uom";
import { customUnits } from "shared-lib/uom";
import * as Interpolation from "shared-lib/interpolation";
import * as Types from "../types";
import * as Messages from "../messages";
import { WaterCoil } from "../result-items-types";
import * as RoenEst from "../dll/roenest";
import * as PressureDrop from "../shared/pressure-drop";
import { Input } from "./types";
import * as Attributes from "../shared/attributes";
import { validateMaxAirFlowAndPressureAttr } from "../shared/validate-max-airflow-and-pressure";

const source = "RoenEstWaterCoilCalculator";

export async function calculate(input: Input): Promise<Types.CalculatorResult<WaterCoil>> {
  const {
    airFlow,
    inletAirTemperature,
    inletAirHumidity,
    inletWaterTemperature,
    calculationMethod,
    outletWaterTemperature,
    waterFlow,
    outletAirTemperature,
    attributes,
  } = input;
  const airFlowLps = Amount.valueAs(customUnits.LiterPerSecond, airFlow);
  const inletAirTemperatureC = Amount.valueAs(customUnits.Celsius, inletAirTemperature);
  const inletAirHumidityP = Amount.valueAs(customUnits.PercentHumidity, inletAirHumidity);
  const inletWaterTemperatureC = Amount.valueAs(customUnits.Celsius, inletWaterTemperature);
  const outletWaterTemperatureC = outletWaterTemperature
    ? Amount.valueAs(customUnits.Celsius, outletWaterTemperature)
    : null;
  const waterFlowLps = waterFlow ? Amount.valueAs(customUnits.LiterPerSecond, waterFlow) : null;
  const outletAirTemperatureC = outletAirTemperature ? Amount.valueAs(customUnits.Celsius, outletAirTemperature) : null;

  const roenEstInput: RoenEst.RoenEstInput = {
    AirFlow: airFlowLps, // l/s
    AirTemperatureIn: inletAirTemperatureC, // C
    WaterTempIn: inletWaterTemperatureC, // C
    WaterTempOut: outletWaterTemperatureC, // if method = 0 // C
    AirHumidity: inletAirHumidityP, // %
    Method: calculationMethod, // 0 = Outlet water tempererature supplied, 1 = water flow supplied, 2 output air temperature supplied
    WaterFlow: waterFlowLps, // if methods = 1 // l/s
    AirTemperatureOut: outletAirTemperatureC, // if method = 2 // C
    Length: Attributes.getFloatOrThrow("DLL-input-roenest-length", attributes),
    Height: Attributes.getFloatOrThrow("DLL-input-roenest-height", attributes),
    Rows: Attributes.getIntOrThrow("DLL-input-roenest-rows", attributes),
    FinSpacing: Attributes.getFloatOrThrow("DLL-input-roenest-fins", attributes),
    Circuits: Attributes.getIntOrThrow("DLL-input-roenest-circuits", attributes),
    FinOption: Attributes.getIntOrDefault("DLL-input-roenest-fin-option", attributes, 0),
    Geometry: Attributes.getStringOrThrow("DLL-input-roenest-geometry", attributes),
    RowsMaterial: Attributes.getStringOrThrow("DLL-input-roenest-rows-material", attributes),
    FinsMaterial: Attributes.getStringOrThrow("DLL-input-roenest-finns-material", attributes),
    FreeTubes: Attributes.getIntOrThrow("DLL-input-roenest-free-tubes", attributes),
    HeaderConf: Attributes.getStringOrThrow("DLL-input-roenest-header-conf", attributes),
    Header: Attributes.getStringOrThrow("DLL-input-roenest-header", attributes),
    CoilType: inletWaterTemperatureC < inletAirTemperatureC ? 2 : 1, //coilType === "Heater" ? 1 : 2 // Heater = 1, Cooler = 2, Evaporator = 3, Condenser = 4
    Glycol: 0,
    FluidType: 1,
  };

  const result = await RoenEst.calculate(roenEstInput);
  if (result.Message !== undefined) {
    return Types.createCalculatorError([Messages.Exception(source, result.Message)]);
  }

  const pressureDropCurve = PressureDrop.createPowerPressureCurve(0, 5000, [
    Interpolation.vec2Create(airFlowLps, result.AirPressureDrop),
  ]);

  const messages: Array<Messages.Message> = [];

  messages.push(...validateMaxAirFlowAndPressureAttr(source, attributes, airFlow, result.AirPressureDrop));

  return Types.createCalculatorSuccess(
    [],
    {
      airVelocity: Amount.create(result.AirVelocity, customUnits.MeterPerSecond, 2),
      waterVelocity: Amount.create(result.WaterVelocity, customUnits.MeterPerSecond, 2),
      recomendedKv: Amount.create(result.KvValue, customUnits.One, 2),
      airFlow: airFlow,
      waterPressureDrop: Amount.create(result.WaterPressureDrop, customUnits.KiloPascal, 1),
      airPressureDrop: Amount.create(result.AirPressureDrop, customUnits.Pascal, 1),
      outletAirTemperature: Amount.create(result.AirTemperatureOut, customUnits.Celsius, 1),
      outletAirHumidity: Amount.create(result.AirHumidityOut, customUnits.PercentHumidity, 1),
      waterFlow: Amount.create(result.WaterFlow, customUnits.LiterPerSecond, 1),
      inletWaterTemperature: Amount.create(result.WaterTemperatureIn, customUnits.Celsius, 1),
      outletWaterTemperature: Amount.create(result.WaterTemperatureOut, customUnits.Celsius, 1),
      power: Amount.create(result.Power, customUnits.KiloWatt, 1),
      coilCode: result.CoilCode,
      inletAirTemperature: inletAirTemperature,
      inletAirHumidity: inletAirHumidity,
      connectionSizeIn: "",
      connectionSizeOut: "",
      pressureDropCurve: pressureDropCurve,
    },
    messages
  );
}
