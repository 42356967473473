import * as React from "react";
import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import { CalculationMessages, Spinner, ResultRowContainer, Heading4 } from "client-lib/elements";
import * as SC from "shared-lib/system-calculator";
import * as C from "shared-lib/calculation";
import * as UserSettings from "shared-lib/user-settings";
import * as PC from "shared-lib/product-codes";
import { clientConfig } from "config";
import * as Compare from "client-lib/compare";
import * as Texts from "shared-lib/language-texts";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import * as Accessories from "shared-lib/accessories";
import * as ResultView from "../result-view";
import { Props, ProductProps, ProductResponse, getProductResponse, MetaTables, ProductResponses } from "./types";

interface CalculationResult {
  readonly comparedKey: string;
  readonly system: SC.System;
  readonly result: SC.ResultItemOutputPerComponent;
  readonly errorsWasHidden: boolean;
}

interface ProductInformation {
  readonly itemName: string;
  readonly itemNo: string;
  readonly variantName: string;
}

export function ProductCalculationContainerComponent(props: Props): React.ReactElement<Props> | null {
  const { market, language, translate, metaTables, crm, hideErrors, shopUrl, enablePocDiagram }: Props = props;

  const { isCalculating, calculationResults } = useCalculation(props.productResponses, props.productsProps, hideErrors);

  if (calculationResults.length !== props.productsProps.length) {
    return <Spinner />;
  }

  const resultViewProductProps = props.productsProps.map((p, i) =>
    createProductResults(props, p, calculationResults[i].result)
  );

  return (
    <section className={`relative space-y-24 ${clientConfig.addOuterPadding ? "px-40" : ""}`}>
      {isCalculating && <DebouncedOverlay />}
      {props.productsProps.length > 1 && !props.enablePocDiagram ? (
        <ResultRowContainer>
          {renderProductInformation(props.productsProps[0], props, false)}
          {renderProductInformation(props.productsProps[1], props, true)}
        </ResultRowContainer>
      ) : null}
      {!hideErrors && !calculationResults.some((r) => r.errorsWasHidden) && (
        <Messages
          translate={translate}
          market={market}
          metaTables={metaTables}
          productResponses={props.productResponses}
          userSettings={props.userSettings}
          showMessageType="main_product"
          productsProps={props.productsProps}
          calculationResults={calculationResults}
        />
      )}
      <ResultView.ResultView
        translate={translate}
        market={market}
        language={language}
        showDownload={crm !== undefined}
        shopUrl={shopUrl}
        productsProps={resultViewProductProps}
        enablePocDiagram={enablePocDiagram}
      />
      <ResultViewAccessory props={props} calculationResults={calculationResults} />
    </section>
  );
}

export function DebouncedOverlay(): JSX.Element | null {
  const [showSpinner, setShowSpinner] = React.useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 250);
    return () => clearTimeout(timer);
  });
  return showSpinner ? <div className="absolute w-full h-full bg-white z-50">{showSpinner && <Spinner />}</div> : null;
}

function ResultViewAccessory({
  props,
  calculationResults,
}: {
  readonly props: Props;
  readonly calculationResults: ReadonlyArray<CalculationResult>;
}): React.ReactElement<{}> {
  const productAccData = props.productsProps.map((p, i) =>
    createAccessoryResultViewData(
      props.metaTables,
      props.productResponses,
      p,
      calculationResults[i].system,
      calculationResults[i].result,
      props.translate
    )
  );

  type AccData = {
    productIndex: number;
    accData: AccessoryResultViewData;
    accOrder: number;
  };
  const accData: Array<AccData> = [];
  for (let productIndex = 0; productIndex < productAccData.length; productIndex++) {
    accData.push(...productAccData[productIndex].map((a, accOrder) => ({ productIndex, accData: a, accOrder })));
  }

  const renderedRows = [];
  let rowIndex = 0;
  while (accData.length > 0) {
    let current: AccData | undefined = undefined;
    const rowData: Array<AccessoryResultViewData | undefined> = [];
    for (let productIndex = 0; productIndex < props.productsProps.length; productIndex++) {
      let adIndex = -1;
      const matchPriority = ["title" as const, "accessortType" as const, "accessoryId" as const, "resultItem" as const];
      for (const key of matchPriority) {
        adIndex = accData.findIndex(
          (d) => d.productIndex === productIndex && (!current || d.accData[key] === current.accData[key])
        );
        if (adIndex !== -1) {
          break;
        }
      }
      if (adIndex !== -1) {
        rowData.push(accData[adIndex].accData);
        if (!current) {
          current = accData[adIndex];
        }
        accData.splice(adIndex, 1);
      } else {
        rowData.push(undefined);
      }
    }
    renderedRows.push(
      <div key={++rowIndex}>
        <ResultRowContainer>
          {rowData.map((d, di) => (
            <Heading4 className={props.productsProps.length > 1 ? "text-center" : ""} key={di}>
              {d?.title}
            </Heading4>
          ))}
        </ResultRowContainer>
        {!props.hideErrors && !calculationResults.some((r) => r.errorsWasHidden) && (
          <Messages
            translate={props.translate}
            market={props.market}
            metaTables={props.metaTables}
            productResponses={props.productResponses}
            userSettings={props.userSettings}
            showMessageType="accessory"
            productsProps={props.productsProps}
            calculationResults={calculationResults}
          />
        )}
        <ResultView.ResultView
          translate={props.translate}
          market={props.market}
          language={props.language}
          showDownload={props.crm !== undefined}
          shopUrl={props.shopUrl}
          enablePocDiagram={false}
          productsProps={rowData.map((r) => r?.productsProps)}
        />
      </div>
    );
  }

  return <React.Fragment>{...renderedRows}</React.Fragment>;
}

interface AccessoryResultViewData {
  readonly title: string;
  readonly resultItem: string;
  readonly accessoryId: string;
  readonly accessortType: string | undefined;
  readonly productsProps: ResultView.ProductProps;
}

function createAccessoryResultViewData(
  metaTables: MetaTables,
  productResponses: ProductResponses,
  productProps: ProductProps,
  system: SC.System,
  results: SC.ResultItemOutputPerComponent,
  translate: Texts.TranslateFunction
): ReadonlyArray<AccessoryResultViewData> {
  const key = createComparedKey(productProps);
  const productResponse = productResponses[key];
  const config = productProps.config;

  const resultViewsAccessories = config.accessories.map((accessory) => {
    const accessoryAttributes = Attributes.createMap(
      accessory.properties,
      productResponse.accessoryTables[accessory.productId].ct_Attributes2
    );
    const resultViewsToShow = SC.getResultViewsToUse(
      "CatalogueScreen",
      metaTables.ct_ResultViews,
      productResponse.accessoryTables[accessory.productId].ct_DiaqTemplates,
      accessory.properties,
      metaTables.ct_AttributeTemplateMapping,
      accessoryAttributes
    );

    const resultItems = results[accessory.id];

    return {
      accessoryId: accessory.productId,
      accessoryType: PropertyValueSet.getText("type", accessory.properties),
      productId: accessory.productId,
      variantId: accessory.productId,
      calcParams: accessory.calcParams,
      resultViewsToShow: resultViewsToShow,
      resultItemOutputMap: resultItems,
      properties: accessory.properties,
    };
  });

  return resultViewsAccessories
    .map((view): AccessoryResultViewData | undefined => {
      if (!view.resultItemOutputMap || view.resultViewsToShow.length < 1) {
        return undefined;
      }

      const accTables = productResponse.accessoryTables[view.productId];
      const accCodes = PC.getProductCodes(accTables, view.properties);
      const [resultItemNames] = view.resultViewsToShow.map((v) => v.result_item.split(";"));
      const resultItemsOutput = resultItemNames.map((name) => view.resultItemOutputMap[name]);
      const failures = resultItemsOutput.filter((r) => !r || r.type !== "OutputMapperSuccess");
      const rows = metaTables.ct_ResultVisualizerParamsTable.filter((r) => r.table_name === resultItemNames[0]);
      const title = Accessories.getAccessoryName(translate, accTables, view.properties);

      if (failures.length > 0 || rows.length === 0) {
        return undefined;
      }

      return {
        title: title,
        resultItem: resultItemNames[0],
        accessoryId: view.accessoryId,
        accessortType: view.accessoryType,
        productsProps: {
          system: system,
          productId: view.productId,
          codes: accCodes,
          variant: PropertyValueSet.Empty,
          calcParams: view.calcParams,
          calcParamsChanged: productProps.calcParamsChanged,
          resultItemOutputMap: view.resultItemOutputMap,
          resultViewsToShow: view.resultViewsToShow,
          navigateToItemNo: productProps.navigateToItemNo,
        },
      };
    })
    .filter((d): d is AccessoryResultViewData => !!d);
}

function renderProductInformation(
  productProps: ProductProps,
  props: Props,
  selectedForComparison: boolean
): React.ReactElement<{}> {
  const productResponse = getProductResponse(props, productProps);
  const { itemName, itemNo, variantName } = getProductInformation(props, productProps, productResponse);
  const { translate } = props;
  return (
    <div className="text-sm text-center">
      <Heading4>{itemName}</Heading4>
      <div>{variantName}</div>
      <div>{`${translate(Texts.articleNo())}: ${itemNo}`}</div>
      {selectedForComparison && <div className="font-bold mt-8">{translate(Texts.saved_for_comparison())}</div>}
    </div>
  );
}

function Messages(props: {
  readonly translate: Texts.TranslateFunction;
  readonly market: string;
  readonly metaTables: MetaTables;
  readonly productResponses: {
    readonly [configKey: string]: ProductResponse;
  };
  readonly userSettings: UserSettings.State;
  readonly productsProps: ReadonlyArray<ProductProps>;
  readonly calculationResults: ReadonlyArray<CalculationResult>;
  readonly showMessageType: "main_product" | "accessory";
}): React.ReactElement<{}> {
  const { translate, market, metaTables, productResponses, userSettings } = props;

  const products = props.productsProps.map((productProps, i) => ({
    productProps,
    result: props.calculationResults[i].result,
  }));

  const getUnit = UserSettings.getUnit({
    market: market,
    ct_MarketUnits: metaTables.ct_MarketUnits,
    userSettings: userSettings,
  });

  const getDecimals = UserSettings.getDecimals({
    market,
    ct_MarketUnits: metaTables.ct_MarketUnits,
  });

  const renderedMessages = products.map((product, i) => {
    const { productProps, result } = product;
    const key = createComparedKey(productProps);
    const productResponse = productResponses[key];
    if (!result || !productResponse) {
      return null;
    }
    const productId = productProps.productId;
    const config = productProps.config;
    const codeTables = productResponse.codeTables;
    const componentMessages = SC.getMessages(result[productId]).filter((m) =>
      props.showMessageType === "accessory" ? SC.isAccessoryViewMessage(m) : !SC.isAccessoryViewMessage(m)
    );
    const accessoryMessages = config.accessories
      .map((a) => ({
        code: PC.getProductCodes(codeTables[a.productId], a.properties).code,
        messages: SC.getMessages(result[a.id]).filter((m) =>
          props.showMessageType === "accessory" ? SC.isAccessoryViewMessage(m) : !SC.isAccessoryViewMessage(m)
        ),
      }))
      .filter((a) => a.messages.length > 0);

    return (
      <CalculationMessages
        key={i}
        componentMessages={componentMessages}
        accessoryMessages={accessoryMessages}
        translate={translate}
        getUnit={getUnit}
        getDecimals={getDecimals}
        horizontalAlign={products.length > 1 ? true : undefined}
      />
    );
  });

  if (props.showMessageType === "main_product") {
    return products.length > 1 && props.showMessageType === "main_product" ? (
      <ResultRowContainer>{...renderedMessages}</ResultRowContainer>
    ) : (
      <div>{...renderedMessages}</div>
    );
  } else {
    // All accessories always have the same message, so avoid repeating it
    return <div>{renderedMessages[0]}</div>;
  }
}

function createProductResults(
  props: Props,
  productProps: ProductProps,
  results: SC.ResultItemOutputPerComponent
): ResultView.ProductProps | undefined {
  const { metaTables } = props;
  if (!metaTables) {
    return undefined;
  }

  const key = createComparedKey(productProps);
  const productResponse = props.productResponses[key];
  const calculationResponse = productResponse.calculationResponse;
  const system = C.buildSystem(productProps, calculationResponse);
  if (!system || !productResponse.productTables) {
    return undefined;
  }
  const { productId, config, calcParamsChanged, navigateToItemNo } = productProps;
  const { calcParams } = config;
  const resultItemOutputMap = results && results[productId];

  // Get the result view items that are used for our view
  const attributes = Attributes.createMap(config.properties, productResponse.productTables.ct_Attributes2);
  const resultViewsToShow = SC.getResultViewsToUse(
    "CatalogueScreen",
    metaTables.ct_ResultViews,
    productResponse.productTables.ct_DiaqTemplates,
    config.properties,
    metaTables.ct_AttributeTemplateMapping,
    attributes
  );

  const codes = PC.getProductCodes(productResponse.codeTables[productId], config.properties);

  return {
    system,
    productId,
    codes,
    variant: config.properties,
    calcParams,
    calcParamsChanged,
    resultItemOutputMap,
    navigateToItemNo,
    resultViewsToShow,
  };
}

function createComparedKey(productProps: ProductProps): string {
  return Compare.createComparedKey({
    productId: productProps.productId,
    itemConfig: productProps.config,
  });
}

function getProductInformation(
  props: Props,
  productProps: ProductProps,
  productResponse: ProductResponse
): ProductInformation {
  const { translate } = props;
  const { productId, config } = productProps;
  const codeTables = productResponse.codeTables[productId];
  const codes = PC.getProductCodes(codeTables, config.properties);
  return {
    itemName: translate(Texts.item_name(productId, config.properties), codes.code),
    itemNo: codes.itemNo,
    variantName: translate(Texts.property_value(productId, "variant", parseInt(codes.variantId || "", 10)), ""),
  };
}

function useCalculation(
  productResponses: ProductResponses,
  allProductsProps: ReadonlyArray<ProductProps>,
  hideErrors: boolean
): { readonly isCalculating: boolean; readonly calculationResults: ReadonlyArray<CalculationResult> } {
  const [calculationResults, setCalculationResults] = React.useState<ReadonlyArray<CalculationResult>>([]);
  const isCalculating = useCallRateLimit(
    async () => {
      const updatedResults: Array<CalculationResult> = [];
      for (const productProps of allProductsProps) {
        const comparedKey = createComparedKey(productProps);
        const calculationResponse = productResponses[comparedKey].calculationResponse;

        const { result, system } = await C.calculateSystem(productProps, calculationResponse);
        if (!result || !system) {
          continue;
        }

        const mainResult = result[productProps.productId];
        let newCalcParams = productProps.config.calcParams;
        for (const r of R.values(mainResult)) {
          if (r && r.type === "OutputMapperSuccess") {
            newCalcParams = PropertyValueSet.setValues(r.calcParams, newCalcParams);
          }
        }
        if (!PropertyValueSet.equals(newCalcParams, productProps.config.calcParams)) {
          productProps.calcParamsChanged(newCalcParams);
        }

        for (const acc of productProps.config.accessories) {
          const accResult = result[acc.id];
          const oldCalcParams = PropertyValueSet.removeProperties(Accessories.inheritedCalcParams, acc.calcParams);
          let newAccCalcParams = oldCalcParams;
          for (const r of R.values(accResult)) {
            if (r && r.type === "OutputMapperSuccess") {
              newAccCalcParams = PropertyValueSet.setValues(r.calcParams, newAccCalcParams);
            }
          }
          newAccCalcParams = PropertyValueSet.removeProperties(Accessories.inheritedCalcParams, newAccCalcParams);
          if (!PropertyValueSet.equals(newAccCalcParams, oldCalcParams)) {
            productProps.accCalcParamsChanged(acc.id, newAccCalcParams);
          }
        }

        updatedResults.push({
          comparedKey: comparedKey,
          system,
          result,
          errorsWasHidden: hideErrors,
        });
      }
      setCalculationResults(updatedResults);
    },
    50, // Enough time to let all state changes settle, should avoid unucessary calculations
    [allProductsProps.map((p) => createComparedKey(p)).join("|")]
  );
  return { isCalculating, calculationResults };
}

export function useCallRateLimit(func: () => Promise<void>, rateLimitMs: number, deps?: React.DependencyList): boolean {
  const toBeQueued = React.useRef<() => Promise<void>>();
  const timeout = React.useRef<number>();
  const calling = React.useRef<boolean>(false);
  const [isCalling, setIsCalling] = React.useState(false);
  React.useEffect(() => {
    toBeQueued.current = func;

    // Nothing to do if there already is a call in progress
    if (calling.current) {
      return;
    }

    // Debounce by clearing pending calls
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current);
    }

    const queueCall = (): void => {
      const fn = toBeQueued.current;
      if (!fn) {
        return;
      }
      toBeQueued.current = undefined;

      setIsCalling(true);

      timeout.current = (setTimeout(() => {
        timeout.current = undefined;
        calling.current = true;
        fn().then(
          () => {
            calling.current = false;
            setIsCalling(false);
            queueCall();
          },
          () => {
            calling.current = false;
            setIsCalling(false);
            queueCall();
          }
        );
      }, rateLimitMs) as unknown) as number;
    };

    queueCall();
  }, deps);
  return isCalling;
}
