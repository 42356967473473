/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import * as React from "react";
import * as SC from "shared-lib/system-calculator";
import {
  OctaveBandsTable,
  OctaveBandsTableTd,
  OctaveBandsTableTh,
  Heading3,
  Textfield,
  HToolbar,
} from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as FanSound from "shared-lib/system-calculator/shared/fan-sound";
import * as UserSettings from "shared-lib/user-settings";
import * as UserSettingsClient from "client-lib/user-settings";
import { DispatchProp } from "client-lib/redux-integration";
import { isAllDefined } from "shared-lib/utils";
import { VisualizerOwnProps } from "../types";
import * as RWS from "../shared";

export type Props = VisualizerOwnProps & StateProps & DispatchProp<UserSettingsClient.Action>;

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export function SoundPressureLevelVisualizerContainerComponent({
  visualizerParams,
  products,
  translate,
  userSettings,
  dispatch,
}: Props): React.ReactElement<Props> {
  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const allResultItemMaps = products.map((p) => p.resultItemMap);
  const allResults = allResultItemMaps.map(
    (resultItemMap) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (R.values(resultItemMap)[0].value as any) as {
        [key: string]: SC.OctaveBands3rd;
      }
  );

  const items = visualizerParams.split(",");
  const itemsInResult = allResults[0] || {};
  const rows = items.filter((i) => itemsInResult[i]);
  if (rows.length === 0) {
    return <span />;
  }

  const sabine = UserSettings.getSoundAbsorptionArea(userSettings);
  const sabineDouble = parseFloat(sabine);
  const damping = calculateDamping(sabineDouble);

  const singleProduct = products.length === 1;

  return (
    <div>
      <HToolbar>
        <span>{translate(Texts.equivalent_absorptions_area()) + ":"}</span>
        <Textfield
          className="max-w-144"
          value={sabine}
          onChange={(e) => dispatch(UserSettingsClient.setSoundAbsorptionArea(e))}
        />
      </HToolbar>
      <OctaveBandsTable equalWidths={true}>
        {singleProduct ? (
          <colgroup key={"single"}>
            <col />
            <col width={"8%"} />
            <col width={"8%"} />
            <col width={"8%"} />
            <col width={"8%"} />
            <col width={"8%"} />
          </colgroup>
        ) : (
          <colgroup key={"multi"}>
            <col key={"label"} width={"10%"} />
            {products.map((_p, pIdx) => (
              <React.Fragment key={pIdx}>
                {R.range(0, 6).map((idx) => (
                  <col key={`band_${pIdx}_${idx}`} />
                ))}
              </React.Fragment>
            ))}
            <col key={"empty"} width={"10%"} />
          </colgroup>
        )}
        <thead>
          <tr>
            <HeaderFreqBands translate={translate} numProducts={products.length} />
            {!singleProduct ? <OctaveBandsTableTh /> : null}
          </tr>
        </thead>
        <tbody>
          {rows.map((i) =>
            renderSoundPressureTableRow(
              translate(Texts.createText(i)),
              sabineDouble,
              damping,
              allResults.map((results) => results[i])
            )
          )}
        </tbody>
      </OctaveBandsTable>
    </div>
  );
}

function HeaderFreqBands({
  translate,
  numProducts,
}: {
  readonly translate: Texts.TranslateFunction;
  readonly numProducts: number;
}): React.ReactElement<{}> {
  if (numProducts === 1) {
    return (
      <>
        <OctaveBandsTableTh>
          <Heading3>{translate(Texts.sound_pressure_level_reverberant_field())}</Heading3>
        </OctaveBandsTableTh>
        <OctaveBandsTableTh />
        <OctaveBandsTableTh />
        <OctaveBandsTableTh />
        <OctaveBandsTableTh />
        <OctaveBandsTableTh textAlign="center">{translate(Texts.total())}</OctaveBandsTableTh>
      </>
    );
  } else {
    return (
      <>
        <OctaveBandsTableTh colSpan={2}>
          <Heading3>{translate(Texts.sound_pressure_level_reverberant_field())}</Heading3>
        </OctaveBandsTableTh>
        {R.range(0, numProducts).map((idx) => {
          return (
            <React.Fragment key={idx}>
              {idx !== 0 ? <OctaveBandsTableTh /> : null}
              <OctaveBandsTableTh />
              <OctaveBandsTableTh />
              <OctaveBandsTableTh />
              <OctaveBandsTableTh />
              <OctaveBandsTableTh textAlign="center">{translate(Texts.total())}</OctaveBandsTableTh>
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

function calculateDamping(sabine: number): number {
  if (!isFinite(sabine)) {
    return 0;
  }
  return 10 * Math.log10(1 / (4 * Math.PI * 9) + 4 / sabine);
}

function renderSoundPressureTableRow(
  label: string,
  area: number,
  damping: number,
  rawOctaveBands3rd: ReadonlyArray<SC.OctaveBands3rd | undefined>
): React.ReactElement<{}> | undefined {
  const octaveBands3rdA = rawOctaveBands3rd.map(
    (bands) => bands && (FanSound.aWeightOctaveBands3rd(bands) as SC.OctaveBands3rd)
  );
  const totalAs = octaveBands3rdA.map((bands) => bands && FanSound.calcTotFromOctaveBands3rd(bands));
  const totalDampenedAs = totalAs.map((totalA) => (totalA !== undefined ? totalA + damping : undefined));

  const singleProduct = rawOctaveBands3rd.length === 1;

  if (singleProduct) {
    return (
      <tr key={label}>
        <OctaveBandsTableTd>{label}</OctaveBandsTableTd>
        <OctaveBandsTableTd textAlign="center">({damping.toFixed(0)} dB)</OctaveBandsTableTd>
        <OctaveBandsTableTd textAlign="center">dB</OctaveBandsTableTd>
        <OctaveBandsTableTd textAlign="center">
          {area.toFixed(0)} m<sup>2</sup> (Sabin)
        </OctaveBandsTableTd>
        <OctaveBandsTableTd />
        <RWS.SoundCell sound={totalDampenedAs[0]} />
      </tr>
    );
  } else {
    return (
      <tr key={label}>
        <OctaveBandsTableTd colSpan={2}>{label}</OctaveBandsTableTd>
        {totalDampenedAs.map((totalDampenedA, idx) => (
          <React.Fragment key={idx}>
            {!singleProduct && idx !== 0 ? <OctaveBandsTableTd /> : null}
            <OctaveBandsTableTd textAlign="center">({damping.toFixed(0)} dB)</OctaveBandsTableTd>
            <OctaveBandsTableTd textAlign="center">dB</OctaveBandsTableTd>
            <OctaveBandsTableTd textAlign="center">
              {area.toFixed(0)} m<sup>2</sup> (Sabin)
            </OctaveBandsTableTd>
            <OctaveBandsTableTd />
            <RWS.SoundCell sound={totalDampenedA} />
          </React.Fragment>
        ))}
        <OctaveBandsTableTd />
      </tr>
    );
  }
}
