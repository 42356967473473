import { PropertyValueSet, PropertyFilter, PropertyValue } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as QP from "shared-lib/query-product";
import * as PC from "shared-lib/product-codes";
import * as QD from "shared-lib/query-diaq";
import { CustomUnitsLookup } from "shared-lib/uom";
import { getHeatingMethod } from "shared-lib/product-utils";
import { Input } from "./types";
import {
  ComponentInput,
  InputMapperSuccess,
  InputMapperError,
  createInputMapperError,
  createInputMapperSuccess,
  InputParam,
  ResultQuery,
} from "../types";
import * as Messages from "../messages";
import * as Attributes from "../shared/attributes";
import { SpeedControl } from "../result-items-types";
import * as AirDensity from "../shared/air-density";

const msgSource = "SystemairHeatRecoveryUnitInputMapper";

export function getCalcParams(_: string, attributes: Attributes.Attributes): ReadonlyArray<InputParam> {
  const inputParams: Array<InputParam> = [
    {
      type: "Amount",
      group: "calculationParams",
      name: "supplyAirFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "supplyAirFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
      calculate: autofillExtractAirFlow,
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "extractAirFlow",
      quantity: "VolumeFlow",
      fieldName: "airFlow",
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "extractAirFlow=0.1:CubicMeterPerHour~999999:CubicMeterPerHour",
        CustomUnitsLookup
      ),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "supplyPressure",
      quantity: "Pressure",
      fieldName: "airPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty("supplyPressure=0.1:Pascal~999999:Pascal", CustomUnitsLookup),
      calculate: autofillExtractPressure,
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "extractPressure",
      quantity: "Pressure",
      fieldName: "airPressure",
      validationFilter: PropertyFilter.fromStringOrEmpty("extractPressure=0.1:Pascal~999999:Pascal", CustomUnitsLookup),
    },
    {
      type: "Discrete",
      group: "calculationParams",
      name: "airDensityCalculationMethod",
      values: [
        {
          value: 0,
          name: "AirDensity",
        },
        {
          value: 1,
          name: "Pressure",
        },
        {
          value: 2,
          name: "SeaLevel",
        },
      ],
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensity",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=0", CustomUnitsLookup),
      quantity: "Density",
      fieldName: "airDensity",
      defaultValue: Amount.create(1.204, Units.KilogramPerCubicMeter),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensity>0:KilogramPerCubicMeter", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityPressure",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=1", CustomUnitsLookup),
      quantity: "Pressure",
      fieldName: "airPressure",
      defaultValue: Amount.create(101325, Units.Pascal),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityPressure>0:Pascal", CustomUnitsLookup),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensitySeaLevel",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod=2", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensitySeaLevel!=null", CustomUnitsLookup),
      quantity: "Length",
      fieldName: "airDensitySeaLevel",
      defaultValue: Amount.create(0, Units.Meter),
    },

    {
      type: "Amount",
      group: "calculationParams",
      name: "airDensityTemperature",
      visibilityFilter: PropertyFilter.fromStringOrEmpty("airDensityCalculationMethod!=0", CustomUnitsLookup),
      validationFilter: PropertyFilter.fromStringOrEmpty("airDensityTemperature!=null", CustomUnitsLookup),
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(20, Units.Celsius),
    },

    {
      type: "Discrete",
      group: "climateData",
      name: "season",
      values: [
        {
          value: 0,
          name: "winter",
        },
        {
          value: 1,
          name: "summer",
        },
      ],
    },
    {
      type: "Amount",
      group: "climateData",
      name: "freshTemperatureWinter",
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(-20, Units.Celsius),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "freshTemperatureWinter=-60:Celsius~80:Celsius",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=0", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "freshHumidityWinter",
      quantity: "RelativeHumidity",
      fieldName: "airHumidity",
      defaultValue: Amount.create(90, Units.PercentHumidity),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "freshHumidityWinter=0:PercentHumidity~100:PercentHumidity",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=0", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "extractTemperatureWinter",
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(22, Units.Celsius),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "extractTemperatureWinter=-60:Celsius~80:Celsius",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=0", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "extractHumidityWinter",
      quantity: "RelativeHumidity",
      fieldName: "airHumidity",
      defaultValue: Amount.create(40, Units.PercentHumidity),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "extractHumidityWinter=0:PercentHumidity~100:PercentHumidity",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=0", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "freshTemperatureSummer",
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(30, Units.Celsius),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "freshTemperatureSummer=-60:Celsius~80:Celsius",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=1", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "freshHumiditySummer",
      quantity: "RelativeHumidity",
      fieldName: "airHumidity",
      defaultValue: Amount.create(60, Units.PercentHumidity),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "freshHumiditySummer=0:PercentHumidity~100:PercentHumidity",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=1", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "extractTemperatureSummer",
      quantity: "Temperature",
      fieldName: "airTemperature",
      defaultValue: Amount.create(22, Units.Celsius),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "extractTemperatureSummer=-60:Celsius~80:Celsius",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=1", CustomUnitsLookup),
    },
    {
      type: "Amount",
      group: "climateData",
      name: "extractHumiditySummer",
      quantity: "RelativeHumidity",
      fieldName: "airHumidity",
      defaultValue: Amount.create(40, Units.PercentHumidity),
      validationFilter: PropertyFilter.fromStringOrEmpty(
        "extractHumiditySummer=0:PercentHumidity~100:PercentHumidity",
        CustomUnitsLookup
      ),
      visibilityFilter: PropertyFilter.fromStringOrEmpty("season=1", CustomUnitsLookup),
    },
  ];
  if (
    getHeatingMethod(attributes) !== "ambient"
  ) {
    return [
      ...inputParams,
      {
        type: "Amount",
        group: "heating",
        name: "supplyOutletTemperature",
        quantity: "Temperature",
        fieldName: "airTemperature",
      },
    ];
  } else if (Attributes.getString("CL-heat-exchanger-type", attributes) === undefined) {
    return [
      ...inputParams,
      {
        type: "Amount",
        group: "performanceFilters",
        name: "maxSfp",
        quantity: "SpecificFanPower",
        fieldName: "sfp",
      },
      {
        type: "Amount",
        group: "performanceFilters",
        name: "minHeatExchangeEfficiency",
        quantity: "Dimensionless",
        fieldName: "efficiency",
      },
    ];
  }
  return inputParams;
}

function autofillExtractAirFlow(calcParams: PropertyValueSet.PropertyValueSet): PropertyValueSet.PropertyValueSet {
  const supply = PropertyValueSet.getAmount("supplyAirFlow", calcParams);
  return supply ? PropertyValueSet.setAmount("extractAirFlow", supply, calcParams) : calcParams;
}

function autofillExtractPressure(calcParams: PropertyValueSet.PropertyValueSet): PropertyValueSet.PropertyValueSet {
  const supply = PropertyValueSet.getAmount("supplyPressure", calcParams);
  return supply ? PropertyValueSet.setAmount("extractPressure", supply, calcParams) : calcParams;
}

export function getQuery(productId: string, variantId: string | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    property: QP.tableByProductId(productId, "property"),
    code: QP.tableByProductId(productId, "code"),
    ct_ItemNo: QP.tableByProductId(productId, "ct_ItemNo"),
    ct_VariantNo: QP.tableByProductId(productId, "ct_VariantNo"),
    ct_AirData2: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirData2"),
    ct_AirLimitsData: QP.tableFromMtOrByProductId(productId, variantId, "ct_AirLimitsData"),
    ct_SoundData: QP.tableFromMtOrByProductId(productId, variantId, "ct_SoundData"),
    ct_TempEfficiencyCorrection: QP.tableByProductId(productId, "ct_TempEfficiencyCorrection"),
  });
}

export function getResultsQuery(): ReadonlyArray<ResultQuery> {
  return [];
}

export interface Response {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_AirData2: QP.AirDataTable;
  readonly ct_AirLimitsData: QP.AirLimitsDataTable;
  readonly ct_SoundData: QP.SoundDataTable;
  readonly ct_TempEfficiencyCorrection: QP.TempEfficiencyCorrectionTable;
}

export function map(
  { properties, calcParams, attributes }: ComponentInput,
  queryResultMap: Response
): InputMapperSuccess<Input> | InputMapperError {
  const supplyAirFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("supplyAirFlow", calcParams);
  const supplyPressure = PropertyValueSet.getAmount<Quantity.Pressure>("supplyPressure", calcParams);

  const extractAirFlow = PropertyValueSet.getAmount<Quantity.VolumeFlow>("extractAirFlow", calcParams);
  const extractPressure = PropertyValueSet.getAmount<Quantity.Pressure>("extractPressure", calcParams);

  const tolerancePlus =
    PropertyValueSet.getAmount<Quantity.Dimensionless>("tolerancePlus", calcParams) || Amount.create(50, Units.Percent);
  const toleranceMinus =
    PropertyValueSet.getAmount<Quantity.Dimensionless>("toleranceMinus", calcParams) ||
    Amount.create(-10, Units.Percent);

  const season = PropertyValueSet.getInteger("season", calcParams) === 0 ? "Winter" : "Summer";
  const freshTemperature = PropertyValueSet.getAmount<Quantity.Temperature>("freshTemperature" + season, calcParams);
  const freshHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>("freshHumidity" + season, calcParams);
  const extractTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    "extractTemperature" + season,
    calcParams
  );
  const extractHumidity = PropertyValueSet.getAmount<Quantity.RelativeHumidity>("extractHumidity" + season, calcParams);

  const supplyOutletTemperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    "supplyOutletTemperature",
    calcParams
  );

  const maxSfp = PropertyValueSet.getAmount<Quantity.SpecificFanPower>("maxSfp", calcParams);
  const minHeatExchangeEfficiency = PropertyValueSet.getAmount<Quantity.Dimensionless>(
    "minHeatExchangeEfficiency",
    calcParams
  );

  const tempEfficiencyCorrections = queryResultMap.ct_TempEfficiencyCorrection.filter((r) =>
    PropertyFilter.isValid(properties, r.property_filter)
  );

  const speedControlAttr = attributes.byAttribute["PROP-speed-regulation-type-MULTI"] || [];
  const speedControl = (speedControlAttr[0] || "Transformer") as SpeedControl;

  const airMeasurement = Attributes.getMeasurementNo("measurement-number-air", attributes);
  const soundMeasurement = Attributes.getMeasurementNo("measurement-number-sound", attributes);
  const airData = (airMeasurement && queryResultMap.ct_AirData2[airMeasurement]) || [];
  const airLimitsData = (airMeasurement && queryResultMap.ct_AirLimitsData[airMeasurement]) || [];
  const soundData = (soundMeasurement && queryResultMap.ct_SoundData[soundMeasurement]) || [];

  const productCodes = PC.getProductCodes(queryResultMap, properties);
  const variantValue = PropertyValueSet.getValue("variant", properties);
  // eslint-disable-next-line
  const variantProperty = queryResultMap.property.find((p: any) => p.name === "variant");
  const variantPropertyValue =
    // eslint-disable-next-line
    variantProperty && variantProperty.value.find((v: any) => PropertyValue.equals(variantValue, v.value));
  const variantName = variantPropertyValue ? variantPropertyValue.description : "";
  const airDensity = AirDensity.getAirDensityKgPerCubicMeter(calcParams);
  if (
    !freshTemperature ||
    !freshHumidity ||
    !extractTemperature ||
    !extractHumidity ||
    !speedControl ||
    airData.length === 0
  ) {
    console.warn({
      attributes,
      airMeasurement,
      freshTemperature,
      freshHumidity,
      extractTemperature,
      extractHumidity,
      speedControl,
      airData,
    });
    return createInputMapperError([Messages.ProductDataError_NoDataAvailable(msgSource)]);
  }

  return createInputMapperSuccess<Input>({
    supplyAirFlow: supplyAirFlow,
    supplyPressure: supplyPressure,
    freshTemperature: freshTemperature,
    freshHumidity: freshHumidity,
    tolerancePlus: tolerancePlus,
    toleranceMinus: toleranceMinus,

    extractAirFlow: extractAirFlow,
    extractPressure: extractPressure,
    extractTemperature: extractTemperature,
    extractHumidity: extractHumidity,
    airDensity: airDensity,

    supplyOutletTemperature: supplyOutletTemperature,

    maxSfp: maxSfp,
    minHeatExchangeEfficiency: minHeatExchangeEfficiency,

    attributes: attributes,
    speedControl: speedControl as SpeedControl,
    tempEfficiencyCorrections: tempEfficiencyCorrections,
    airData: airData,
    airLimitsData: airLimitsData,
    soundData: soundData,
    variantName: variantName,
    productCodes: productCodes,
  });
}
