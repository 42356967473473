import { createSelector, Selector } from "reselect";
import { diaqConnect } from "client-lib/redux-integration";
import * as QD from "shared-lib/query-diaq";
import * as PU from "shared-lib/product-utils";
import * as QP from "shared-lib/query-product";
import * as QI from "shared-lib/query-identity";
import * as QEcom from "shared-lib/query-ecom";
import * as UserSettings from "client-lib/user-settings";
import * as Compare from "client-lib/compare";
import * as Texts from "shared-lib/language-texts";
import * as C from "shared-lib/calculation";
import { Response, OwnProps, StateProps, ProductTables } from "./types";
import { RootState } from "../../types";
import { ProductContainerComponent } from "./container-component";
// import * as Crm from "shared-lib/crm";

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  (s: RootState) => s.ui.product,
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (s: RootState) => s.crm,
  Compare.selectComparedProduct,
  (state, showAdvanced, userSettings, crmState, comparedProduct) => ({
    state: state,
    showAdvanced: showAdvanced,
    userSettings: userSettings,
    crmState: crmState,
    comparedProduct: comparedProduct,
  })
);

function mapPropsToQuery(props: OwnProps & StateProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    productId: QP.productIdByM3ItemNo(props.m3ItemNo, props.productId),
    variantNoToPrice: props.crm ? QEcom.variantNoToPrice(props.ecomUrl, props.market) : QD.createMapQuery({}),
    accessoryNoToPrice: QEcom.variantNoToPrice(props.ecomUrl, props.market),
    ct_DivisionCurrency: QP.tableByProductId(QP.metaProductId, "ct_DivisionCurrency"),
    ct_LanguageMapping: QP.tableByProductId(QP.fricoProductId, "ct_LanguageMapping"),
    translateTables:
      response &&
      response.productTables?.ct_Accessories &&
      Texts.getTablesQuery([response.productId, ...response.productTables.ct_Accessories.map((a) => a.product)]),
    productTables: response && productTablesQuery(props, response),
    accessoryTables: accessoryTablesQuery(response),
    marketTables: PU.createMarketTablesQuery(props.ecomUrl, props.market),
    metaTables: QP.tablesByProductId(QP.metaProductId, [
      "ct_ResultItems",
      "ct_MarketSettings",
      "ct_AttributeTemplateMapping",
    ]),
    calculationResponse:
      response !== undefined && response.productId !== undefined
        ? C.calculationQuery(
            { productId: response.productId, config: props.config, variantId: props.variant },
            response && response.calculationResponse
          )
        : undefined,
    comparedProductTables: props.comparedProduct
      ? QP.tablesByProductId(props.comparedProduct.productId, ["ct_DiaqTemplates", "ct_Attributes2"])
      : QI.createIdentityQuery(null),
  });
}

function productTablesQuery(ownProps: OwnProps, response: Response): QD.DiaqMapQuery<ProductTables> {
  return QD.createMapQuery<ProductTables>({
    property: QP.tableByProductId(response.productId, "property"),
    code: QP.tableByProductId(response.productId, "code"),
    ct_ItemNo: QP.tableByProductId(response.productId, "ct_ItemNo"),
    ct_DiaqTemplates: QP.tableByProductId(response.productId, "ct_DiaqTemplates"),
    ct_AirFlowStep: QP.tableByProductId(response.productId, "ct_AirFlowStep"),
    ct_CalcParamDefault: QP.tableByProductId(response.productId, "ct_CalcParamDefault"),
    ct_Accessories: QP.tableByProductId(response.productId, "ct_Accessories"),
    ct_VariantNo: QP.tableByProductId(response.productId, "ct_VariantNo"),
    ct_Attributes2: QP.tableFromMtOrByProductId(response.productId, ownProps.variant, "ct_Attributes2"),
  });
}

function accessoryTablesQuery(response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of response.productTables.ct_Accessories) {
    map[accessory.product] = QP.tablesByProductId(accessory.product, [
      "property",
      "code",
      "ct_ItemNo",
      "ct_DiaqTemplates",
      "ct_CalcParamDefault",
      "ct_Accessories",
      "ct_Attributes2",
      "ct_VariantNo",
    ]);
  }
  return QD.createMapQuery(map);
}

export const ProductContainer = diaqConnect(mapPropsToQuery, mapStateToProps)(ProductContainerComponent);
