/* eslint-disable no-restricted-globals */
import { GroupValues, FieldUnitSettings, getFieldUnitsFromUriComponent } from "shared-lib/user-settings";

export function setFieldUnitsCookie(fieldUnits: GroupValues): void {
  const cookieValue = encodeURIComponent(JSON.stringify(fieldUnits));
  const domainParts = location.hostname.split(".");
  const domainPart = domainParts.length >= 2 ? domainParts.slice(-2).join(".") : location.hostname;
  const domain = domainPart === "localhost" ? domainPart : `.${domainPart}`;
  const maxAge = 3600 * 24 * 30 * 3;
  document.cookie = `fieldUnits=${cookieValue};max-age=${maxAge};domain=${domain};path=/`;
}

export function getFieldUnitsFromCookie(): FieldUnitSettings | undefined {
  // eslint-disable-next-line no-useless-escape
  const value = document.cookie.replace(/(?:(?:^|.*;\s*)fieldUnits\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  if (!value) {
    return undefined;
  }
  return getFieldUnitsFromUriComponent(value);
}
