import * as R from "ramda";
import { retryFetch } from "shared-lib/fetch";
import { CacheLoadRequest, UrlCacheState } from "./types";

export async function executeLoadRequest(
  cache: UrlCacheState,
  cacheLoadRequest: CacheLoadRequest
): Promise<UrlCacheState> {
  cache = await loadUrls(cache, cacheLoadRequest.blobUrls, cacheLoadRequest.jsonUrls);
  return cache;
}

// Remove everything that is already in the cache
export function optimizeLoadRequest(cache: UrlCacheState, cacheLoadRequest: CacheLoadRequest): CacheLoadRequest {
  const neededBlobUrls: Array<string> = [];
  for (const url of cacheLoadRequest.blobUrls) {
    const existing = cache.blobByUrl[url];
    const duplicate = neededBlobUrls.indexOf(url) !== -1;
    if (existing === undefined && !duplicate) {
      neededBlobUrls.push(url);
    }
  }

  const neededJsonUrls: Array<string> = [];
  for (const url of cacheLoadRequest.jsonUrls) {
    const existing = cache.jsonByUrl[url];
    const duplicate = neededJsonUrls.indexOf(url) !== -1;
    if (existing === undefined && !duplicate) {
      neededJsonUrls.push(url);
    }
  }

  return {
    blobUrls: neededBlobUrls,
    jsonUrls: neededJsonUrls,
  };
}

async function loadUrls(
  cache: UrlCacheState,
  blobUrls: ReadonlyArray<string>,
  jsonUrls: ReadonlyArray<string>
): Promise<UrlCacheState> {
  const blobPromises = blobUrls.map((url) => fetchUrlBlob(url));
  const jsonPromises = jsonUrls.map((url) => fetchUrlJson(url));
  const results = await Promise.all([...blobPromises, ...jsonPromises]);
  const newBlobs = R.fromPairs(
    R.take(blobPromises.length, results).map((b) => [b.url, b.data] as R.KeyValuePair<R.Prop, Uint8Array>)
  );
  const newJsons = R.fromPairs(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    R.drop(blobPromises.length, results).map((b) => [b.url, b.data] as R.KeyValuePair<R.Prop, any>)
  );
  const amendedCache: UrlCacheState = {
    ...cache,
    blobByUrl: { ...cache.blobByUrl, ...newBlobs },
    jsonByUrl: { ...cache.jsonByUrl, ...newJsons },
  };
  return amendedCache;
}

interface UrlBlob {
  readonly url: string;
  readonly data: Uint8Array;
}

export function fetchUrlBlob(url: string): Promise<UrlBlob> {
  return new Promise((resolve, reject) => {
    retryFetch(url, { redirect: "follow" })
      .then((response: Response) => response.arrayBuffer())
      .then((buffer: ArrayBuffer) => resolve({ url: url, data: new Uint8Array(buffer) }))
      .catch((e) => reject(e));
  });
}

interface UrlJson {
  readonly url: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly data: any;
}

export function fetchUrlJson(url: string): Promise<UrlJson> {
  return new Promise((resolve, reject) => {
    retryFetch(url, { redirect: "follow" })
      .then((response: Response) => response.json())
      .then((json) => resolve({ url: url, data: json }))
      .catch((e) => reject(e));
  });
}
