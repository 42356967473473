import { AnyQuantity } from "shared-lib/uom";
import { Unit, Amount } from "uom";

export function convertFromTo(value: number, from: Unit.Unit<AnyQuantity>, to: Unit.Unit<AnyQuantity>): number {
  return Amount.valueAs(to, Amount.create(value, from));
}

export function maybeAmount<T extends AnyQuantity>(
  value: number | undefined,
  unit: Unit.Unit<T>,
  decimals: number
): Amount.Amount<T> | undefined {
  return value !== undefined ? Amount.create(value, unit, decimals) : undefined;
}

export function amountPercentDifference(
  a: Amount.Amount<AnyQuantity> | undefined,
  b: Amount.Amount<AnyQuantity> | undefined
): number {
  if (!a || !b) {
    return 0;
  }
  const unit = a.unit;
  const aValue = Amount.valueAs(unit, a);
  const bValue = Amount.valueAs(unit, b);
  const minValue = Math.min(aValue, bValue);
  const maxValue = Math.max(aValue, bValue);
  const difference = 1 - minValue / maxValue;
  return difference * 100;
}
