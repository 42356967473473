import { Amount } from "uom";
import { Quantity, Units } from "uom-units";
import * as Types from "../types";
import * as Messages from "../messages";
import { BoxFanNicotra, NicotraAir, NicotraProduct, OctaveBands } from "../result-items-types";
import { Input } from "./types";
import * as NicotraDLL from "../dll/nicotra";

export async function calculate(input: Input): Promise<Types.CalculatorResult<BoxFanNicotra>> {
  const {
    calcParams,
    fanSeries,
    fanSize,
    maxShaftPowerKw,
    motorPowerKw,
    airFlow,
    soundPressureDistance,
    externalPressure,
    frequency,
    airDensity,
    accessoryPressureDrop,
    fanCondition,
    motorCurrent,
    fanType,
  } = input;
  const messages: Array<Messages.Message> = [];

  if (!airFlow || !externalPressure || !soundPressureDistance) {
    return Types.createCalculatorError([Messages.Error_CalculationInputMissing("Nicotra")]);
  }
  const airFlowm3h = Amount.valueAs(Units.CubicMeterPerHour, airFlow);
  const externalPressurePa = Amount.valueAs(Units.Pascal, externalPressure);
  const soundPressureDistanceM = Amount.valueAs(Units.Meter, soundPressureDistance);

  const externalPressurePaWithAccessories =
    externalPressurePa +
    accessoryPressureDrop.reduce((sum, accessory) => {
      return (sum += Amount.valueAs(Units.Pascal, accessory.pressure_drop));
    }, 0);

  const calculationResult = await NicotraDLL.calculate({
    Airflow: airFlowm3h,
    Pressure: externalPressurePaWithAccessories,
    FanSeries: fanSeries,
    Frequency: frequency,
    Distance: soundPressureDistanceM,
  });

  const fixedResult =
    fanCondition !== undefined
      ? calculationResult.map((t) =>
          t.FanCode.startsWith("DD") && t.FanCode.indexOf("-60") > -1
            ? { ...t, FanCode: t.FanCode.replace("-60", "") }
            : t
        )
      : calculationResult;

  const dllResult = fanCondition
    ? fixedResult.find(
        (t) => t.FanDiameter === fanSize && t.FanShaftPower <= maxShaftPowerKw && t.FanCode === fanCondition.condition
      )
    : fixedResult.find((t) => t.FanDiameter === fanSize && t.FanShaftPower <= maxShaftPowerKw);

  if (dllResult === undefined) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange("Nicotra")]);
  }

  const air: NicotraAir = {
    airFlow: Amount.create(dllResult.Airflow, Units.CubicMeterPerHour),
    externalPressure: Amount.create(dllResult.AirPressure, Units.Pascal),
    efficiency: dllResult.FanEfficiency > 0 ? Amount.create(dllResult.FanEfficiency, Units.Percent) : undefined,
    totalPressure: Amount.create(dllResult.TotalPressure, Units.Pascal),
    fanSpeed:
      dllResult.FanRPM !== 0
        ? Amount.create(dllResult.FanRPM, Units.RevolutionsPerMinute)
        : dllResult.MotorSpeed !== 0
        ? Amount.create(dllResult.MotorSpeed, Units.RevolutionsPerMinute)
        : undefined,
    airVelocity: Amount.create(dllResult.OutletVelocity, Units.MeterPerSecond),
    absorbedPower: dllResult.AbsorbedPower > 0 ? Amount.create(dllResult.AbsorbedPower, Units.KiloWatt) : undefined,
    fanShaftPower: dllResult.FanShaftPower > 0 ? Amount.create(dllResult.FanShaftPower, Units.KiloWatt) : undefined,
    airDensity: airDensity,
  };

  const [motorModel, motorVoltage] = getMotorModelAndVoltage(dllResult.MotorModel, frequency);

  const product: NicotraProduct = {
    family: fanType,
    size: getFanSize(fanSize),
    motor: motorModel,
    motorPower: Amount.create(motorPowerKw, Units.KiloWatt),
    motorVoltage: Amount.create(motorVoltage, Units.Volt),
    motorCurrent: Amount.create(motorCurrent, Units.Ampere), // TODO
  };

  return Types.createCalculatorSuccess(
    [{ value: fanSize, descending: false }],
    {
      air,
      product,
      diagramBase64: dllResult.DiagramBase64Png || undefined,
      accessories: accessoryPressureDrop.map((t) => t.name),

      soundPressureDistance: soundPressureDistance,
      fanInlet: createSoundResult(dllResult.SoundInlet, dllResult.InletDBA),
      fanOutlet: createSoundResult(dllResult.SoundOutlet, dllResult.SoundPowerOutletDBA),
      unitBreakout: createSoundResult(dllResult.SoundBreakOut, dllResult.UnitBreakOutDBA),
      soundPressureLevel: createSoundResult(dllResult.SoundPressure, dllResult.SoundPressureDBA),
    },
    messages,
    calcParams
  );
}

function getMotorModelAndVoltage(dllMotorModel: string, frequency: number): readonly [string, number] {
  const phases = dllMotorModel.indexOf("3~") > -1 ? 3 : 1;
  const voltage = phases === 1 ? 230 : frequency === 50 ? 400 : 460;
  return [`${phases}˜-${voltage}-${frequency}`, voltage];
}

function getFanSize(fanSize: string): string {
  switch (fanSize) {
    case "0500":
      return "20-20";
    case "0560":
      return "22-22";
    case "0630":
      return "25-25";
    case "0710":
      return "30-28";
    default:
      return fanSize.replace("/", "-");
  }
}

function createSoundResult(
  octaveBands: NicotraDLL.NicotraSoundResult | undefined,
  totalSound: number
): OctaveBands | undefined {
  if (
    octaveBands === undefined ||
    totalSound < 1 ||
    Object.keys(octaveBands).every((k) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const oct = octaveBands as any;
      return oct[k] === 0;
    }) === true
  ) {
    return undefined;
  }
  return {
    type: "Octave",
    hz63: octaveBands.Hz63,
    hz125: octaveBands.Hz125,
    hz250: octaveBands.Hz250,
    hz500: octaveBands.Hz500,
    hz1000: octaveBands.Hz1000,
    hz2000: octaveBands.Hz2000,
    hz4000: octaveBands.Hz4000,
    hz8000: octaveBands.Hz8000,
    total: Amount.create<Quantity.SoundPowerLevel>(totalSound, Units.DecibelLw),
  };
}
