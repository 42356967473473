import * as React from "react";
import * as SC from "shared-lib/system-calculator";
import { GetUnitFunction, GetDecimalsFunction } from "shared-lib/user-settings";
import * as FD from "shared-lib/system-calculator/shared/boxfan-diagram";
import * as AbstractChart from "shared-lib/abstract-chart";
import * as AbstractImage from "abstract-image";
import * as Texts from "shared-lib/language-texts";
import { FormatNumberFunction } from "shared-lib/utils";

export interface Props {
  readonly results: SC.ResultItemOutputMap;
  readonly getUnit: GetUnitFunction;
  readonly getDecimals: GetDecimalsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly translate: Texts.TranslateFunction;
}

const diagramSize = AbstractImage.createSize(200, 150);

export function BoxFanDiagram(props: Props): React.ReactElement<{}> {
  const { results, getUnit, translate, getDecimals } = props;

  const maybeResultItem = results["BoxFan"];
  const resultItem = maybeResultItem && maybeResultItem.type === "OutputMapperSuccess" && maybeResultItem.result;
  const boxFan = resultItem && resultItem.type === "BoxFan" && resultItem.value.air;
  if (!boxFan) {
    return <div />;
  }

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);
  const powerUnit = getUnit("power", "Power");

  const { pressure, power } = FD.generateCharts({
    fan: boxFan,
    flowUnit,
    pressureUnit,
    translate,
    powerUnit,
    showRpmCurves: false,
    showEfficiencyCurves: false,
    showMotorPowerCurves: false,
    showLineLabels: false,
    showActualRpmCurve: true,
    showActualRpmCurveLabel: false,
  });

  const pressureWithDecimals = {
    ...pressure,
    linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
    linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
  };

  const powerWithDecimals = {
    ...power,
    linesAndTextXAxisNoOfDecimals: flowUnitDecimals,
    linesAndTextYAxisNoOfDecimals: pressureUnitDecimals,
  };

  const abstractImagePressure = AbstractChart.renderChart(pressureWithDecimals, diagramSize, {
    allowTextOverlap: true,
    formatNumber: props.formatNumber.format,
  });
  const renderedPressure = AbstractImage.createReactSvg(abstractImagePressure);

  const abstractImagePower = AbstractChart.renderChart(powerWithDecimals, diagramSize, {
    allowTextOverlap: true,
    formatNumber: props.formatNumber.format,
  });
  const renderedPower = AbstractImage.createReactSvg(abstractImagePower);

  return (
    <div>
      {renderedPressure} {renderedPower}
    </div>
  );
}
