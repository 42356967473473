import * as React from "react";
import * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";
import * as UserSettings from "shared-lib/user-settings";
import { getAmcaStatements } from "shared-lib/amca-statements";
import * as UserSettingsClient from "client-lib/user-settings";
import { DispatchProp } from "client-lib/redux-integration";
import { VisualizerOwnProps } from "../types";
import { Heading4 } from "client-lib/elements";

export type Props = VisualizerOwnProps & Response & StateProps & DispatchProp<UserSettingsClient.Action>;

export interface Response {
  readonly metaTables: {
    readonly ct_MarketUnits: QP.MarketUnitsTable;
  };
  readonly allProductTables: AllProductTables;
}

export interface AllProductTables {
  readonly [configKey: string]: {
    readonly ct_AmcaStatements: QP.AmcaStatementsTable;
  };
}

export interface StateProps {
  readonly userSettings: UserSettings.State;
}

export function AmcaStatementsVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { userSettings, market, language, translate, metaTables, allProductTables, products, visualizerParams } = props;
  const { ct_MarketUnits } = metaTables;

  const getUnit = UserSettings.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });

  const getExtraText = UserSettings.getExtraText({
    market,
    ct_MarketUnits,
  });

  const soundFilter = UserSettings.getSoundFilter(userSettings);

  const amcaStatements = products.map((p) => {
    if (!p) {
      return [];
    }
    const { ct_AmcaStatements } = allProductTables[p.configKey];
    return getAmcaStatements(
      getUnit,
      getExtraText,
      translate,
      ct_AmcaStatements,
      soundFilter,
      visualizerParams,
      language,
      p.variant
    );
  });

  if (amcaStatements.every((s) => s.length === 0)) {
    return <span />;
  }

  return (
    <div>
      <Heading4>{translate(Texts.amca_statements())}</Heading4>
      <div className="flex flex-row w-full space-x-16">
        {amcaStatements.map((stmts, si) => (
          <ul key={si} className="pl-0 list-none text-sm space-y-8">
            {stmts.map((s, i) => (
              <li key={i}>{s}</li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
}
