/* eslint-disable @typescript-eslint/no-explicit-any */
import { exhaustiveCheck } from "shared-lib/exhaustive-check";

type SendMessages = Resize | ResultClick | ConfigResult | PrintResult | InputChanged | Ready;
type RecieveMessages = GetConfig | GetPrintItems | SetItem;

interface GetConfig {
  readonly type: "getConfig";
}

interface GetPrintItems {
  readonly type: "getPrintItems";
}

interface SetItem {
  readonly type: "setItem";
  readonly itemNo: string;
  readonly variantId?: string;
  readonly config?: string;
}

interface ConfigResult {
  readonly type: "configResult";
  readonly data: string | undefined;
}

interface PrintResult {
  readonly type: "printResult";
  readonly data: any;
}

interface Ready {
  readonly type: "ready";
  readonly data: string | undefined;
}

interface InputChanged {
  readonly type: "inputChanged";
  readonly data: any;
}

interface Resize {
  readonly type: "resize";
  readonly data: number;
}

interface ResultClick {
  readonly type: "resultClick";
  readonly data: {
    readonly itemnumber: string;
    readonly variantId: string | undefined;
    readonly config: string | undefined;
  };
}

function postMessage(messageToSend: SendMessages, event?: MessageEvent): void {
  if (!postEnabled()) {
    return;
  }
  if (event === undefined || event.source === null) {
    window.parent.postMessage(messageToSend, "*");
  } else {
    // @ts-ignore
    event.source.postMessage(messageToSend, "*");
  }
}

// eslint-disable-next-line functional/no-let
let lastHeight = 0;
// When we know we have resized the application
function postResizeEvent(): void {
  const appHeight = document.getElementById("app-container")?.offsetHeight || 2000;
  if (lastHeight !== appHeight) {
    postMessage({ type: "resize", data: appHeight });
    lastHeight = appHeight;
  }
}

// When user changes inputs when viewing the performance of one variant
export function postInputChangedEvent(config: string | undefined): void {
  postMessage({ type: "inputChanged", data: config });
}

// When the application has loaded and is ready for inputs
export function postReadyEvent(config: string | undefined): void {
  postMessage({ type: "ready", data: config });
}

// When user clicks on a result
export function postClickEvent(itemnumber: string, variantId: string | undefined, config: string | undefined): void {
  postMessage({ type: "resultClick", data: { itemnumber, variantId, config } });
}

// Only sent as an answer to a message
function postConfigResult(config: string | undefined, event?: MessageEvent): void {
  postMessage({ type: "configResult", data: config }, event);
}

// Only sent as an answer to a message
function postPrintResult(printItems: any, event?: MessageEvent): void {
  postMessage({ type: "printResult", data: printItems }, event);
}

function startEventListener(): void {
  console.log("started listening");
  window.addEventListener(
    "message",
    (event: MessageEvent) => {
      // if (event.origin !== "http://example.com:8080") {
      //   return;
      // }
      handleRecievedMessage(event);
    },
    false
  );
}
// eslint-disable-next-line functional/no-let
let resizeObserver: ResizeObserver | undefined;

function startResizeObserver(): void {
  if (!resizeObserver) {
    resizeObserver = new ResizeObserver((_entries) => {
      postResizeEvent();
    });
    console.log("resizeObserver initialized");
  }

  const appContainer = document.getElementById("app-container");
  if (appContainer) {
    resizeObserver.observe(appContainer);
  }
}

export function startPubSubPostMessage(): void {
  if (!postEnabled()) {
    return;
  }
  startResizeObserver(); // Post messages on resize event of app-container
  startEventListener(); // Listen to messages from parent
}

function handleRecievedMessage(event: MessageEvent): void {
  if (event.source === null) {
    return;
  }
  const data = event.data as RecieveMessages;
  switch (data.type) {
    case "getConfig":
      (window as any).Ganymed.getConfig().then((result: string | undefined) => postConfigResult(result, event));
      break;
    case "getPrintItems":
      (window as any).Ganymed.getPrintItems().then((result: any) => postPrintResult(result, event));
      break;
    case "setItem":
      if (data.itemNo) {
        (window as any).Ganymed.setItem(data.itemNo, data.variantId, data.config);
      }
      break;
    default:
      exhaustiveCheck(data, false);
  }
}

//export because used in index.html
export function postEnabled(): boolean {
  return inIframe();
}

function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
