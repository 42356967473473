import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import { Button, withTw, HToolbar, Textfield, TextAreaInput } from "client-lib/elements";
import { TranslateFunction } from "shared-lib/language-texts";
import { Dispatch } from "client-lib/redux-integration";
import * as Actions from "../actions";

const InputLabel = withTw("div", "w-144");

interface Props {
  readonly projectName: string;
  readonly projectComments: string;
  readonly dispatch: Dispatch<Actions.Action>;
  readonly translate: TranslateFunction;
}

export function ProjectSpecification({
  projectName,
  projectComments,
  dispatch,
  translate,
}: Props): React.ReactElement<{}> {
  return (
    <div>
      <div className="flex flex-col space-y-24">
        <div className="max-w-xl">
          <InputLabel>{translate(Texts.project_name())}</InputLabel>
          <Textfield value={projectName} onChange={(value) => dispatch(Actions.updateProjectName(value))} />
        </div>
        <div className="max-w-xl">
          <InputLabel>{translate(Texts.comments())}</InputLabel>
          <TextAreaInput value={projectComments} onChange={(value) => dispatch(Actions.updateProjectComments(value))} />
        </div>
        <HToolbar>
          <Button label={translate(Texts.reset())} clicked={() => dispatch(Actions.reset())} />
          <Button
            label={translate(Texts.create())}
            clicked={() => {
              window.history.pushState("print", "");
              dispatch(Actions.displayPrint(true));
            }}
          />
        </HToolbar>
      </div>
    </div>
  );
}
