import { retryFetch } from "shared-lib/fetch";

export interface RecutechInput {
  readonly Type: number;
  readonly Width: number;
  readonly AtmosphericPressure: number;
  readonly FreshAirFlow: number;
  readonly FreshAirTemperature: number;
  readonly FreshAirRelativeHumidity: number;
  readonly ExtractAirFlow: number;
  readonly ExtractAirTemperature: number;
  readonly ExtractAirRelativeHumidity: number;
}

export interface RecutechOutput {
  readonly SupplyAirTemperature: number;
  readonly ExhaustAirTemperature: number;
  readonly DryEfficiency: number;
  readonly WetEfficiency: number;
  readonly SupplyAirPressureDrop: number;
  readonly ExhaustAirPressureDrop: number;
  readonly SupplyRelativeHumidity: number;
  readonly ExhaustRelativeHumidity: number;
  readonly TransferredPowerSensible: number;
  readonly Condensate: number;
  readonly Messages: ReadonlyArray<RecutechMessage>;
}

export interface RecutechError {
  readonly Message: string;
}

export interface RecutechMessage {
  readonly Id: number;
  readonly Content: string;
}

export async function calculate(input: RecutechInput): Promise<RecutechOutput | undefined> {
  const result = await retryFetch("https://design-dllcal.systemair.com/api/recutech/calculate", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  }).then((r) => r.json());

  if (result["Message"]) {
    console.error("Error from Recutech calculation service: " + result["Message"]);
    return undefined;
  }

  return result;
}
