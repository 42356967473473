import * as React from "react";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import { filterPrettyPrintIndented } from "@promaster-sdk/property-filter-pretty";
import { UnitsFormat } from "uom-units";
import { CustomUnitsLookup } from "shared-lib/uom";
import * as Texts from "shared-lib/language-texts";
import * as QP from "shared-lib/query-product";
import * as UserSettings from "shared-lib/user-settings";
import * as UserSettingsClient from "client-lib/user-settings";
import { Dispatch } from "client-lib/redux-integration";
import { FormatNumberFunction } from "shared-lib/utils";
import { Group } from "client-lib/elements";
import { PropertiesColumns } from "client-lib/properties-selector-elements";
import { PropertySelector } from "./property-selector";
import { createFilterPrettyPrintMessages } from "./filter-pretty-print-messages";
import * as Shared from "./shared";

export interface PropertyGroupProps {
  readonly productId: string;
  readonly group: string;
  readonly properties: ReadonlyArray<PromasterPropertiesSelector.Property>;
  readonly allProperties: ReadonlyArray<PromasterPropertiesSelector.Property>;
  readonly hiddenProperties: ReadonlyArray<string>;
  readonly variants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly selections: Shared.Selections;
  readonly selectionsChanged: Shared.SelectionsChanged;
  readonly translate: Texts.TranslateFunction;
  readonly translatePropertyName?: PromasterPropertiesSelector.TranslatePropertyName;
  readonly translatePropertyValue?: PromasterPropertiesSelector.TranslatePropertyValue;
  readonly selectorTypes: QP.PropertySelectorTypesTable;
  readonly fieldNames: QP.PropertyFieldNamesTable;
  readonly getUnit: UserSettings.GetUnitFunction;
  readonly getDecimals: UserSettings.GetDecimalsFunction;
  readonly getUnits: UserSettings.GetUnitsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly dispatch: Dispatch<UserSettingsClient.Action>;
  readonly showAdvanced: boolean;
  readonly images: Shared.Images;
  readonly hideInvalidValues?: boolean;
  readonly hideEmptyUnitSelectors?: boolean;
  readonly comboBoxImageCSSFilter?: string;
  readonly closed: boolean | undefined;
  readonly onToggleClosed: () => void;
  readonly hideFirstGroupTitle?: boolean;
}

export function PropertyGroup(props: PropertyGroupProps): JSX.Element {
  const {
    productId,
    group,
    properties,
    allProperties,
    variants,
    selections,
    selectionsChanged,
    translate,
    selectorTypes,
    fieldNames,
    getUnit,
    getDecimals,
    formatNumber,
    getUnits,
    dispatch,
    showAdvanced,
    images,
    hideInvalidValues,
    comboBoxImageCSSFilter,
    translatePropertyName,
    translatePropertyValue,
    hideEmptyUnitSelectors,
    hiddenProperties,
    closed,
    onToggleClosed,
    hideFirstGroupTitle,
  } = props;
  const visibleProperties = properties.filter((p) => Shared.isFilterValid(variants, p.visibility_filter));
  if (visibleProperties.length === 0) {
    return <span />;
  }

  // Special case where visibility filters and hiddenproperties cause all properties to be hidden.
  if (visibleProperties.every((v) => hiddenProperties.find((h) => h === v.name))) {
    return <span />;
  }

  const filterPrettyPrint = (filter: PropertyFilter.PropertyFilter): string =>
    filterPrettyPrintIndented(
      createFilterPrettyPrintMessages(productId, translate, fieldNames, getUnit, getDecimals),
      2,
      " ",
      filter,
      UnitsFormat,
      CustomUnitsLookup
    );

  return (
    <>
      <Group
        header={hideFirstGroupTitle ? translate(Texts.property_group(group)) : ""}
        closed={closed}
        onToggleClosed={onToggleClosed}
      >
        <PropertiesColumns>
          {visibleProperties.map((p) => (
            <PropertySelector
              comboBoxImageCSSFilter={comboBoxImageCSSFilter}
              key={p.name}
              hiddenProperties={hiddenProperties}
              productId={productId}
              property={p}
              index={allProperties.findIndex((pr) => pr.name === p.name)}
              variants={variants}
              selections={selections}
              selectionsChanged={selectionsChanged}
              translate={translate}
              translatePropertyName={translatePropertyName}
              translatePropertyValue={translatePropertyValue}
              selectorType={selectorTypes.find((t) => t.name === p.name)}
              getUnit={getUnit}
              getUnits={getUnits}
              getDecimals={getDecimals}
              formatNumber={formatNumber}
              fieldName={fieldNames.find((n) => n.property === p.name)?.field_name}
              dispatch={dispatch}
              filterPrettyPrint={filterPrettyPrint}
              showAdvanced={showAdvanced}
              images={images}
              hideInvalidValues={hideInvalidValues}
              hideEmptyUnitSelectors={hideEmptyUnitSelectors}
            />
          ))}
        </PropertiesColumns>
      </Group>
    </>
  );
}
