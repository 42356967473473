import { PropertyValueSet } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as SC from "shared-lib/system-calculator";
import * as SharedSearch from "shared-lib/search";
import { RehydrateAction } from "redux-persist";
import { Selections } from "../properties-selector-multi";

export type Action =
  | Reset
  | SetSelections
  | SetCalcParams
  | SetAccessories
  | SetGroupBy
  | SetGroupOpen
  | SetSorting
  | SetGroupSorting
  | SetColumnGroup
  | SetNumResults
  | DisableEcomCategory
  | RehydrateAction
  | SaveCrmResult;

export interface Reset {
  readonly type: "search-products/RESET";
}

export function reset(): Reset {
  return {
    type: "search-products/RESET",
  };
}

export interface DisableEcomCategory {
  readonly type: "search-products/DISABLE_ECOM_CATEGORY";
}

export function disableEcomCategory(): DisableEcomCategory {
  return {
    type: "search-products/DISABLE_ECOM_CATEGORY",
  };
}

export interface SetSelections {
  readonly type: "search-products/SET_SELECTIONS";
  readonly payload: {
    readonly searchProductId: string;
    readonly selections: Selections;
  };
}

export function setSelections(selections: Selections, searchProductId: string): SetSelections {
  return {
    type: "search-products/SET_SELECTIONS",
    payload: {
      selections: selections,
      searchProductId: searchProductId,
    },
  };
}

export interface SetCalcParams {
  readonly type: "search-products/SET_CALC_PARAMS";
  readonly payload: {
    readonly searchProductId: string;
    readonly calcParams: PropertyValueSet.PropertyValueSet;
  };
}

export function setCalcParams(calcParams: PropertyValueSet.PropertyValueSet, searchProductId: string): SetCalcParams {
  return {
    type: "search-products/SET_CALC_PARAMS",
    payload: {
      calcParams: calcParams,
      searchProductId: searchProductId,
    },
  };
}

export interface SetAccessories {
  readonly type: "search-products/SET_ACCESSORIES";
  readonly payload: {
    readonly accessories: ReadonlyArray<QP.AccessoryType>;
    readonly searchProductId: string;
  };
}

export function setAccessories(accessories: ReadonlyArray<QP.AccessoryType>, searchProductId: string): SetAccessories {
  return {
    type: "search-products/SET_ACCESSORIES",
    payload: {
      accessories: accessories,
      searchProductId: searchProductId,
    },
  };
}

export interface Reset {
  readonly type: "search-products/RESET";
}

export interface SetGroupBy {
  readonly type: "search-products/SET_GROUP_BY";
  readonly payload: SharedSearch.GroupBy;
}

export function setGroupBy(groupBy: SharedSearch.GroupBy): SetGroupBy {
  return {
    type: "search-products/SET_GROUP_BY",
    payload: groupBy,
  };
}

export interface SetGroupOpen {
  readonly type: "search-products/SET_GROUP_OPEN";
  readonly payload: {
    readonly group: string;
    readonly open: boolean;
  };
}

export function setGroupOpen(group: string, open: boolean): SetGroupOpen {
  return {
    type: "search-products/SET_GROUP_OPEN",
    payload: {
      group: group,
      open: open,
    },
  };
}

export interface SetSorting {
  readonly type: "search-products/SET_SORTING";
  readonly payload: {
    readonly sortType: SC.ResultItemType | undefined;
    readonly sortPath: string;
    readonly descending: boolean;
  };
}

export function setSorting(sortType: SC.ResultItemType | undefined, sortPath: string, descending: boolean): SetSorting {
  return {
    type: "search-products/SET_SORTING",
    payload: {
      sortType: sortType,
      sortPath: sortPath,
      descending: descending,
    },
  };
}

export interface SetGroupSorting {
  readonly type: "search-products/SET_GROUP_SORTING";
  readonly payload: SharedSearch.SortParams | undefined;
}

export function setGroupSorting(groupSortParams: SharedSearch.SortParams | undefined): SetGroupSorting {
  return {
    type: "search-products/SET_GROUP_SORTING",
    payload: groupSortParams,
  };
}

export interface SetColumnGroup {
  readonly type: "search-products/SET_COLUMN_GROUP";
  readonly payload: string;
}

export function setColumnGroup(columnGroup: string): SetColumnGroup {
  return {
    type: "search-products/SET_COLUMN_GROUP",
    payload: columnGroup,
  };
}

export interface SetNumResults {
  readonly type: "search-products/SET_NUM_RESULTS";
  readonly payload: number;
}

export function setNumResults(numResults: number): SetNumResults {
  return {
    type: "search-products/SET_NUM_RESULTS",
    payload: numResults,
  };
}

export interface SaveCrmResult {
  readonly type: "search-products/SAVE_CRM_RESULT";
  readonly payload: string;
}

export function saveCrmResult(id: string): SaveCrmResult {
  return {
    type: "search-products/SAVE_CRM_RESULT",
    payload: id,
  };
}
