import * as R from "ramda";
import log from "loglevel";
import * as MeasureToolApi from "shared-lib/measuretool-api";
import * as PromasterApi from "../../promaster-api";
import * as MappingFunctions from "./mapping-functions";
import { TableName, ItemNoToProductId, VariantNoToProductId, ProductIdToRetired } from "../table-types";
import { ProductCacheState, Products, Product, CacheLoadRequest, Tables, Measurement, MarkerInfo } from "./types";

type CacheUpdate = (cache: ProductCacheState) => ProductCacheState;

export async function executeLoadRequest(
  baseAddress: string,
  cache: ProductCacheState,
  cacheLoadRequest: CacheLoadRequest,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): Promise<ProductCacheState> {
  const { tableNamesByProductId, blobs, measurements } = cacheLoadRequest;

  const promises: Array<Promise<CacheUpdate>> = [];
  for (const productId of R.keys(tableNamesByProductId)) {
    promises.push(
      fetchTablesByProduct(
        baseAddress,
        cache,
        productId,
        tableNamesByProductId[productId],
        useMeasureToolForMeasurements
      )
    );
  }
  for (const url of blobs) {
    promises.push(fetchUrl(cache, url || ""));
  }
  for (const measurement of measurements) {
    promises.push(
      fetchMeasurement(
        measurement.productId,
        measurement.variantId,
        measurement.tables,
        useMeasureToolForMeasurements,
        mtUrl
      )
    );
  }
  if (promises.length === 0) {
    return cache;
  }

  const cacheUpdates = await Promise.all(promises);
  return cacheUpdates.reduce((c, u) => u(c), cache);
}

export function convertMtRequests(
  cacheLoadRequest: CacheLoadRequest,
  useMeasureToolForMeasurements: boolean
): CacheLoadRequest {
  // All measurement queries will be turned to tableByProductId queries if measuretool shouldn't
  // be used to query measurement tables.
  if (!useMeasureToolForMeasurements && cacheLoadRequest.measurements.length > 0) {
    const { tableNamesByProductId, measurements } = cacheLoadRequest;
    const neededTableNamesByProductId: {
      [productId: string]: Array<TableName>;
    } = {};
    for (const measurement of measurements) {
      if (neededTableNamesByProductId[measurement.productId]) {
        neededTableNamesByProductId[measurement.productId].push(...measurement.tables);
      } else {
        neededTableNamesByProductId[measurement.productId] = [...measurement.tables];
      }
    }
    const updatedNeededTableNamesByProductId = neededTableNamesByProductId as {
      [productId: string]: ReadonlyArray<TableName>;
    };
    for (const productId of Object.keys(tableNamesByProductId)) {
      if (updatedNeededTableNamesByProductId[productId]) {
        updatedNeededTableNamesByProductId[productId] = [
          ...tableNamesByProductId[productId],
          ...updatedNeededTableNamesByProductId[productId],
        ];
      } else {
        updatedNeededTableNamesByProductId[productId] = tableNamesByProductId[productId];
      }
    }
    return {
      tableNamesByProductId: updatedNeededTableNamesByProductId,
      blobs: cacheLoadRequest.blobs,
      measurements: [],
    };
  } else {
    return cacheLoadRequest;
  }
}

// Remove everything that is already in the cache
export function optimizeLoadRequest(cache: ProductCacheState, cacheLoadRequest: CacheLoadRequest): CacheLoadRequest {
  const { tableNamesByProductId, blobs, measurements } = cacheLoadRequest;
  const neededTableNamesByProductId: {
    [productId: string]: ReadonlyArray<TableName>;
  } = {};

  for (const productId of Object.keys(tableNamesByProductId)) {
    const existingTables = cache.tables[productId] || {};
    const neededTableNames = R.uniq(tableNamesByProductId[productId]).filter((t) => existingTables[t] === undefined);
    if (neededTableNames.length > 0) {
      neededTableNamesByProductId[productId] = neededTableNames;
    }
  }
  const neededBlobs: Array<string> = [];
  for (const url of blobs) {
    const existing = cache.blobs[url];
    const duplicate = neededBlobs.indexOf(url) !== -1;
    if (existing === undefined && !duplicate) {
      neededBlobs.push(url);
    }
  }
  // A request to measure tool will load all measurements tables, so only one fetch per productid/variantid
  // is needed.
  const uniqueMeasurements = R.uniqBy((m) => `${m.productId}_${m.variantId}`, measurements);
  const neededMeasurements: Array<Measurement> = [];
  for (const measurement of uniqueMeasurements) {
    const existingTables = cache.tables[measurement.productId] || {};
    if (MeasureToolApi.tableFromMeasureTool.some((t) => existingTables[t] === undefined)) {
      neededMeasurements.push(measurement);
    }
  }

  return {
    tableNamesByProductId: neededTableNamesByProductId,
    blobs: neededBlobs,
    measurements: neededMeasurements,
  };
}

export async function loadProductsIfNotLoaded(
  baseAddress: string,
  cache: ProductCacheState
): Promise<ProductCacheState> {
  if (cache.products === undefined) {
    // const rawProducts = await Api.getProductsAndTablesByReleaseId(tenantId, getCurrentReleaseId(cache), []);
    const rawProducts = await getProducts(baseAddress, cache);

    // Prevent getting FRI_Products instead of FRICO_ products when getting productId by itemnumber!
    // Both products will have the same itemnumbers!
    //const epimFricoProductsRemoved = rawProductToItemNo.filter((p) => !p.key.startsWith("FRI_"));
    const rawProductsNoOldFrico = rawProducts.filter((p) => !p.key.toLowerCase().startsWith("frico_")); //EPIM ENABLED

    const products = MappingFunctions.mapRawProducts(rawProductsNoOldFrico);
    cache = amendCacheWithProducts(cache, products);

    const rawProductToItemNo = await getItemNoToProductId(baseAddress, cache);

    const rawProductToItemNoNoFRICO_ = rawProductToItemNo.filter((t) => !t.key.toLowerCase().startsWith("frico_"));

    const itemNoToProductId = MappingFunctions.mapItemNoToProduct(rawProductToItemNoNoFRICO_);
    cache = amendCacheWithItemNoToProductId(cache, itemNoToProductId);

    const variantNoToProductId = MappingFunctions.mapVariantNoToProduct(rawProductToItemNoNoFRICO_);
    cache = amendCacheWithVariantNoToProductId(cache, variantNoToProductId);

    const productIdToRetired = MappingFunctions.mapProductIdToRetired(rawProductToItemNoNoFRICO_);
    cache = amendCacheWithProductIdToRetired(cache, productIdToRetired);
  }
  return cache;
}

async function getItemNoToProductId(
  baseAddress: string,
  cache: ProductCacheState
): Promise<ReadonlyArray<PromasterApi.RawProduct>> {
  if (cache.markerInfo.type === "Loading") {
    throw new Error("Source not set");
  } else if (cache.markerInfo.type === "PromasterWorking") {
    return PromasterApi.getProductsAndTablesByTransactionId(
      baseAddress,
      cache.markerInfo.transactionId,
      ["ct_ItemNo", "ct_VariantNo"],
      true
    );
  } else if (cache.markerInfo.type === "PromasterRelease") {
    return PromasterApi.getProductsAndTablesByReleaseId(
      baseAddress,
      cache.markerInfo.releaseId,
      ["ct_ItemNo", "ct_VariantNo"],
      true
    );
  }
  throw new Error("No current release or transaction");
}

// Either get for current release id or for current transaction id
async function getProducts(
  baseAddress: string,
  cache: ProductCacheState
): Promise<ReadonlyArray<PromasterApi.RawProduct>> {
  if (cache.markerInfo.type === "Loading") {
    throw new Error("Source not set");
  } else if (cache.markerInfo.type === "PromasterWorking") {
    return PromasterApi.getProductsAndTablesByTransactionId(baseAddress, cache.markerInfo.transactionId, [], true);
  } else if (cache.markerInfo.type === "PromasterRelease") {
    return PromasterApi.getProductsAndTablesByReleaseId(baseAddress, cache.markerInfo.releaseId, [], true);
  }
  throw new Error("No current release or transaction");
}

// How often the cached marker should be checked against the latest published marker. In milliseconds.
const maxTime = 1000 * 60 * 5;

export async function setCurrentReleaseOrTransactionFromMarkerIfNotSet(
  baseAddress: string,
  cache: ProductCacheState,
  markerName: string
): Promise<ProductCacheState> {
  const now = Date.now();
  if (cache.markerInfo.type === "Loading" || (typeof window === "undefined" && now - cache.markerInfoTime > maxTime)) {
    log.info("Fetching marker...");
    let marker;
    try {
      marker = await PromasterApi.getMarkerByName(baseAddress, markerName);
    } catch (e) {
      log.warn("############################## PIM ERROR ##############################");
      log.warn("ERROR:", e.name, e.message);
      log.warn("############################## PIM ERROR ##############################");
      throw e;
    }

    if (marker === undefined) {
      throw new Error(`Could not find a marker named '${markerName}'`);
    }

    const newMarkerInfo: MarkerInfo | undefined = marker.release_id
      ? {
          type: "PromasterRelease",
          releaseId: marker.release_id,
          releaseName: marker.release_name || "",
        }
      : marker.transaction_id
      ? {
          type: "PromasterWorking",
          transactionId: marker.transaction_id,
        }
      : undefined;

    if (!newMarkerInfo) {
      throw new Error(`Marker '${markerName}' had neither release_id nor transaction_id`);
    }

    // Clear cache if marker changed
    if (!R.equals(newMarkerInfo, cache.markerInfo)) {
      log.info("New marker, clearing cache");
      cache = {
        ...cache,
        clearCache: cache.clearCache === false,
        markerInfoTime: now,
        markerInfo: newMarkerInfo,
      };
    } else {
      log.info("Same marker, keeping cache and updating time");
      cache = {
        ...cache,
        markerInfoTime: now,
        markerInfo: newMarkerInfo,
      };
    }
  }

  return cache;
}

async function fetchUrl(cache: ProductCacheState, url: string): Promise<CacheUpdate> {
  if (cache.markerInfo.type === "Loading") {
    throw new Error("Release not loaded");
  }
  return (oldCache: ProductCacheState) => ({
    ...oldCache,
    blobs: {
      ...oldCache.blobs,
      [url]: url,
    },
  });
}

async function fetchMeasurement(
  productId: string,
  variantId: string | undefined,
  _tables: ReadonlyArray<TableName>,
  useMeasureToolForMeasurements: boolean,
  mtUrl: string | undefined
): Promise<CacheUpdate> {
  const response = (await MeasureToolApi.getMeasurementTables(variantId, useMeasureToolForMeasurements, mtUrl)) as {
    [tableName: string]: {};
  };
  //  const measurentTables: { [tableName: string]: {} } = {};
  //  for (const tableName of tables) {
  //    measurentTables[tableName] = response[tableName];
  //  }
  // MT API always sends all tables, so cache them even if they are not requested to avoid unnecessary requests.
  const measurentTables = response;
  return (oldCache: ProductCacheState) => amendCacheWithTables(oldCache, productId, measurentTables);
}

async function fetchTablesByProduct(
  baseAddress: string,
  cache: ProductCacheState,
  productId: string,
  tables: ReadonlyArray<TableName>,
  useMeasureToolForMeasurements: boolean
): Promise<CacheUpdate> {
  // Find the product's transaction_id
  const product = getProductFromCache(cache, productId);
  if (cache.markerInfo.type === "Loading") {
    throw new Error("Release not loaded");
  }
  const rawTables = await PromasterApi.getTablesByProductId(
    baseAddress,
    product.transactionId,
    productId,
    tables,
    true
  );
  const mappedTables = MappingFunctions.mapTables(rawTables, tables);

  // If measure tool is active measure tool tables should only come from measure tool. Remove them from
  // the result if they are there by mistake.
  const mappedTablesWithoutMtTables = useMeasureToolForMeasurements
    ? removeMtTablesFromResult(mappedTables, tables)
    : mappedTables;

  return (oldCache: ProductCacheState) => amendCacheWithTables(oldCache, productId, mappedTablesWithoutMtTables);
}

function removeMtTablesFromResult(
  mappedTables: { readonly [tableName: string]: {} },
  tables: ReadonlyArray<string>
): {} {
  const wthoutMtTables: { [tableName: string]: {} } = {};
  for (const tableName of tables) {
    const table = mappedTables[tableName];
    if (MeasureToolApi.tableFromMeasureTool.some((mtTable) => mtTable === tableName)) {
      if (Array.isArray(table)) {
        wthoutMtTables[tableName] = [];
      } else {
        wthoutMtTables[tableName] = {};
      }
    } else {
      wthoutMtTables[tableName] = table;
    }
  }
  return wthoutMtTables;
}

function getProductFromCache(cache: ProductCacheState, productId: string): Product {
  if (!cache.products) {
    throw new Error(`Could not find products'`);
  }
  const product = cache.products[productId];
  if (!product) {
    throw new Error(`Could not find product with id '${productId}'`);
  }
  return product;
}

function amendCacheWithProducts(cache: ProductCacheState, products: Products): ProductCacheState {
  return { ...cache, products: products };
}

function amendCacheWithTables(cache: ProductCacheState, productId: string, tables: Tables): ProductCacheState {
  const mergedTables = {
    ...cache.tables,
    ...{ [productId]: { ...cache.tables[productId], ...tables } },
  };
  return { ...cache, tables: mergedTables };
}

function amendCacheWithItemNoToProductId(
  cache: ProductCacheState,
  itemNoToProductId: ItemNoToProductId
): ProductCacheState {
  return { ...cache, itemNoToProductId: itemNoToProductId };
}

function amendCacheWithVariantNoToProductId(
  cache: ProductCacheState,
  variantNoToProductId: VariantNoToProductId
): ProductCacheState {
  return { ...cache, variantNoToProductId: variantNoToProductId };
}

function amendCacheWithProductIdToRetired(
  cache: ProductCacheState,
  productIdToRetired: ProductIdToRetired
): ProductCacheState {
  return { ...cache, productIdToRetired: productIdToRetired };
}
