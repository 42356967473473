import * as R from "ramda";
import * as QP from "shared-lib/query-product";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";

export type MappedDiaqTemplate = Omit<QP.DiaqTemplate, "property_filter">;
export type MappedDiaqTemplateTable = ReadonlyArray<MappedDiaqTemplate>;

export function getTemplatesFromAttributes(
  variant: PropertyValueSet.PropertyValueSet,
  diaqTemplates: QP.DiaqTemplatesTable,
  attributeTemplateMapping: QP.AttributeTemplateMappingTable,
  attributes: Attributes.Attributes
): MappedDiaqTemplateTable {
  const matchingSelector = attributeTemplateMapping.filter(
    (m) => Attributes.getString(m.selector_attribute, attributes) === m.selector_attribute_value
  );

  const shouldMatchDllAttribute = matchingSelector.some((m) => !!m.dll_attribute_prefix);
  let matchingMappingRows;
  if (shouldMatchDllAttribute) {
    const allAttributeNames = Object.keys(attributes.byAttribute).filter((n) => n.startsWith("DLL-input-"));
    matchingMappingRows = matchingSelector.filter((m) =>
      allAttributeNames.some((a) => a.startsWith(m.dll_attribute_prefix))
    );
  } else {
    matchingMappingRows = matchingSelector;
  }

  if (matchingMappingRows.length > 0) {
    return mappingRowsToDiaqTemplateRows(matchingMappingRows[0]);
  } else {
    return diaqTemplates
      .filter((t) => PropertyFilter.isValid(variant, t.property_filter))
      .map((t) => ({ type: t.type, template: t.template }));
  }
}

export function getAllTemplatesFromAttributes(
  diaqTemplates: QP.DiaqTemplatesTable,
  attribteTemplateMapping: QP.AttributeTemplateMappingTable,
  attributesTable: QP.AttributesTable
): MappedDiaqTemplateTable {
  const selectorAttributes = attribteTemplateMapping.reduce((sofar, m) => {
    sofar.add(m.selector_attribute);
    return sofar;
  }, new Set<string>());
  const matchingSelectorMap = new Map<string, QP.AttributeTemplateMapping>();
  for (const attribute of attributesTable) {
    if (selectorAttributes.has(attribute.attribute)) {
      attribteTemplateMapping
        .filter((m) => m.selector_attribute === attribute.attribute && m.selector_attribute_value === attribute.value)
        .forEach((m) => matchingSelectorMap.set(`${m.result_items};${m.result_views}`, m));
    }
  }
  const matchingSelector = Array.from(matchingSelectorMap.values());

  const shouldMatchDllAttribute = matchingSelector.some((m) => !!m.dll_attribute_prefix);
  let matchingMappingRows;
  if (shouldMatchDllAttribute) {
    const allAttributeNames = attributesTable.filter((a) => a.attribute.startsWith("DLL-input-"));
    matchingMappingRows = matchingSelector.filter((m) =>
      allAttributeNames.some((a) => a.attribute.startsWith(m.dll_attribute_prefix))
    );
  } else {
    matchingMappingRows = matchingSelector;
  }

  const templatesFromAttributes = R.unnest<MappedDiaqTemplate>(matchingMappingRows.map(mappingRowsToDiaqTemplateRows));

  return [
    ...templatesFromAttributes,
    ...diaqTemplates.map((t): MappedDiaqTemplate => ({ type: t.type, template: t.template })),
  ];
}

function mappingRowsToDiaqTemplateRows(attributeTemplateMapping: QP.AttributeTemplateMapping): MappedDiaqTemplateTable {
  return [
    {
      type: "ResultItems",
      template: attributeTemplateMapping.result_items,
    },
    {
      type: "ResultViews",
      template: attributeTemplateMapping.result_views,
    },
  ];
}
