/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import * as React from "react";
import {
  LinkButton,
  Button,
  DropdownButtonItem,
  DropdownButton,
  HToolbar,
  SelectButton,
  Heading4,
} from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as Uuid from "uuid";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { Icon } from "client-lib/elements/icon";
import * as ProductCodes from "shared-lib/product-codes";
import { clientConfig } from "config";
import * as C from "shared-lib/calculation";
import { Accessory } from "shared-lib/query-product";
import * as ProductUtils from "shared-lib/product-utils";
import * as Accessories from "shared-lib/accessories";
import { Props, ProductTables, AccessoryTables } from "../types";
import * as Actions from "../actions";
import { VariantNoToPriceResponse } from "shared-lib/query-ecom";

export type AccessoriesProps = Props & {
  readonly fullConfig: C.ItemConfig;
  readonly translate: Texts.TranslateFunction;
};

export function AccessoriesTable(props: AccessoriesProps): React.ReactElement<{}> {
  const { translate, productTables, accessoryTables, fullConfig, dispatch, marketTables } = props;
  if (
    productTables.ct_Accessories.length === 0 ||
    productTables.ct_Accessories.find((a) => !accessoryTables[a.product])
  ) {
    return <span />;
  }
  const mainAccessories = fullConfig.accessories.filter((a) => !a.parentId);
  return (
    <div className={clientConfig.addOuterPadding ? "px-40" : ""}>
      <table>
        <thead>
          <tr>
            <th className="w-3/5">
              <div className="flex flex-row justify-between items-center">
                <Heading4>{translate(Texts.accessories())}</Heading4>
                <AccessorySelectorButton
                  dispatch={props.dispatch}
                  productTables={props.productTables}
                  accessoryTables={props.accessoryTables}
                  accessoryNoToPrice={props.accessoryNoToPrice}
                  marketTables={marketTables}
                  translate={props.translate}
                  variant={props.fullConfig.properties}
                  accessories={mainAccessories}
                  parentAccessoryId={undefined}
                  type={undefined}
                />
              </div>
            </th>
            <th>{translate(Texts.articleNo())}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {mainAccessories.map((accessory) => (
            <AccessoryRow
              key={accessory.id}
              dispatch={dispatch}
              translate={translate}
              accessoryNoToPrice={props.accessoryNoToPrice}
              productTables={productTables}
              accessoryTables={accessoryTables}
              marketTables={marketTables}
              variant={props.fullConfig.properties}
              accessories={fullConfig.accessories}
              accessory={accessory}
              changingAccessory={props.state.changingAccessory}
              childAccessory={false}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function AccessoryRow(props: {
  readonly dispatch: Props["dispatch"];
  readonly translate: Texts.TranslateFunction;
  readonly accessoryNoToPrice: VariantNoToPriceResponse;
  readonly productTables: ProductTables;
  readonly accessoryTables: AccessoryTables;
  readonly marketTables: ProductUtils.MarketTablesResponse;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly accessories: ReadonlyArray<C.Accessory>;
  readonly accessory: C.Accessory;
  readonly changingAccessory: C.Accessory | undefined;
  readonly childAccessory: boolean;
}): React.ReactElement<{}> {
  const {
    translate,
    productTables,
    accessoryTables,
    marketTables,
    variant,
    dispatch,
    accessories,
    changingAccessory,
    accessory,
    childAccessory,
  } = props;
  const accProductTables = accessoryTables[accessory.productId];
  const codes = ProductCodes.getProductCodes(accProductTables, accessory.properties);
  const childAccessories = accessories.filter((a) => a.parentId === accessory.id);

  const accessoryProperties = accProductTables.property.map((p) => p.name);
  const alternatives = R.unnest<PropertyValueSet.PropertyValueSet>(
    productTables.ct_Accessories
      .filter((a) => PropertyFilter.isValid(variant, a.property_filter) && a.product === accessory.productId)
      .filter((a) => filterAmountAccessories(a, childAccessories))
      .map((a) =>
        ProductUtils.generateVariantsForProperties(
          accessoryProperties,
          PropertyValueSet.setText("type", a.type, a.variant),
          accProductTables.property
        )
      )
  );
  const unqiueAlternatives = R.uniqBy((a) => PropertyValueSet.toString(a), alternatives);

  const accessoryName = Accessories.getAccessoryName(translate, accProductTables, accessory.properties);

  return changingAccessory?.id === accessory.id && unqiueAlternatives.length > 0 ? (
    <ChangeAccessoryRow
      dispatch={dispatch}
      translate={translate}
      accProductTables={accProductTables}
      unqiueAlternatives={unqiueAlternatives}
      accessory={accessory}
      changingAccessory={changingAccessory}
    />
  ) : (
    <>
      <tr key={accessory.id}>
        <td className={childAccessory ? "pl-24" : ""}>
          <div className="flex flex-row justify-between items-center">
            <div>{accessoryName}</div>
            <AccessorySelectorButton
              dispatch={props.dispatch}
              productTables={{
                ...accProductTables,
                // No data for max_amount for accessories of accessories. Hard coded to 1 to not allow multiple filter panels in one filter section.
                ct_Accessories: accProductTables.ct_Accessories.map((r) => ({ ...r, max_amount: 1 })),
              }}
              accessoryNoToPrice={props.accessoryNoToPrice}
              marketTables={marketTables}
              accessoryTables={props.accessoryTables}
              translate={props.translate}
              variant={accessory.properties}
              accessories={childAccessories}
              parentAccessoryId={accessory.id}
              type={"Filter"}
            />
          </div>
        </td>
        <td>{codes.itemNo}</td>
        <td>
          {unqiueAlternatives.length > 1 && (
            <LinkButton onClick={() => dispatch(Actions.changeAccessory(accessory))}>
              {translate(Texts.change_item())}
            </LinkButton>
          )}
        </td>
        <td>
          <LinkButton onClick={() => dispatch(Actions.removeAccessory(accessory.id))}>
            <Icon icon="trash" />
          </LinkButton>
        </td>
      </tr>
      {childAccessories.map((childAccessory) => (
        <AccessoryRow
          key={childAccessory.id}
          dispatch={dispatch}
          translate={translate}
          accessoryNoToPrice={props.accessoryNoToPrice}
          productTables={accProductTables}
          accessoryTables={accessoryTables}
          marketTables={marketTables}
          variant={accessory.properties}
          accessories={accessories}
          accessory={childAccessory}
          changingAccessory={changingAccessory}
          childAccessory={true}
        />
      ))}
    </>
  );
}

function ChangeAccessoryRow(props: {
  readonly dispatch: Props["dispatch"];
  readonly translate: Texts.TranslateFunction;
  readonly accProductTables: ProductTables;
  readonly unqiueAlternatives: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly accessory: C.Accessory;
  readonly changingAccessory: C.Accessory;
}): React.ReactElement<{}> | null {
  const { translate, dispatch, accProductTables, unqiueAlternatives, accessory, changingAccessory } = props;
  return (
    <tr key={accessory.id + "-change"}>
      <td colSpan={5}>
        <div className="flex flex-col p-16 space-y-24">
          <HToolbar>
            {unqiueAlternatives.map((alternative) => {
              const codes = ProductCodes.getProductCodes(accProductTables, alternative);
              const accessoryTypeProperty = PropertyValueSet.getText("type", alternative);
              const accessoryType = Accessories.getAccessoryTypeText(accessoryTypeProperty, translate);
              return (
                <SelectButton
                  key={codes.itemNo + codes.variant + accessoryType}
                  selected={PropertyValueSet.equals(
                    alternative,
                    PropertyValueSet.removeProperty("market", changingAccessory.properties)
                  )}
                  onClick={() =>
                    dispatch(
                      Actions.changeAccessory({
                        id: accessory.id,
                        parentId: accessory.parentId,
                        productId: accessory.productId,
                        properties: alternative,
                        calcParams: PropertyValueSet.Empty,
                      })
                    )
                  }
                  label={
                    translate(Texts.item_name(accessory.productId, alternative)) +
                    (accessoryType ? " " + accessoryType : "") +
                    (codes.variant ? " (" + codes.variant + ")" : "")
                  }
                />
              );
            })}
          </HToolbar>
          <HToolbar>
            <Button clicked={() => dispatch(Actions.changeAccessory(undefined))} label={translate(Texts.cancel())} />
            <Button
              clicked={() => dispatch(Actions.updateAccessory(changingAccessory))}
              label={translate(Texts.select())}
            />
          </HToolbar>
        </div>
      </td>
    </tr>
  );
}

function AccessorySelectorButton(props: {
  readonly dispatch: Props["dispatch"];
  readonly translate: Texts.TranslateFunction;
  readonly accessoryNoToPrice: VariantNoToPriceResponse;
  readonly productTables: ProductTables;
  readonly accessoryTables: AccessoryTables;
  readonly marketTables: ProductUtils.MarketTablesResponse;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly accessories: ReadonlyArray<C.Accessory>;
  readonly parentAccessoryId: string | undefined;
  readonly type: string | undefined;
}): React.ReactElement<{}> | null {
  const {
    variant,
    productTables,
    translate,
    dispatch,
    accessoryTables,
    accessories,
    parentAccessoryId,
    type,
    marketTables,
    accessoryNoToPrice,
  } = props;
  const validAccessories = productTables.ct_Accessories
    .filter((a) => PropertyFilter.isValid(variant, a.property_filter) && (!type || a.type === type))
    .filter((a) => {
      const accessoryProductTables = accessoryTables[a.product];
      const accessoryProperties = accessoryProductTables.property.map((p) => p.name);

      const fullVariants = ProductUtils.generateVariantsForProperties(
        accessoryProperties,
        PropertyValueSet.setText("type", a.type, a.variant),
        accessoryProductTables.property
      );

      if (fullVariants.length === 0) {
        return false;
      }
      const codes = ProductCodes.getProductCodes(accessoryProductTables, fullVariants[0]);
      return codes.variantId && accessoryNoToPrice[codes.variantId] !== undefined;
    })
    .filter((a) => {
      if (!a.max_amount) {
        return true;
      }

      const currentAccessories = accessories.filter((c) => {
        const accessoryTypeProperty = PropertyValueSet.getText("type", c.properties);
        return c.productId === a.product && accessoryTypeProperty === a.type && c.parentId === parentAccessoryId;
      });

      return currentAccessories.length < a.max_amount;
    });

  const marketProductIds = new Set(
    ProductUtils.filterProductsByMarket(marketTables, R.uniq(validAccessories.map((a) => a.product)))
  );

  const marketFilteredAccessories = R.unnest<DropdownButtonItem>(
    validAccessories
      .filter((a) => marketProductIds.has(a.product))
      .map((a) => {
        const accessoryProductTables = accessoryTables[a.product];
        const accessoryProperties = accessoryProductTables.property.map((p) => p.name);
        const variants = ProductUtils.generateVariantsForProperties(
          accessoryProperties,
          PropertyValueSet.setText("type", a.type, a.variant),
          accessoryProductTables.property
        );
        const variantsType = variants.map((v) => ({ variant: v, type: a.type }));

        return R.uniqBy((c) => c, variantsType).map((v) => {
          const codes = ProductCodes.getProductCodes(accessoryProductTables, v.variant);
          const itemName = translate(Texts.item_name(a.product, v.variant));
          const accessoryType = Accessories.getAccessoryTypeText(v.type, translate);
          const label = `${itemName}${accessoryType ? " " + accessoryType : ""} ${
            codes.variant ? " (" + codes.variant + ")" : ""
          }`;
          return {
            value: label,
            label: label,
            onClick: () =>
              dispatch(
                Actions.addAccessory({
                  id: Uuid(),
                  parentId: parentAccessoryId,
                  productId: a.product,
                  properties: v.variant,
                  calcParams: PropertyValueSet.Empty,
                })
              ),
          };
        });
      })
  );
  const uniqueAccessories = R.uniqBy((a) => a.value, marketFilteredAccessories);
  if (uniqueAccessories.length === 0) {
    return null;
  }
  return (
    <DropdownButton
      className="mr-16"
      label={type ? translate(Texts.add_accessory_type(type)) : translate(Texts.add_accessory())}
      leftIcon={"plus"}
      rightIcon={"chevron-down"}
      items={uniqueAccessories}
    />
  );
}

function filterAmountAccessories(currentAccesory: Accessory, accessories: ReadonlyArray<C.Accessory>): boolean {
  if (!currentAccesory.max_amount) {
    return true;
  }

  const currentAccessories = accessories.filter((c) => {
    const accessoryTypeProperty = PropertyValueSet.getText("type", c.properties);
    return c.productId === currentAccesory.product && accessoryTypeProperty === currentAccesory.type;
  });

  return currentAccessories.length < currentAccesory.max_amount;
}
