import * as QP from "shared-lib/query-product";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as Attributes from "../shared/attributes";

export function match(
  _: string,
  searchAttributes: QP.SearchAttributesTable,
  variantAttributes: Attributes.Attributes,
  __: PropertyValueSet.PropertyValueSet,
  ___: PropertyValueSet.PropertyValueSet
): boolean {
  return (
    Attributes.matchInputFrequency(searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("CONFIGURABLE-FAN", searchAttributes, variantAttributes) &&
    Attributes.matchAttribute("input-voltage-NOM", searchAttributes, variantAttributes)
  );
}
