import { retryFetch } from "../fetch";
import { RawProduct, RawTree, RawProductData, Marker, ApiType } from "./types";

export async function getMarkerByName(baseAddress: string, markerName: string): Promise<Marker | undefined> {
  const url = `${baseAddress}/public/markers/${markerName}`;
  const result = await retryFetch(url, { redirect: "follow" });
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  return result.json();
}

/**
 * Gets a list of products, and optionally some tables for each product.
 * The result of this function can be mapped to more strict types by
 * using the `mapRawProducts()` function.
 * @param releaseId The release to get from.
 * @param tablesToGet The tables to get.
 * @returns A list of products with the specified tables.
 */
export async function getProductsAndTablesByReleaseId(
  baseAddress: string,
  releaseId: string,
  tablesToGet: ReadonlyArray<string> = [],
  numbers: boolean = false
): Promise<ReadonlyArray<RawProduct>> {
  const publicUrl = `${baseAddress}/public`;
  const queryParams = getQueryParams(tablesToGet, numbers);
  const url = `${publicUrl}/releases/${releaseId}${queryParams}`;
  const result = await retryFetch(url);
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  const json = await result.json();
  return json;
}

/**
 * Gets a list of products, and optionally some tables for each product.
 * The result of this function can be mapped to more strict types by
 * using the `mapRawProducts()` function.
 * @param transactionId The transaction to get from.
 * @param tablesToGet The tables to get.
 * @returns A list of products with the specified tables.
 */
export async function getProductsAndTablesByTransactionId(
  baseAddress: string,
  transactionId: string,
  tablesToGet: ReadonlyArray<string> = [],
  numbers: boolean = false
): Promise<ReadonlyArray<RawProduct>> {
  const publicUrl = `${baseAddress}/public`;
  const queryParams = getQueryParams(tablesToGet, numbers);
  const url = `${publicUrl}/transactions/${transactionId}${queryParams}`;
  const result = await retryFetch(url);
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  const json = await result.json();
  return json;
}

/**
 * Gets raw table data for a product.
 * The result of this function can be mapped to more strict types by
 * using the `mapRawTables()` function.
 * @param transactionId The release to get from.
 * @param productId The product to get from.
 * @param tablesToGet The tables to get.
 * @returns A dictionary of table name to table rows.
 */
export async function getTablesByProductId(
  baseAddress: string,
  transactionId: string,
  productId: string,
  tablesToGet: ReadonlyArray<string>,
  numbers: boolean = false
): Promise<RawProductData> {
  const queryParams = getQueryParams(tablesToGet, numbers);
  const url = `${baseAddress}/public/transactions/${transactionId}/products/${productId}${queryParams}`;
  const result = await retryFetch(url, { redirect: "follow" });
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  const json = await result.json();
  return json;
}

/**
 * Gets the trees.
 * The result of this function can be mapped to more strict types by
 * using the `mapRawTree()` function.
 * @returns
 */
export async function getTrees(baseAddress: string): Promise<ReadonlyArray<RawTree>> {
  const publicUrl = `${baseAddress}/public`;
  const url = `${publicUrl}/trees`;
  const result = await retryFetch(url);
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  const json = await result.json();
  return json;
}

export async function getCachedDataByTransactionId(
  baseAddress: string,
  transactionId: string,
  cacheName: string,
  cacheParams: ReadonlyArray<string>
): Promise<ReadonlyArray<RawProduct>> {
  const params = cacheParams.join(",");
  const url = `${baseAddress}/public/cache/transactions/${transactionId}/${cacheName}?params=${params}`;
  const result = await retryFetch(url);
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  const data = await result.json();
  return data;
}

export async function getCachedDataByReleaseId(
  baseAddress: string,
  releaseId: string,
  cacheName: string,
  cacheParams: ReadonlyArray<string>
): Promise<ReadonlyArray<RawProduct>> {
  const params = cacheParams.join(",");
  const url = `${baseAddress}/public/cache/releases/${releaseId}/${cacheName}?params=${params}`;
  const result = await retryFetch(url);
  if (!result.ok) {
    throw new Error("Error when fetching data: " + result.statusText);
  }
  const data = await result.json();
  return data;
}

export async function getTranslationsByReleaseId(
  apiType: ApiType,
  baseAddress: string,
  releaseId: string,
  locale: string,
  serverUrl?: string
): Promise<ReadonlyArray<RawProduct>> {
  if (typeof window !== "undefined" && apiType !== "Publish") {
    const result = await retryFetch(
      `${serverUrl || ""}/rest/translations/get-translations?locale=${locale}&release=${releaseId}`
    );
    if (!result.ok) {
      throw new Error("Error when fetching data: " + result.statusText);
    }
    const translations = await result.json();
    return translations;
  } else if (apiType === "Publish") {
    return getCachedDataByReleaseId(baseAddress, releaseId, "translations", [locale]);
  } else {
    return getProductsAndTablesByReleaseId(baseAddress, releaseId, ["text", "property", "ct_DiaqFallbackLanguage"]);
  }
}

export async function getTranslationsByTransactionId(
  apiType: ApiType,
  baseAddress: string,
  transactionId: string,
  locale: string,
  serverUrl?: string
): Promise<ReadonlyArray<RawProduct>> {
  if (typeof window !== "undefined" && apiType !== "Publish") {
    const result = await retryFetch(
      `${serverUrl || ""}/rest/translations/get-translations?locale=${locale}&transaction=${transactionId}`
    );
    if (!result.ok) {
      throw new Error("Error when fetching data: " + result.statusText);
    }
    const translations = await result.json();
    return translations;
  } else if (apiType === "Publish") {
    return getCachedDataByTransactionId(baseAddress, transactionId, "translations", [locale]);
  } else {
    return getProductsAndTablesByTransactionId(baseAddress, transactionId, [
      "text",
      "property",
      "ct_DiaqFallbackLanguage",
    ]);
  }
}

function getQueryParams(tablesToGet: ReadonlyArray<string>, numbers: boolean): string {
  const params: Array<string> = [];
  if (tablesToGet.length > 0) {
    params.push(`tables=${tablesToGet.join(",")}`);
  }
  if (numbers) {
    params.push(`numbers=true`);
  }
  if (params.length > 0) {
    return "?" + params.join("&");
  }
  return "";
}
