import { PropertyValueSet } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import * as PC from "shared-lib/product-codes";
import * as Texts from "shared-lib/language-texts";

// These calc params will be taken from the main product when calculating the accessory
export const inheritedCalcParams = ["airFlow"];

export interface AccessoryTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
}

export function getAccessoryTypeText(type: string | undefined, translate: Texts.TranslateFunction): string | undefined {
  return type === "InletSilencer" || type === "OutletSilencer" ? translate(Texts.accessory_type(type)) : undefined;
}

export function getAccessoryName(
  translate: Texts.TranslateFunction,
  tables: AccessoryTables,
  properties: PropertyValueSet.PropertyValueSet
): string {
  const accessoryTypeProperty = PropertyValueSet.getText("type", properties);
  const accCodes = PC.getProductCodes(tables, properties);
  const accessoryType = getAccessoryTypeText(accessoryTypeProperty, translate);
  return `${accCodes.code}${accessoryType ? ` ${accessoryType}` : ""} (${accCodes.variant})`;
}
