/* eslint-disable no-restricted-properties */
import { Amount } from "uom";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Quantity, Units } from "uom-units";

export function calculateExternalPressureWithDensityNumber(
  externalPressurePascal: number,
  airDensity: Amount.Amount<Quantity.Density> | undefined
): number {
  const newPressure = calculateExternalPressureWithDensity(
    Amount.create(externalPressurePascal, Units.Pascal),
    airDensity
  );
  return (newPressure && Amount.valueAs(Units.Pascal, newPressure)) || externalPressurePascal;
}

export function calculateExternalPressureWithDensity(
  externalPressure: Amount.Amount<Quantity.Pressure> | undefined,
  airDensity: Amount.Amount<Quantity.Density> | undefined
): Amount.Amount<Quantity.Pressure> | undefined {
  if (externalPressure === undefined) {
    return undefined;
  }
  if (airDensity === undefined) {
    return externalPressure;
  }

  const airDensityKilogramPerCubicMeter = Amount.valueAs(Units.KilogramPerCubicMeter, airDensity);
  const externalPressurePascal = Amount.valueAs(Units.Pascal, externalPressure);
  const externalPressureWithDensityPascal = (1.204 / airDensityKilogramPerCubicMeter) * externalPressurePascal;

  return Amount.create(externalPressureWithDensityPascal, Units.Pascal);
}

export function getAirDensityKgPerCubicMeter(
  pvs: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.Density> | undefined {
  const airDensityCalculationMethod = PropertyValueSet.getInteger("airDensityCalculationMethod", pvs);

  if (airDensityCalculationMethod === undefined) {
    return undefined;
  }

  switch (airDensityCalculationMethod) {
    case 0: // Density as input
      return PropertyValueSet.getAmount("airDensity", pvs);
    case 1: // Pressure as input
      return calculateDensityByPressure(pvs);
    case 2: // SeaLevel as input
      return calculateDensityBySeaLevel(pvs);
    default:
      return undefined;
  }
}

function calculateDensityBySeaLevel(
  pvs: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.Density> | undefined {
  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>("airDensityTemperature", pvs);
  const seaLevel = PropertyValueSet.getAmount<Quantity.Length>("airDensitySeaLevel", pvs);
  if (temperature === undefined || seaLevel === undefined) {
    return undefined;
  }

  const seaLevelMeter = Amount.valueAs(Units.Meter, seaLevel);
  const temperatureCelsius = Amount.valueAs(Units.Celsius, temperature);

  const h1 = 101325 * Math.pow(1 - (0.0065 * seaLevelMeter) / 288.15, 5.255);

  const airDensity = h1 / (287.05 * (temperatureCelsius + 273.15));
  return Amount.create(airDensity, Units.KilogramPerCubicMeter);
}

function calculateDensityByPressure(
  pvs: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.Density> | undefined {
  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>("airDensityTemperature", pvs);
  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>("airDensityPressure", pvs);
  if (temperature === undefined || pressure === undefined) {
    return undefined;
  }

  const pressurePascal = Amount.valueAs(Units.Pascal, pressure);
  const temperatureCelsius = Amount.valueAs(Units.Celsius, temperature);

  const airDensity = pressurePascal / (287.15 * (temperatureCelsius + 273.15));
  return Amount.create(airDensity, Units.KilogramPerCubicMeter);
}
