import * as React from "react";

export interface TextboxPropertySelectorProps {
  readonly value: string | undefined;
  readonly onChange: (newValue: string) => void;
}

export function TextboxPropertySelector(props: TextboxPropertySelectorProps): JSX.Element {
  const { value, onChange } = props;
  return <input type="text" className="w-1/3" value={value} onChange={(e) => onChange(e.target.value)} />;
}
