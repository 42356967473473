/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as R from "ramda";
import { PropertyFilter, PropertyValueSet, PropertyValue } from "@promaster-sdk/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Amount, Serialize } from "uom";
import { AnyQuantity, CustomUnitsLookup } from "shared-lib/uom";
import * as Types from "./types";

export function mapRawTree(tree: Types.RawTree): Types.Tree {
  return {
    name: tree.name,
    relations: convertArray(tree.relations, convertTreeRelation),
  };
}

/**
 * Maps table data from raw data, using the mapping declaration provided.
 */
export function mapTable<T>(
  rawProductData: Types.RawProductData,
  tableName: string,
  mapping: Types.Mapping<T>
): ReadonlyArray<T> | undefined {
  const rawTableData: Types.RawTableData = rawProductData[tableName];
  if (!rawTableData) {
    return [];
  }
  return rawTableData.map((row) => runMapping(mapping, row) as T);
}

export function mapText(rawProductData: Types.RawProductData): Types.TextTable {
  return convertTextTable(rawProductData["text"]);
}

export function mapProperty(rawProductData: Types.RawProductData): ReadonlyArray<Types.ProductProperty> {
  return convertArray(rawProductData["property"], convertProductProperty);
}

export function mapImage(rawProductData: Types.RawProductData): ReadonlyArray<Types.ProductImage> {
  return convertArray(rawProductData["image"], convertProductImage);
}

export function mapDocument(rawProductData: Types.RawProductData): ReadonlyArray<Types.ProductDocument> {
  return convertArray(rawProductData["document"], convertProductDocument);
}

export function mapLanguage(rawProductData: Types.RawProductData): ReadonlyArray<Types.ProductLanguage> {
  return convertArray(rawProductData["language"], convertProductLanguage);
}

export function mapCode(rawProductData: Types.RawProductData): ReadonlyArray<Types.ProductCode> {
  return convertArray(rawProductData["code"], convertProductCode);
}

export function mapModel(rawProductData: Types.RawProductData): ReadonlyArray<Types.Model> {
  return convertArray(rawProductData["model"], convertModel);
}

function runMapping(mapping: Types.Mapping<{}>, rawData: Types.RawTableRow): {} {
  const x = R.toPairs<Types.ValueMapping>(mapping).map(
    ([key, valueMapping]) => [key, mapValue(valueMapping as any, rawData[key as any])] as [string, any]
  );
  return R.fromPairs(x);
}

function rawValueToString(rawValue: Types.RawColumnData): string {
  if (rawValue === null || rawValue === undefined) {
    return "";
  }
  return rawValue.toString();
}

// eslint-disable-next-line consistent-return
function mapValue(mapping: Types.ValueMapping, rawValue: Types.RawColumnData): any {
  if (typeof mapping !== "string") {
    switch (mapping.type) {
      case "Amount": {
        const amountValue = typeof rawValue === "number" ? rawValue : parseFloat(rawValue as string);
        return Number.isFinite(amountValue as any) ? Amount.create(amountValue as any, mapping.unit) : undefined;
      }
      default: {
        exhaustiveCheck(mapping.type);
        //throw new Error("Exhaustive check");
      }
    }
  }

  switch (mapping) {
    case "string": {
      return rawValueToString(rawValue);
    }
    case "number": {
      return typeof rawValue === "number" ? rawValue : parseFloat(rawValueToString(rawValue));
    }
    case "PropertyFilter": {
      return convertFilter(rawValueToString(rawValue));
    }
    case "PropertyValueSet": {
      return convertPropertyValueSet(rawValueToString(rawValue));
    }
    case "Quantity": {
      if (rawValue) {
        const unit = Serialize.stringToUnit(rawValueToString(rawValue), CustomUnitsLookup);
        return unit?.quantity;
      }
      return undefined;
    }
    case "Unit": {
      if (rawValue && CustomUnitsLookup(rawValueToString(rawValue))) {
        return Serialize.stringToUnit(rawValueToString(rawValue), CustomUnitsLookup);
      }
      return undefined;
    }
    case "Blob": {
      return convertBlob(rawValueToString(rawValue));
    }
    default: {
      exhaustiveCheck(mapping);
    }
  }
}

function convertTextTable(textTable: Types.RawTableData | undefined): Types.TextTable {
  const table: {
    // tslint:disable-next-line:readonly-keyword
    [locale: string]: {
      [key: string]: Array<{
        readonly property_filter: PropertyFilter.PropertyFilter;
        readonly text: string;
        readonly comment: string;
      }>;
    };
  } = {};
  for (const text of textTable || []) {
    const propertyFilter = convertFilter(rawValueToString(text.property_filter));
    const item = {
      property_filter: propertyFilter,
      text: rawValueToString(text.text),
      comment: rawValueToString(text.comment),
    };

    if (table[rawValueToString(text.language)] === undefined) {
      table[rawValueToString(text.language)] = {};
    }
    if (table[rawValueToString(text.language)][rawValueToString(text.name)] === undefined) {
      table[rawValueToString(text.language)][rawValueToString(text.name)] = [];
    }
    table[rawValueToString(text.language)][rawValueToString(text.name)].push(item);
  }
  return table;
}

function convertModel(model: any): Types.Model {
  return {
    property_filter: convertFilter(rawValueToString(model.property_filter)),
    name: rawValueToString(model.name),
    model: convertBlob(rawValueToString(model.model)),
    file_name: rawValueToString(model.file_name),
    params: convertArray(model.params, convertModelParams),
  };
}

function convertModelParams(params: Types.RawTableRow): Types.ModelParams {
  return {
    property_filter: convertFilter(rawValueToString(params.property_filter)),
    model_params: rawValueToString(params.model_params),
  };
}

function convertProductProperty(productProperty: any): Types.ProductProperty {
  return {
    name: rawValueToString(productProperty.name),
    group: rawValueToString(productProperty.group),
    validation_filter: convertFilter(rawValueToString(productProperty.validation_filter)),
    visibility_filter: convertFilter(rawValueToString(productProperty.visibility_filter)),
    quantity: rawValueToString(productProperty.quantity) as AnyQuantity,
    def_value: convertArray(productProperty.def_value, convertProductPropertyDefValue),
    value: convertArray(productProperty.value, convertProductPropertyValue),
    translation: convertArray(productProperty.translation, convertProductPropertyTranslation),
    sort_no: productProperty.sort_no,
  };
}

function convertProductPropertyTranslation(rawTranslation: Types.RawTableRow): Types.ProductPropertyTranslation {
  return {
    language: rawValueToString(rawTranslation.language),
    type: rawValueToString(rawTranslation.type),
    translation: rawValueToString(rawTranslation.translation),
  };
}

function convertProductPropertyDefValue(defValue: Types.RawTableRow): Types.ProductPropertyDefValue | undefined {
  const value = convertPropertyValue(rawValueToString(defValue.value));
  if (value === undefined) {
    return undefined;
  }
  return {
    value: value,
    property_filter: convertFilter(rawValueToString(defValue.property_filter)),
  };
}

function convertProductPropertyValue(propertyValue: any): Types.ProductPropertyValue | undefined {
  const value = parseInt(rawValueToString(propertyValue.value), 10);
  if (!Number.isFinite(value)) {
    return undefined;
  }
  const image = propertyValue.image ? rawValueToString(propertyValue.image) : undefined;
  return {
    value: { type: "integer", value },
    property_filter: convertFilter(rawValueToString(propertyValue.property_filter)),
    description: rawValueToString(propertyValue.description),
    image: convertBlob(image),
    translation: convertArray(propertyValue.translation, convertProductPropertyTranslation),
    sort_no: propertyValue.sort_no,
  };
}

function convertProductCode(code: Types.RawTableRow): Types.ProductCode {
  return {
    property_filter: convertFilter(rawValueToString(code.property_filter)),
    type: code.type !== undefined ? rawValueToString(code.type) : "main",
    code: rawValueToString(code.code),
  };
}

function convertProductLanguage(language: Types.RawTableRow): Types.ProductLanguage {
  return {
    sort_no:
      language.sort_no !== null && language.sort_no !== undefined ? parseInt(language.sort_no.toString(), 10) : 0,
    name: rawValueToString(language.name),
  };
}

function convertProductImage(image: Types.RawTableRow): Types.ProductImage {
  return {
    property_filter: convertFilter(rawValueToString(image.property_filter)),
    image: convertBlob(rawValueToString(image.image)),
    size: rawValueToString(image.size),
    type: rawValueToString(image.type),
    name: rawValueToString(image.name),
    file_name: rawValueToString(image.file_name),
    comment: rawValueToString(image.comment),
  };
}

function convertProductDocument(document: Types.RawTableRow): Types.ProductDocument {
  return {
    property_filter: convertFilter(rawValueToString(document.property_filter)),
    file_name: convertBlob(rawValueToString(document.file_name)),
    document: rawValueToString(document.document),
    language: rawValueToString(document.language),
    name: rawValueToString(document.name),
    type: rawValueToString(document.type),
  };
}

function convertTreeRelation(relation: Types.RawTreeRelation): Types.TreeRelation {
  const parent = relation.parent || relation.parentId;
  const child = relation.child || relation.childId;
  return {
    parent: parent ? parent.toLocaleUpperCase() : null,
    child: child.toLocaleUpperCase(),
    sort_no: relation.sort_no,
  };
}

function convertArray<TFrom, TTo>(
  array: ReadonlyArray<TFrom> | undefined,
  mapper: (t: TFrom) => TTo | undefined
): ReadonlyArray<TTo> {
  const valid: Array<TTo> = [];
  for (const item of array || []) {
    const mapped = mapper(item);
    if (mapped !== undefined) {
      //tslint:disable-next-line
      valid.push(mapped);
    }
  }
  return valid;
}

function convertFilter(filter: string | undefined): PropertyFilter.PropertyFilter {
  return PropertyFilter.fromStringOrEmpty(filter || "", CustomUnitsLookup);
}

function convertPropertyValueSet(values: string | undefined): PropertyValueSet.PropertyValueSet {
  return values ? PropertyValueSet.fromString(values, CustomUnitsLookup) : PropertyValueSet.Empty;
}

function convertPropertyValue(value: string | undefined): PropertyValue.PropertyValue | undefined {
  return PropertyValue.fromString(value || "", CustomUnitsLookup);
}

function convertBlob(value: string | undefined): string {
  if (!value) {
    return "";
  }
  return value;
  // const url = new URL(value);
  // return url.pathname + url.search;
}
