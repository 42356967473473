/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable functional/no-this-expression */
import * as React from "react";
import * as debounce from "lodash.debounce";

interface Props {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onEnter?: () => void;
  readonly className?: string;
}

interface LocalState {
  readonly value: string;
}

// eslint-disable-next-line functional/no-class
export class Textfield extends React.Component<Props, LocalState> {
  // eslint-disable-next-line functional/prefer-readonly-type
  textInput: HTMLInputElement | HTMLTextAreaElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  _update = debounce(() => {
    this.props.onChange(this.state.value);
  }, 250);

  onChange(value: string) {
    this._update(value);
    this.setState(() => ({ value: value }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState(() => ({
      value: nextProps.value,
    }));
  }

  render(): JSX.Element {
    return (
      <input
        className={`form-input p-4 pl-8 h-36 w-full ${this.props.className || ""}`}
        value={this.state.value}
        onChange={(e) => this.onChange(e.currentTarget.value)}
        onBlur={() => {
          this.props.onChange(this.state.value);
          this._update.cancel();
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && this.props.onEnter) {
            this.props.onEnter();
          }
        }}
      />
    );
  }
}
