/* eslint-disable no-restricted-properties */
import * as R from "ramda";
import * as Vec2 from "./vector2";

//#region  Old Code
export interface Spline {
  readonly xMin: number;
  readonly xMax: number;
  readonly segments: ReadonlyArray<SplineSegment>;
}

export interface SplineSegment {
  readonly xMin: number;
  readonly xMax: number;
  readonly yMin: number;
  readonly slope: number;
  readonly c0: number;
  readonly c1: number;
  // TODO: Not used here, only added to work with current types
  readonly a: number;
  readonly b: number;
  readonly c: number;
  readonly d: number;
}

function splineCreate(segments: ReadonlyArray<SplineSegment>): Spline {
  const xMin = Math.min(...segments.map((s) => s.xMin));
  const xMax = Math.max(...segments.map((s) => s.xMax));
  return { xMin, xMax, segments };
}

export function splineGetPoint(x: number, spline: Spline): number | undefined {
  const segment = spline.segments.find((s) => x >= s.xMin && x <= s.xMax);
  if (!segment) {
    return undefined;
  }
  return splineSegmentGetPoint(x, segment);
}

export function splineGetPoints(numPoints: number, spline: Spline): ReadonlyArray<Vec2.Vec2> {
  const step = (spline.xMax-spline.xMin) / (numPoints-1);
  const points: Array<Vec2.Vec2> = [];
  for (let i = 0; i < numPoints; ++i) {
    const x = spline.xMin+i * step;
    const y = splineGetPoint(x, spline);
    if (y !== undefined) {
      points.push({ x, y });
    }
  }
  return points;
}

export function splineGetPointsPerSegment(numPointsPerSegment: number, spline: Spline): ReadonlyArray<Vec2.Vec2> {
  const points: Array<Vec2.Vec2> = [];
  spline.segments.forEach((segment) => {
    const step = (segment.xMax-segment.xMin) / (numPointsPerSegment-1);
    for (let i = 0; i < numPointsPerSegment; ++i) {
      const x = segment.xMin+i * step;
      const y = splineGetPoint(x, spline);
      if (y !== undefined) {
        points.push({ x, y });
      }
    }
  });
  return points;
}

function splineSegmentGetPoint(x: number, segment: SplineSegment): number | undefined {
  if (x < segment.xMin || x > segment.xMax) {
    return undefined;
  }

  const a = x-segment.xMin;
  return (a)*((a)*((a)*segment.c0+segment.c1)+segment.slope)+segment.yMin // C3 is measured ps
}



export function splineCreateFromPoints(points: ReadonlyArray<Vec2.Vec2>): Spline {

  const segments: Array<SplineSegment> = [];

  const array = R.sort((a, b) => a.x-b.x, points);

  for (let i = 0; i < points.length-1; ++i) {
    
    const slopeP1 = calculateSlope(array, i);
    const slopeP2 = calculateSlope(array, i+1);
    const p1 = array[i];
    const p2 = array[i+1];

 
    const c0 =((slopeP2-slopeP1)-((((p2.y-p1.y)*(1/(p2.x-p1.x))))-slopeP1)*2)* Math.pow((1/(p2.x-p1.x)),2)
      
    const c1 = (((((
      p2.y-p1.y)*(1/(
      p2.x-p1.x))))-slopeP1)-((
      slopeP2-slopeP1)-((((
        p2.y-p1.y)*(1/(
          p2.x-p1.x))))-slopeP1)*2))*(1/(
            p2.x-p1.x));
      

    segments.push({
      slope: slopeP1,
      c0: c0,
      c1: c1,
      xMin: p1.x,
      xMax: p2.x,
      yMin: p1.y,
      // Below is used by old calculation, we set it to 0 as we don't use it.
      a:0,
      b:0,
      c:0,
      d:0
    });
  }

  return splineCreate(segments);
}

export function splineSubtract(a: Spline, b: Spline): Spline {
  const newPoints = a.segments.map((s) => {
    const aY = splineSegmentGetPoint(s.xMin, s) || 0;
    const bY = splineGetPoint(s.xMin, b);
    const newY = (aY || 0)-(bY || 0);
    return Vec2.vec2Create(s.xMin, newY);
  });
  if (a.segments.length > 0) {
    const s = a.segments[a.segments.length-1];
    const aY = splineSegmentGetPoint(s.xMax, s) || 0;
    const bY = splineGetPoint(s.xMax, b);
    const newY = (aY || 0)-(bY || 0);
    newPoints.push(Vec2.vec2Create(s.xMax, newY));
  }
  return splineCreateFromPoints(newPoints);
}



const epsilon = 1e-12;
function solveCubic(a:number, b:number, c:number, d:number): ReadonlyArray<number> {
  if (Math.abs(a) < epsilon) { // Quadratic case, ax^2+bx+c=0
      a = b; b = c; c = d;
      if (Math.abs(a) < epsilon) { // Linear case, ax+b=0
          a = b; b = c;
          if (Math.abs(a) < epsilon){ // Degenerate case
              return [];
            }
          return [-b/a];
      }

      const D = b*b - 4*a*c;
      if (Math.abs(D) < epsilon){
          return [-b/(2*a)];
        }
      else if (D > 0){
          return [(-b+Math.sqrt(D))/(2*a), (-b-Math.sqrt(D))/(2*a)];
        }
      return [];
  }
  

  // Convert to depressed cubic t^3+pt+q = 0 (subst x = t - b/3a)
  const p = (3*a*c - b*b)/(3*a*a);
  const q = (2*b*b*b - 9*a*b*c + 27*a*a*d)/(27*a*a*a);
  let roots:Array<number> = [];

  if (Math.abs(p) < epsilon) { // p = 0 -> t^3 = -q -> t = -q^1/3
      roots = [Math.cbrt(-q)];
  } else if (Math.abs(q) < epsilon) { // q = 0 -> t^3 + pt = 0 -> t(t^2+p)=0
      roots = [0].concat(p < 0 ? [Math.sqrt(-p), -Math.sqrt(-p)] : []);
  } else {
      const D = q*q/4 + p*p*p/27;
      if (Math.abs(D) < epsilon) {       // D = 0 -> two roots
          roots = [-1.5*q/p, 3*q/p];
      } else if (D > 0) {             // Only one real root
          const u = Math.cbrt(-q/2 - Math.sqrt(D));
          roots = [u - p/(3*u)];
      } else {                        // D < 0, three roots, but needs to use complex numbers/trigonometric solution
          const u = 2*Math.sqrt(-p/3);
          const t = Math.acos(3*q/p/u)/3;  // D < 0 implies p < 0 and acos argument in [-1..1]
          const k = 2*Math.PI/3;
          roots = [u*Math.cos(t), u*Math.cos(t-k), u*Math.cos(t-2*k)];
      }
  }

  // Convert back from depressed cubic
  for (let i = 0; i < roots.length; i++){
      roots[i] -= b/(3*a);
    }

  return roots;
}

// Returns the x value where the spline has a local maximum
export function findSplineMax(spline: Spline): number | undefined{

  // f(x) = ax^3 - atx^2 + bx^2 - tax^2  +  axt^2  -   tbx + cx - tax^2+ axt^2 - bxt + axt^2 - at^3 + bt^2 -ct  + d
  // f'(x) 3ax^2 - 2atx  + 2bx -  2tax   +  at^2   -   tb +  c -  2tax + at^2  - bt  + at^2  - 0    + 0    - 0  + 0


  // f(X) =  ax^3   -   atx^2   +   bx^2   -   tax^2   +   axt^2   -   tbx   +   cx  - tax^2   +   axt^2   -   bxt   +   axt^2   -   at^3   +   bt^2   -   ct   +   d
  // f'(x) = 3ax^2  -   2atx    +   2bx    -   2tax    +   at^2   -   tb    +   c   - 2tax    +   at^2    -   bt    +   at^2    -   0      +   0      -   0    +   0

let maxAirflowPoint = 0;
let maxEFF =  0;


  for(const segment of spline.segments){

  

    const a = segment.c0;
    const b = segment.c1;
    const c = segment.slope;
    // const d = segment.yMin;
    const t= segment.xMin;
   

   

      const an = 0;
      const bn = 3*a;
      const cn = -(2*(a*t)) + (2*b) - (2*t*a) - (2*t*a) ;
      const dn = (a*(t*t)) - (t*b) + c + (a*(t*t)) - (b*t) + (a*(t*t));
      console.log(an,bn,cn,dn)


      const roots =  solveCubic(an,bn,cn,dn);

      const validRoots = roots.filter((r) => r!== undefined && r>0);

      for(const root of validRoots){
        const eff = splineGetPoint(root,spline);
        if(eff !== undefined && eff > maxEFF){
          maxAirflowPoint = root;
          maxEFF = eff;

        }
      }

  }

  if(maxAirflowPoint !== undefined && maxEFF !== undefined){
    return maxAirflowPoint;
  }

  return undefined;


}



export function findSystemCurveIntersection(k: number, spline: Spline): Vec2.Vec2 | undefined {
 

  // This function finds the intersection between two curves
  // System Curve: y = (x/k)^2
  // A segment inside the spline.segment array: (x-xMin )*((x-xMin )*((x-xMin )*c0+c1)+slope)+yMin

  for(const segment of spline.segments){
     const a = segment.c0;
  const b = segment.c1;
  const c = segment.slope;
  const d = segment.yMin;
  const t= segment.xMin;

  const an = a;
  const bn = -(a*t)+(b)-(t*a)-((t*a))-k;
  const cn = (a*(t*t))-(t*b)+(c)+(a*(t*t))-(b*t)+(a*(t*t))
  const dn = -(a*(t*t*t))+(b*(t*t))-(c*t)+d

  const roots = solveCubic(an,bn,cn,dn);
  // Rounding added as we had issues where the intersections was just at the end of a segment 8320.9000122 and the xmax was 8320.9
  const intersection = roots.find((r) =>  Math.round(r * 10) / 10 >= segment.xMin && Math.round(r * 10) / 10 <= segment.xMax); // TODO Will not work if we have two points in the middle of the curve... (Ie if sound has two points in the middle of the curve but user selected a point outside)

  if(intersection){
   return Vec2.vec2Create(intersection, splineGetPoint(intersection, spline)!)
  }
}
return undefined;
}

export function findSplineSplineInterSection(a: Spline, b: Spline): Vec2.Vec2 | undefined {
  for (const aSegment of a.segments) {
    const aSplineMinY = splineGetPoint(aSegment.xMin, a);
    const aSplineMaxY = splineGetPoint(aSegment.xMax, a);

    if (aSplineMaxY === undefined || aSplineMinY === undefined) {
      continue;
    }

    const aLineK = (aSplineMaxY-aSplineMinY) / (aSegment.xMax-aSegment.xMin);
    const aLineM = (aSegment.xMax * aSplineMinY-aSegment.xMin * aSplineMaxY) / (aSegment.xMax-aSegment.xMin);

    for (const bSegment of b.segments) {
      const bSplineMaxY = splineGetPoint(bSegment.xMax, b);
      const bSplineMinY = splineGetPoint(bSegment.xMin, b);

      if (bSplineMinY === undefined || bSplineMaxY === undefined) {
        continue;
      }

      const bLineK = (bSplineMaxY-bSplineMinY) / (bSegment.xMax-bSegment.xMin);
      const bLineM = (bSegment.xMax * bSplineMinY-bSegment.xMin * bSplineMaxY) / (bSegment.xMax-bSegment.xMin);

      const x = (bLineM-aLineM) / (aLineK-bLineK);

      const ay = splineGetPoint(x, a);
      const by = splineGetPoint(x, b);

      if (
        x >= aSegment.xMin &&
        x <= aSegment.xMax &&
        x >= bSegment.xMin &&
        x <= bSegment.xMax &&
        ay !== undefined &&
        by !== undefined
      ) {
        return { x: x, y: ay };
      }
    }
  }
  return undefined;
}

//#endregion


//#region NewCode

// Basic functions as specified in Word
function abs(num:number):number{
  return Math.abs(num);
}

function sgn(num:number):number{
  return Math.sign(num);
}

// h[i] = Q[i+1] -Q[i] 
function calculateDiff(array: ReadonlyArray<Vec2.Vec2>, i:number):number{
  if(i+1 >= array.length){ // not possible as we don't have points
    return 0;
  }
  

    const p0 = array[i];
    const p1 = array[i+1];
    const h = p1.x-p0.x;
    return h;
}

// Delta
function calculateDelta(array: ReadonlyArray<Vec2.Vec2>, i:number):number{

if(i+1 >= array.length){ // not possible as we don't have points
  return 0;
}

  const p0 = array[i];
  const p1 = array[i+1];
  const h = calculateDiff(array,i);
  const delta = (p1.y-p0.y) / h;
  return delta;

}

function calculateSlope(array: ReadonlyArray<Vec2.Vec2>, i: number): number {
  if (array.length <= 1) {
    return 0;
  }
  if(i === 1 && array.length === 2){ // Todo check this with Jonas.
    return calculateInteriorSlope(array,i);
  }

  if(i === 0){
    return calculateInitialSlope(array);
  }
  else if(i === array.length-1){
    return calculateFinalSlope(array);
  } else {
    return calculateInteriorSlope(array,i);
  }
  
}


//#region Slope Calculations
function calculateInitialSlope(array: ReadonlyArray<Vec2.Vec2>): number {


  const p0Delta = calculateDelta(array,0)
  const p1Delta = calculateDelta(array,1)


  const p0h = calculateDiff(array,0);
  const p1h = calculateDiff(array,1);

  const v = (((p0h*2+p1h) * p0Delta)-(p0h * p1Delta)) / (p0h+p1h);



  const signv = sgn(v);
  const signP0Delta = sgn(p0Delta);
  const signP1Delta = sgn(p1Delta);


  if(signv !== signP0Delta){
    return 0;
  }else if(abs(v) > abs(3*p0Delta) && signP0Delta !== signP1Delta){
    return 3*p0Delta;
  } else { //if(abs(v) > abs(3*p0Delta) && signP0Delta === signP1Delta){ TODO, asked Jonas to clarify this, this is how the Excel does it.
    return v;
  }
}

function calculateInteriorSlope(array: ReadonlyArray<Vec2.Vec2>, i:number): number {
  
  const p0Delta = calculateDelta(array,i)
  const p1Delta = calculateDelta(array,i-1)

  const p0h = calculateDiff(array,i);
  const p1h = calculateDiff(array,i-1);

  const signP0Delta = sgn(p0Delta);
  const signP1Delta = sgn(p1Delta);

  if(signP0Delta !== signP1Delta || (p0Delta <= 0.0000000001 && p0Delta >= 0.0000000001) || (p1Delta <= 0.0000000001 && p1Delta >= 0.0000000001)){
    return 0;
  }
  else if(p0h === p1h){
    return 2/((1/p0Delta)+(1/p1Delta));
  }
  else {
    return ((2*p0h+p1h)+(2*p1h+p0h)) / ( ((2*p0h+p1h)/p1Delta)+ ((2*p1h+p0h)/p0Delta));
  }
}

function calculateFinalSlope(array: ReadonlyArray<Vec2.Vec2>): number {

  const p0Delta = calculateDelta(array, array.length-2);
  const p1Delta = calculateDelta(array,array.length-3);


  const p0h = calculateDiff(array,array.length-2);
  const p1h = calculateDiff(array,array.length-3);

  const v = (((p0h*2+p1h) * p0Delta)-(p0h * p1Delta)) / (p0h+p1h);



  const signv = sgn(v);
  const signP0Delta = sgn(p0Delta);
  const signP1Delta = sgn(p1Delta);


  if(signv !== signP0Delta){
    return 0;
  }else if(abs(v) > abs(3*p0Delta) && signP0Delta !== signP1Delta){
    return 3*p0Delta;
  } else { //if(abs(v) > abs(3*p0Delta) && signP0Delta === signP1Delta){ TODO, asked Jonas to clarify this, this is how the Excel does it.
    return v;
  }

}





//#endregion