import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import * as SC from "shared-lib/system-calculator";
import * as UserSettingsShared from "shared-lib/user-settings";
import { FanResultItem } from "shared-lib/system-calculator/result-items-types";
import { formatNumberFunction, isAllDefined } from "shared-lib/utils";
import * as FanDiagram from "shared-lib/system-calculator/shared/fan-diagram";
import * as QP from "shared-lib/query-product";
import * as Style from "shared-lib/style";
import { ResultRowContainer } from "client-lib/elements";
import { ViewerDiagramContainer } from "../../viewer-diagram";
import { VisualizerOwnProps, VisualizerProductProps } from "../types";

export type Props = VisualizerOwnProps & StateProps & Response;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

const diagramWidth = 800;
const diagramHeight = 400;

export function FanDiagramsVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  return (
    <ResultRowContainer heading={translate(Texts.performanceCurve())}>
      {products.map((p, idx) => renderDiagram(idx, props, p, idx !== 0))}
    </ResultRowContainer>
  );
}

function renderDiagram(
  key: number,
  { translate, market, language, userSettings, ct_MarketUnits, ct_LanguageMapping, showDownload }: Props,
  { resultItemMap, calcParams, calcParamsChanged }: VisualizerProductProps,
  disableHoverPoint: boolean
): React.ReactElement<{}> {
  const maybeFan: FanResultItem = R.head(
    R.values(R.filter((ri: SC.ResultItem) => ri.type === "Fan", resultItemMap))
  ) as FanResultItem;
  const fan = maybeFan && maybeFan.value.air;

  if (!fan) {
    return <div />;
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");

  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);

  const { pressure } = FanDiagram.generateCharts({
    fan,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels: true,
    style: Style.diagramGanymed,
  });

  return (
    <ViewerDiagramContainer
      key={key}
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={pressureUnitDecimals}
      id="fanExternalPressure"
      chart={pressure}
      maxWidth={diagramWidth}
      maxHeight={diagramHeight}
      showDownload={showDownload}
      formatNumber={formatNumber}
      onClick={
        !disableHoverPoint
          ? (x, y) => {
              const withFlow = PropertyValueSet.setAmount(
                "airFlow",
                Amount.create(x, flowUnit, flowUnitDecimals),
                calcParams
              );
              const withPressure = PropertyValueSet.setAmount(
                "externalPressure",
                Amount.create(y, pressureUnit, pressureUnitDecimals),
                withFlow
              );
              calcParamsChanged(withPressure);
            }
          : undefined
      }
    />
  );
}
