import { TableName, ItemNoToProductId, VariantNoToProductId, ProductIdToRetired } from "./table-types";
import { TablesByProductId, Tables, Table, MarkerInfo } from "./cache/types";

export type ProductId = string;
export type ProductKey = string;

export type ProductQuery =
  | MarkerInfoQuery
  | DataSourceQuery
  | TableByProductIdQuery
  | TablesByProductIdQuery
  | TablesByProductIdsQuery
  | ProductIdByM3ItemNoQuery
  | ItemNoToProductIdQuery
  | VariantNoToProductIdQuery
  | ProductIdToRetiredQuery
  | BlobQuery
  | MeasurementTableQuery;
export type ProductResponse =
  | MarkerInfoResponse
  | DataSourceResponse
  | TableByProductIdResponse
  | TablesByProductIdResponse
  | TablesByProductIdsResponse
  | ProductIdByM3ItemNoResponse
  | ItemNoToProductIdResponse
  | VariantNoToProductIdResponse
  | ProductIdToRetiredResponse
  | BlobResponse;

export interface DataSourceQuery {
  readonly source: "product";
  readonly type: "DataSource";
}

export type DataSourceResponse = MarkerInfo;
export function dataSource(): DataSourceQuery {
  return { source: "product", type: "DataSource" };
}

// MarkerInfo
export interface MarkerInfoQuery {
  readonly source: "product";
  readonly type: "MarkerInfo";
}
export type MarkerInfoResponse = MarkerInfo;
export function markerInfo(): MarkerInfoQuery {
  return { source: "product", type: "MarkerInfo" };
}

// TableByProductId
export interface TableByProductIdQuery {
  readonly source: "product";
  readonly type: "TableByProductId";
  readonly productId: ProductId;
  readonly table: TableName;
}
export type TableByProductIdResponse = Table;
export function tableByProductId(productId: ProductId, table: TableName): TableByProductIdQuery {
  return { source: "product", type: "TableByProductId", productId, table };
}

// TablesByProductKey
export interface TablesByProductIdQuery {
  readonly source: "product";
  readonly type: "TablesByProductId";
  readonly productId: ProductId;
  readonly tables: ReadonlyArray<TableName>;
}
export type TablesByProductIdResponse = Tables;
export function tablesByProductId(productId: ProductId, tables: ReadonlyArray<TableName>): TablesByProductIdQuery {
  return { source: "product", type: "TablesByProductId", productId, tables };
}

// TablesByProductKey
export interface TablesByProductIdsQuery {
  readonly source: "product";
  readonly type: "TablesByProductIds";
  readonly productIds: ReadonlyArray<ProductId>;
  readonly tables: ReadonlyArray<TableName>;
}
export type TablesByProductIdsResponse = TablesByProductId;
export function tablesByProductIds(
  productIds: ReadonlyArray<ProductId>,
  tables: ReadonlyArray<TableName>
): TablesByProductIdsQuery {
  return { source: "product", type: "TablesByProductIds", productIds, tables };
}

export interface ProductIdByM3ItemNoQuery {
  readonly source: "product";
  readonly type: "ProductIdByM3ItemNo";
  readonly productId?: string;
  readonly itemNo: string;
}
export type ProductIdByM3ItemNoResponse = string;
export function productIdByM3ItemNo(itemNo: string, productId?: string): ProductIdByM3ItemNoQuery {
  return { source: "product", type: "ProductIdByM3ItemNo", itemNo, productId: productId };
}

export interface ItemNoToProductIdQuery {
  readonly source: "product";
  readonly type: "ItemNoToProductId";
}
export type ItemNoToProductIdResponse = ItemNoToProductId;
export function itemNoToProductId(): ItemNoToProductIdQuery {
  return { source: "product", type: "ItemNoToProductId" };
}

export interface VariantNoToProductIdQuery {
  readonly source: "product";
  readonly type: "VariantNoToProductId";
}
export type VariantNoToProductIdResponse = VariantNoToProductId;
export function variantNoToProductId(): VariantNoToProductIdQuery {
  return { source: "product", type: "VariantNoToProductId" };
}

export interface ProductIdToRetiredQuery {
  readonly source: "product";
  readonly type: "ProductIdToRetired";
}
export type ProductIdToRetiredResponse = ProductIdToRetired;
export function productIdToRetired(): ProductIdToRetiredQuery {
  return { source: "product", type: "ProductIdToRetired" };
}

export interface BlobQuery {
  readonly source: "product";
  readonly type: "Blob";
  readonly url: string;
}
export type BlobResponse = string;
export function blob(url: string): BlobQuery {
  return { source: "product", type: "Blob", url };
}

export interface MeasurementTableQuery {
  readonly source: "product";
  readonly type: "MeasurementTable";
  readonly productId: ProductId;
  readonly variantId: string | undefined;
  readonly table: TableName;
}
export type MeasurementTableResponse = Table;
export function tableFromMtOrByProductId(
  productId: ProductId,
  variantId: string | undefined,
  table: TableName
): MeasurementTableQuery {
  return { source: "product", type: "MeasurementTable", productId, variantId, table };
}

// -- Cache state

export { Products, Product } from "./cache/types";
