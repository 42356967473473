import * as QP from "shared-lib/query-product";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";

export function findTranslation(
  textTable: QP.TextTable,
  locale: string,
  key: string,
  variant: PropertyValueSet.PropertyValueSet
): string | undefined {
  const texts = textTable && textTable[locale] && textTable[locale][key];
  const translation = texts ? texts.find((t) => PropertyFilter.isValid(variant, t.property_filter)) : undefined;
  return translation && translation.text;
}
