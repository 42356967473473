import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as SC from "shared-lib/system-calculator";
import { Result } from "../types";

export function postFilter(
  _: PropertyValueSet.PropertyValueSet,
  results: ReadonlyArray<Result>
): ReadonlyArray<Result> {
  const groupedResults = R.groupBy((item) => {
    if (item.result.results.BoxFan && item.result.results.BoxFan.type !== "OutputMapperError") {
      const result: SC.BoxFanResultItem = item.result.results.BoxFan.result as SC.BoxFanResultItem;
      const resultItem: SC.BoxFan = result.value;
      return resultItem.air.fanId;
    }
    return "";
  }, results);

  const bestVariants = R.keys(groupedResults).map((fanId) => {
    const sortedVariants = groupedResults[fanId].sort((a, b) => {
      const aFit = calculateFit(
        a.result.results.BoxFan || a.result.results.BoxFanNicotra || a.result.results.BoxFanYilida
      );
      const bFit = calculateFit(
        b.result.results.BoxFan || b.result.results.BoxFanNicotra || b.result.results.BoxFanYilida
      );
      return aFit - bFit;
    });
    return sortedVariants[0];
  });

  return bestVariants;
}

function calculateFit(result: SC.OutputMapperResult): number {
  if (result.type !== "OutputMapperError") {
    return result.sortValues[2].value as number;
  }
  return Number.MAX_VALUE;
}
