import * as React from "react";
import { AnyQuantity } from "shared-lib/uom";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { Amount, Unit } from "uom";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import { FormatNumberFunction } from "shared-lib/utils";
import { AmountInputBox, UnitSelector } from "client-lib/elements";
import * as Texts from "shared-lib/language-texts";
import * as UserSettings from "shared-lib/user-settings";
import { ValidateFilterFn } from "./types";

export interface AmountPropertySelectorProps {
  readonly value: Amount.Amount<AnyQuantity> | undefined;
  readonly validationFilter: PropertyFilter.PropertyFilter;
  readonly unit: Unit.Unit<AnyQuantity>;
  readonly getUnits: UserSettings.GetUnitsFunction;
  readonly decimalCount: number;
  readonly fieldName: string;
  readonly translate: Texts.TranslateFunction;
  readonly validateFilter: ValidateFilterFn;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly index?: number;
  readonly hideEmptyUnitSelector?: boolean;
  readonly onValueChange: (newValue: Amount.Amount<AnyQuantity>) => void;
  readonly unitChanged: (u: Unit.Unit<AnyQuantity>) => void;
  readonly unitCleared: () => void;
  readonly formatNumber: FormatNumberFunction;
}

export function AmountPropertySelector(props: AmountPropertySelectorProps): JSX.Element {
  const {
    value,
    unit,
    validationFilter,
    getUnits,
    decimalCount,
    fieldName,
    translate,
    index,
    hideEmptyUnitSelector,
    validateFilter,
    filterPrettyPrint,
    onValueChange,
    unitChanged,
    unitCleared,
    formatNumber,
  } = props;
  const units = getUnits(fieldName, unit.quantity);
  const validationMessage = getValidationMessage(value, validationFilter, validateFilter, filterPrettyPrint);
  const isRequiredMessage = !PropertyFilter.isValid(PropertyValueSet.Empty, validationFilter)
    ? translate(Texts.value_is_required())
    : "";
  return (
    <span className="w-1/2 min-w-224 flex flex-row space-x-8">
      <div className="basis-1/2">
        <AmountInputBox
          key={fieldName}
          index={index}
          value={value}
          unit={unit}
          decimalCount={decimalCount}
          notNumericMessage={translate(Texts.value_must_be_numeric())}
          isRequiredMessage={isRequiredMessage}
          errorMessage={validationMessage}
          isValid={validateFilter(validationFilter)}
          readOnly={false}
          onValueChange={(v) => v && onValueChange(v)}
          formatNumber={formatNumber}
          debounceTime={350}
        />
      </div>
      <div className="basis-1/2">
        {hideEmptyUnitSelector !== true || unit.name !== "One" ? (
          <UnitSelector
            unit={unit}
            units={units}
            unitChanged={unitChanged}
            unitCleared={unitCleared}
            translate={translate}
          />
        ) : (
          <span />
        )}
      </div>
    </span>
  );
}

function getValidationMessage(
  value: Amount.Amount<AnyQuantity> | undefined,
  validationFilter: PropertyFilter.PropertyFilter,
  validateFilter: ValidateFilterFn,
  filterPrettyPrint: FilterPrettyPrint
): string {
  if (!value || !validationFilter) {
    return "";
  }
  if (validateFilter(validationFilter)) {
    return "";
  } else {
    return filterPrettyPrint(validationFilter);
  }
}
