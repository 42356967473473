import { PropertyValueSet } from "@promaster-sdk/property";
import * as Types from "../types";
import { Input } from "./types";
import { calculate } from "./calculator";
import * as ResultTypes from "../result-items-types";
import * as Messages from "../messages";

const source = "BoxFanSelector";
export async function select(input: Input): Promise<Types.CalculatorResult<ResultTypes.BoxFan>> {
  const { calcParams } = input;
  const restrictedFreq = PropertyValueSet.getInteger("freq", calcParams) || -1;
  const erp2018 = PropertyValueSet.getInteger("classification", calcParams) === 1;

  if (restrictedFreq > 0 && input.frequency !== restrictedFreq) {
    // Wrong Frequency
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]); // SET MESSAGE MISMATCH CALCPARAMS
  }
  const calculationResult = await calculate(input);
  if (calculationResult.type === "CalculatorError") {
    return calculationResult;
  }

  if (erp2018 && calculationResult.type === "CalculatorSuccess") {
    const ecoDesign = calculationResult.output.ecoDesignData;
    const ecoDesignResult =
      (ecoDesign && ecoDesign.erpClassification && ecoDesign.erpClassification.indexOf("ErP 2018") > -1) || false;
    if (!ecoDesignResult) {
      return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]); // SET MESSAGE MISMATCH CALCPARAMS
    }
  }

  if (!calculationResult.output.air.insideWorkingArea) {
    return Types.createCalculatorError([Messages.Error_OutsideValidRange(source)]); // SET MESSAGE MISMATCH CALCPARAMS
  }

  return calculationResult;
}
