import {
  TablesByProductIdQuery,
  TablesByProductIdResponse,
  TablesByProductIdsQuery,
  TablesByProductIdsResponse,
  ProductIdByM3ItemNoQuery,
  ProductIdByM3ItemNoResponse,
  ProductId,
  TableByProductIdQuery,
  TableByProductIdResponse,
  ItemNoToProductIdResponse,
  VariantNoToProductIdResponse,
  BlobQuery,
  BlobResponse,
  MeasurementTableQuery,
  ProductIdToRetiredResponse,
} from "../query-types";
import { Tables, ProductCacheState, MarkerInfo } from "./types";
import { TableName } from "../table-types";
import { notFoundProductId } from "..";

export function selectDataSource(cache: ProductCacheState): MarkerInfo {
  return cache.markerInfo;
}

export function selectTableByProductId(
  cache: ProductCacheState,
  query: TableByProductIdQuery
): TableByProductIdResponse | undefined {
  const productTables = cache.tables[query.productId];
  const table = productTables && productTables[query.table];
  return table;
}

export function selectTablesByProductId(
  cache: ProductCacheState,
  query: TablesByProductIdQuery
): TablesByProductIdResponse | undefined {
  return selectTablesByProductIdInternal(cache, query.productId, query.tables);
}

export function selectTablesByProductIds(
  cache: ProductCacheState,
  query: TablesByProductIdsQuery
): TablesByProductIdsResponse | undefined {
  if (
    query.productIds.some(
      (p) => cache.tables[p] === undefined || query.tables.some((t) => cache.tables[p][t] === undefined)
    )
  ) {
    return undefined;
  }
  return cache.tables;
}

export function selectProductIdByM3ItemNo(
  cache: ProductCacheState,
  query: ProductIdByM3ItemNoQuery
): ProductIdByM3ItemNoResponse | undefined {
  return query.productId
    ? query.productId
    : (cache.itemNoToProductId && cache.itemNoToProductId[query.itemNo]) || notFoundProductId;
}

export function selectItemNoToProductId(cache: ProductCacheState): ItemNoToProductIdResponse | undefined {
  return cache.itemNoToProductId;
}

export function selectVariantNoToProductId(cache: ProductCacheState): VariantNoToProductIdResponse | undefined {
  return cache.variantNoToProductId;
}

export function selectProductIdToRetired(cache: ProductCacheState): ProductIdToRetiredResponse | undefined {
  return cache.productIdToRetired;
}

export function selectBlob(cache: ProductCacheState, query: BlobQuery): BlobResponse | undefined {
  return cache.blobs[query.url];
}

export function selectTablesByProductIdInternal(
  cache: ProductCacheState,
  productId: ProductId,
  tableNames: ReadonlyArray<TableName>
): Tables | undefined {
  if (cache.tables[productId] === undefined || tableNames.some((t) => cache.tables[productId][t] === undefined)) {
    // This product has not been requested before
    return undefined;
  }
  return cache.tables[productId];
}

export function selectMeasurement(
  cache: ProductCacheState,
  query: MeasurementTableQuery
): TableByProductIdResponse | undefined {
  // Measurement query responses are stored as regular product tables
  const productTables = cache.tables[query.productId];
  const table = productTables && productTables[query.table];
  return table;
}
