import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { AnyQuantity } from "shared-lib/uom";
import * as R from "ramda";
import {
  ViewName,
  ResultViewsTable,
  DiaqTemplatesTable,
  AttributeTemplateMappingTable,
} from "shared-lib/query-product";
import * as DiaqTemplates from "shared-lib/diaq-templates";
import { Message } from "./messages";
import { ResultItem } from "./result-items-types";
import { ResultItemOutputMap, ResultItemOutputPerComponent, ResultItemType } from "./types";
import * as Attributes from "./shared/attributes";

export function getResultViewsToUse(
  view: ViewName,
  resultViewsTable: ResultViewsTable,
  templatesTable: DiaqTemplatesTable,
  variant: PropertyValueSet.PropertyValueSet,
  attributeTemplateMapping: AttributeTemplateMappingTable,
  attributes: Attributes.Attributes
): ResultViewsTable {
  const template = DiaqTemplates.getTemplatesFromAttributes(
    variant,
    templatesTable,
    attributeTemplateMapping,
    attributes
  ).find((t) => t.type === "ResultViews");
  if (!template) {
    return [];
  }
  return resultViewsTable.filter((v) => v.template === template.template && v.view === view);
}

export function getMessages(resultItemOutputMap: ResultItemOutputMap): ReadonlyArray<Message> {
  return R.unnest(R.values(resultItemOutputMap).map((r) => r.messages));
}

export function getAllMessages(resultItemOutputPerComponent: ResultItemOutputPerComponent): ReadonlyArray<Message> {
  return R.unnest(R.values(resultItemOutputPerComponent).map(getMessages));
}

export function getResultItemAmountValue<TQuantity extends AnyQuantity>(
  resultItemType: ResultItemType,
  path: string,
  resultItemOutputMap: ResultItemOutputMap
): Amount.Amount<TQuantity> | undefined {
  return getResultItemValue<Amount.Amount<TQuantity>>(resultItemType, path, resultItemOutputMap);
}

// For example:
// getResultItemValue<Amount>("Sound.Flow", "Sound", resultItemOutputMap);
export function getResultItemValue<TValue>(
  resultItemType: ResultItemType,
  path: string,
  resultItemOutputMap: ResultItemOutputMap
): TValue | undefined {
  const pathParts = path.split(".");
  if (!pathParts || pathParts.length === 0) {
    return undefined;
  }
  const name = pathParts[0];
 
  const resultItem = getResultItem(resultItemType, name, resultItemOutputMap);
  if (!resultItem) {
    return resultItem;
  }

  // The first part of the path is the result items name, the rest is the path inside the result items value
  const value = R.path(R.drop(1, pathParts), resultItem.value);
  return value as TValue;
}

export function getResultItem<TResultItem extends ResultItem>(
  resultItemType: string,
  name: string,
  resultItemOutputMap: ResultItemOutputMap
): TResultItem | undefined {
  const outputMapperResult = resultItemOutputMap[name];
  if (!outputMapperResult) {
    return undefined;
  }
  if (outputMapperResult.type !== "OutputMapperSuccess") {
    return undefined;
  }
  if (outputMapperResult.result.type !== resultItemType) {
    return undefined;
  }
  return outputMapperResult.result as TResultItem | undefined;
}
