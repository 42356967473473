import * as React from "react";
import { withTw } from "client-lib/elements";
import { appInfo } from "shared-lib/version";
import { Props } from "./types";

export const VersionDiv = withTw("div", "w-full h-200 text-xs text-right text-white");

export function VersionInfoContainerComponent(props: Props): JSX.Element {
  const itemInfo = props.itemInfo;
  const itemParts = [
    itemInfo?.itemName,
    itemInfo?.itemNo,
    itemInfo?.variantName,
    itemInfo?.airMeasurement,
    itemInfo?.soundMeasurement,
  ];
  const itemInfoString = itemParts.some((p) => !!p) ? itemParts.join(", ") : "";
  return (
    <VersionDiv>
      Version: {appInfo.clientVersion === "{build_date}" ? "n/a" : appInfo.clientVersion}
      {itemInfoString ? `, ${itemInfoString}` : ""}
    </VersionDiv>
  );
}
