import * as SC from "shared-lib/system-calculator";
import * as QD from "shared-lib/query-diaq";
import * as QP from "shared-lib/query-product";
import * as PU from "shared-lib/product-utils";
import { AnyQuantity, customUnits } from "shared-lib/uom";
import * as R from "ramda";
import * as FanDiagram from "shared-lib/system-calculator/shared/fan-diagram";
import * as BoxFanDiagram from "shared-lib/system-calculator/shared/boxfan-diagram";
import * as Sound from "shared-lib/system-calculator/shared/sound";
import * as CentrifugalFanDiagram from "shared-lib/system-calculator/shared/centrifugal-fan-diagram";
import * as AC from "shared-lib/abstract-chart";
import * as Texts from "shared-lib/language-texts";
import * as AbstractImage from "abstract-image";
import { Amount, UnitFormat } from "uom";
import { Units, UnitsFormat } from "uom-units";
import * as FanSound from "shared-lib/system-calculator/shared/fan-sound";
import * as C from "shared-lib/calculation";
import * as Style from "shared-lib/style";
import * as Attributes from "shared-lib/system-calculator/shared/attributes";
import { exhaustiveCheck } from "shared-lib/exhaustive-check";
import { FormatNumberFunction, formatNumberFunction } from "shared-lib/utils";
import * as PC from "shared-lib/product-codes";
import * as Shared from "shared-lib/result-views";
import * as UserSettingsShared from "shared-lib/user-settings";
import { PropertyValueSet } from "@promaster-sdk/property";
import { OwnProps, Response, ExternalItem, Item, Diagram, createAlertType, Alert, isAlert } from "./types";
import { getAmcaStatements } from "shared-lib/amca-statements";

const fanWidth = 800;
const fanHeight = 400;
const hruWidth = 580;
const hruHeight = 320;
const centrifugalFanWidth = 500;
const centrifugalFanHeight = 600;

const boxFandiagramWidth = 580;
const boxFandiagramHeight = 320;

function externalItemsQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery({
    productId: QP.productIdByM3ItemNo(ownProps.m3ItemNo),
    productTables:
      response &&
      QP.tablesByProductId(response.productId, [
        "property",
        "ct_ItemNo",
        "ct_VariantNo",
        "code",
        "ct_DiaqTemplates",
        "ct_CalcParamDefault",
        "ct_Accessories",
        "ct_Attributes2",
        "ct_AmcaStatements",
      ]),
    metaTables: QP.tablesByProductId(QP.metaProductId, [
      "ct_ResultItems",
      "ct_ResultViews",
      "ct_MarketUnits",
      "ct_LanguageMapping",
      "ct_ResultVisualizerParamsTable",
      "ct_AttributeTemplateMapping",
      "ct_MarketHiddenFields",
    ]),
    accessoryTables: accessoryTablesQuery(response),
    translateTables: response && Texts.getTablesQuery([response.productId]),
    calculationResponse: getCalculationQuery(ownProps, response),
  });
}

function getCalculationQuery(ownProps: OwnProps, response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response) {
    return undefined;
  }
  const { market, m3ItemNo, variant, propsConfig, stateConfig } = ownProps;
  const { productId, productTables, metaTables, accessoryTables, calculationResponse } = response;

  if (
    !productId ||
    !productTables ||
    !metaTables ||
    !accessoryTables ||
    productTables.ct_Accessories.some((a) => !accessoryTables[a.product])
  ) {
    return undefined;
  }

  const fullConfig = PU.getFullConfig({
    market: market,
    m3ItemNo: m3ItemNo,
    variant: variant,
    propsConfig: propsConfig,
    stateConfig: stateConfig,
    metaTables: metaTables,
    productTables: productTables,
    accessoryTables: accessoryTables,
  });

  if (!fullConfig) {
    throw new Error(`Could not find item number: ${m3ItemNo}, variant: ${variant}, market: ${market}`);
  }

  return C.calculationQuery({ productId: productId, config: fullConfig, variantId: variant }, calculationResponse);
}

function accessoryTablesQuery(response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }
  const map: { [id: string]: QP.TablesByProductIdQuery } = {};
  for (const accessory of response.productTables.ct_Accessories) {
    map[accessory.product] = QP.tablesByProductId(accessory.product, [
      "property",
      "code",
      "ct_ItemNo",
      "ct_VariantNo",
      "ct_DiaqTemplates",
      "ct_CalcParamDefault",
      "ct_Accessories",
      "ct_Attributes2",
    ]);
  }
  return QD.createMapQuery(map);
}

async function getExternalItems(props: OwnProps, response: Response): Promise<ReadonlyArray<ExternalItem>> {
  const { language, m3ItemNo, variant, propsConfig, stateConfig, market, userSettings } = props;
  const { productId, productTables, metaTables, accessoryTables } = response;
  const { ct_MarketUnits, ct_LanguageMapping } = metaTables;

  if (!response.translateTables || !productTables || !accessoryTables || !response.calculationResponse) {
    throw new Error("Failed to load all data for print items");
  }
  const translate = Texts.translateFunctionSelector(response.translateTables, language);
  const fullConfig = PU.getFullConfig({
    market: market,

    m3ItemNo: m3ItemNo,
    variant: variant,
    propsConfig: propsConfig,
    stateConfig: stateConfig,

    metaTables: metaTables,
    productTables: productTables,
    accessoryTables: accessoryTables,
  });
  if (!fullConfig) {
    throw new Error("Item number not found");
  }

  const attributes = Attributes.createMap(fullConfig.properties, productTables.ct_Attributes2);

  const calcParamsValid = C.ValidateCalcParams.validate(
    metaTables,
    productTables,
    accessoryTables,
    fullConfig,
    attributes
  );
  if (!calcParamsValid) {
    const mainExternalItem: ExternalItem = {
      itemNumber: m3ItemNo,
      variant: variant || "",
      items: [createAlertType("error", translate(Texts.invalid_parameters()))],
    };
    return [mainExternalItem];
  }

  const resultViews = SC.getResultViewsToUse(
    "CatalogueScreen",
    metaTables.ct_ResultViews,
    productTables.ct_DiaqTemplates,
    fullConfig.properties,
    metaTables.ct_AttributeTemplateMapping,
    attributes
  );

  const results = (
    await C.calculateSystem(
      { productId: productId, config: fullConfig, variantId: variant },
      response.calculationResponse
    )
  )?.result;
  if (!results) {
    throw new Error("Not loaded yet");
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });
  const getExtraText = UserSettingsShared.getExtraText({
    market,
    ct_MarketUnits,
  });
  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });
  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const hideErrors =
    (propsConfig === undefined &&
      PropertyValueSet.isEmpty(stateConfig.calcParams) &&
      PropertyValueSet.isEmpty(stateConfig.properties)) ||
    !propsConfig ||
    PropertyValueSet.isEmpty(propsConfig.calcParams);

  // Main Product
  const mainProductResultItemOutputMap = results[response.productId] || {};
  const mainItems = [];
  for (const resultView of resultViews) {
    mainItems.push(
      ...create(mainProductResultItemOutputMap, resultView, {
        market: market,
        language: language,
        translate: translate,
        getUnit: getUnit,
        getDecimals: getDecimals,
        getExtraText: getExtraText,
        formatNumber: formatNumber,
        userSettings: userSettings,
        ct_ResultVisualizerParamsTable: metaTables.ct_ResultVisualizerParamsTable,
        ct_MarketHiddenFields: metaTables.ct_MarketHiddenFields,
        ct_AmcaStatements: productTables.ct_AmcaStatements,
        variant: fullConfig.properties,
      })
    );
  }
  const mainMessages = createMainAlertItems(translate, getUnit, getDecimals, hideErrors, results, productId);

  // Accessories
  const accessoryMessages = [];
  const accExternalItems: Array<ExternalItem> = [];
  for (const accessory of fullConfig.accessories) {
    const accessoryAttributes = Attributes.createMap(
      accessory.properties,
      accessoryTables[accessory.productId].ct_Attributes2
    );

    const resultViews = SC.getResultViewsToUse(
      "CatalogueScreen",
      metaTables.ct_ResultViews,
      accessoryTables[accessory.productId].ct_DiaqTemplates,
      fullConfig.properties,
      metaTables.ct_AttributeTemplateMapping,
      accessoryAttributes
    );

    const code = PC.getProductCodes(accessoryTables[accessory.productId], accessory.properties);

    const items = [];
    for (const resultView of resultViews) {
      const paramsWithCode = `${code.code};${resultView.visualizer_params.split(";").slice(1).join(";")}`;
      items.push(
        ...create(
          results[accessory.id],
          { ...resultView, visualizer_params: paramsWithCode },
          {
            market: market,
            language: language,
            translate: translate,
            getUnit: getUnit,
            getDecimals: getDecimals,
            getExtraText: getExtraText,
            formatNumber: formatNumber,
            userSettings: userSettings,
            ct_ResultVisualizerParamsTable: metaTables.ct_ResultVisualizerParamsTable,
            ct_MarketHiddenFields: metaTables.ct_MarketHiddenFields,
            ct_AmcaStatements: [],
            variant: fullConfig.properties,
          }
        )
      );
    }

    accessoryMessages.push(
      ...createAccessoryAlertItems(translate, getUnit, getDecimals, hideErrors, results, accessory.id, code.code)
    );

    const messages = createAccessoryViewAlertItems(
      translate,
      getUnit,
      getDecimals,
      hideErrors,
      results,
      response.productId,
      accessory.id
    );

    const accItems = [...messages, ...items];
    const accExternalItem: ExternalItem = {
      itemNumber: code.itemNo,
      variant: code.variantId || "",
      // The shop crashes if items is empty. Unless there is a data error all accessories should have items.
      items: accItems.length > 0 ? accItems : [createAlertType("error", "Data error: missing result view")],
    };
    accExternalItems.push(accExternalItem);
  }

  const messagesSorted = [...mainMessages, ...accessoryMessages].sort((a, b) => (a.type < b.type ? -1 : 1));

  const mainExternalItem: ExternalItem = {
    itemNumber: m3ItemNo,
    variant: variant || "",
    items: [...messagesSorted, ...mainItems],
  };

  return [mainExternalItem, ...accExternalItems];
}

// In the UI, this is the main product messages
function createMainAlertItems(
  translate: Texts.TranslateFunction,
  getUnit: UserSettingsShared.GetUnitFunction,
  getDecimals: UserSettingsShared.GetDecimalsFunction,
  hideErrors: boolean,
  results: SC.ResultItemOutputPerComponent,
  productId: string
): ReadonlyArray<Alert> {
  return createAlertItems(
    translate,
    getUnit,
    getDecimals,
    hideErrors,
    SC.getMessages(results[productId]).filter((m) => !SC.isAccessoryViewMessage(m)),
    undefined
  );
}

// In the UI, this is the accessory messages placed on main product level
function createAccessoryAlertItems(
  translate: Texts.TranslateFunction,
  getUnit: UserSettingsShared.GetUnitFunction,
  getDecimals: UserSettingsShared.GetDecimalsFunction,
  hideErrors: boolean,
  results: SC.ResultItemOutputPerComponent,
  accessoryProductId: string,
  accessoryCode: string
): ReadonlyArray<Alert> {
  return createAlertItems(
    translate,
    getUnit,
    getDecimals,
    hideErrors,
    SC.getMessages(results[accessoryProductId]).filter((m) => !SC.isAccessoryViewMessage(m)),
    accessoryCode
  );
}

// In the UI, this is the messages on each accessory result view
function createAccessoryViewAlertItems(
  translate: Texts.TranslateFunction,
  getUnit: UserSettingsShared.GetUnitFunction,
  getDecimals: UserSettingsShared.GetDecimalsFunction,
  hideErrors: boolean,
  results: SC.ResultItemOutputPerComponent,
  mainProductId: string,
  accessoryProductId: string
): ReadonlyArray<Alert> {
  const mainMessages = createAlertItems(
    translate,
    getUnit,
    getDecimals,
    hideErrors,
    SC.getMessages(results[mainProductId]).filter((m) => SC.isAccessoryViewMessage(m)),
    undefined
  );
  const accMessages = createAlertItems(
    translate,
    getUnit,
    getDecimals,
    hideErrors,
    SC.getMessages(results[accessoryProductId]).filter((m) => SC.isAccessoryViewMessage(m)),
    undefined
  );
  return [...mainMessages, ...accMessages];
}

function createAlertItems(
  translate: Texts.TranslateFunction,
  getUnit: UserSettingsShared.GetUnitFunction,
  getDecimals: UserSettingsShared.GetDecimalsFunction,
  hideErrors: boolean,
  messages: ReadonlyArray<SC.Message>,
  componentName: string | undefined
): ReadonlyArray<Alert> {
  return messages
    .map((message) => SC.renderMessage(componentName, message, translate, getUnit, getDecimals))
    .filter((message) => message.type === "warning" || (message.type === "error" && !hideErrors))
    .map((message: { type: "warning" | "error"; text: string }) => createAlertType(message.type, message.text));
}

function create(
  resultItemOutputMap: SC.ResultItemOutputMap,
  resultView: QP.ResultViews,
  props: Omit<PrintItemProps, "resultItemMap" | "resultView">
): ReadonlyArray<Item> {
  const resultItemNames = resultView.result_item.split(";");

  // Pick the result items to give to the visualizer
  const resultItemsOutput = resultItemNames.map((name) => resultItemOutputMap[name]);
  const failures = resultItemsOutput.filter((r) => !r || r.type !== "OutputMapperSuccess");
  if (failures.length > 0) {
    return [];
  }
  const resultItemsOutputToUseMap = R.pick(resultItemNames, resultItemOutputMap);
  const resultItemMap: SC.ResultItemMap = R.map(
    (r: SC.OutputMapperResult) => r.type === "OutputMapperSuccess" && r.result,
    resultItemsOutputToUseMap
  ) as SC.ResultItemMap;

  const itemProps: PrintItemProps = {
    ...props,
    resultView: resultView,
    resultItemMap: resultItemMap,
  };

  switch (resultView.visualizer) {
    case "Table":
      return tableView(itemProps);
    case "OctaveBandsTableFan":
      return octaveBandsTableFan(itemProps);
    case "OctaveBandsTableHru":
      return octaveBandsTableHru(itemProps);
    case "HeatRecoveryUnitDiagrams":
      return heatRecoveryDiagramsView(itemProps);
    case "FanDiagrams":
      return fanDiagramView(itemProps);
    case "MultiColumnTable":
      return multiColumnTableView(itemProps);
    case "BoxFanDiagrams":
      return boxFanDiagramView(itemProps);
    case "SoundPressureLevel":
      return soundPressureLevel(itemProps);
    case "SoundPressureLevelWithDistance":
      return soundPressureLevelWithDistance(itemProps);
    case "Base64Diagram":
      return base64Diagram(itemProps);
    case "CentrifugalFanDiagrams":
      return centrifugalFanDiagramView(itemProps);
    case "BoxFanRelatedProducts":
      // Should never generate items
      break;
    case "AmcaStatements": {
      return amcaStatementsView(itemProps);
    }
    default:
      return exhaustiveCheck(resultView.visualizer, true);
  }
  return [];
}

interface PrintItemProps {
  readonly market: string;
  readonly language: string;
  readonly resultView: QP.ResultViews;
  readonly resultItemMap: SC.ResultItemMap;
  readonly translate: Texts.TranslateFunction;
  readonly userSettings: UserSettingsShared.State;
  readonly getUnit: UserSettingsShared.GetUnitFunction;
  readonly getDecimals: UserSettingsShared.GetDecimalsFunction;
  readonly getExtraText: UserSettingsShared.GetExtraTextFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly ct_ResultVisualizerParamsTable: QP.ResultVisualizerParamsTableTable;
  readonly ct_MarketHiddenFields: QP.MarketHiddenFieldsTable;
  readonly ct_AmcaStatements: QP.AmcaStatementsTable;
  readonly variant: PropertyValueSet.PropertyValueSet;
}

function heatRecoveryDiagramsView(props: PrintItemProps): ReadonlyArray<Item> {
  const { resultItemMap, resultView, getUnit, translate } = props;
  const result = resultItemMap[resultView.result_item];
  const hru = result.type === "HeatRecoveryUnit" && result.value;
  if (!hru) {
    return [];
  }
  const airFlowUnit = getUnit("airFlow", "VolumeFlow");
  const airPressureUnit = getUnit("airPressure", "Pressure");

  const { supply, extract } = FanDiagram.generateSupplyExtractCharts({
    supplyFan: hru.supplyFan,
    extractFan: hru.extractFan,
    flowUnit: airFlowUnit,
    pressureUnit: airPressureUnit,
    translate: translate,
    showLineLabels: true,
    style: Style.diagramGanymed,
  });

  const items = [
    createPrintDiagram(
      translate(Texts.supply()) + " - " + translate(Texts.performanceCurve()),
      supply.pressure,
      hruWidth,
      hruHeight,
      props
    ),

    createPrintDiagram(
      translate(Texts.extract()) + " - " + translate(Texts.performanceCurve()),
      extract.pressure,
      hruWidth,
      hruHeight,
      props
    ),
  ];
  return items;
}

function createPrintDiagram(
  label: string,
  chart: AC.Chart,
  width: number,
  height: number,
  props: PrintItemProps
): Diagram {
  const image = AC.renderChart(chart, AbstractImage.createSize(width, height), {
    allowTextOverlap: true,
    formatNumber: props.formatNumber.format,
  });
  const svg =
    '<?xml version="1.0"?>' +
    AbstractImage.createSVG(image, (300 * image.size.width) / 96, (300 * image.size.height) / 96);
  return {
    type: "Diagram",
    label: label,
    svg: svg,
  };
}

function fanDiagramView(props: PrintItemProps): ReadonlyArray<Item> {
  const { resultView, resultItemMap, getUnit, translate } = props;
  const result = resultItemMap[resultView.result_item];
  const fan = result.type === "Fan" && result.value.air;
  if (!fan) {
    return [];
  }

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");

  const { pressure } = FanDiagram.generateCharts({
    fan,
    flowUnit,
    pressureUnit,
    translate,
    showLineLabels: true,
    style: Style.diagramGanymed,
  });

  const items = [createPrintDiagram(translate(Texts.performanceCurve()), pressure, fanWidth, fanHeight, props)];
  return items;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBase64Diagram(arg: any): arg is { readonly diagramBase64: string | undefined } {
  return arg.diagramBase64 !== undefined;
}

function base64Diagram(props: PrintItemProps): ReadonlyArray<Item> {
  const { resultView, resultItemMap, translate } = props;
  const result = resultItemMap[resultView.result_item];
  const resultValue = result && result.value;
  if (!resultValue || !isBase64Diagram(resultValue) || !resultValue.diagramBase64) {
    return [];
  }

  return [
    {
      type: "Base64Diagram",
      label: translate(Texts.performanceCurve()),
      format: "png",
      data: resultValue.diagramBase64,
    },
  ];
}

function centrifugalFanDiagramView(props: PrintItemProps): ReadonlyArray<Item> {
  const { resultView, resultItemMap, getUnit, translate } = props;
  const result = resultItemMap[resultView.result_item];
  const centrifugalFanResult = (result.type === "CentrifugalFan" && result.value) || undefined;
  if (!centrifugalFanResult) {
    return [];
  }

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const powerUnit = getUnit("power", "Power");

  const { motor, powerCurves, air, classCurves } = centrifugalFanResult;

  const { pressure, power } = CentrifugalFanDiagram.generateCharts({
    fan: air,
    motor,
    flowUnit,
    pressureUnit,
    powerUnit,
    translate,
    showLineLabels: true,
    style: Style.diagramCentrifugalFan,
    powerCurves: powerCurves,
    classCurves: classCurves,
  });

  const items = [
    createPrintDiagram(translate(Texts.performanceCurve()), pressure, centrifugalFanWidth, centrifugalFanHeight, props),
    createPrintDiagram(translate(Texts.powerCurve()), power, centrifugalFanWidth, centrifugalFanHeight, props),
  ];
  return items;
}

function boxFanDiagramView(props: PrintItemProps): ReadonlyArray<Item> {
  const { resultView, resultItemMap, getUnit, translate } = props;
  const result = resultItemMap[resultView.result_item];
  const fan = result.type === "BoxFan" && result.value.air;
  if (!fan) {
    return [];
  }

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const powerUnit = getUnit("power", "Power");

  const { pressure, power } = BoxFanDiagram.generateCharts({
    fan,
    flowUnit,
    pressureUnit,
    powerUnit,
    translate,
    showRpmCurves: true,
    showEfficiencyCurves: true,
    showMotorPowerCurves: true,
    showLineLabels: true,
    showActualRpmCurve: true,
    showActualRpmCurveLabel: true,
  });

  const items = [
    createPrintDiagram(translate(Texts.performanceCurve()), pressure, boxFandiagramWidth, boxFandiagramHeight, props),
    createPrintDiagram(translate(Texts.powerCurve()), power, boxFandiagramWidth, boxFandiagramHeight, props),
  ];
  return items;
}

function tableView(props: PrintItemProps): ReadonlyArray<Item> {
  const { market, translate, resultView, resultItemMap, ct_ResultVisualizerParamsTable, ct_MarketHiddenFields } = props;
  const params = resultView.visualizer_params.split(";");
  const columns = [translate(Texts.createText(params[0]), params[0]), ""];
  const rows = Shared.getTableRows(
    market,
    { ct_ResultVisualizerParamsTable, ct_MarketHiddenFields },
    resultView.visualizer_params,
    [resultItemMap]
  ).map((r) => [translate(Texts.createText(r.item.label)), valueToString(r.item.field_name, r.values[0], props)]);
  if (rows.length === 0) {
    return [];
  }
  return [
    {
      type: "Table",
      columns: columns,
      rows: rows,
    },
  ];
}

function multiColumnTableView(props: PrintItemProps): ReadonlyArray<Item> {
  const { market, translate, resultView, resultItemMap, ct_ResultVisualizerParamsTable, ct_MarketHiddenFields } = props;

  const params = resultView.visualizer_params.split(";");
  const tableName = params[1];
  const columnItems = params[2].split(",");
  const columns = [translate(Texts.createText(params[0])), ...columnItems.map((i) => translate(Texts.createText(i)))];
  const rowItems = ct_ResultVisualizerParamsTable.filter((p) => p.table_name === tableName);

  const hasSingleCellRows = rowItems.some((item) => item.result_value_path.indexOf(",") === -1);
  const numberOfColumns = columns.length % 2 === 0 ? columns.length + 1 : columns.length;
  const mid = Math.floor(numberOfColumns / 2);

  const rows = Shared.getMultiColumnRows(
    market,
    { ct_ResultVisualizerParamsTable, ct_MarketHiddenFields },
    resultView.visualizer_params,
    [resultItemMap]
  ).map((r) => {
    const { allValues, item } = r;
    const values = allValues[0];
    if (!hasSingleCellRows) {
      return [translate(Texts.createText(item.label)), ...values.map((v) => valueToString(item.field_name, v, props))];
    }
    if (values.length === 1) {
      return [
        translate(Texts.createText(item.label)),
        ...columnItems.slice(0, mid).map(() => ""),
        valueToString(item.field_name, values[0], props),
        ...columnItems.slice(mid).map(() => ""),
      ];
    } else {
      return [
        translate(Texts.createText(item.label)),
        ...values.slice(0, mid).map((v) => valueToString(item.field_name, v, props)),
        "",
        ...values.slice(mid).map((v) => valueToString(item.field_name, v, props)),
      ];
    }
  });
  if (rows.length === 0) {
    return [];
  }

  return [
    {
      type: "Table",
      columns: hasSingleCellRows ? [...columns.slice(0, mid + 1), "", ...columns.slice(mid + 1)] : columns,
      rows: rows,
    },
  ];
}

function amcaStatementsView(props: PrintItemProps): ReadonlyArray<Item> {
  const { userSettings, translate, getUnit, getExtraText, language, variant, ct_AmcaStatements, resultView } = props;
  const soundFilter = UserSettingsShared.getSoundFilter(userSettings);
  const amcaStatements = getAmcaStatements(
    getUnit,
    getExtraText,
    translate,
    ct_AmcaStatements,
    soundFilter,
    resultView.visualizer_params,
    language,
    variant
  );
  if (amcaStatements.length === 0) {
    return [];
  }
  const rows = amcaStatements.map((s) => [s]);
  return [
    {
      type: "Table",
      columns: [translate(Texts.amca_statements())],
      rows: rows,
    },
  ];
}

function valueToString(label: string, value: Shared.TableResultValue | undefined, props: PrintItemProps): string {
  const { translate } = props;
  if (!value) {
    return "-";
  } else if (typeof value === "string") {
    return translate(Texts.createText(value));
  } else if (typeof value === "number") {
    return value.toString();
  } else if (value instanceof Array) {
    return value.map((v) => translate(Texts.createText(v), v)).join(", ");
  } else {
    const amount = value as Amount.Amount<AnyQuantity>;
    if (!amount.unit) {
      return value.toString();
    }
    const unit = props.getUnit(label, amount.unit.quantity);
    const decimals = props.getDecimals(label, unit);
    const unitLabel = translate(Texts.unitLabel(unit), UnitFormat.getUnitFormat(unit, UnitsFormat)?.label);
    const valueStr = Amount.valueAs(unit, amount).toFixed(decimals);
    const formattedValue = props.formatNumber.format(valueStr);
    return `${formattedValue} ${unitLabel}`;
  }
}

function soundPressureLevelWithDistance(props: PrintItemProps): ReadonlyArray<Item> {
  const { translate, resultView, resultItemMap, userSettings } = props;

  const octaveBandsType = UserSettingsShared.getOctaveBandsType(userSettings);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results = (resultItemMap[resultView.result_item].value as any) as {
    [key: string]: SC.OctaveBands;
  };

  const rowItems = resultView.visualizer_params.split(",");
  const octaveCols =
    octaveBandsType === "Octave3rd"
      ? [
          "50",
          "63",
          "80",
          "100",
          "125",
          "160",
          "200",
          "250",
          "315",
          "400",
          "500",
          "630",
          "800",
          "1k",
          "1.25k",
          "1.6k",
          "2k",
          "2.5k",
          "3.15k",
          "4k",
          "5k",
          "6.3k",
          "8k",
          "10k",
        ]
      : ["63", "125", "250", "500", "1k", "2k", "4k", "8k"];
  const columns = [translate(Texts.soundPressureLevelLpa()), "", ...octaveCols, translate(Texts.total())];

  const soundDistance = UserSettingsShared.getSoundDistance(userSettings);
  const soundDistanceDouble = parseFloat(soundDistance);
  const damping = Sound.calculateDampingForDistance(soundDistanceDouble);

  const rows = rowItems
    .filter((ri) => !!results[ri])
    .map((ri) => {
      const rawOb = results[ri];
      const octaveBand = FanSound.calcOctaveBandsFrom3rds(rawOb);
      const octaveBandA = FanSound.aWeightOctaveBands(octaveBand);
      const ob = FanSound.applyOneAttenuation(octaveBandA, damping);
      if (!ob) {
        return [];
      }
      const values =
        octaveBandsType === "Octave3rd"
          ? R.repeat(undefined, 24)
          : [ob.hz63, ob.hz125, ob.hz250, ob.hz500, ob.hz1000, ob.hz2000, ob.hz4000, ob.hz8000];
      return [
        translate(Texts.createText(ri)),
        filterToString("A"),
        ...values.map(octaveBandToString),
        octaveBandToString(ob.total && Amount.valueAs(Units.DecibelLw, ob.total)),
      ];
    });
  if (rows.every((r) => r.slice(2).every((c) => c === "-"))) {
    return [];
  }

  const spacing = R.repeat("", octaveBandsType === "Octave3rd" ? 8 : 3);
  const rowsWithMeta = [
    ...rows,
    [
      ...spacing,
      `${translate(Texts.distance())}: ${soundDistanceDouble}m`,
      ...spacing,
      `${translate(Texts.directivity())}: ${translate(Texts.spherical_q1())}`,
      ...spacing,
      ...(octaveBandsType === "Octave3rd" ? [""] : []),
    ],
  ];

  return [
    {
      type: "Table",
      columns: columns,
      rows: rowsWithMeta,
    },
  ];
}

function soundPressureLevel(props: PrintItemProps): ReadonlyArray<Item> {
  const { translate, resultView, resultItemMap, userSettings } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results = (resultItemMap[resultView.result_item].value as any) as {
    [key: string]: SC.OctaveBands3rd;
  };
  const rowItems = resultView.visualizer_params.split(",");
  const columns = [translate(Texts.sound_pressure_level_reverberant_field()), "", "", "", translate(Texts.total())];

  const sabine = UserSettingsShared.getSoundAbsorptionArea(userSettings);
  const sabineDouble = parseFloat(sabine);
  const damping = Sound.calculateDampingSabine(sabineDouble);

  const rows = rowItems
    .filter((ri) => results[ri])
    .map((ri) => {
      const ob3rd = results[ri];

      const octaveBand = FanSound.aWeightOctaveBands3rd(ob3rd);
      const totalA = FanSound.calcTotFromOctaveBands3rd(octaveBand);
      const totalDampenedA = totalA !== undefined ? totalA + damping : undefined;
      return [
        translate(Texts.createText(ri)),
        `${damping.toFixed(0)} dB`,
        "dB",
        `${sabineDouble.toFixed(0)} m² (Sabin)`,
        `${totalDampenedA === undefined ? "-" : totalDampenedA < 10 ? "<10" : totalDampenedA.toFixed(0)}`,
      ];
    });
  return [
    {
      type: "Table",
      columns: columns,
      rows: rows,
    },
  ];
}

function octaveBandsTableFan(props: PrintItemProps): ReadonlyArray<Item> {
  const { translate, resultView, resultItemMap, userSettings } = props;

  const filter = UserSettingsShared.getSoundFilter(userSettings);
  const octaveBandsType = UserSettingsShared.getOctaveBandsType(userSettings);

  const rowItems = resultView.visualizer_params.split(",");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results = (resultItemMap[resultView.result_item].value as any) as {
    [key: string]: SC.OctaveBands | SC.OctaveBands3rd;
  };

  const octaveCols =
    octaveBandsType === "Octave3rd"
      ? [
          "50",
          "63",
          "80",
          "100",
          "125",
          "160",
          "200",
          "250",
          "315",
          "400",
          "500",
          "630",
          "800",
          "1k",
          "1.25k",
          "1.6k",
          "2k",
          "2.5k",
          "3.15k",
          "4k",
          "5k",
          "6.3k",
          "8k",
          "10k",
        ]
      : ["63", "125", "250", "500", "1k", "2k", "4k", "8k"];
  const columns = [translate(Texts.sound_power_level()), "", ...octaveCols, translate(Texts.total())];

  const rows = rowItems
    .map((ri) => {
      let ob: SC.OctaveBands | SC.OctaveBands3rd | undefined = results[ri];
      ob = ob && FanSound.applyFilter(filter, ob);
      ob = octaveBandsType === "Octave" ? FanSound.calcOctaveBandsFrom3rds(ob) : ob;
      if (!ob) {
        return [];
      }
      const cols = [];
      if (octaveBandsType === "Octave3rd" && ob.type === "Octave3rd") {
        cols.push(
          ...[
            ob.hz50,
            ob.hz63,
            ob.hz80,
            ob.hz100,
            ob.hz125,
            ob.hz160,
            ob.hz200,
            ob.hz250,
            ob.hz315,
            ob.hz400,
            ob.hz500,
            ob.hz630,
            ob.hz800,
            ob.hz1000,
            ob.hz1250,
            ob.hz1600,
            ob.hz2000,
            ob.hz2500,
            ob.hz3150,
            ob.hz4000,
            ob.hz5000,
            ob.hz6300,
            ob.hz8000,
            ob.hz10000,
          ]
        );
      } else if (octaveBandsType === "Octave" && ob.type === "Octave") {
        cols.push(...[ob.hz63, ob.hz125, ob.hz250, ob.hz500, ob.hz1000, ob.hz2000, ob.hz4000, ob.hz8000]);
      } else {
        cols.push(...R.repeat(undefined, octaveBandsType === "Octave3rd" ? 24 : 8));
      }
      cols.push(ob.total && Amount.valueAs(Units.DecibelLw, ob.total));
      return [translate(Texts.createText(ri)), filterToString(filter), ...cols.map(octaveBandToString)];
    })
    .filter((r) => r.length > 0);

  if (rows.length === 0 || rows.every((r) => r.slice(2).every((c) => c === "-"))) {
    return [];
  }

  const rowsAtThreeMeters = soundAtThreeMeters(
    results["surroundingSound"],
    filter,
    octaveBandsType,
    rowItems,
    translate
  );

  // Box fan distance row
  const metaRows = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const maybeBoxFanResult = results as any; // SC.BoxFan
  const distance = maybeBoxFanResult["soundPressureDistance"];
  const soundPressureLevel = maybeBoxFanResult["soundPressureLevelLpa"];
  if (distance && soundPressureLevel) {
    const spacing = R.repeat("", octaveBandsType === "Octave3rd" ? 5 : 2);
    metaRows.push([
      ...spacing,
      `${translate(Texts.soundPressureLevelLpa())}: ${Amount.valueAs(customUnits.Decibel, soundPressureLevel)}dB(A)`,
      ...spacing,
      `${translate(Texts.distance())}: ${Amount.valueAs(customUnits.Meter, distance)}m`,
      ...spacing,
      `${translate(Texts.directivity())}: ${translate(Texts.spherical_q1())}`,
      ...spacing,
    ]);
  }

  return [
    {
      type: "Table",
      columns: columns,
      rows: [...rows, ...rowsAtThreeMeters, ...metaRows],
    },
  ];
}

function soundAtThreeMeters(
  surroundingSoundOctaveBands: SC.OctaveBands | SC.OctaveBands3rd | undefined,
  filter: UserSettingsShared.SoundFilter,
  octaveBandsType: UserSettingsShared.OctaveBandsType,
  items: ReadonlyArray<string>,
  translate: Texts.TranslateFunction
): ReadonlyArray<ReadonlyArray<string>> {
  if (!surroundingSoundOctaveBands) {
    return [];
  }
  const octaveBand = FanSound.calcOctaveBandsFrom3rds(FanSound.applyFilter(filter, surroundingSoundOctaveBands));
  const total = FanSound.calcTotFromOctaveBands(octaveBand);

  if (!total) {
    return [];
  }
  const rows: Array<Array<string>> = [];

  const emptyCols = R.repeat(octaveBandToString(undefined), octaveBandsType === "Octave3rd" ? 24 : 8);

  if (items.find((i) => i === "soundAt3mSabine")) {
    rows.push([
      translate(Texts.createText("soundAt3mSabine")),
      filterToString(filter),
      ...emptyCols,
      octaveBandToString(total - 7),
    ]);
  }

  if (items.find((i) => i === "soundAt3mFree")) {
    rows.push([
      translate(Texts.createText("soundAt3mFree")),
      filterToString(filter),
      ...emptyCols,
      octaveBandToString(total - 21),
    ]);
  }

  return rows;
}

function filterToString(filter: UserSettingsShared.SoundFilter): string {
  if (filter === "A") {
    return "dB(A)";
  } else if (filter === "C") {
    return "dB(C)";
  } else {
    return "dB";
  }
}

function octaveBandsTableHru(props: PrintItemProps): ReadonlyArray<Item> {
  const { translate, resultView, resultItemMap } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const results = (resultItemMap[resultView.result_item].value as any) as {
    [key: string]: SC.OctaveBands3rd;
  };
  const rowItems = resultView.visualizer_params.split(",");
  const columns = [
    translate(Texts.sound_power_level()),
    "63",
    "125",
    "250",
    "500",
    "1k",
    "2k",
    "4k",
    "8k",
    "",
    translate(Texts.total()),
    "",
  ];
  const rows = rowItems
    .filter((ri) => results[ri])
    .map((ri) => {
      const ob3rd = results[ri];
      const ob = FanSound.calcOctaveBandsFrom3rds(ob3rd) as SC.OctaveBands;
      const ob3rdA = FanSound.aWeightOctaveBands3rd(ob3rd);
      return [
        translate(Texts.createText(ri)),
        octaveBandToString(ob.hz63),
        octaveBandToString(ob.hz125),
        octaveBandToString(ob.hz250),
        octaveBandToString(ob.hz500),
        octaveBandToString(ob.hz1000),
        octaveBandToString(ob.hz2000),
        octaveBandToString(ob.hz4000),
        octaveBandToString(ob.hz8000),
        "dB",
        octaveBandToString(FanSound.calcTotFromOctaveBands3rd(ob3rdA)),
        "dB(A)",
      ];
    });
  if (rows.every((r) => r.slice(1, 9).every((c) => c === "-"))) {
    return [];
  }
  return [
    {
      type: "Table",
      columns: columns,
      rows: rows,
    },
  ];
}

function octaveBandToString(value: number | undefined): string {
  if (value === undefined) {
    return "-";
  }
  if (value < 10) {
    return "<10";
  }
  return value.toFixed(0);
}

export async function createExternalItem(
  input: OwnProps,
  resolveQuery: QD.ResolveQueryFn
): Promise<ReadonlyArray<ExternalItem>> {
  const response = await resolveQuery(externalItemsQuery, input);
  const externalItems = await getExternalItems(input, response);
  return externalItems;
}

export async function createExternalItemOld(input: OwnProps, resolveQuery: QD.ResolveQueryFn): Promise<ExternalItem> {
  const externalItems = await createExternalItem(input, resolveQuery);
  const alerts = [];
  const items = [];
  const addedAlerts = new Set();
  for (const ei of externalItems) {
    for (const i of ei.items) {
      if (isAlert(i)) {
        const alertKey = `${i.type}_${i.text}`;
        if (!addedAlerts.has(alertKey)) {
          alerts.push(i);
          addedAlerts.add(alertKey);
        }
      } else {
        items.push(i);
      }
    }
  }
  const mainItem = externalItems.find((ei) => ei.itemNumber === input.m3ItemNo);
  if (!mainItem) {
    // The main item should always be included among the returned items
    throw new Error("Main item not found");
  }
  return {
    itemNumber: mainItem.itemNumber,
    variant: mainItem.variant,
    items: [...alerts, ...items],
  };
}

export async function createExternalConfig(
  input: OwnProps,
  resolveQuery: QD.ResolveQueryFn
): Promise<string | undefined> {
  const response = await resolveQuery(externalItemsQuery, input);

  const { m3ItemNo, variant, propsConfig, stateConfig, market } = input;
  const { productTables, metaTables, accessoryTables, productId, calculationResponse } = response;

  const fullConfig = PU.getFullConfig({
    market: market,

    m3ItemNo: m3ItemNo,
    variant: variant,
    propsConfig: propsConfig,
    stateConfig: stateConfig,

    metaTables: metaTables,
    productTables: productTables,
    accessoryTables: accessoryTables,
  });

  const results = await C.calculateSystem(
    { productId, config: fullConfig || C.emptyItemConfig, variantId: variant },
    calculationResponse
  );
  if (!results) {
    return undefined;
  }

  // If there are messages we dont allow a config to be exported, we still want the selected units:
  // There is a case where no input is entered, in this case we show the nominal result. The user might change a unit and print it
  // const componentMessages = SC.getMessages(results[productId]);
  // if (componentMessages.length > 0) {
  //   return C.serializeConfig({
  //     ...emptyItemConfig,
  //     meta: {
  //       fieldUnits: UserSettingsShared.getFieldUnitSettings({
  //         market,
  //         ct_MarketUnits: metaTables.ct_MarketUnits,
  //         userSettings: input.userSettings,
  //       }),
  //     },
  //   });
  // }

  const configWithMeta = {
    ...(fullConfig === undefined ? emptyItemConfig : fullConfig),
    meta: {
      ...input.userSettings,
    },
  };

  return (configWithMeta && C.serializeConfig(configWithMeta)) || undefined;
}

const emptyItemConfig = {
  properties: PropertyValueSet.Empty,
  calcParams: PropertyValueSet.Empty,
  accessories: [],
};
