/* eslint-disable functional/no-this-expression */
import * as React from "react";
import { DispatchProp, Dispatch } from "client-lib/redux-integration";
import { PropertyFilter } from "@promaster-sdk/property";
import * as UserSettingsClient from "client-lib/user-settings";
import * as UserSettingsShared from "shared-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as C from "shared-lib/calculation";
import * as Texts from "shared-lib/language-texts";
import * as PU from "shared-lib/product-utils";
import { Spinner, Heading3, withTw, LinkButton } from "client-lib/elements";
import * as Uuid from "uuid";
import { ComponentResult } from "shared-lib/search";
import * as PC from "shared-lib/product-codes";
import {
  CalculationResultTable,
  ResultTableProps,
  ProductTable,
  TableRow,
  renderFricoSelectionCriteria,
} from "client-lib/frico";
import { TranslateFunction } from "shared-lib/language-texts";
import * as SC from "shared-lib/system-calculator";
import { createSelector } from "reselect";
import * as R from "ramda";
import { ItemConfig } from "shared-lib/calculation";
import { getFricoDefaultVariantsEpim, MarketTablesResponse } from "shared-lib/product-utils";
import { formatNumberFunction } from "shared-lib/utils";
import { PrintViewContainerEpim } from "../frico-print-view-epim";
import { VariantSelector } from "./components/variant-selector";
import { FricoProductTableContainer } from "../frico-product-table";
import { FricoAccessoriesTableContainer } from "../frico-accessories-table-epim";
import { ProjectSpecification } from "./components/project-specification";
import { Item, TablesForProducts, SelectedVariant } from "./types";
import { ProductContainer } from "../product";
import { State } from "./reducer";
import * as Actions from "./actions";
import { FricoProductBrowserEpimContainer } from "../frico-product-browser-epim";

type Props = OwnProps & StateProps & Response & DispatchProp<Actions.Action | UserSettingsClient.Action>;

const HelpContainer = withTw("div", "bg-gray-100 p-24 text-sm min-w-224 max-w-224");
const StepRow = withTw("div", "flex flex-row space-x-16");
const StepContent = withTw("div", "flex flex-col space-y-24 w-full pb-32 pl-20 print:pl-0");
const Step = withTw("div", "w-full");

export interface OwnProps {
  readonly ecomUrl: string;
  readonly market: string;
  readonly language: string;
  readonly specificationDataUrl: string;
}

export interface StateProps {
  readonly state: State;
  readonly userSettings: UserSettingsShared.State;
  readonly itemConfig: ItemConfig;
}

export interface Response {
  readonly ct_SearchProducts: QP.SearchProductsTable;
  readonly translateTables: Texts.TranslationTables;
  readonly tablesForProducts: TablesForProducts | undefined;
  readonly marketTables: PU.MarketTablesResponse;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly calculationResponse: { readonly [value: string]: C.Response | undefined };
  readonly fricoTables: {
    readonly property: QP.PropertyTable;
    readonly ct_PropertyFieldNames: QP.PropertyFieldNamesTable;
    readonly ct_PropertySelectorTypes: QP.PropertySelectorTypesTable;
    readonly ct_SavedResultColumns: QP.SavedResultColumnsTable;
  };
  readonly productTables: {
    readonly ct_Accessories: QP.AccessoriesTable;
  };
}

export interface LocalState {
  readonly calculationStarted: boolean;
}

const searchNotFoundSelector = createSelector(
  (props: Props) => props.dispatch,
  (dispatch) => () => {
    dispatch(Actions.searchNotFound());
  }
);

const onSelectionChangedSelector = createSelector(
  (props: Props) => props.dispatch,
  (dispatch) => (
    lvl1: string | undefined,
    lvl2: string | undefined,
    productId: string | undefined,
    selectedVariant: SelectedVariant | undefined,
    ecomProductId: string | undefined,
    itemName: string | undefined,
    itemNumber: string | undefined,
    variantNo: string | undefined
  ) => {
    dispatch(Actions.setSelectedPath(lvl1, lvl2, productId, selectedVariant, ecomProductId));
    if (itemName && itemNumber && variantNo && productId && selectedVariant) {
      dispatch(
        Actions.addVariant(itemName, selectedVariant.m3ItemNo, variantNo, 1, 0, productId, selectedVariant.properties)
      );
    }
  }
);

// eslint-disable-next-line functional/no-class
export class FricoSpecificationTextEpimContainerComponent extends React.Component<Props, LocalState> {
  // eslint-disable-next-line functional/prefer-readonly-type
  onPopState: () => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      calculationStarted: false,
    };
    this.onPopState = () => props.dispatch(Actions.displayPrint(false));
  }

  componentDidMount(): void {
    window.addEventListener("popstate", this.onPopState);
    this.performCalculation(this.props, true);
  }

  componentWillUnmount(): void {
    window.removeEventListener("popstate", this.onPopState);
  }

  async performCalculation(props: Props, isFirstRun?: boolean): Promise<void> {
    this.setState({ calculationStarted: true });

    for (const item of props.state.addedItems) {
      const config = createConfig(item);
      const calculationResponse = props.calculationResponse[item.productId];
      const { result } = await C.calculateSystem(
        { productId: item.productId, config: config, variantId: item.variantNo },
        calculationResponse
      );
      const translate = Texts.translateFunctionSelector(props.translateTables, props.language);
      if (isFirstRun && !item.addedFromSearch) {
        continue;
      }
      calculationCallback(result, config, translate, props, item, props.state.savedCalculations, props.dispatch);
    }
  }

  render(): React.ReactElement<Props> {
    const {
      ecomUrl,
      state,
      dispatch,
      language,
      market,
      tablesForProducts,
      translateTables,
      specificationDataUrl,
      ct_MarketUnits,
      ct_LanguageMapping,
      userSettings,
      fricoTables,
    } = this.props;
    const { productId, addedItems } = state;
    if (tablesForProducts === undefined || translateTables === undefined) {
      return <Spinner />;
    }
    const translate = Texts.translateFunctionSelector(translateTables, this.props.language);

    const resultTableProps = getResultTableProps(this.props, translate);
    if (state.displayPrint) {
      return (
        <PrintViewContainerEpim
          projectName={state.projectName}
          projectDescription={state.projectComments}
          translate={translate}
          closeCallBack={() => window.history.back()}
          products={state.addedItems}
          searchSelections={state.searchSelections}
          addedAccessories={state.addedAccessories}
          savedCalculations={state.savedCalculations}
          getUnit={UserSettingsShared.getUnit({
            market,
            ct_MarketUnits,
            userSettings,
          })}
          getDecimals={UserSettingsShared.getDecimals({ market, ct_MarketUnits })}
          formatNumber={formatNumberFunction(language, ct_LanguageMapping)}
          specificationDataUrl={specificationDataUrl}
          market={market}
          language={language}
          ecomUrl={ecomUrl}
        />
      );
    }
    const selectedItem = getSelectedItem(this.props);

    return (
      <div>
        <SearchCriteria
          market={market}
          language={language}
          searchSelections={state.searchSelections}
          ct_LanguageMapping={ct_LanguageMapping}
          ct_MarketUnits={ct_MarketUnits}
          userSettings={userSettings}
          fricoTables={fricoTables}
          translate={translate}
        />
        <StepRow>
          <HelpContainer>
            <p>{translate(Texts.step1())}</p>
            <p style={{ fontWeight: "bold" }}>{translate(Texts.find_add_products())}</p>
            <p>{translate(Texts.browse_by_product())}</p>
          </HelpContainer>
          <Step>
            <Heading3>{translate(Texts.product_finder())}</Heading3>
            <StepContent>
              <FricoProductBrowserEpimContainer
                ecomUrl={ecomUrl}
                market={market}
                selectedEcomId={state.ecomProductId}
                context={"specification_text"}
                lvl1={state.browserLvl1}
                searchNotFound={state.searchNotFound}
                lvl2={state.browserLvl2}
                language={language}
                selectedProductId={productId}
                onSelectionChanged={onSelectionChangedSelector(this.props)}
                onSearchNotFound={searchNotFoundSelector(this.props)}
              />
              {createProductTable(this.props, translate)}
            </StepContent>
          </Step>
        </StepRow>

        {state.addedItems.length > 0 && (
          <StepRow>
            <HelpContainer>
              <p>{translate(Texts.step2())}</p>
              <p style={{ fontWeight: "bold" }}>{translate(Texts.define_products_quantity())}</p>
              <p>{translate(Texts.change_default_quantity())}</p>
            </HelpContainer>
            <Step>
              <Heading3>{translate(Texts.product_text())}</Heading3>
              <StepContent>
                <FricoProductTableContainer
                  market={market}
                  language={language}
                  translate={translate}
                  products={state.addedItems}
                  addedAccessories={state.addedAccessories}
                  ecomUrl={ecomUrl}
                  specificationDataUrl={specificationDataUrl}
                  updateVariant={(itemName, m3ItemNo, variantNo, quantity, sortNo, id, properties) =>
                    dispatch(Actions.updateVariant(itemName, m3ItemNo, variantNo, quantity, sortNo, id, properties))
                  }
                  removeVariant={(m3ItemNo, accessoriesToRemove) =>
                    dispatch(Actions.removeVariant(m3ItemNo, accessoriesToRemove))
                  }
                />
              </StepContent>
            </Step>
          </StepRow>
        )}

        {/* {BasketTable(props, translate)} */}
        {selectedItem !== undefined ? (
          <StepRow>
            <HelpContainer>
              <p>{translate(Texts.step3())}</p>
              <p style={{ fontWeight: "bold" }}>{translate(Texts.execute_heating_calculation())}</p>
              <p>{translate(Texts.choose_between_products())}</p>
            </HelpContainer>
            <Step>
              <Heading3>{translate(Texts.heating_calculation())}</Heading3>
              <StepContent className="pl-0">
                <div className="pl-20">
                  <VariantSelector
                    translate={translate}
                    addedItems={addedItems}
                    selectedItem={selectedItem}
                    tablesForProducts={tablesForProducts}
                    selectionChanged={(itemNo) => dispatch(Actions.setSelectedVariant(itemNo))}
                  />
                </div>
                <ProductContainer
                  productId={(selectedItem && selectedItem.productId) || undefined}
                  calculationExists={state.calculationExists}
                  variant={undefined}
                  ecomUrl={ecomUrl}
                  market={market}
                  hideName={true}
                  crm={undefined}
                  updateVariant={(calcParams) => {
                    // dispatch(
                    //   Actions.updateVariant(
                    //     selectedItem.itemName,
                    //     selectedItem.m3ItemNo,
                    //     selectedItem.variantNo,
                    //     selectedItem.quantity,
                    //     selectedItem.sortNo,
                    //     selectedItem.productId,
                    //     selectedItem.properties,
                    //     calcParams
                    //   )
                    // );
                    dispatch(Actions.updateAllCalcParams(calcParams));
                  }}
                  saveCalculationCallBack={(config, result) => {
                    calculationCallback(
                      result,
                      config,
                      translate,
                      this.props,
                      selectedItem,
                      state.savedCalculations,
                      dispatch
                    );
                  }}
                  language={language}
                  m3ItemNo={selectedItem.m3ItemNo}
                  config={createConfig(selectedItem)}
                  hideInvalidValues={true}
                />
                {state.savedCalculations.length > 0 && <CalculationResultTable {...resultTableProps} />}
              </StepContent>
            </Step>
          </StepRow>
        ) : null}

        {state.addedItems.length > 0 && (
          <StepRow>
            <HelpContainer>
              <p>{translate(Texts.step4())}</p>
              <p className="font-bold">{translate(Texts.define_accessories())}</p>
              <p>{translate(Texts.choose_between_control())}</p>
            </HelpContainer>
            <Step>
              <Heading3>{translate(Texts.accessories())}</Heading3>
              <StepContent>
                <FricoAccessoriesTableContainer
                  market={market}
                  language={language}
                  ecomUrl={ecomUrl}
                  specificationDataUrl={specificationDataUrl}
                  translate={translate}
                  addedAccessories={state.addedAccessories}
                  addedProducts={state.addedItems}
                  updateQuantity={(productId, itemNumber, quantity, category) =>
                    dispatch(Actions.updateAccessoryQuantity(productId, itemNumber, quantity, category))
                  }
                />
              </StepContent>
            </Step>
          </StepRow>
        )}

        {state.addedItems.length > 0 && (
          <StepRow>
            <HelpContainer>
              <p>{translate(Texts.step5())}</p>
              <p style={{ fontWeight: "bold" }}>{translate(Texts.add_project_description())}</p>
              <p>{translate(Texts.enter_project_name_comment())}</p>
            </HelpContainer>
            <Step>
              <Heading3>{translate(Texts.project_description())}</Heading3>
              <StepContent>
                <ProjectSpecification
                  projectName={state.projectName}
                  projectComments={state.projectComments}
                  dispatch={dispatch}
                  translate={translate}
                />
              </StepContent>
            </Step>
          </StepRow>
        )}
      </div>
    );
  }
}

function calculationCallback(
  result: SC.ResultItemOutputPerComponent | undefined,
  config: C.ItemConfig,
  translate: Texts.TranslateFunction,
  props: Props,
  selectedItem: Item,
  savedCalculations: ReadonlyArray<ComponentResult>,
  dispatch: Dispatch<Actions.Action | UserSettingsClient.Action>
): void {
  if (result !== undefined) {
    const calculationResult = createCalculationResult(config, result, translate, props, selectedItem);

    // const outCalcParams = R.unnest<string>(
    //   R.values(result[selectedItem.productId]).map((r) =>
    //     r.type === "OutputMapperSuccess" ? PropertyValueSet.getPropertyNames(r.calcParams) : []
    //   )
    // );

    if (calculationResult !== undefined) {
      const exists = savedCalculations.find(
        (c) =>
          c.productId === calculationResult.productId &&
          // PropertyValueSet.equals(c.properties, calculationResult.properties) &&
          // PropertyValueSet.equals(
          //   PropertyValueSet.removeProperties(outCalcParams, c.calcParams),
          //   PropertyValueSet.removeProperties(outCalcParams, calculationResult.calcParams)
          // ) &&
          R.equals(calculationResult.results, c.results) && // compare results instead of properties & calcparams
          c.m3 === calculationResult.m3
      );
      if (exists) {
        dispatch(Actions.calculationExists());
      } else {
        dispatch(Actions.saveCalculation(calculationResult));
      }
    }
  }
}

export function createConfig(selectedItem: Item): C.ItemConfig {
  return {
    properties: selectedItem.properties,
    calcParams: selectedItem.calcParams || {},
    accessories: [],
  };
}

function createProductTable(props: Props, translate: TranslateFunction): React.ReactElement<{}> {
  const { state, tablesForProducts, marketTables, dispatch } = props;
  const { productId, addedItems, ecomProductId } = state;

  if (productId === undefined || tablesForProducts === undefined || marketTables === undefined) {
    return <div />;
  }

  const nextSortNo = addedItems.length === 0 ? 0 : Math.max(...addedItems.map((r) => r.sortNo)) + 1;

  const addedItemsx = state.addedItems.map((i) => i.m3ItemNo);

  return (
    <ProductTable
      items={getProductsEpim(productId, tablesForProducts, ecomProductId, props.marketTables, translate)}
      translate={translate}
      addedItems={addedItemsx}
      productId={productId}
      nextSortNo={nextSortNo}
      selectedRowCallBack={(itemName, m3ItemNo, variantNo, quantity, sortNo, id, properties) =>
        dispatch(Actions.addVariant(itemName, m3ItemNo, variantNo, quantity, sortNo || 0, id, properties))
      }
      highLightItemNo={state.highLightItemNo}
      ct_itemNumberStatus={tablesForProducts[productId].ct_ItemNumberStatus}
    />
  );
}

function getResultTableProps(props: Props, translate: TranslateFunction): ResultTableProps {
  const { state, market, language, dispatch, fricoTables, ct_MarketUnits, ct_LanguageMapping } = props;

  return {
    market: market,
    language: language,
    translate: translate,
    userSettings: props.userSettings,
    onClearSavedCalculations: () => dispatch(Actions.clearSavedCalculations()),
    onRemoveSavedCalculation: (s) => dispatch(Actions.removeCalculation(s)),
    dispatch: dispatch,
    results: state.savedCalculations,
    ct_SavedResultColumns: fricoTables.ct_SavedResultColumns,
    ct_MarketUnits: ct_MarketUnits,
    ct_LanguageMapping,
  };
}

function getSelectedItem(props: Props): Item | undefined {
  const { state } = props;

  if (state.selectedM3ItemNo !== undefined) {
    return state.addedItems.find((r) => r.m3ItemNo === state.selectedM3ItemNo);
  }

  // If nothing selected, Find first that is not ambient
  return state.addedItems.find((r) => {
    if (!PU.isAmbient(props.tablesForProducts![r.productId].ct_Attributes2, r.properties)) {
      return true;
    }
    return false;
  });
}

function createCalculationResult(
  config: C.ItemConfig,
  calcResult: SC.ResultItemOutputPerComponent,
  translate: Texts.TranslateFunction,
  props: Props,
  selectedItem: Item | undefined
): ComponentResult | undefined {
  const { tablesForProducts } = props;

  if (selectedItem !== undefined) {
    const codes = PC.getProductCodes(tablesForProducts![selectedItem.productId], config.properties);
    const itemName = translate(Texts.item_name(selectedItem.productId, config.properties), codes.code);
    return {
      id: Uuid(),
      productId: selectedItem.productId,
      properties: config.properties,
      //showProperties: [],
      calcParams: config.calcParams,
      productCode: codes.code || "",
      itemName: itemName,
      m3: selectedItem.m3ItemNo || "",
      variant: codes.variantId || "",
      additionalData: undefined,
      results: calcResult[selectedItem.productId],
    };
  }
  return undefined;
}

function SearchCriteria({
  market,
  language,
  searchSelections,
  ct_MarketUnits,
  ct_LanguageMapping,
  userSettings,
  fricoTables,
  translate,
}: {
  readonly market: string;
  readonly language: string;
  readonly searchSelections: PU.Selections | undefined;
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly userSettings: UserSettingsShared.State;
  readonly fricoTables: Response["fricoTables"];
  readonly translate: Texts.TranslateFunction;
}): React.ReactElement<{}> | null {
  const [isOpen, setIsOpen] = React.useState(false);
  const selectionCriteria =
    searchSelections &&
    renderFricoSelectionCriteria({
      property: fricoTables.property,
      ct_MarketUnits: ct_MarketUnits,
      ct_PropertyFieldNames: fricoTables.ct_PropertyFieldNames,
      market: market,
      language: language,
      userSettings: userSettings,
      translate: translate,
      searchSelections: searchSelections,
      ct_LanguageMapping,
    });
  if (!selectionCriteria) {
    return null;
  }
  return (
    <StepRow>
      <HelpContainer></HelpContainer>
      <Step>
        <LinkButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? translate(Texts.frico_hide_search_criteria()) : translate(Texts.frico_show_search_criteria())}
        </LinkButton>
        {isOpen && (
          <>
            <Heading3>{translate(Texts.frico_search_criteria())}</Heading3>
            <StepContent>
              <table>
                {selectionCriteria.groups.map((group) => (
                  <>
                    <thead>
                      <tr key={group.key}>
                        <th colSpan={2}>{group.label}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.properties.map((property) => {
                        return (
                          <tr key={property.key}>
                            <td>{property.label}</td>
                            <td>{property.value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ))}
              </table>
            </StepContent>
          </>
        )}
      </Step>
    </StepRow>
  );
}

function getProductsEpim(
  productId: string | undefined,
  tablesForProducts: TablesForProducts | undefined,
  ecomProductId: string | undefined,
  marketTables: MarketTablesResponse,
  translate: Texts.TranslateFunction
): ReadonlyArray<TableRow> {
  if (productId === undefined || tablesForProducts === undefined || ecomProductId === undefined) {
    return [];
  }
  const isExpired = ecomProductId.includes("expired");
  const ecomId = isExpired ? ecomProductId.split("_")[1] : ecomProductId;

  const allVariants = getFricoDefaultVariantsEpim(tablesForProducts[productId], ecomId, true, marketTables, isExpired);

  return allVariants.reduce((sofar, current) => {
    if (
      !tablesForProducts[productId].ct_Attributes2.some(
        (att) =>
          att.attribute === "ecom-product-id" &&
          att.collection === "ECOM-HIERARCHY-FRICO" &&
          att.value === ecomId &&
          PropertyFilter.isValid(current, att.property_filter)
      )
    ) {
      return sofar;
    }

    const orderingCode = PC.getProductCodes(tablesForProducts[productId], current);

    if (!isExpired) {
      if (PU.availableInMarket(marketTables, orderingCode) === false) {
        return sofar;
      }
      const itemNumber = tablesForProducts![productId].ct_ItemNo.find((row) =>
        PropertyFilter.isValid(current, row.property_filter)
      );

      const itemNumberStatus = tablesForProducts![productId].ct_ItemNumberStatus.find(
        (row) => row.item_no === itemNumber?.item_no
      );

      if (itemNumberStatus && itemNumberStatus.status > 20) {
        return sofar;
      }
    }
    sofar.push({
      itemName: translate(Texts.item_name(productId, current)),
      m3ItemNo: orderingCode.itemNo,
      properties: current,
      variantNo: orderingCode.variantId || "",
    });
    return sofar;
  }, Array<TableRow>());
}
