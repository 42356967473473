import { createSelector, Selector } from "reselect";
import * as UserSettings from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as QD from "shared-lib/query-diaq";
import { diaqConnect } from "client-lib/redux-integration";
import { RootState } from "../../types";
import { SearchPropertiesSelectorContainerComponent, StateProps, Response, OwnProps } from "./container-component";

function mapPropsToQuery(props: OwnProps, response: Response | undefined): QD.DiaqMapQuery<Response> {
  return QD.createMapQuery<Response>({
    productTables: QP.tablesByProductId(props.productId, [
      "property",
      "ct_PropertySelectorTypes",
      "ct_PropertyFieldNames",
      "ct_ClosedGroups",
      "image",
    ]),
    ct_MarketUnits: QP.tableByProductId(QP.metaProductId, "ct_MarketUnits"),
    images: getImagesQuery(response),
  });
}

function getImagesQuery(response: Response | undefined): QD.DiaqMapQuery<{}> | undefined {
  if (!response || !response.productTables) {
    return undefined;
  }

  const map: { [url: string]: QP.BlobQuery | undefined } = {};
  for (const property of response.productTables.property) {
    for (const value of property.value) {
      if (value.image) {
        map[value.image] = QD.allowUndefined(QP.blob(value.image));
      }
    }
  }
  for (const image of response.productTables.image) {
    if (image.image !== undefined && image.type !== undefined && image.type !== "") {
      map[image.image] = QD.allowUndefined(QP.blob(image.image));
    }
  }

  return QD.createMapQuery(map);
}

const mapStateToProps: Selector<RootState, OwnProps, StateProps> = createSelector(
  UserSettings.showAdvancedSelector,
  UserSettings.stateSelector,
  (showAdvanced, userSettings) => ({
    showAdvanced: showAdvanced,
    userSettings: userSettings,
  })
);

export const SearchPropertiesSelectorContainer = diaqConnect(
  mapPropsToQuery,
  mapStateToProps
)(SearchPropertiesSelectorContainerComponent);
