import * as React from "react";
import * as R from "ramda";
import * as SC from "shared-lib/system-calculator";
import { LinkButton } from "client-lib/elements";
import * as UserSettingsShared from "shared-lib/user-settings";
import { BoxFanResultItem } from "shared-lib/system-calculator/result-items-types";
import * as QP from "shared-lib/query-product";
import { DispatchProp } from "client-lib/redux-integration";
// import * as PC from "shared-lib/product-codes";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as C from "shared-lib/calculation";
import { NavigateToItemNo } from "types";
import { SingleVisualizerOwnProps as VisualizerOwnProps } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Props = VisualizerOwnProps & StateProps & Response & DispatchProp<any>;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
  // readonly showStandardFanAlternatives: boolean;
}

export interface ProductTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_Attributes2: QP.AttributesTable;
  readonly ct_Fan: QP.BoxFanFanTable;
  readonly ct_StandardFan: QP.BoxFanStandardFanTable;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly productTables: ProductTables;
}

export function BoxFanRelatedProductsVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { resultItemMap } = props;

  const fanSystem: BoxFanResultItem = R.head(
    R.values(R.filter((ri: SC.ResultItem) => ri.type === "BoxFan", resultItemMap))
  ) as BoxFanResultItem;
  const fan = fanSystem && fanSystem.value.air;

  if (!fan) {
    return <div />;
  }

  return fan.fanIsConfigurable
    ? configurableFan(/*props, fan.fanId, PropertyValueSet.getInteger("freq", calcParams), fan.bestStandardFan*/)
    : standardFan(props, fan.fanId);
}

function configurableFan(): React.ReactElement<Props> {
  // _props: Props,
  // _fanId: string,
  // _frequency: number | undefined,
  // _bestStandardFan: string | undefined
  return <span />;
  // const { ct_StandardFan, code } = props.productTables;
  // const { navigateToItemNo, calcParams, shopUrl } = props;
  // const availableStandardFans = ct_StandardFan.filter(
  //   f =>
  //     f.fan_id === fanId &&
  //     (!frequency || (frequency === 60 && f.is60Hz === "1") || (frequency === 50 && f.is60Hz === "0"))
  // );

  // const relatedFans = availableStandardFans.map(f => {
  //   const m3Variant = PC.getVariantFromM3ItemNo(props.productTables, f.item_number, undefined);
  //   const orderingCode = code.find(
  //     o => m3Variant !== undefined && PropertyFilter.isValid(m3Variant, o.property_filter)
  //   );
  //   if (orderingCode !== undefined && m3Variant !== undefined) {
  //     return {
  //       orderingCode: orderingCode.code,
  //       variant: PropertyValueSet.getInteger("variant", m3Variant),
  //       model: PropertyValueSet.getInteger("model", m3Variant),
  //       isbest: f.item_number === bestStandardFan
  //     };
  //   }
  //   return { orderingCode: "", variant: "", model: "", isbest: false };
  // });

  // const config = {
  //   properties: PropertyValueSet.Empty,
  //   calcParams: calcParams,
  //   accessories: []
  // };

  // const dropDownItems: Array<DropdownItem> = relatedFans.map(f => {
  //   return {
  //     key: f.orderingCode,
  //     label: f.orderingCode,
  //     onClick: () => redirectToVariant(`${f.model}`, `${f.variant}`, config, shopUrl, navigateToItemNo)
  //   };
  // });

  // const bestFan = relatedFans.find(t => t.isbest) || relatedFans[0];
  // return (
  //   <HToolbar>
  //     <p>
  //       Configurable item |{" "}
  //       <LinkButton
  //         onClick={() =>
  //           bestFan
  //             ? redirectToVariant(`${bestFan.model}`, `${bestFan.variant}`, config, shopUrl, navigateToItemNo)
  //             : null}
  //       >
  //         Go to Standard Item
  //       </LinkButton>&nbsp;&nbsp;
  //     </p>
  //     <Dropdown items={dropDownItems} label={(bestFan && bestFan.orderingCode) || ""} />
  //   </HToolbar>
  // );
}

function standardFan(props: Props, fanId: string): React.ReactElement<Props> {
  // Allow the user to go to the configurable fan
  const { calcParams, navigateToItemNo, shopUrl } = props;
  const { ct_Fan } = props.productTables;
  const configurableFan = ct_Fan.find((cf) => cf.id === fanId);
  if (configurableFan) {
    const config = {
      properties: PropertyValueSet.Empty,
      calcParams: calcParams,
      accessories: [],
    };

    const variant = undefined;
    return (
      <div className="p-8">
        <p>
          <LinkButton
            onClick={() => redirectToVariant(configurableFan.item_number, variant, config, shopUrl, navigateToItemNo)}
          >
            Go to Configurable item
          </LinkButton>{" "}
          &nbsp;| Standard item
        </p>
      </div>
    );
  }
  return <div />;
}

function redirectToVariant(
  model: string,
  variant: string | undefined,
  config: C.ItemConfig,
  shopUrl?: string,
  navigateToItemNo?: NavigateToItemNo
): void {
  if (navigateToItemNo) {
    navigateToItemNo(model, C.serializeConfig(config), variant, undefined);
  } else if (shopUrl) {
    window.location.href = `${shopUrl}?p=${variant}&fullConfig=${C.serializeConfig(config)}`;
  } else {
    console.log("No Navigate to Itemnumber or ShopUrl supplied");
  }
}
