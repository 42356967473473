import { EcomQuery, EcomResponse } from "./query-types";
import { exhaustiveCheck } from "../exhaustive-check/index";
import * as CacheSelectors from "./cache/cache-selectors";
import * as CacheLoader from "./cache/cache-loader";
import { EcomCacheState, CacheLoadRequest, UrlAndMarket, UrlMarketlanguageItem } from "./cache/types";

const initialCache = {
  itemNoPrices: {},
  variantNoPrices: {},
  productDescriptions: {},
};

export function selectQuery(
  cache: EcomCacheState | undefined = initialCache,
  query: EcomQuery
): EcomResponse | undefined {
  switch (query.type) {
    case "VariantNoToPrice":
      return CacheSelectors.selectVariantNoToPrice(cache, query);
    case "ProductDescription":
      return CacheSelectors.selectProductDescription(cache, query);
    case "Noop":
      return undefined;
    default:
      return exhaustiveCheck(query, true);
  }
}

export async function loadQueries(
  cache: EcomCacheState = initialCache,
  queries: ReadonlyArray<EcomQuery>
): Promise<EcomCacheState> {
  // Fold queries together to minimize number of requests when loading cache
  const fullCacheLoadRequest = mapQueriesToCacheLoadRequest(queries);

  // Remove things that are already present in cache
  const optimizedCacheLoadRequest = CacheLoader.optimizeLoadRequest(cache, fullCacheLoadRequest);

  // Now load the rest
  return CacheLoader.executeLoadRequest(cache, optimizedCacheLoadRequest);
}

function mapQueriesToCacheLoadRequest(queries: ReadonlyArray<EcomQuery>): CacheLoadRequest {
  // Collect data to make cache load from all queries
  const itemNoIdentifiers: Array<UrlAndMarket> = [];
  const variantNoIdentifiers: Array<UrlAndMarket> = [];
  const productDescriptionIdentifiers: Array<UrlMarketlanguageItem> = [];
  for (const query of queries) {
    switch (query.type) {
      case "VariantNoToPrice":
        variantNoIdentifiers.push({ url: query.url, market: query.market });
        break;
      case "ProductDescription":
        productDescriptionIdentifiers.push({
          url: query.url,
          market: query.market,
          language: query.language,
          itemNo: query.itemNo,
          variantId: query.variantId,
        });
        break;
      case "Noop":
        break;
      default:
        exhaustiveCheck(query);
        // If we are sent an unknown query type we do nothing
        break;
    }
  }

  return {
    itemNoIdentifiers: itemNoIdentifiers,
    variantNoIdentifiers: variantNoIdentifiers,
    productDescriptionsIdentifiers: productDescriptionIdentifiers,
  };
}
