import { withTw } from "./with-tw";

export const SearchTable = withTw("table", "border-y-0");

export interface SearchCellProps {
  readonly textAlign?: "left" | "center" | "right";
  readonly noPrint?: boolean;
  readonly alignTop?: boolean;
}

export const SearchTableTr = withTw("tr", "print:p-0 print:text-xs");

export const SearchTableTh = withTw(
  "th",
  "text-[0.75rem] leading-tighter max-w-[6.5rem] pt-0 truncate border-y-0 align-top pt-0 print:break-words print:text-xs",
  ({ noPrint = false }: SearchCellProps) => (noPrint ? "print:hidden" : "")
);
export const SearchTableTd = withTw(
  "td",
  "text-[0.75rem]",
  "print:overflow-hidden print:text-ellipsis print:break-words print:pb-4 print:pl-4 print:pr-2 print:pt-8",
  ({ noPrint = false }: SearchCellProps) => (noPrint ? "print:hidden" : ""),
  ({ alignTop = false }: SearchCellProps) => (alignTop ? "align-top" : "")
);
