import * as R from "ramda";
import * as React from "react";
import { DispatchProp } from "client-lib/redux-integration";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as UserSettingsClient from "client-lib/user-settings";
import * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";
import { Spinner, CustomListBox, ListBoxItem, Button, Alert, withTw, Textfield } from "client-lib/elements";
//import { BrowserProduct } from "shared-lib/query-product";
import * as PU from "shared-lib/product-utils";
import * as PC from "shared-lib/product-codes";
import { SelectedVariant } from "containers/frico-heating-calculation-epim/types";
import { getFricoDefaultVariantsEpim } from "shared-lib/product-utils";
import * as Actions from "./actions";
import { State } from "./reducer";

const Main = withTw("div", "flex flex-row print:hidden");
const ListBoxesMain = withTw("div", "flex flex-col w-full");
const ListBoxColumn = withTw("div", "flex flex-col space-y-16 w-full min-w-700 min-h-36");
const SearchColumn = withTw("div", "flex flex-col space-y-16 w-full max-w-270");
const Label = withTw("div", "text-style-label-small-bold");

type Props = OwnProps & StateProps & Response & DispatchProp<Actions.Action | UserSettingsClient.Action>;

type selectionChanged = (
  lvl1: string | undefined,
  lvl2: string | undefined,
  productId: string | undefined,
  selectedVariant: SelectedVariant | undefined,
  ecomProductId: string | undefined,
  itemName: string | undefined,
  itemNumber: string | undefined,
  variantNo: string | undefined
) => void;

type Context = "heating_calculation" | "specification_text";

export interface OwnProps {
  readonly ecomUrl: string;
  readonly market: string;
  readonly language: string;
  readonly selectedProductId: string | undefined;
  readonly selectedEcomId: string | undefined;
  readonly lvl1: string | undefined;
  readonly lvl2: string | undefined;
  readonly searchNotFound: boolean;
  readonly onSelectionChanged: selectionChanged;
  readonly onSearchNotFound: () => void;
  readonly context: Context;
}

export interface StateProps {
  readonly state: State;
}

export interface Response {
  readonly ct_EcomCategories: QP.EcomCategoriesTable;
  readonly ct_SearchProducts: QP.SearchProductsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
  readonly translateTables: Texts.TranslationTables | undefined;
  readonly tablesForProducts: TablesForProducts | undefined;
  readonly marketTables: PU.MarketTablesResponse;
  readonly ct_HiddenEcomCategories: QP.HiddenEcomCategoryTable;
}

export interface TablesForProducts {
  readonly [id: string]: AllProductTables;
}

export interface AllProductTables {
  readonly property: QP.PropertyTable;
  readonly code: QP.CodeTable;
  readonly ct_ItemNo: QP.ItemNoTable;
  readonly ct_VariantNo: QP.VariantNoTable;
  readonly ct_Attributes2: QP.AttributesTable;
  readonly ct_ItemNumberStatus: QP.ItemNumberStatusTable;
}

interface SearchResult {
  readonly level_1: string;
  readonly level_2: string;
  readonly productId: string;
  readonly itemNumber: string;
  readonly variantNo: string | undefined;
  readonly ecomProductId: string | undefined;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly itemName: string;
}

export function FricoProductBrowserEpimContainerComponent(props: Props): React.ReactElement<Props> {
  const { tablesForProducts, translateTables } = props;
  if (tablesForProducts === undefined || translateTables === undefined) {
    return <Spinner />;
  }

  return <div>{renderListBoxes(props)}</div>;
}

function renderListBoxes(props: Props): React.ReactElement<{}> {
  const {
    state,
    dispatch,
    selectedEcomId,
    onSelectionChanged,
    ct_EcomCategories,
    ct_SearchProducts,
    language,
    translateTables,
    tablesForProducts,
    ct_HiddenEcomCategories,
    lvl1,
    marketTables,
    lvl2,
    context,
    searchNotFound,
  } = props;

  const translate = Texts.translateFunctionSelector(translateTables!, language);

  const productsInMarket = getProductIdsToShow(ct_SearchProducts, context, tablesForProducts, marketTables, false);
  const allProducts =
    context === "specification_text"
      ? productsInMarket
      : getProductIdsToShow(ct_SearchProducts, context, tablesForProducts, marketTables, true);

  const categoriesToUse = getCategoriesToShow(
    productsInMarket,
    tablesForProducts!,
    context,
    marketTables,
    ct_HiddenEcomCategories
  );

  const productsInCat = productsInMarket.filter((p) =>
    tablesForProducts![p]?.ct_Attributes2.find(
      (att) =>
        att.attribute === "ecom-category-id-MULTI" && att.collection === "ECOM-HIERARCHY-FRICO" && att.value === lvl2
    )
  );

  let groupedProducts = lvl2
    ? groupVariantsByEpimProductId(productsInCat, tablesForProducts!, lvl2, marketTables, context)
    : {};

  // Only get categories that have products in them
  const categories: Array<QP.EcomCategory> = [];
  for (const cat of ct_EcomCategories) {
    if (categoriesToUse.find((q) => q === cat.id)) {
      categories.push(cat);
    }
  }

  const firstLevels: ReadonlyArray<ListBoxItem> = categories
    .filter((c) => c.parent_id === "")
    .map((r) => {
      return { label: translate(Texts.frico_category(r.id)), key: r.id };
    });

  const secondLevels: Array<ListBoxItem> = categories
    .filter((c) => c.parent_id === lvl1)
    .map((r) => {
      return { label: translate(Texts.frico_category(r.id)), key: r.id };
    });

  // const secondLevelCategories = secondLevels.map((group) => group.key);

  const getExpired = lvl1 !== undefined && (lvl1 === "45175" || lvl1 === "45350") && context === "heating_calculation";

  const expiredProducts = getExpired
    ? getExpiredProducts(
        ct_SearchProducts,
        tablesForProducts!,
        marketTables,
        context,
        lvl1 === "45175" ? "Fan heaters" : "Air curtains"
      )
    : {};

  const hasExpiredProducts = Object.values(expiredProducts).length > 0;
  if (hasExpiredProducts) {
    secondLevels.push({ label: translate(Texts.expired()), key: "expired" });
    if (lvl2 === "expired") {
      groupedProducts = { ...groupedProducts, ...expiredProducts };
    }
  }

  const products: ReadonlyArray<ListBoxItem> = Object.keys(groupedProducts).reduce((sofar, key) => {
    const labels = R.uniq(
      groupedProducts[key].reduce((acc, curr) => {
        const att = tablesForProducts![curr.productId].ct_Attributes2 || [];

        const names = att.filter(
          (at) => at.attribute === "td-product-name" && PropertyFilter.isValid(curr.variant, at.property_filter)
        );
        acc.push(...names.map((a) => a.value));
        return acc;
      }, [] as Array<string>)
    );

    const labelForProduct = labels.find((t) => t !== undefined);

    sofar.push({ label: labelForProduct || "Unknown", key: key });

    return sofar;
  }, [] as Array<ListBoxItem>);

  return (
    <Main>
      <ListBoxesMain>
        <div className="flex flex-row flex-no-wrap md-max:flex-wrap space-x-8 md-max:space-x-0 space-y-0 md-max:space-y-16 mr-16">
          <ListBoxColumn>
            <Label>{translate(Texts.area())}</Label>
            <CustomListBox
              items={firstLevels}
              onChange={(item) =>
                onSelectionChanged(item, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
              }
              selectedItem={lvl1}
            />
          </ListBoxColumn>

          <ListBoxColumn>
            {secondLevels.length > 0 && (
              <>
                <Label>{translate(Texts.group())}</Label>
                <CustomListBox
                  items={secondLevels}
                  onChange={(item) =>
                    onSelectionChanged(lvl1, item, undefined, undefined, undefined, undefined, undefined, undefined)
                  }
                  selectedItem={lvl2}
                />
              </>
            )}
          </ListBoxColumn>
          <ListBoxColumn>
            {products.length > 0 && (
              <>
                <Label>{translate(Texts.family())}</Label>
                <CustomListBox
                  items={products}
                  onChange={(item) => {
                    onSelectionChanged(
                      lvl1,
                      lvl2,
                      groupedProducts[item][0].productId,
                      undefined,
                      item,
                      undefined,
                      undefined,
                      undefined
                    );
                  }}
                  selectedItem={selectedEcomId}
                />
              </>
            )}
          </ListBoxColumn>
          <SearchColumn>
            <Label>{translate(Texts.enter_item_or_alias())}</Label>
            <p>{translate(Texts.enter_item_or_alias_example())}</p>
            <div className="inline-flex items-center flex-nowrap space-x-8">
              <Textfield
                value={state.searchTerm}
                onChange={(v) => dispatch(Actions.setSearchTerm(v))}
                className={"min-w-104"}
                onEnter={() => {
                  executeSearch(
                    state.searchTerm,
                    props,
                    productsInMarket,
                    context,
                    props.onSelectionChanged,
                    props.onSearchNotFound,
                    allProducts,
                    translate
                  );
                }}
              />
              <Button
                small={true}
                clicked={() =>
                  executeSearch(
                    state.searchTerm,
                    props,
                    productsInMarket,
                    context,
                    props.onSelectionChanged,
                    props.onSearchNotFound,
                    allProducts,
                    translate
                  )
                }
                iconLeft="search"
              />
            </div>
            <div>{searchNotFound ? <Alert type="warning">{translate(Texts.no_product_found())}</Alert> : null}</div>
          </SearchColumn>
        </div>
      </ListBoxesMain>
    </Main>
  );
}

function executeSearch(
  searchTerm: string | undefined,
  props: Props,
  productsInMarket: ReadonlyArray<string>,
  context: Context,
  onSelectionChanged: selectionChanged,
  onSearchNotFound: () => void,
  allProducts: ReadonlyArray<string>,
  translate: Texts.TranslateFunction
): void {
  const row = findByCodeOrItemNumber(
    searchTerm,
    props,
    productsInMarket,
    context,
    props.ct_EcomCategories,
    allProducts,
    translate
  );

  if (row !== undefined) {
    onSelectionChanged(
      row.level_1,
      row.level_2,
      row.productId,
      {
        m3ItemNo: row.itemNumber,
        properties: row.properties,
        variantNo: row.variantNo || "",
      },
      row.ecomProductId,
      row.itemName,
      row.itemNumber,
      row.variantNo
    );
  } else {
    onSearchNotFound();
  }
}

function findByCodeOrItemNumber(
  searchTerm: string | undefined,
  props: Props,
  productsInMarket: ReadonlyArray<string>,
  context: Context,
  ecomCategories: QP.EcomCategoriesTable,
  allProducts: ReadonlyArray<string>,
  translate: Texts.TranslateFunction
): SearchResult | undefined {
  const { tablesForProducts, marketTables } = props;
  if (searchTerm === undefined || tablesForProducts === undefined) {
    return undefined;
  }

  const searchParam = searchTerm.toUpperCase();

  // 1. Find itemno or name
  for (const productId of allProducts) {
    const productTables = tablesForProducts[productId];
    const itemNoTable = productTables.ct_ItemNo;
    const codeTable = productTables.code;

    if (
      itemNoTable.find((itno) => itno.item_no.toUpperCase() === searchParam) ||
      codeTable.find((code) => code.code.toUpperCase() === searchParam)
    ) {
      // Hit on product in Promaster, now find matching variant
      const variants = PU.getFricoDefaultVariantsEpim(
        productTables,
        undefined,
        context === "specification_text",
        marketTables,
        true
      );

      for (const variant of variants) {
        const code = PC.getProductCodes(productTables, variant);
        if (code.itemNo.toUpperCase() === searchParam || code.code.toUpperCase() === searchParam) {
          const itemNumber = tablesForProducts![productId].ct_ItemNo.find((row) =>
            PropertyFilter.isValid(variant, row.property_filter)
          );

          const itemNumberStatus = tablesForProducts![productId].ct_ItemNumberStatus.find(
            (row) => row.item_no === itemNumber?.item_no
          );

          const isExpired = (itemNumberStatus?.status && itemNumberStatus?.status > 20) || false;

          if (isExpired === false && !productsInMarket.some((p) => p === productId)) {
            continue;
          }

          const variantCategories = productTables.ct_Attributes2.filter(
            (att) =>
              att.attribute === "ecom-category-id-MULTI" &&
              att.collection === "ECOM-HIERARCHY-FRICO" &&
              PropertyFilter.isValid(variant, att.property_filter)
          );

          const topLevelCategories = ecomCategories.filter((cat) => cat.parent_id === "");

          const lvl1 = variantCategories.find((c) =>
            topLevelCategories.find((tl) => tl.parent_id === "" && tl.id === c.value)
          );
          if (lvl1 === undefined && !isExpired) {
            return undefined;
          }
          const lvl2 = variantCategories.find((c) =>
            ecomCategories.find((tl) => tl.parent_id === lvl1?.value && tl.id === c.value)
          );
          if (lvl2 === undefined && !isExpired) {
            return undefined;
          }

          const ecomProductId = isExpired
            ? `expired_${productId}`
            : productTables.ct_Attributes2.find(
                (att) =>
                  att.attribute === "ecom-product-id" &&
                  att.collection === "ECOM-HIERARCHY-FRICO" &&
                  PropertyFilter.isValid(variant, att.property_filter)
              )?.value;

          if (!ecomProductId) {
            return undefined;
          }

          const heatType = tablesForProducts![productId].ct_Attributes2.find(
            (t) => t.attribute === "CL-product-type-heat" && PropertyFilter.isValid(variant, t.property_filter)
          );

          const lvl1Expired = heatType?.value === "Fan heaters" ? "45175" : "45350";

          //45350 = aircurtain, 45175 = fan heaters

          if (context === "specification_text" && isExpired) {
            return undefined;
          }

          return {
            level_1: isExpired ? lvl1Expired : lvl1?.value || "",
            level_2: isExpired ? "expired" : lvl2?.value || "",
            productId: productId,
            itemNumber: code.itemNo,
            properties: variant,
            ecomProductId: ecomProductId,
            variantNo: code.variantId,
            itemName: translate(Texts.item_name(productId, variant)),
          };
        }
      }
    }
  }
  return undefined; // no match
}

function productHasNonAmbimentVariants(
  productId: string | undefined,
  tablesForProducts: TablesForProducts | undefined,
  marketTables: PU.MarketTablesResponse,
  isExpired: boolean
): boolean {
  if (productId === undefined || tablesForProducts === undefined) {
    return false;
  }

  const allVariants = getFricoDefaultVariantsEpim(
    tablesForProducts[productId],
    undefined,
    true,
    marketTables,
    isExpired
  );
  for (const variant of allVariants) {
    if (!PU.isAmbient(tablesForProducts[productId].ct_Attributes2, variant)) {
      return true;
    }
  }
  return false;
}

interface EcomVariantGroup {
  readonly productId: string;
  readonly variant: PropertyValueSet.PropertyValueSet;
}

function groupVariantsByEpimProductId(
  products: ReadonlyArray<string>,
  tablesForProducts: TablesForProducts,
  lvl2: string,
  marketTables: PU.MarketTablesResponse,
  context: Context
): { readonly [epimId: string]: ReadonlyArray<EcomVariantGroup> } {
  const results: { [epimId: string]: Array<EcomVariantGroup> } = {};
  for (const product of products) {
    const variants = PU.getFricoDefaultVariantsEpim(
      tablesForProducts![product],
      undefined,
      context === "specification_text",
      marketTables,
      false
    ).filter((v) =>
      tablesForProducts![product].ct_Attributes2.find(
        (att) =>
          att.attribute === "ecom-category-id-MULTI" &&
          att.collection === "ECOM-HIERARCHY-FRICO" &&
          att.value === lvl2 &&
          PropertyFilter.isValid(v, att.property_filter)
      )
    );

    for (const variant of variants) {
      const ecomId = tablesForProducts![product].ct_Attributes2.find(
        (t) =>
          t.attribute === "ecom-product-id" &&
          t.collection === "ECOM-HIERARCHY-FRICO" &&
          PropertyFilter.isValid(variant, t.property_filter)
      );
      if (ecomId === undefined) {
        continue;
      }

      const itemNumber = tablesForProducts![product].ct_ItemNo.find((row) =>
        PropertyFilter.isValid(variant, row.property_filter)
      );

      const itemNumberStatus = tablesForProducts![product].ct_ItemNumberStatus.find(
        (row) => row.item_no === itemNumber?.item_no
      );

      if (itemNumberStatus && itemNumberStatus.status > 20) {
        continue;
      }

      if (results[ecomId.value] === undefined) {
        results[ecomId.value] = [];
      }
      results[ecomId.value].push({ productId: product, variant: variant });
    }
  }
  return results;
}

function getExpiredProducts(
  products: QP.SearchProductsTable,
  tablesForProducts: TablesForProducts,
  marketTables: PU.MarketTablesResponse,
  context: Context,
  filterHeatType: "Fan heaters" | "Air curtains"
): { readonly [epimId: string]: ReadonlyArray<EcomVariantGroup> } {
  const results: { [epimId: string]: Array<EcomVariantGroup> } = {};

  for (const product of products) {
    const variants = PU.getFricoDefaultVariantsEpim(
      tablesForProducts[product.product],
      undefined,
      context === "specification_text",
      marketTables,
      true
    );

    for (const variant of variants) {
      const heatType = tablesForProducts![product.product].ct_Attributes2.find(
        (t) =>
          t.attribute === "CL-product-type-heat" &&
          t.value === filterHeatType &&
          PropertyFilter.isValid(variant, t.property_filter)
      );

      if (heatType === undefined) {
        continue;
      }
      const itemNumber = tablesForProducts![product.product].ct_ItemNo.find((row) =>
        PropertyFilter.isValid(variant, row.property_filter)
      );

      const itemNumberStatus = tablesForProducts![product.product].ct_ItemNumberStatus.find(
        (row) => row.item_no === itemNumber?.item_no
      );

      if (itemNumberStatus?.status && itemNumberStatus?.status > 20) {
        const productName = tablesForProducts![product.product].ct_Attributes2.find(
          (t) => t.attribute === "td-product-name" && PropertyFilter.isValid(variant, t.property_filter)
        );

        const expiredEcomId = (productName && `expired_${productName.value}`) || `expired_unknown`;

        if (results[expiredEcomId] === undefined) {
          results[expiredEcomId] = [];
        }

        results[expiredEcomId].push({ productId: product.product, variant: variant });
      }
    }
  }
  return results;
}

function getCategoriesToShow(
  products: ReadonlyArray<string>,
  tablesForProducts: TablesForProducts,
  context: Context,
  marketTables: PU.MarketTablesResponse,
  ct_HiddenEcomCategories: QP.HiddenEcomCategoryTable
): ReadonlyArray<string> {
  const categoryIds: Array<string> = [];
  for (const product of products) {
    const variants = PU.getFricoDefaultVariantsEpim(
      tablesForProducts![product],
      undefined,
      context === "specification_text",
      marketTables,
      false
    );

    for (const variant of variants) {
      const ecomIds = tablesForProducts![product].ct_Attributes2.filter(
        (t) =>
          t.attribute === "ecom-category-id-MULTI" &&
          t.collection === "ECOM-HIERARCHY-FRICO" &&
          PropertyFilter.isValid(variant, t.property_filter)
      );

      const availableIds = ecomIds.filter(
        (att) => ct_HiddenEcomCategories.find((hid) => hid.category_id === att.value) === undefined
      );

      categoryIds.push(...availableIds.map((t) => t.value));
    }
  }
  return R.uniq(categoryIds);
}

function getProductIdsToShow(
  ct_SearchProducts: QP.SearchProductsTable,
  context: Context,
  tablesForProducts: TablesForProducts | undefined,
  marketTables: PU.MarketTablesResponse,
  isExpired: boolean
): ReadonlyArray<string> {
  if (tablesForProducts === undefined) {
    return [];
  }
  const allProducts = ct_SearchProducts.map((r) => r.product);

  const productsToShow =
    context === "heating_calculation"
      ? allProducts.filter((id) => productHasNonAmbimentVariants(id, tablesForProducts, marketTables, isExpired))
      : allProducts;

  const includedProducts = ct_SearchProducts.filter((r) => {
    const temp =
      getFricoDefaultVariantsEpim(
        tablesForProducts![r.product],
        undefined,
        context === "specification_text",
        marketTables,
        false
      ).length > 0;

    return productsToShow.some((s) => s === r.product) && (isExpired || temp);
  });

  return includedProducts.map((p) => p.product);
}
