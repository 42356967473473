import { retryFetch } from "shared-lib/fetch";
import { ExternalItem } from "shared-lib/external-item";
import { CrmStatus } from "./types";

export async function addToCrm(
  crmUrl: string,
  quoteId: string,
  crmItems: ReadonlyArray<ExternalItem>
): Promise<CrmStatus> {
  try {
    const result = await retryFetch(crmUrl, {
      method: "POST",
      body: JSON.stringify({ quoteId, selectionToolData: crmItems }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.ok) {
      return {
        type: "success",
      };
    } else {
      const json = await result.json();
      const error = typeof json === "object" ? json.error : undefined;
      return {
        type: "error",
        code: typeof error?.code === "number" ? error?.code : undefined,
        message: typeof error?.message === "string" ? error?.message : undefined,
      };
    }
  } catch {
    return {
      type: "error",
      code: undefined,
      message: undefined,
    };
  }
}
