import { clientConfig } from "config";
import * as React from "react";
import { Icon } from "./icon";
import { withTw } from "./with-tw";

const Toggle = withTw("div", "text-style-heading-h4 mt-24 mb-16");

interface Props {
  readonly header: string;
  readonly children?: React.ReactNode;
  readonly closed?: boolean;
  readonly onToggleClosed: () => void;
}

export function Group(props: Props): React.ReactElement<Props> {
  const { header, children, closed, onToggleClosed } = props;
  const closable = closed !== undefined;
  return (
    <div className={clientConfig.addOuterPadding ? "px-40" : ""}>
      {closable ? (
        <div onClick={() => onToggleClosed()} className="flex items-center space-x-8">
          <a>
            <Toggle className="cursor-pointer">{header}</Toggle>
          </a>
          <Icon className="text-lg text-gray-400 mt-8" icon={closed ? "angle-down" : "angle-up"} />
        </div>
      ) : (
        header && <Toggle>{header}</Toggle>
      )}
      {!closed && children}
    </div>
  );
}
