import { retryFetch } from "shared-lib/fetch";
import { memoize } from "shared-lib/utils/memoize";

const dllPassword = "4dsaDLX4xV0a1vzG16jw";

export interface FritermInput {
  readonly dllPassword: string;
  readonly airFlow: number;
  readonly airTemperatureIn: number;
  readonly waterTempIn?: number;
  readonly waterTempOut?: number | null;
  readonly airHumidity: number;
  readonly method: number;
  readonly geometry: string;
  readonly waterFlow?: number | null;
  readonly airTemperatureOut: number | null;
  readonly numberOfTubes: number;
  readonly tubeMaterial: string;
  readonly finsMaterial: string;
  readonly tubeThickness: number;
  readonly finThickness: number;
  readonly finSpacing: number;
  readonly length: number;
  readonly rows: number;
  readonly circuits: number;
  readonly coilType: number;
  // Not used by DLL
  // readonly connectionMaterial: string;
  // readonly connectionIn: number;
  // readonly connectionOut: number;
  readonly correctionFactorWaterPressure: number;
  readonly correctionFactorPower: number;
  readonly manifoldMaterial?: string; // TODO: These should not be optional according to latest Madam source code
  readonly manifoldInletDiameter?: number; //
  readonly manifoldOutletDiameter?: number; //
  readonly fluidType?: number;
  readonly fluidEvaporationTemperature?: number;
  readonly fldTemperatureCondensation?: number;
  readonly fluidSubCoolingTemperature?: number;
  readonly fluidSuperHeatingTemperature?: number;
  readonly ErrorThrowException?: number; // 0: throw exception on error, 1: return result with "ErrorNumber" and "ErrorDescription", 422: 422 http error, defaults to 1
}

export interface FritermResult {
  readonly airFlow: number;
  readonly airHumidityIn: number;
  readonly airHumidityOut: number;
  readonly airPressureDrop: number;
  readonly airPressureDropDry: number;
  readonly airTemperatureIn: number;
  readonly airTemperatureOut: number;
  readonly airVelocity: number;
  readonly coilInnerVolume: number;
  readonly cvValue: number;
  readonly kvValue: number;
  readonly power: number;
  readonly waterFlow: number;
  readonly waterMassFlow: number;
  readonly waterPressureDrop: number;
  readonly waterTemperatureIn: number;
  readonly waterTemperatureOut: number;
  readonly waterVelocity: number;
  readonly message: string | undefined;
}

export const calculate = memoize(_calculate);
async function _calculate(input: Omit<FritermInput, "dllPassword">): Promise<FritermResult> {
  try {
    const result = await retryFetch(
      "https://design-dllcal.systemair.com/api/fritermfrtcoils/calculate",
      //"http://localhost:5000/calculate",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ dllPassword, ...input }),
      },
      { breakOnHTTPCodes: [422], returnResponseOnError: true }
    );
    if (result.ok) {
      const json = await result.json();
      return json;
    } else {
      return { message: `Calculation request input error` } as FritermResult;
    }
  } catch (e) {
    return { message: `Calculation request error: ${e}` } as FritermResult;
  }
}
