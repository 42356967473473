import * as Search from "shared-lib/search";
import { PropertyValueSet } from "@promaster-sdk/property";
import * as QP from "shared-lib/query-product";
import { RehydrateAction } from "redux-persist";

export type Action =
  | StartCalculation
  | SetProgress
  | AbortCalculation
  | CalculationFinished
  | Reset
  | RehydrateAction
  | SearchProductsReset;

export interface StartCalculation {
  readonly type: "search/START_CALCULATION";
  readonly payload: {
    readonly source: QP.DataSourceResponse;
    readonly ownProps: Search.OwnProps;
  };
}

export function startCalculation(source: QP.DataSourceResponse, ownProps: Search.OwnProps): StartCalculation {
  return {
    type: "search/START_CALCULATION",
    payload: { source, ownProps },
  };
}

export interface Reset {
  readonly type: "search/RESET";
}

export function reset(): Reset {
  return {
    type: "search/RESET",
  };
}

export interface SearchProductsReset {
  readonly type: "search-products/RESET";
}

export interface SetProgress {
  readonly type: "search/SET_PROGRESS";
  readonly payload: number;
}

export function setProgress(progress: number): SetProgress {
  return {
    type: "search/SET_PROGRESS",
    payload: progress,
  };
}

export interface AbortCalculation {
  readonly type: "search/ABORT_CALCULATION";
  readonly payload: undefined;
}

export function abortCalculation(): AbortCalculation {
  return {
    type: "search/ABORT_CALCULATION",
    payload: undefined,
  };
}

export interface CalculationFinished {
  readonly type: "search/CALCULATION_FINISHED";
  readonly payload: {
    readonly source: QP.DataSourceResponse;
    readonly results: ReadonlyArray<Search.Result>;
    readonly searchProductId: string;
    readonly searchVariants: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
    readonly searchId: string;
  };
}

export function calculationFinished(
  source: QP.DataSourceResponse,
  results: ReadonlyArray<Search.Result>,
  searchProductId: string,
  searchVariants: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  searchId: string
): CalculationFinished {
  return {
    type: "search/CALCULATION_FINISHED",
    payload: {
      source: source,
      searchProductId: searchProductId,
      results: results,
      searchVariants: searchVariants,
      searchId: searchId,
    },
  };
}
