/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Unit } from "uom";
import { AnyQuantity } from "shared-lib/uom";
import * as QP from "shared-lib/query-product";
import * as Texts from "shared-lib/language-texts";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import * as PromasterPropertiesSelector from "@promaster-sdk/react-properties-selector";
import * as UserSettings from "shared-lib/user-settings";
import {
  AmountPropertySelector,
  CheckboxPropertySelector,
  ComboboxPropertySelector,
  TextboxPropertySelector,
  PropertyLabel,
  Property,
  Images,
  CheckboxPropertySelectorLabel,
} from "client-lib/properties-selector-elements";
import { FormatNumberFunction } from "shared-lib/utils";
import { FilterPrettyPrint } from "@promaster-sdk/property-filter-pretty";
import { RadioGroupPropertySelector } from "./radio-group-property-selector";
import { MultiPropertySelector } from "./multi-property-selector";
import { MultiHorizontalPropertySelector } from "./multi-horizontal-property-selector";
import { PropertyFormats, OnUnitChangedFn, OnUnitClearedFn } from "./types";

export interface PropertySelectorProps {
  readonly productId: string;
  readonly property: PromasterPropertiesSelector.Property;
  readonly onChanged: PromasterPropertiesSelector.OnPropertiesChanged;
  readonly index: number;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly onUnitChanged: OnUnitChangedFn;
  readonly onUnitCleared: OnUnitClearedFn;
  readonly translate: Texts.TranslateFunction;
  readonly translatePropertyName?: PromasterPropertiesSelector.TranslatePropertyName;
  readonly translatePropertyValue?: PromasterPropertiesSelector.TranslatePropertyValue;
  readonly getUnits: UserSettings.GetUnitsFunction;
  readonly formatNumber: FormatNumberFunction;
  readonly propertyFormats: PropertyFormats;
  readonly selectorType: QP.PropertySelectorType | undefined;
  readonly fieldName: string | undefined;
  readonly filterPrettyPrint: FilterPrettyPrint;
  readonly showAdvanced: boolean;
  readonly images: Images;
  readonly hideInvalidValues?: boolean;
  readonly hideEmptyUnitSelectors?: boolean;
  readonly comboBoxImageCSSFilter?: string;
}

export function PropertySelector(props: PropertySelectorProps): JSX.Element {
  const {
    productId,
    property,
    index,
    propertyValueSet,
    onUnitChanged,
    onUnitCleared,
    translate,
    getUnits,
    formatNumber,
    propertyFormats,
    selectorType,
    fieldName,
    filterPrettyPrint,
    showAdvanced,
    images,
    hideInvalidValues,
    comboBoxImageCSSFilter,
    translatePropertyName,
    translatePropertyValue,
    hideEmptyUnitSelectors,
    onChanged,
  } = props;

  const finalSelectorType: QP.SelectorType = selectorType
    ? selectorType.type
    : property.quantity === "Discrete"
    ? "ComboBox"
    : property.quantity === "Text"
    ? "TextBox"
    : "AmountField";

  const fieldNameOrPropertyName = fieldName || property.name;

  const defaultFormat = { unit: Unit.One, decimalCount: 2 };
  const format = propertyFormats[property.name] || defaultFormat;

  const isRequired =
    finalSelectorType === "AmountField" && !PropertyFilter.isValid(PropertyValueSet.Empty, property.validation_filter);
  const label = translatePropertyName
    ? translatePropertyName(property.name)
    : translate(Texts.property_name(productId, property.name));
  return (
    <Property selectorType={finalSelectorType}>
      {finalSelectorType === "AmountField" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <AmountPropertySelector
            value={PropertyValueSet.getAmount(property.name, propertyValueSet)}
            unit={format.unit as Unit.Unit<AnyQuantity>}
            validationFilter={property.validation_filter}
            getUnits={getUnits}
            decimalCount={format.decimalCount}
            fieldName={fieldNameOrPropertyName}
            translate={translate}
            formatNumber={formatNumber}
            validateFilter={(validationFilter) => PropertyFilter.isValid(propertyValueSet, validationFilter)}
            filterPrettyPrint={filterPrettyPrint}
            index={index}
            hideEmptyUnitSelector={hideEmptyUnitSelectors}
            onValueChange={(newAmount) =>
              onChanged(PropertyValueSet.setAmount(property.name, newAmount, propertyValueSet), [property.name])
            }
            unitChanged={(u) => onUnitChanged(property.name, u)}
            unitCleared={() => onUnitCleared(property.name)}
          />
        </>
      ) : finalSelectorType === "Checkbox" ? (
        <>
          <CheckboxPropertySelectorLabel label={label} property={property} />
          <CheckboxPropertySelector
            productId={productId}
            property={property}
            value={PropertyValueSet.getInteger(property.name, propertyValueSet)}
            onChange={(newValue) =>
              onChanged(PropertyValueSet.setInteger(property.name, newValue, propertyValueSet), [property.name])
            }
            images={images}
            translate={translate}
            validateFilter={(validationFilter) => PropertyFilter.isValid(propertyValueSet, validationFilter)}
            filterPrettyPrint={filterPrettyPrint}
          />
        </>
      ) : finalSelectorType === "ComboBox" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <ComboboxPropertySelector
            productId={productId}
            property={property}
            value={PropertyValueSet.getInteger(property.name, propertyValueSet)}
            onChange={(newValue) =>
              onChanged(PropertyValueSet.setInteger(property.name, newValue, propertyValueSet), [property.name])
            }
            translate={translate}
            translatePropertyValue={translatePropertyValue}
            validateFilter={(validationFilter) => PropertyFilter.isValid(propertyValueSet, validationFilter)}
            filterPrettyPrint={filterPrettyPrint}
            showAdvanced={showAdvanced}
            images={images}
            hideInvalidValues={hideInvalidValues}
            imageCSSFilter={comboBoxImageCSSFilter}
          />
        </>
      ) : finalSelectorType === "RadioGroup" ? (
        <RadioGroupPropertySelector />
      ) : finalSelectorType === "TextBox" ? (
        <>
          <PropertyLabel label={label} isRequired={isRequired} />
          <TextboxPropertySelector
            value={PropertyValueSet.getText(property.name, propertyValueSet) || ""}
            onChange={(newValue) =>
              onChanged(PropertyValueSet.setText(property.name, newValue, propertyValueSet), [property.name])
            }
          />
        </>
      ) : finalSelectorType === "Multi" ? (
        <MultiPropertySelector />
      ) : finalSelectorType === "MultiHorizontal" ? (
        <MultiHorizontalPropertySelector />
      ) : (
        <span />
      )}
    </Property>
  );
}
