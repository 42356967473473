import { CalculatorSuccess, OutputMapperResult, createOutputMapperSuccess } from "../types";
import { AirCurtain, createAirCurtainResultItem } from "../result-items-types";

export function map(result: CalculatorSuccess<AirCurtain>): OutputMapperResult {
  return createOutputMapperSuccess(
    result.sortValues,
    createAirCurtainResultItem(result.output),
    result.messages,
    result.calcParams
  );
}
