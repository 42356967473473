import * as React from "react";
import * as Texts from "shared-lib/language-texts";
import * as R from "ramda";
import { PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { formatNumberFunction, isAllDefined } from "shared-lib/utils";
import * as SC from "shared-lib/system-calculator";
import { Heading3, ResultRowContainer } from "client-lib/elements";
import * as UserSettingsShared from "shared-lib/user-settings";
import { CentrifugalFanResultItem } from "shared-lib/system-calculator/result-items-types";
import * as CentrifugalFanDiagram from "shared-lib/system-calculator/shared/centrifugal-fan-diagram";
import * as QP from "shared-lib/query-product";
import * as Style from "shared-lib/style";
import { ViewerDiagramContainer } from "../../viewer-diagram";
import { VisualizerOwnProps, VisualizerProductProps } from "../types";

export type Props = VisualizerOwnProps & StateProps & Response;

export interface StateProps {
  readonly userSettings: UserSettingsShared.State;
}

export interface Response {
  readonly ct_MarketUnits: QP.MarketUnitsTable;
  readonly ct_LanguageMapping: QP.LanguageMappingTable;
}

const diagramWidth = 500;
const diagramHeight = 600;

export function CentrifugalFanDiagramsVisualizerContainerComponent(props: Props): React.ReactElement<Props> {
  const { translate, products } = props;

  if (!isAllDefined(products)) {
    // "products" can only contain undefined if the products are accessory products,
    // regular products are never undefined. We don't handle undefined and accessories
    // don't have this result view, so it's ok to not rendering anything.
    return <span />;
  }

  const renderedDiagrams = products.map((p, idx) => renderCentrifugalFanDiagramsVisualizer(idx, props, p, idx !== 0));
  if (renderedDiagrams.length === 1) {
    const performance = renderedDiagrams[0] ? renderedDiagrams[0][0] : undefined;
    const power = renderedDiagrams[0] ? renderedDiagrams[0][1] : undefined;
    if (!performance || !power) {
      return <div />;
    }
    return (
      <div className="flex flex-row">
        <div className="w-1/2">
          <Heading3>{translate(Texts.performanceCurve())}</Heading3>
          {performance}
        </div>
        <div className="w-1/2">
          <Heading3>{translate(Texts.powerCurve())}</Heading3>
          {power}
        </div>
      </div>
    );
  } else {
    const performance = renderedDiagrams.map((diagrams) => (diagrams ? diagrams[0] : <div />));
    const power = renderedDiagrams.map((diagrams) => (diagrams ? diagrams[1] : <div />));
    return (
      <div>
        <ResultRowContainer heading={translate(Texts.performanceCurve())}>{performance}</ResultRowContainer>
        <ResultRowContainer heading={translate(Texts.powerCurve())}>{power}</ResultRowContainer>
      </div>
    );
  }
}

export function renderCentrifugalFanDiagramsVisualizer(
  key: number,
  { translate, market, language, userSettings, ct_MarketUnits, ct_LanguageMapping, showDownload }: Props,
  { resultItemMap, calcParams, calcParamsChanged }: VisualizerProductProps,
  disableHoverPoint: boolean
): readonly [React.ReactElement<Props>, React.ReactElement<Props>] | undefined {
  const maybeCentrifugalFan: CentrifugalFanResultItem = R.head(
    R.values(R.filter((ri: SC.ResultItem) => ri.type === "CentrifugalFan", resultItemMap))
  ) as CentrifugalFanResultItem;
  const fan = maybeCentrifugalFan && maybeCentrifugalFan.value.air;
  const motor = maybeCentrifugalFan && maybeCentrifugalFan.value.motor;

  if (!fan) {
    return undefined;
  }

  const getUnit = UserSettingsShared.getUnit({
    market,
    ct_MarketUnits,
    userSettings,
  });

  const flowUnit = getUnit("airFlow", "VolumeFlow");
  const pressureUnit = getUnit("airPressure", "Pressure");
  const powerUnit = getUnit("power", "Power");

  const getDecimals = UserSettingsShared.getDecimals({
    market,
    ct_MarketUnits,
  });

  const formatNumber = formatNumberFunction(language, ct_LanguageMapping);

  const flowUnitDecimals = getDecimals("airFlow", flowUnit);
  const pressureUnitDecimals = getDecimals("airPressure", pressureUnit);
  const powerUnitDecimals = getDecimals("power", powerUnit);

  const { pressure, power } = CentrifugalFanDiagram.generateCharts({
    fan,
    motor,
    flowUnit,
    pressureUnit,
    powerUnit,
    translate,
    showLineLabels: true,
    style: Style.diagramCentrifugalFan,
    powerCurves: maybeCentrifugalFan.value.powerCurves,
    classCurves: maybeCentrifugalFan.value.classCurves,
  });

  return [
    <ViewerDiagramContainer
      key={`pressure_${key}`}
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={pressureUnitDecimals}
      allowTextOverlap={true}
      formatNumber={formatNumber}
      id="fanExternalPressure"
      chart={pressure}
      maxWidth={diagramWidth}
      maxHeight={diagramHeight}
      showDownload={showDownload}
      onClick={
        !disableHoverPoint
          ? (x, y) => {
              const withFlow = PropertyValueSet.setAmount(
                "airFlow",
                Amount.create(x, flowUnit, flowUnitDecimals),
                calcParams
              );
              const withPressure = PropertyValueSet.setAmount(
                "externalPressure",
                Amount.create(y, pressureUnit, pressureUnitDecimals),
                withFlow
              );
              calcParamsChanged(withPressure);
            }
          : undefined
      }
    />,
    <ViewerDiagramContainer
      key={`power_${key}`}
      linesAndTextXAxisNoOfDecimals={flowUnitDecimals}
      linesAndTextYAxisNoOfDecimals={powerUnitDecimals}
      id="fanExternalPower"
      chart={power}
      formatNumber={formatNumber}
      maxWidth={diagramWidth}
      maxHeight={diagramHeight}
      showDownload={showDownload}
      allowTextOverlap={true}
    />,
  ];
}
