import * as React from "react";
import { OctaveBandsTableTd } from "client-lib/elements";

export function SoundCell({ sound }: { readonly sound: number | undefined }): React.ReactElement<{}> {
  return (
    <OctaveBandsTableTd textAlign="center">
      {sound === undefined ? "-" : sound < 10 ? "<10" : sound.toFixed(0)}
    </OctaveBandsTableTd>
  );
}
