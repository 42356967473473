import * as React from "react";
import { withTw } from "./with-tw";

export interface ComboboxProps {
  readonly value: string | undefined;
  readonly options: ReadonlyArray<ComboboxOption>;
  readonly onChange: (value: string) => void;
  readonly readOnly?: boolean;
  readonly noBackgroundColor?: boolean;
  readonly useFixedPos?: boolean;
  readonly allowOverFlow?: boolean;
  readonly imageCSSFilter?: string;
  readonly className?: string;
  readonly small?: boolean;
  readonly noBorder?: boolean;
}

export interface ComboboxOption {
  readonly value: string;
  readonly label: string;
  readonly image?: string;
  readonly error?: string;
}

interface StyledSelectProps {
  readonly small: boolean;
  readonly noBorder: boolean;
}

interface WrapperSelectProps {
  readonly small: boolean;
  readonly isValid: boolean;
}

const Wrapper = withTw(
  "div",
  " dropdown-wrapper flex justify-between",
  (p: WrapperSelectProps) => (!p.isValid ? "was-invalidated" : ""),
  (p: WrapperSelectProps) => (p.small ? "w-fit" : "")
);

const StyledSelect = withTw(
  "select",
  "form-input form-dropdown p-4 pr-[2rem] print:border-white",
  ({ small }: StyledSelectProps) => (small ? "text-xs h-26" : "h-36 pl-8"),
  ({ noBorder }: StyledSelectProps) => (noBorder ? "border-white hover:border-gray-200  hover:border" : "")
);

const ImageSelectWrapper = withTw(
  "div",
  "relative dropdown-wrapper flex justify-between",
  (p: { readonly isValid: boolean }) => (!p.isValid ? "was-invalidated" : "")
);

const StyledImageSelect = withTw(
  "div",
  "form-input form-dropdown p-4 pr-40 print:border-white",
  ({ small }: StyledSelectProps) => (small ? "text-xs h-26" : "h-36 pl-8"),
  ({ noBorder }: StyledSelectProps) => (noBorder ? "border-white hover:border-gray-200  hover:border" : "")
);
const Background = withTw("div", "fixed z-10 top-0 left-0 w-full h-full");
const Option = withTw(
  "div",
  "font-normal whitespace-nowrap  cursor-pointer text-sm pl-16 pr-16 pt-8 pb-8  hover:bg-brand-50 hover:text-primary-light",
  ({ selected }: { readonly selected: boolean }) => (selected ? "text-primary-light" : "")
);

export function Combobox(props: ComboboxProps): React.ReactElement<ComboboxProps> {
  if (props.options.some((o) => !!o.image)) {
    return (
      <div className={props.className}>
        <div className="hide-on-desktop">
          <SystemCombobox {...{ ...props, className: "" }} />
        </div>
        <div className="hide-on-mobile">
          <ImageCombobox {...{ ...props, className: "" }} />
        </div>
      </div>
    );
  } else {
    return <SystemCombobox {...props} />;
  }
}

function SystemCombobox(props: ComboboxProps): React.ReactElement<ComboboxProps> {
  const { options, value, className, small = false, noBorder = false, onChange } = props;
  const selectedOption = options.find((o) => o.value === value);
  return (
    <Wrapper className={className} title={selectedOption?.error} isValid={!selectedOption?.error} small={small}>
      <StyledSelect
        small={small}
        noBorder={noBorder}
        onChange={(e) => onChange(e.target.value)}
        value={selectedOption?.value || ""}
      >
        {props.options.map((o) => (
          <option key={o.value} value={o.value} disabled={!!o.error}>
            {o.label}
          </option>
        ))}
      </StyledSelect>
      <ArrowDown />
    </Wrapper>
  );
}

function ImageCombobox(props: ComboboxProps): React.ReactElement<ComboboxProps> {
  const { options, value, className, small = false, noBorder = false, onChange } = props;
  const selectedOption = options.find((o) => o.value === value);
  const [showDropDown, setShowDropDown] = React.useState(false);

  // Logic to move the drop down to the left a bit if it overflows the right window side
  const selectElement = React.useRef<HTMLDivElement>(null);
  const optionsElement = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (optionsElement.current && selectElement.current) {
      const optionsRect = optionsElement.current.getBoundingClientRect();
      const selectRect = selectElement.current.getBoundingClientRect();
      const rightPos = selectRect.x + optionsRect.width;
      const xOverflow = Math.max(0, rightPos - window.innerWidth);
      optionsElement.current.style.left = `${-xOverflow}px`;
    }
  }, [showDropDown]);

  return (
    <ImageSelectWrapper
      className={className}
      title={selectedOption?.error}
      isValid={!selectedOption?.error}
      onClick={() => setShowDropDown(!showDropDown)}
    >
      {showDropDown ? <Background onClick={() => setShowDropDown(false)} /> : undefined}
      <StyledImageSelect elementref={selectElement} small={small} noBorder={noBorder} tabIndex={0}>
        {selectedOption && <ImageSelectItem option={selectedOption} />}
      </StyledImageSelect>
      <ArrowDown />
      <div
        ref={optionsElement}
        className={`z-50 absolute top-36 w-full min-w-fit flex flex-col text-sm dropdown-item-container pt-8 pb-8 ${
          showDropDown ? "visible" : "invisible"
        }`}
      >
        {options.map((o) => (
          <Option key={o.value} title={o.label} selected={selectedOption?.value === o.value}>
            <ImageSelectItem
              option={o}
              onClick={() => {
                setShowDropDown(false);
                onChange(o.value);
              }}
            />
          </Option>
        ))}
      </div>
    </ImageSelectWrapper>
  );
}

function ImageSelectItem({
  option,
  onClick,
}: {
  readonly option: ComboboxOption;
  readonly onClick?: () => void;
}): JSX.Element {
  return (
    <div className="flex items-center h-full" onClick={() => onClick && onClick()}>
      <img className="h-full mr-8 max-w-none" src={option?.image} />
      <span className="overflow-hidden whitespace-nowrap text-ellipsis text-current">{option?.label}</span>
    </div>
  );
}

function ArrowDown(): JSX.Element {
  return (
    <svg className="print:hidden" style={{ height: "1em" }} viewBox="0 0 384 512">
      <path
        fill="currentColor"
        d="M360.5 217.5l-152 143.1C203.9 365.8 197.9 368 192 368s-11.88-2.188-16.5-6.562L23.5 217.5C13.87 208.3 13.47 193.1 22.56 183.5C31.69 173.8 46.94 173.5 56.5 182.6L192 310.9l135.5-128.4c9.562-9.094 24.75-8.75 33.94 .9375C370.5 193.1 370.1 208.3 360.5 217.5z"
      ></path>
    </svg>
  );
}
