import * as React from "react";
import { DispatchProp } from "client-lib/redux-integration";
import { PropertyValueSet, PropertyFilter } from "@promaster-sdk/property";
import { createSelector } from "reselect";
import * as UserSettingsClient from "client-lib/user-settings";
import * as C from "shared-lib/calculation";
import * as Texts from "shared-lib/language-texts";
import { getFricoDefaultVariantsEpim, MarketTablesResponse } from "shared-lib/product-utils";
import { Spinner, withTw, Button, HToolbar, Heading3 } from "client-lib/elements";
import { ResultItemOutputPerComponent } from "shared-lib/system-calculator";
import { ComponentResult } from "shared-lib/search";
import * as Uuid from "uuid";
import { CalculationResultTable, ResultTableProps, ProductTable, TableRow } from "client-lib/frico";
import * as PC from "shared-lib/product-codes";
import { TranslateFunction } from "shared-lib/language-texts";
import { SelectedVariant } from "containers/frico-heating-calculation-epim/types";
import { ProductContainer } from "../product";
import { FricoProductBrowserEpimContainer } from "../frico-product-browser-epim";
import { OwnProps, StateProps, Response, TablesForProducts } from "./types";
import * as Actions from "./actions";

type Props = OwnProps & StateProps & Response & DispatchProp<Actions.Action | UserSettingsClient.Action>;

const HelpContainer = withTw("div", "print:hidden bg-gray-100 p-24 text-sm min-w-224 max-w-224 mr-16");
const StepRow = withTw("div", "flex flex-row");
const StepContent = withTw("div", "flex flex-col space-y-24 w-full pb-32 pl-20 print:pl-0");
const Step = withTw("div", "w-full");
const BoldParagraph = withTw("div", "font-bold");

export function FricoHeatingCalculationEpimContainerComponent(props: Props): React.ReactElement<Props> {
  const { ecomUrl, state, itemConfig, language, market, tablesForProducts, translateTables, dispatch } = props;
  const { selectedVariant } = state;
  if (tablesForProducts === undefined || translateTables === undefined) {
    return <Spinner />;
  }
  const translate = Texts.translateFunctionSelector(translateTables, props.language);
  const resultTableProps = getResultTableProps(props, translate);
  return (
    <div>
      <StepRow className="print:hidden">
        <HelpContainer>
          <p>{translate(Texts.step1())}</p>
          <BoldParagraph>{translate(Texts.select_product())}</BoldParagraph>
          <p>{translate(Texts.browse_by_product())}</p>
        </HelpContainer>
        <Step>
          <Heading3>{translate(Texts.product_finder())}</Heading3>
          <StepContent>
            <FricoProductBrowserEpimContainer
              ecomUrl={ecomUrl}
              market={market}
              context={"heating_calculation"}
              lvl1={state.browserLvl1}
              searchNotFound={state.browserSearchNotFound}
              lvl2={state.browserLvl2}
              language={language}
              selectedProductId={state.productId}
              selectedEcomId={state.ecomProductId}
              onSelectionChanged={selectionChangedSelector(props)}
              onSearchNotFound={searchNotFoundSelector(props)}
            />
            {createProductTable(props, translate)}
          </StepContent>
        </Step>
      </StepRow>

      {selectedVariant !== undefined ? (
        <StepRow className="print:hidden">
          <HelpContainer>
            <p>{translate(Texts.step2())}</p>
            <BoldParagraph>{translate(Texts.input_data())}</BoldParagraph>
            <p>{translate(Texts.fill_input())}</p>
          </HelpContainer>
          <Step>
            <StepContent className="pl-0">
              <ProductContainer
                calculationExists={state.calculationExists}
                market={market}
                ecomUrl={ecomUrl}
                variant={selectedVariant.variantNo}
                productId={state.productId}
                crm={undefined}
                saveCalculationCallBack={(config, result) => {
                  if (result !== undefined) {
                    const calculationResult = createCalculationResult(config, result, translate, props);
                    const exists = state.savedCalculations.find(
                      (c) =>
                        c.productId === calculationResult.productId &&
                        PropertyValueSet.equals(c.properties, calculationResult.properties) &&
                        PropertyValueSet.equals(c.calcParams, calculationResult.calcParams) &&
                        c.m3 === calculationResult.m3
                    );
                    if (exists) {
                      dispatch(Actions.calculationExists());
                    } else {
                      dispatch(Actions.saveCalculation(calculationResult));
                    }
                  }
                }}
                language={language}
                m3ItemNo={selectedVariant.m3ItemNo}
                config={createConfig(selectedVariant, itemConfig)}
                hideInvalidValues={true}
              />
            </StepContent>
          </Step>
        </StepRow>
      ) : null}

      {state.savedCalculations.length > 0 ? (
        <StepRow>
          <HelpContainer>
            <p>{translate(Texts.step3())}</p>
            <BoldParagraph>{translate(Texts.verify_result())}</BoldParagraph>
            <p>{translate(Texts.make_print())}</p>
          </HelpContainer>
          <Step>
            <StepContent>
              <CalculationResultTable {...resultTableProps} />
              <HToolbar className="print:hidden">
                <Button
                  clicked={() => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const win = window as any;
                    win.print();
                  }}
                  label={translate(Texts.print())}
                />
              </HToolbar>
            </StepContent>
          </Step>
        </StepRow>
      ) : null}

      <div className="hidden print:block">
        <p>
          <b>{translate(Texts.frico_note())} </b>
          {translate(Texts.frico_print_note())}
        </p>
      </div>
    </div>
  );
}

function getResultTableProps(props: Props, translate: TranslateFunction): ResultTableProps {
  const { state, market, language, dispatch, ct_SavedResultColumns, ct_MarketUnits, ct_LanguageMapping } = props;

  return {
    market: market,
    language: language,
    translate: translate,
    userSettings: props.userSettings,
    onClearSavedCalculations: () => dispatch(Actions.clearSavedCalculations()),
    onRemoveSavedCalculation: (s) => dispatch(Actions.removeCalculation(s)),
    dispatch: dispatch,
    results: state.savedCalculations,
    ct_SavedResultColumns: ct_SavedResultColumns,
    ct_MarketUnits: ct_MarketUnits,
    ct_LanguageMapping,
  };
}

function createCalculationResult(
  config: C.ItemConfig,
  calcResult: ResultItemOutputPerComponent,
  translate: Texts.TranslateFunction,
  props: Props
): ComponentResult {
  const { tablesForProducts, state } = props;
  const { productId, selectedVariant } = state;

  const name = PC.getProductCodes(tablesForProducts![productId!], config.properties);
  const variantNo = tablesForProducts![productId!].ct_VariantNo.find((r) =>
    PropertyFilter.isValid(config.properties, r.property_filter)
  );
  
  const itemName = translate(Texts.item_name(productId!, config.properties), "");

  return {
    id: Uuid(),
    productId: props.state.productId!,
    properties: config.properties,
    //showProperties: [],
    calcParams: config.calcParams,
    productCode: (name && name.code) || "",
    itemName: itemName,
    m3: selectedVariant!.m3ItemNo || "",
    variant: (variantNo && variantNo.variant_no) || undefined,
    additionalData: undefined,
    results: calcResult[props.state.productId!],
  };
}

function createConfig(selectedVariant: SelectedVariant, itemConfig: C.ItemConfig): C.ItemConfig {
  return {
    properties: selectedVariant.properties,
    calcParams: itemConfig.calcParams,
    accessories: [],
  };
}

function createProductTable(props: Props, translate: TranslateFunction): React.ReactElement<{}> {
  const { state, tablesForProducts,marketTables } = props;
  const { productId, selectedVariant,ecomProductId, browserLvl1 } = state;
  if (productId === undefined || tablesForProducts === undefined) {
    return <div />;
  }
  return (
    <ProductTable
      items={getProductsEpim(productId, tablesForProducts, ecomProductId, marketTables, translate, browserLvl1)}
      translate={translate}
      addedItems={undefined}
      productId={productId}
      nextSortNo={undefined}
      selectedRowCallBack={selectedRowChangedSelector(props)}
      highLightItemNo={selectedVariant !== undefined ? selectedVariant.m3ItemNo : ""}
      ct_itemNumberStatus={tablesForProducts[productId].ct_ItemNumberStatus}
    />
  );
}

function getProductsEpim(
  productId: string | undefined,
  tablesForProducts: TablesForProducts | undefined,
  ecomProductId: string | undefined,
  marketTables: MarketTablesResponse, 
  translate:TranslateFunction,
  lvl1: string | undefined
): ReadonlyArray<TableRow> {
  if (productId === undefined || tablesForProducts === undefined || ecomProductId === undefined) {
    return [];
  }
  const isExpired = ecomProductId.includes("expired");
  const ecomId = isExpired ? undefined : ecomProductId;
  const tdProductName = isExpired ? ecomProductId.split("_")[1] : undefined;
  const filterHeatType = lvl1 === "45175" ? "Fan heaters" : "Air curtains"

  const allVariants = getFricoDefaultVariantsEpim(tablesForProducts[productId], ecomId, false, marketTables, isExpired);
  return allVariants.reduce((sofar, current) => {
    const orderingCode = PC.getProductCodes(tablesForProducts[productId], current);
    if (isExpired) {
      const itemNumber = tablesForProducts![productId].ct_ItemNo.find((row) =>
        PropertyFilter.isValid(current, row.property_filter)
      );

      const heatType = tablesForProducts![productId].ct_Attributes2.find(
        (t) =>
          t.attribute === "CL-product-type-heat" &&
          t.value === filterHeatType &&
          PropertyFilter.isValid(current, t.property_filter)
      );

      if(heatType === undefined){
        return sofar;
      }
      const itemNumberStatus = tablesForProducts![productId].ct_ItemNumberStatus.find(
        (row) => row.item_no === itemNumber?.item_no
      );

      if (itemNumberStatus && itemNumberStatus.status <= 20) {
        return sofar;
      }

      if(tdProductName !== undefined && !tablesForProducts![productId].ct_Attributes2.some((row) =>
      PropertyFilter.isValid(current, row.property_filter) && row.attribute === "td-product-name" && row.value === tdProductName)){
        return sofar;
      }

    }
    sofar.push({
      itemName: translate(Texts.item_name(productId, current)),
      m3ItemNo: orderingCode.itemNo,
      properties: current,
      variantNo: orderingCode.variantId || "",
    });
    return sofar;
  }, Array<TableRow>());
}


const searchNotFoundSelector = createSelector(
  (p: Props) => p.dispatch,
  (dispatch) => () => dispatch(Actions.searchNotFound())
);

const selectionChangedSelector = createSelector(
  (p: Props) => p.dispatch,
  (dispatch) => (
    lvl1: string | undefined,
    lvl2: string | undefined,
    productId: string | undefined,
    variant: SelectedVariant | undefined,
    ecomProductId: string | undefined
  ) => dispatch(Actions.setSelectedPath(lvl1, lvl2, productId, variant, ecomProductId))
);

const selectedRowChangedSelector = createSelector(
  (p: Props) => p.dispatch,
  (dispatch) => (
    _: string,
    m3ItemNo: string,
    variantNo: string,
    __: number,
    ___: number | undefined,
    ____: string,
    properties: PropertyValueSet.PropertyValueSet
  ) => dispatch(Actions.setSelectedVariant({ m3ItemNo, variantNo, properties }))
);
