import * as React from "react";
import * as ReduxQuery from "shared-lib/redux-query/core";
import { withReduxQuery, MapPropsToQuery, ReduxQueryProp } from "shared-lib/redux-query/react";
import { FuncOrSelf, ComponentDecorator, MapStateToProps, connect } from "react-redux";
import * as QD from "shared-lib/query-diaq";
import { Spinner } from "client-lib/elements";

export function diaqConnect<
  TAtomicQuery extends ReduxQuery.AnyQuery,
  TAtomicResponse,
  TComposedResponse,
  TStateProps,
  TOwnProps,
  TResponse
>(
  mapPropsToQuery: MapPropsToQuery<TAtomicQuery, TOwnProps, TResponse, TAtomicResponse, TComposedResponse>,
  mapStateToProps: FuncOrSelf<MapStateToProps<TStateProps, TOwnProps>>
): ComponentDecorator<TStateProps, TOwnProps> {
  return (
    component: React.ComponentClass<TStateProps> | React.StatelessComponent<TStateProps>
  ): React.ComponentClass<TOwnProps> => {
    const withQuery = withReduxQuery(component, mapPropsToQuery, Spinner, QD.selectAtomicQuery, undefined, mergeProps);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return connect<TStateProps, any, TOwnProps>(mapStateToProps)(withQuery);
  };
}

function mergeProps(ownProps: {}, reduxQueryProp: ReduxQueryProp): {} {
  return { ...ownProps, ...reduxQueryProp.response, reduxQueryProp };
}
