/* eslint-disable no-case-declarations */
import { ComponentResult } from "shared-lib/search";
import * as PU from "shared-lib/product-utils";
import * as Actions from "./actions";
import { Item } from "./types";

export interface Accessory {
  readonly productId: string;
  readonly m3ItemNo: string;
  readonly quantity: number;
}

export interface State {
  readonly productId: string | undefined;
  readonly ecomProductId: string | undefined;
  readonly selectedM3ItemNo: string | undefined;
  readonly searchSelections: PU.Selections | undefined;
  readonly addedItems: ReadonlyArray<Item>;
  readonly addedAccessories: ReadonlyArray<Accessory>;
  readonly browserLvl1: string | undefined;
  readonly browserLvl2: string | undefined;
  readonly searchNotFound: boolean;
  readonly savedCalculations: ReadonlyArray<ComponentResult>;
  readonly calculationExists: boolean;
  readonly projectName: string;
  readonly projectComments: string;
  readonly displayPrint: boolean;
  readonly highLightItemNo: string | undefined;
}

const initialState: State = {
  productId: undefined,
  selectedM3ItemNo: undefined,
  searchSelections: undefined,
  addedItems: [],
  ecomProductId: undefined,
  browserLvl1: undefined,
  browserLvl2: undefined,
  searchNotFound: false,
  savedCalculations: [],
  addedAccessories: [],
  calculationExists: false,
  projectName: "",
  projectComments: "",
  displayPrint: false,
  highLightItemNo: undefined,
};

export function reducer(state: State = initialState, action: Actions.Action): State {
  switch (action.type) {
    case "frico-specification-text-epim/SET_SELECTED_PATH":
      return {
        ...state,
        browserLvl1: action.payload.lvl1,
        browserLvl2: action.payload.lvl2,
        productId: action.payload.productId,
        ecomProductId: action.payload.ecomProductId,
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/SET_SELECTED_VARIANT":
      return {
        ...state,
        selectedM3ItemNo: action.payload,
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/SEARCH_NOT_FOUND":
      return { ...state, searchNotFound: true, calculationExists: false };
    case "frico-specification-text-epim/ADD_VARIANT":
      const calcParams = state.addedItems.length > 0 ? state.addedItems[0].calcParams : undefined;
      return {
        ...state,
        addedItems: [
          ...state.addedItems.filter((i) => i.m3ItemNo !== action.payload.m3ItemNo),
          { ...action.payload, calcParams: calcParams },
        ],
        calculationExists: false,
        highLightItemNo: action.payload.m3ItemNo,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/REMOVE_VARIANT":
      const newItemsList = state.addedItems.filter((i) => i.m3ItemNo !== action.payload.m3ItemNo);
      const selectedStillExists = newItemsList.find((i) => i.m3ItemNo === state.selectedM3ItemNo);
      const addedAccessories = state.addedAccessories.filter(
        (a) => action.payload.accessoriesToRemove.find((r) => r === a.m3ItemNo) === undefined
      );
      const calculations = state.savedCalculations.filter((c) => c.m3 !== action.payload.m3ItemNo);
      return {
        ...state,
        addedItems: newItemsList,
        selectedM3ItemNo: selectedStillExists !== undefined ? selectedStillExists.m3ItemNo : undefined,
        calculationExists: false,
        searchNotFound: false,
        addedAccessories: addedAccessories,
        savedCalculations: calculations,
      };
    case "frico-specification-text-epim/UPDATE_VARIANT": {
      const updatedItems = state.addedItems.map((item) =>
        item.m3ItemNo === action.payload.m3ItemNo ? action.payload : item
      );
      return {
        ...state,
        addedItems: updatedItems,
        calculationExists: false,
        searchNotFound: false,
      };
    }
    case "frico-specification-text-epim/UPDATE_ALL_CALCPARAMS": {
      const updatedItems = state.addedItems.map((item) => ({ ...item, calcParams: action.payload }));
      return {
        ...state,
        addedItems: updatedItems,
        calculationExists: false,
        searchNotFound: false,
      };
    }
    case "frico-specification-text-epim/CALCULATION_EXISTS":
      return { ...state, calculationExists: true, searchNotFound: false };
    case "frico-specification-text-epim/CLEAR_SAVED_CALCULATIONS":
      return {
        ...state,
        savedCalculations: [],
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/SAVE_CALCULATION":
      return {
        ...state,
        savedCalculations: [...state.savedCalculations, action.payload],
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/REMOVE_CALCULATION":
      return {
        ...state,
        savedCalculations: state.savedCalculations.filter((c) => c.id !== action.payload),
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/DISPLAY_PRINT":
      return {
        ...state,
        displayPrint: action.payload,
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/RESET":
      return initialState;
    case "frico-specification-text-epim/UPDATE_PROJECT_NAME":
      return {
        ...state,
        calculationExists: false,
        searchNotFound: false,
        projectName: action.payload,
      };
    case "frico-specification-text-epim/UPDATE_PROJECT_COMMENTS":
      return {
        ...state,
        calculationExists: false,
        searchNotFound: false,
        projectComments: action.payload,
      };
    case "frico-specification-text/GLOBAL_ADD_VARIANTS":
      const existingItems = state.addedItems;
      const filteredItems = action.payload.items.filter(
        (n) => existingItems.find((e) => e.m3ItemNo === n.m3ItemNo) === undefined
      );
      let nextSortNo = existingItems.length === 0 ? 0 : Math.max(...existingItems.map((r) => r.sortNo)) + 1;
      const itemsToAdd = filteredItems.map((r) => {
        return { ...r, sortNo: nextSortNo++, addedFromSearch: true };
      });
      return {
        ...state,
        searchSelections: action.payload.searchSelections,
        addedItems: [...existingItems, ...itemsToAdd],
        calculationExists: false,
        searchNotFound: false,
      };
    case "frico-specification-text-epim/UPDATE_ACCESSORY_QUANTITY":
      const clean = state.addedAccessories.filter((r) => r.m3ItemNo !== action.payload.itemNumber);

      return {
        ...state,
        addedAccessories: [
          ...clean,
          {
            productId: action.payload.productId,
            m3ItemNo: action.payload.itemNumber,
            quantity: action.payload.quantity,
          },
        ],
      };
    default:
      return state;
  }
}
